export const initialState = {
    flag: false,
};

export const DrawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "toggleDrawer":
            return {
                flag: !state.flag,
            };
        default:{
            return {...state}
        }

    }

};
