import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFindMeta } from "../store/actions/metaAction";
const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFindMeta("privacy"));
  }, [dispatch]);
  return (
    <div className="homeMargin">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            width: "100px",
            height: "1px",
          }}
        ></div>
        <h2 style={{ color: "#d6286d" }}>Privacy</h2>
        <h2 style={{ marginLeft: "5px" }}>Policy</h2>
        <div
          style={{
            backgroundColor: "black",
            width: "100px",
            height: "1px",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "50px",
          flexDirection: "column",
          marginRight: "50px",
        }}
      >
        <h6 style={{ color: "#d6286d" }}>Privacy Policy</h6>
        <p style={{ textAlign: "justify" }}>
          For the best of your knowledge please read this privacy policy
          (“Policy”) carefully to understand how we will use and protect your
          Delivery information. As a responsible organisation we will not share
          your Delivery information with anyone except as described in this
          Policy. Our privacy policy is subject to change at any time without
          prior notice. To make sure you are aware of any changes, please review
          this policy periodically.
        </p>
        <p style={{ textAlign: "justify" }}>
          By visiting our Website you agree to be bound by the terms and
          conditions of this Privacy Policy. If you do not agree or comfortable
          please do not use or access our Website.
        </p>
        <p style={{ textAlign: "justify" }}>
          By mere use of the Website, you expressly consent to our use and
          disclosure of your Delivery Information in accordance with this
          Privacy Policy. This Privacy Policy is incorporated into and subject
          to the Terms and Conditions. Questions regarding this Policy should be
          directed to{" "}
          <a href="mailto:support@giftabliss.com">support@giftabliss.com</a>.
        </p>
        <h6 style={{ color: "#d6286d" }}>Collection of Delivery Information</h6>
        <p style={{ textAlign: "justify" }}>
          When you use our website, we collect and store your information which
          is provided by you from time to time. Our primary goal in doing so is
          to provide you a safe, efficient, smooth and customized experience.
          This allows us to provide services and features that most likely meet
          your needs & expecations, and to customize our website to make your
          experience faster, safer and easier. More importantly, while doing so
          we collect Delivery information from you that we consider necessary
          for achieving this purpose.
        </p>
        <p style={{ textAlign: "justify" }}>
          If you choose to buy on the website www.giftabliss.com, we collect
          information about your buying behaviour. If you transact with us, we
          collect some additional information, such as a billing address, other
          payment instrument details and tracking information from cheques or
          money orders etc.
        </p>
        <p style={{ textAlign: "justify" }}>
          If you send us Personal correspondence, such as emails or letters, or
          if other users or third parties send us correspondence about your
          activities or postings on the Website, we may collect such information
          into a file specific to you.
        </p>
        <p style={{ textAlign: "justify" }}>
          We collect Delivery identifiable information (email address, name,
          phone number, other payment instrument details, etc.) from you when
          you set up a free account with us. While you can browse some sections
          of our Website without being a registered member, certain activities
          (such as placing an order) do require registration. We do use your
          contact information to send you offers based on your previous orders
          and your interests.
        </p>
        <p style={{ textAlign: "justify" }}>
          All credit/debit cards’ details and personally identifiable
          information will NOT be stored, sold, shared, rented or leased to any
          other third parties.
        </p>
        <p style={{ textAlign: "justify" }}>
          If your Delivery identifiable information changes, or if you no longer
          desire our service, you may correct, update, or delete by emailing our
          Customer Support at support@giftabliss.com or by contacting us by
          telephone or postal mail at the contact information listed below.
        </p>
        <h6 style={{ color: "#d6286d" }}>Communications from the Site</h6>
        <p style={{ textDecorationLine: "underline" }}>
          Special Offers and Updates
        </p>
        <p style={{ textAlign: "justify" }}>
          We may occasionally send you information on products, services,
          special deals, and promotions. Out of respect for your privacy and
          comfort, we present the option not to receive these types of
          communications.
        </p>
        <p style={{ textDecorationLine: "underline" }}>
          Press Releases and Newsletters
        </p>
        <p style={{ textAlign: "justify" }}>
          If you request to receive our press release(s) or newsletter(s), we
          will use your name and email address to send these documents to you.
          Out of respect for your privacy and comfort, please contact us if you
          subsequently wish to be removed from this mailing list.
        </p>
        <p style={{ textDecorationLine: "underline" }}>
          Service-related Announcements
        </p>
        <p style={{ textAlign: "justify" }}>
          We will send you strictly service-related announcements on rare
          occasions when it is necessary to do so. For instance, if our service
          is temporarily suspended for maintenance, we might send you an email
          advising you of this.
        </p>
        <h6 style={{ color: "#d6286d" }}>Legal Disclaimer</h6>
        <p style={{ textAlign: "justify" }}>
          We may share your information with our other corporate entities and
          affiliates to help detect and prevent identity theft, fraud and other
          potentially illegal acts; correlate related or multiple accounts to
          prevent abuse of our services; and to facilitate joint or co-branded
          services that you request where such services are provided by more
          than one corporate entity. Those entities and affiliates may not
          market to you as a result of such sharing unless you explicitly
          opt-in. We may disclose your information if required to do so by law
          or in the good faith belief that such disclosure is reasonably
          necessary to respond to subpoenas, court orders, or other legal
          process. We may disclose your information to law enforcement offices,
          third party rights owners, or others in the good faith belief that
          such disclosure is reasonably necessary to: enforce our Terms and
          Conditions or Privacy Policy; respond to claims that an advertisement,
          posting or other content violates the rights of a third party; or
          protect the rights, property or Personal safety of our users or the
          general public.
        </p>
        <p style={{ textAlign: "justify" }}>
          If you no longer wish to receive our press releases, newsletter and
          promotional communications, you may opt-out of receiving them by
          following the instructions included in each newsletter or
          communication or by emailing us at{" "}
          <a href="mailto:support@giftabliss.com">support@giftabliss.com</a>.
        </p>
        <h6 style={{ color: "#d6286d" }}>Clear Gifs (Web Beacons/Web Bugs)</h6>
        <p style={{ textAlign: "justify" }}>
          We do not use clear gifs (I.e. Web Beacons/Web Bugs) in our HTML-based
          emails. Clear gifs are tiny graphics with a unique identifier, similar
          in function to cookies, and are used to track the online movements of
          Web users and to let companies know which emails recipients have
          opened, allowing them to gauge the effectiveness of certain
          communications and the effectiveness of their on-line marketing
          campaigns. In contrast to cookies, which are stored on a users
          computer hard drive, clear gifs are embedded invisibly on Web pages
          and are about the size of the period at the end of this sentence.
        </p>
        <h6 style={{ color: "#d6286d" }}>Links to Other Sites</h6>
        <p style={{ textAlign: "justify" }}>
          Our Website links to other websites that may collect Delivery
          identifiable information about you. In such cases GIFTABLISS is not
          responsible for the privacy practices or the content of those linked
          websites.
        </p>
        <h6 style={{ color: "#d6286d" }}>Advertisements on GIFTABLISS</h6>
        <p style={{ textAlign: "justify" }}>
          We use third-party advertising companies to serve ads when you visit
          our Website. Some of the advertisements you see on the Site are
          selected and delivered by third parties, such as ad networks,
          advertising agencies, advertisers, and audience segment providers.
          These third parties may collect information about you and your online
          activities, either on the Site or another websites, through cookies,
          web beacons, and other technologies in an effort to understand your
          interests and deliver to you advertisements that are tailored to your
          interests. Please remember that we do not have access to, or control
          over, the information these third parties may collect. The information
          practices of these third parties are not covered by this privacy
          policy.
        </p>
        <h6 style={{ color: "#d6286d" }}>Security</h6>
        <p style={{ textAlign: "justify" }}>
          The security of your information is extremely important to us. We do
          not use any encryption on our forms, as we do not collect credit card
          numbers, social security numbers or any other sensitive information
          that can be used in identity theft.
        </p>
        <h6 style={{ color: "#d6286d" }}>Business Transitions</h6>
        <p style={{ textAlign: "justify" }}>
          In the event GIFTABLISS goes through a business transition, such as a
          merger, acquisition by another company, or sale of all or a portion of
          its assets, your Delivery identifiable information will be among the
          assets transferred.
        </p>
        <h6 style={{ color: "#d6286d" }}>Changes in this Privacy Statement</h6>
        <p style={{ textAlign: "justify" }}>
          If we decide to change our privacy policy, we will post those changes
          to this privacy statement on the homepage and other places we deem
          appropriate so that you are aware of what information we collect, how
          we use it, and under what circumstances, if any, we disclose it.{" "}
        </p>
        <p style={{ textAlign: "justify" }}>
          We reserve the right to modify this privacy statement at any time, so
          please review it periodically. If we make material changes to this
          policy, we will notify you here, by email, or by means of a notice on
          our home page.
        </p>
        <p style={{ textAlign: "justify" }}>
          The Website Policies and Terms & Conditions may be changed or updated
          occasionally to meet the requirements and standards. Therefore the
          Customers’ are encouraged to frequently visit these sections in order
          to be updated about the changes on the website. Modifications will be
          effective on the day they are posted.
        </p>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
