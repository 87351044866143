import React from "react";
import {
  Toolbar,
  AppBar,
  IconButton,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import makeStyles from "@material-ui/core/styles/makeStyles";
import logo from "../../assets/images/Logo.webp";
import { NavBarActions } from "./TopNav";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router";

const useStyles = makeStyles({
  appBarRoot: {
    backgroundColor: "white",
  },
});

const SecondaryNav = ({ children, header, token, position, orders }) => {
  const location = useLocation();
  const history = useHistory();
  // const [previousRoute,setRoute]= useState(location.pathname)
  const classes = useStyles();
  return (
    <>
      <AppBar
        position={position ? position : "relative"}
        color="primary"
        classes={{ root: classes.appBarRoot }}
      >
        <Toolbar disableGutters>
          {location.pathname !== "/profile/my-account" &&
            location.pathname !== "/contact-us" && (
              <div
                onClick={() => {
                  history.go(-1);
                }}
                style={{ position: "absolute", top: "1px" }}
              >
                <IconButton>
                  <ArrowBackIcon style={{ color: "#B379C5" }} />
                </IconButton>
              </div>
            )}

          <Grid container justify={"space-between"} alignItems={"center"}>
            <Grid item style={{ marginLeft: "46px" }}>
              <Link to={"/"}>
                <img
                  src={logo}
                  className="my-1"
                  alt={"logo"}
                  height="55px"
                  loading="lazy"
                  width="60px"
                />
              </Link>
            </Grid>
            <Grid item>
              <NavBarActions token={token} order={orders} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
const mapStateToProps = (state) => ({
  orders: state?.cart?.orders,
});

export default connect(mapStateToProps)(React.memo(React.memo(SecondaryNav)));
