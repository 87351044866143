import React, { Component } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Grid,
  Icon,
} from "@material-ui/core";
import styles from "./ContactDetails.module.css";
import PhonePopUp from "../PhonePopUp/PhonePopUp";
import { connect } from "react-redux";
import { storeUser } from "../../../store/actions/authActions";
class ContactDetails extends Component {
  render() {
    const { user } = this.props;
    return (
      <ExpansionPanel expanded={false} elevation={2}>
        <ExpansionPanelSummary>
          <Grid container className="expansionH" justify="space-between">
            <div className="row">
              <div
                className={`bg_primary badgeE text-white ${styles.numbering} mr-2`}
              >
                <span className="mx-3">1</span>
              </div>
              <div>
                <h5>{user?.name}</h5>
              </div>
            </div>
            <div className="row">
              <div className="child">
                <Icon className="mr-2">email</Icon> {user?.email}
              </div>
              <div className="child">
                <Icon className="mr-2">smartphone</Icon> {user?.mobile}
              </div>
            </div>
          </Grid>
        </ExpansionPanelSummary>
        {!user.mobile && (
          <PhonePopUp user={user} storeUser={this.props.storeUser} />
        )}
      </ExpansionPanel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user,
    currency: state?.auth?.currency,
    orders: state?.cart?.orders,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    storeUser: (data) => dispatch(storeUser(data, router, false)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);
