import React, { Component, lazy, Suspense } from "react";
import "./ProductsLayout.scss";
import queryString from "query-string";
import {
  Grid,
  Card,
  CardContent,
  Fab,
  AppBar,
  Toolbar,
  createStyles,
  withStyles,
  Typography,
  Tab,
  Tabs,
  Box,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@material-ui/core";
import { titleCase } from "../../../constants/utils";
import { putRequest } from "../../../store/services";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "../../common/Loader";
import Timer from "../../common/Timer";
import { getRequest } from "../../../store/services/index";
import { WhatsApp } from "@material-ui/icons";
import { compose } from "redux";
import AddIcon from "@material-ui/icons/Add";
import MinimizeIcon from "@material-ui/icons/Minimize";
import InfiniteScrollComponent from "./InfiniteScrollComponent";
import { getFindMeta } from "../../../store/actions/metaAction";
// import SecondaryNav from "../../TopNav/SecondaryNav";
const SecondaryNav = lazy(() => import("../../TopNav/SecondaryNav"));
const _ = require("underscore");

const useStyels = createStyles({
  appBarRoot: {
    backgroundColor: "transparent",
  },
  tabSelected: {
    color: "#B379C5",
  },
  tabsRoot: {
    transform: "capitalize",
    color: "black",
  },
  tabIndicator: {
    backgroundColor: "#B379C5",
  },
  paper: {
    backgroundColor: "#F2F2F2",
  },
  accordionSummaryContent: {
    justifyContent: "center",
  },
  accordionDetails: {
    display: "inline-block",
    backgroundColor: "#F2F2F2",
    "& h4": {
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
});

class ProductsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      category: "",
      price: "",
      date: new Date(),
      // loader
      showLoading: false,
      pType: null,
      currencyRates: {},
      prevCurrency: "",
      others: {},
      page: 1,
      totalCount: null,
      sortedBy: "Recommended",
      content: "",
      sortData: [],
      tempLimit: 20,
      isSortHandled: false,
      isOuter: false,
      isMount: false,
    };
  }

  componentDidUpdate(prevProps, preState) {
    if (
      this.props.location !== prevProps.location ||
      this.props.location.search !== prevProps.location.search ||
      this.state.page !== preState.page ||
      this.props?.navigations.length !== prevProps.navigations.length
    ) {
      if (
        this.props.location !== prevProps.location ||
        this.props.location.search !== prevProps.location.search ||
        this.props?.navigations.length !== prevProps.navigations.length
      ) {
        this.setState({ isOuter: true, page: 1, products: [] }, () => {
          this.getData(this.state.others);
          let { pathname } = this.props.location;
          this.props.metaApi(pathname.substring(1));
        });
      }
      if (this.state.isSortHandled) {
        if (this.state.page !== 1) {
          this.sortHandler("", this.state.sortedBy);
        }
      } else {
        this.setState({ isSortHandled: false });
        // this.getData(this.state.others);
      }
    }
  }

  componentDidMount() {
    let { pathname } = this.props.location;
    this.props.metaApi(pathname.substring(1));
    this.getData();
    this.setState({ currencyRates: this.props.currencyRates, sortedBy: "" });
  }

  sortHandler = async (event, newValue) => {
    let sortData = this.state.sortData?.products;
    let page = this.state.page;
    if (this.state.sortedBy !== newValue) {
      sortData = [];
      page = 1;
    }
    // this.setState({ sortedBy: newValue });
    try {
      this.setState({
        showLoading: false,
        isSortHandled: true,
        sortedBy: newValue,
      });
      let { qs } = queryString.parse(this.props.location.search);
      let { pathname } = this.props?.location;
      let id = pathname?.substr(1);
      let body = {};
      if (id) {
        // body.categories = [id];
        body.category = id;
      }
      if (qs) {
        body.title = qs;
      }
      const res = await axios.put(
        // `${process.env.REACT_APP_BACKEND_URL}products/searchBarBySlug?limit=${
        `${process.env.REACT_APP_BACKEND_URL}products/searchBySlug?limit=${
          this.state.tempLimit
        }&page=${sortData?.length === 0 ? 1 : page}`,
        {
          ...body,
          ...this.state.others,
          sort: newValue,
          // selectedCurrency: this.props.selectedCurrency,
        }
      );

      if (res) {
        this.setState({
          products:
            sortData?.length === 0
              ? res?.data?.data
              : {
                  products: [
                    ...this.state.products?.products,
                    ...res?.data?.data?.products,
                  ],
                },
          showLoading: false,
          sortData: res?.data?.data,
          page: sortData?.length === 0 ? 1 : page,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getData = async (others = {}, prod = false, pg = false) => {
    // this.setState({ showLoading: true });
    let tempProduct = this.state.products;
    let page = this.state.page;
    if (others?.price) {
      if (prod && pg) {
        tempProduct = [];
        page = 1;
      }
    }
    this.setState({ others });

    // var mainCats = this.props?.mainCats && JSON.parse(this.props?.mainCats);
    let mainCats = this.props?.navigations;
    let limit = 1000;
    let url =
      "products/searchBySlug?limit=" + this.state.tempLimit + "&page=" + page;
    let category = "";
    let { catalog, pcategory, cCategory, ccCategory } = this.props.match.params;
    if (ccCategory === undefined) {
      category = pcategory;
    } else {
      if (cCategory) {
        category = cCategory;
      } else {
        category = pcategory;
      }
    }
    let { qs } = queryString.parse(this.props.location.search);
    // var catId = this.props.location.hash.replace("#", "");
    let { pathname } = this.props?.location;
    // let filterPath = pathname?.split("/")
    let catId = pathname?.substr(1);
    if (!catId && window.location.pathname !== "/search") {
      let that = this;
      setTimeout(function () {
        that.props.history.push("/404");
      }, 2000);
    }
    let body = { category: catId };
    let searched = mainCats;
    if (qs || others?.price) {
      url = "products/searchBySlug?limit=" + limit;
      // url = "products/searchBarBySlug?limit=" + limit;
      // var cats = catId ? [catId] : undefined;
      // others = { ...others, categories: cats };
      others = {
        ...others,
        category: catId || undefined,
        sort: this.state.sort || undefined,
      };
      body = {
        title: qs,
        ...others,
        // selectedCurrency: this.props.selectedCurrency,
      };
      searched = mainCats;
      this.setState({ pType: null });
    } else if (catId) {
      searched = _.where(mainCats, { _id: catId });
      let parentC = null;
      if (searched.length < 1) {
        if (this.state?.products?.length < 1) {
          await getRequest(`categories/findBySlug?slug=${catId}`)
            .then((res) => {
              parentC = res?.data?.parentCategory?._id;
              this.setState({
                pType: res?.data?.name,
                category: res.data,
                content: res?.data?.content,
                expanded: res?.data?.content ? false : true,
              });
            })
            .catch((error) => console.error(error));
        }
        if (parentC) {
          searched = _.where(mainCats, { slug: parentC });
        }
      }
    }
    this.setState({ productTypes: searched });
    putRequest(url, body, false)
      .then((response) => {
        this.updateData(response, catalog, category, tempProduct);
        this.setState({ isMount: true });
      })
      .catch((err) => this.updateData({}));
  };

  updateData = (response, catalog, category, tempProduct) => {
    this.setState({
      products: response?.data
        ? this.state.isOuter
          ? response?.data
          : tempProduct?.products
          ? {
              ...tempProduct,
              products: [...tempProduct?.products, ...response?.data?.products],
            }
          : response?.data
        : [],
      // category: response.data?.[0]?.category,
      catName: category,
      totalCount: response?.data?.count,
      catalog: catalog,
      showLoading: false,
      isMount: true,
      // content: response?.data?.content,
    });
  };
  manageAccordionIcon = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  fetchMoreData = () => {
    // this.setState({ page: this.state.page + 1, isOuter: false })
    if (this.state.isMount) {
      this.setState({ page: this.state.page + 1, isOuter: false }, () => {
        if (this.state.isSortHandled) {
          // this.sortHandler(this.state.sort)
        } else {
          this.getData(this.state.others);
        }
      });
    }
    // this.getData()
  };
  render() {
    const { pcategory } = this.props.match.params;
    const categoryName = titleCase(pcategory ? pcategory : "");
    const prods = this.state.products;
    const { classes } = this.props;

    return (
      <div className="homeMargin">
        <Loader open={this.state.showLoading} />

        <a
          href="https://wa.me/971555231077"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Fab className="whatsApp">
            <WhatsApp fontSize="large" />
          </Fab>
        </a>

        {/*1st row*/}
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          className="mt-5"
        >
          <div className="">
            <Timer />
          </div>

          {categoryName && (
            <Typography
              variant="h1"
              align={"center"}
              style={{
                position: "relative",
                zIndex: "-1",
                width: "100%",
                fontSize: "3rem",
              }}
            >
              {categoryName}
            </Typography>
          )}
          {/*{<p>{window.location.href}</p>}*/}

          <AppBar
            elevation={0}
            classes={{ root: classes.appBarRoot }}
            position={"fixed"}
          >
            <Toolbar disableGutters>
              <Grid direction={"column"} container>
                <Grid item>
                  <Suspense
                    fallback={<p style={{ textAlign: "center" }}>Loading…</p>}
                  >
                    <SecondaryNav />
                  </Suspense>
                </Grid>
                <Grid container item style={{ position: "relative" }}>
                  <Grid container item direction={"row"} id={"hide-onScrollY"}>
                    <Grid item xs={3}>
                      <Card square elevation={3} style={{ height: "100%" }}>
                        <CardContent style={{ padding: "4%" }}>
                          <Typography variant={"body1"} align={"left"}>
                            Sort by:
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={9}>
                      <Tabs
                        scrollButtons={"auto"}
                        classes={{
                          indicator: classes.tabIndicator,
                          root: classes.tabsRoot,
                        }}
                        variant={"scrollable"}
                        value={this.state.sortedBy}
                        color={"primary"}
                        label="Sort by"
                        onChange={this.sortHandler}
                      >
                        <Tab
                          classes={{ selected: classes.tabSelected }}
                          id={"0"}
                          label={"Recommended"}
                          value={"Recommended"}
                        />
                        <Tab
                          classes={{ selected: classes.tabSelected }}
                          id={"1"}
                          label={"New"}
                          value={"New"}
                        />
                        <Tab
                          classes={{ selected: classes.tabSelected }}
                          id={"2"}
                          label={"Price: Low to High"}
                          value={"Price: Low to High"}
                        />
                        <Tab
                          classes={{ selected: classes.tabSelected }}
                          id={"3"}
                          label={"Price: High to Low"}
                          value={"Price: High to Low"}
                        />
                      </Tabs>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>

        <InfiniteScrollComponent
          prods={prods}
          products={this.state.products}
          totalCount={this.state.totalCount}
          fetchMoreData={this.fetchMoreData}
          catName={this.state.catName}
          category={this.state.category}
          currencyRates={this.state.currencyRates}
          selectedCurrency={this.props.selectedCurrency}
          catalog={this.state.catalog}
          isMount={this.state.isMount}
        />
        {this.state.content && (
          <Accordion
            className="mx-4 mt-4 home-page"
            style={{ textAlign: "justify", marginTop: "50px" }}
            elevation={0}
            expanded={this.state.expanded === true}
          >
            <AccordionSummary
              onClick={this.manageAccordionIcon}
              classes={{
                content: classes.accordionSummaryContent,
                root: { maxHeight: "50px" },
              }}
              expandIcon={
                this.state.expanded === false ? <AddIcon /> : <MinimizeIcon />
              }
            >
              <Typography variant="body1"> More Info</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
              {
                <span
                  dangerouslySetInnerHTML={{ __html: this.state.content }}
                ></span>
              }
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // mainCats: state?.others?.mainCats,
  navigations: state?.navigations?.navigations,
  selectedCurrency: state?.auth?.currency,
  currencyRates: state?.others?.currencyRates,
});
const mapDispatchToProps = (dispatch, router) => {
  return {
    metaApi: (data) => dispatch(getFindMeta(data)),
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyels)
)(withRouter(React.memo(ProductsLayout)));
