import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
class Paypal extends React.Component {
    render() {
        const {
            amount,
            onSuccess,
            currency,
            onCreate,
            onCancel,
            onButtonReady,
        } = this.props;
        return (
            <PayPalButton
                // amount={amount}
                // currency={currency}
                onCancel={(data) => onCancel(data)}
                onButtonReady={() => onButtonReady()}
                locale="en_AE"
                createOrder={(data, action) => onCreate(data, action)}
                onSuccess={(details, data) => onSuccess(details, data)}
                options={{
                    clientId:
                        "AWJmM0vBaXdyOVqAoSDME5__e5BgBv49Vi_ZMiyXSdOdSjCWWa4pY0scOaDfePAPBTPPrJKRkoUvfjCt",
                    disableFunding: "credit,card",
                }}
            />
        );
    }
}
export default Paypal;
