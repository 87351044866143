export const currencies = [
  { value: 0, short: "USD", long: "United States Dollar" },
  { value: 0, short: "INR", long: "Indian Rupee" },
  { value: 0, short: "GBP", long: "British Pound" },
  { value: 0, short: "EUR", long: "Euro" },
  { value: 0, short: "AUD", long: "Australian Dollar" },
  { value: 0, short: "OMR", long: "Omani Rial" },
  { value: 0, short: "KWD", long: "Kuwaiti Dinar" },
  { value: 0, short: "CAD", long: "Canadian Dollar" },
  { value: 0, short: "BHD", long: "Bahrain Dinar" },
  { value: 0, short: "AED", long: "United Arab Emirates Dirham" },
];
export const shippingTypes = [
  { label: "1 Hour Delivery", price: 90, val: "1" },
  { label: "Morning Delivery", price: 59, val: "2" },
  { label: "Standard-Delivery", price: 49, val: "3" },
];
export const defaultCurrency = "AED";
