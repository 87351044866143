import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import "react-calendar/dist/Calendar.css";
import "./styles.scss";
import { Carousel } from "react-bootstrap";
import {
  createOrder,
  getActiveOrders,
  getOtherProductData,
  getOtherProductPrices,
} from "../../store/actions/cartActions";
import moment from "moment";
import { getRequest, postRequest } from "../../store/services/index";
import { saveAddons } from "../../store/actions/othersActions";
import { validate } from "../../store/actions/authActions";
import {
  Box,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Grid,
  AppBar,
  Tabs,
  Tab,
  createStyles,
} from "@material-ui/core";
import { compose } from "redux";
import Loader from "../common/Loader";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import { getFindMeta } from "../../store/actions/metaAction";
const Addons = lazy(() => import("./Addons/Addons"));
const DeliveryInfo = lazy(() => import("../common/DeliveryInfo"));
const YouMayAlsoLike = lazy(() => import("./YouMayAlsoLike/YouMayAlsoLike"));
const WhatOthersAreViewing = lazy(() =>
  import("./YouMayAlsoLike/WhatOthersAreViewing")
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      className={"mt-1"}
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const useStyels = createStyles({
  appBarRoot: {
    backgroundColor: "transparent",
  },
  tabSelected: {
    color: "#B379C5",
  },
  tabsRoot: {
    transform: "capitalize",
    color: "black",
    borderBottom: "1px solid #D5D5D5",
  },
  tabIndicator: {
    backgroundColor: "#B379C5",
  },
});

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: "",
      selectedImage: null,
      destinationOpen: false,
      details: {},
      images: [],
      city: null,
      error: null,
      showAddOn: false,
      price: "",
      type: "cart",
      showLoading: false,
      addons: [],
      openDeliveryInfo: false,
      cities: [],
      selectedCity: {},
      finalDeliveryDate: {},
      message: "",
      image: "",
      selected: "",
      selectedValue: "",
      eggOption: "",
      eggless: "",
      typeFeatures: "",
      show: false,
      partyId: "",
      deliveryInfo: "",
      careInfo: "",
      description: "",
      productSku: "",
      currencyRates: {},
      modalState: false,
      tabValue: "Description",
      productTitle: "",
      productId: "",
      pricesData: {},
      catLink: "",
      relevance: "",
    };
  }

  inputHandler = async (event) => {
    const file = event.target.files[0];
    const fileType = file.type;
    this.setState({ show: false });

    if (fileType !== "image/jpeg" && fileType !== "image/png") {
      this.setState({ file: "", show: true });
    } else {
      this.setState({ file });
      let formData = new FormData();
      formData.append("image", this.image.files[0]);
      let image = await postRequest("cart/uploadImage", formData);
      let location = image.data;
      if (location) {
        this.setState({ image: location });
        let productDetails = this.props.productDetails;
        productDetails.image = location;

        this.props.saveSelected(productDetails);
      }
    }
  };

  handleFeatureSelect = async () => {
    let { selected, typeFeatures, eggOption } = this.state;
    let products = this.state.details.product?.virtualProducts;

    for (let i = 0; i < products?.length; i++) {
      let product = products[i];
      let features = product.Feature;
      const fleavourFeatures = features?.find(
        (feature) => feature.Type.id == "DROPDOWN"
      );
      const sizeFeatures = features?.find(
        (feature) => feature.Type.id == "SIZE"
      );
      const egglessFeatures = features?.find(
        (feature) => feature.Type.id == "RADIO"
      );
      let flag = false;
      if (selected) {
        if (selected !== sizeFeatures._id) {
          flag = true;
        }
      }
      if (eggOption) {
        if (eggOption !== egglessFeatures._id) {
          flag = true;
        }
      }
      if (typeFeatures) {
        if (typeFeatures !== fleavourFeatures._id) {
          flag = true;
        }
      }
      if (!flag) {
        let productId = product._id;
        this.setState({ showLoading: true });
        let data = await this.props.getOtherProductData(productId);

        // let price = product.Price[0].price;
        let webPrice = data.Price.find(
          (onePrice) => onePrice.priceType === "WEB_PRICE"
        );

        let price = 0;
        if (webPrice) {
          let pCurrency = webPrice.currencyUom;
          if (this.props.currency === pCurrency) {
            price = webPrice.price;
          } else {
            price = parseFloat(
              (
                (webPrice.price / this.state.currencyRates[pCurrency]) *
                this.state.currencyRates[this.props.currency]
              ).toFixed(2)
            );
          }
        } else {
          let listPrice = data.Price.find(
            (onePrice) => onePrice.priceType === "LIST_PRICE"
          );
          if (listPrice) {
            let pCurrency = listPrice.currencyUom;
            if (this.props.currency === pCurrency) {
              price = listPrice.price;
            } else {
              price = parseFloat(
                (
                  (listPrice.price / this.state.currencyRates[pCurrency]) *
                  this.state.currencyRates[this.props.currency]
                ).toFixed(2)
              );
            }
          }
        }
        let productSku = this.state.productSku;
        if (data.productIdentifications) {
          let productIdentifications = data?.productIdentifications;
          if (productIdentifications) {
            let sku = productIdentifications.filter(
              (id) => id.idType === "SKU"
            );

            if (sku.length > 0) {
              productSku = sku[0].idValue;
            }
          }
        }

        let title = data.title;
        let contents = product.contents;
        let deliveryInfo = "";
        let careInfo = "";
        let description = "";
        if (data.contents) {
          let descriptionObj = data.contents.find(
            (one) => one.contentType === "LONG_DESCRIPTION"
          );
          if (descriptionObj) {
            description = descriptionObj.text;
          }
        }
        if (contents) {
          let deliveryInfoObj = contents.find(
            (one) => one.contentType === "DELIVERY_INFO"
          );
          let careInfoObj = contents.find(
            (one) => one.contentType === "CARE_INSTRUCTIONS"
          );

          if (deliveryInfoObj) {
            deliveryInfo = deliveryInfoObj.text;
          }
          if (careInfoObj) {
            careInfo = careInfoObj.text;
          }
        }
        await this.setState({
          showLoading: false,
          productTitle: title,
          price,
          description,
          productId,
          productSku,
        });
        break;
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currency !== this.props.currency) {
      let pCurrency = prevProps.currency;

      let price = parseFloat(
        (
          (this.state.price / this.state.currencyRates[pCurrency]) *
          this.state.currencyRates[this.props.currency]
        ).toFixed(2)
      );
      let finalDeliveryDate = {};
      if (this.state.finalDeliveryDate?.price) {
        let shippingPrice = parseFloat(
          (
            (this.state.finalDeliveryDate?.price /
              this.state.currencyRates[pCurrency]) *
            this.state.currencyRates[this.props.currency]
          ).toFixed(2)
        );
        finalDeliveryDate = {
          ...this.state.finalDeliveryDate,
          price: shippingPrice,
        };
      }
      this.setState({ price: price, finalDeliveryDate });
      let productDetails = this.props.productDetails;
      productDetails.currency = this.props.currency;
      productDetails.price = price;
      productDetails.finalDeliveryDate = finalDeliveryDate;
      this.props.saveSelected(productDetails);
    }
  }

  saveOrignalAmounts = (carts) => {
    for (let i = 0; i < carts.length; i++) {
      if (!carts[i].orignalAmount) {
        carts[i].orignalAmount = carts[i].amount;
      }
      let amount = parseFloat(
        (
          (carts[i].orignalAmount /
            this.state.currencyRates[carts[i].currency]) *
          this.state.currencyRates[this.props.currency]
        ).toFixed(2)
      );
      carts[i].amount = amount;

      let shippingPrice = parseFloat(
        (
          (carts[i].finalDeliveryDate.shippingOrignal /
            this.state.currencyRates["AED"]) *
          this.state.currencyRates[this.props.currency]
        ).toFixed(2)
      );

      carts[i].shippingPrice = shippingPrice;

      for (let j = 0; j < carts[i].addons.length; j++) {
        if (!carts[i].addons[j].orignalAmount) {
          carts[i].addons[j].orignalAmount = carts[i].addons[j].amount;
        }
        let amount = parseFloat(
          (
            (carts[i].addons[j].orignalAmount /
              this.state.currencyRates[carts[i].currency]) *
            this.state.currencyRates[this.props.currency]
          ).toFixed(2)
        );
        carts[i].addons[j].amount = amount;
      }
    }

    return carts;
  };

  calculate(cart) {
    //let amount = order?.amount;
    let totalProductAmount = 0;
    let totalShipping = 0;
    let carts = JSON.parse(JSON.stringify(cart));
    let arrayDates = [];
    if (carts) {
      for (let i = 0; i < carts.length; i++) {
        let filetered = arrayDates.filter(
          (date) =>
            date === moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
        );
        if (filetered.length > 0) {
        } else {
          arrayDates.push(
            moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
          );
        }
      }
    }
    if (arrayDates.length > 0) {
      for (let j = 0; j < arrayDates.length; j++) {
        let dateCarts = carts.filter(
          (cart) =>
            moment(cart.shippingDate).utc(true).format("Y-MM-DD") ===
            arrayDates[j]
        );
        let addressCarts;
        addressCarts = dateCarts;

        let timeSlotsArray = [];
        for (let n = 0; n < addressCarts.length; n++) {
          let timeCarts = timeSlotsArray.filter(
            (timeSlot) => timeSlot === addressCarts[n].shippingTime
          );
          if (timeCarts.length > 0) {
          } else {
            timeSlotsArray.push(addressCarts[n].shippingTime);
          }
        }
        for (let l = 0; l < timeSlotsArray.length; l++) {
          let timeCarts = addressCarts.filter(
            (addressCart) => addressCart.shippingTime === timeSlotsArray[l]
          );
          let amount = 0;
          let productIds = "";
          let shippingPrice = 0;
          let productDescription = "";
          for (let r = 0; r < timeCarts.length; r++) {
            if (shippingPrice < timeCarts[r].shippingPrice) {
              shippingPrice = timeCarts[r].shippingPrice;
            }
            amount = amount + timeCarts[r].amount * timeCarts[r].quantity;
            productIds = productIds + timeCarts[r].products[0]._id + ",";
            productDescription =
              productDescription + timeCarts[r].products[0].description;
            if (timeCarts[r].addons.length > 0) {
              for (let s = 0; s < timeCarts[r].addons.length; s++) {
                amount =
                  amount +
                  timeCarts[r].addons[s].amount *
                    timeCarts[r].addons[s].quantity;
                productIds =
                  productIds + timeCarts[r].addons[s].products[0]._id + ",";
                productDescription =
                  productDescription +
                  timeCarts[r].addons[s].products[0].description;
              }
            }
          }
          totalProductAmount = totalProductAmount + amount;
          totalShipping = totalShipping + shippingPrice;
        }
        // }
      }
    }

    return { totalProductAmount, totalShipping };
  }

  async componentDidMount() {
    this.setState({ showLoading: true });
    this.props.d();
    let { pathname } = this.props.location;
    let filterPath = pathname?.split("/");

    // var id = this.props.location.hash;
    let pId = filterPath?.[filterPath?.length - 1];
    this.props.metaApi(pId);
    if (!pId) {
      this.props.history.push("/404");
    }
    await getRequest(
      `products/findBySlug?slug=${pId}&admin=false&detailsPage=${true}`,
      {},
      false
    )
      .then(async (res) => {
        let pricesData = await this.props.getOtherProductPrices(pId);
        this.setState({ pricesData });
        let productSku = "";
        let contents = res.data?.product?.contents;
        let productIdentifications = res.data?.product?.productIdentifications;
        if (productIdentifications) {
          let sku = productIdentifications.filter((id) => id.idType === "SKU");

          if (sku.length > 0) {
            productSku = sku[0].idValue;
          }
        }
        let deliveryInfo = "";
        let careInfo = "";
        let description = "";
        if (contents) {
          let deliveryInfoObj = contents.find(
            (one) => one.contentType === "DELIVERY_INFO"
          );
          let careInfoObj = contents.find(
            (one) => one.contentType === "CARE_INSTRUCTIONS"
          );
          let descriptionObj = contents.find(
            (one) => one.contentType === "LONG_DESCRIPTION"
          );
          if (deliveryInfoObj) {
            deliveryInfo = deliveryInfoObj.text;
          }
          if (careInfoObj) {
            careInfo = careInfoObj.text;
          }
          if (descriptionObj) {
            description = descriptionObj.text;
          }
        }
        this.setState({
          productId: res.data?.product?._id,
          productTitle: res.data?.product?.title,
        });
        if (res.data.product.Feature.length) {
          if (res?.data?.product?.virtualProducts?.length) {
            let p = res.data.product.virtualProducts[0];
            let features = p.Feature;
            const fleavourFeatures = features?.find(
              (feature) => feature.Type.id == "DROPDOWN"
            );
            const sizeFeatures = features?.find(
              (feature) => feature.Type.id == "SIZE"
            );
            const egglessFeatures = features?.find(
              (feature) => feature.Type.id == "RADIO"
            );
            let { selected, typeFeatures, eggOption } = this.state;
            if (fleavourFeatures) {
              typeFeatures = fleavourFeatures._id;
            }
            if (sizeFeatures) {
              selected = sizeFeatures._id;
            }
            if (egglessFeatures) {
              eggOption = egglessFeatures._id;
            }
            this.setState({ selected, typeFeatures, eggOption });
          }
        }
        getRequest("cities/get/" + res.data.product?.metaData?.country)
          .then(async (city) => {
            let ress = await getRequest("currencies/getCurrency");
            if (ress.data?.currencyRates) {
              this.setState({ currencyRates: ress.data.currencyRates });
            }
            let priceOfProduct = 0;
            let webPrice = res.data.product?.Price.find(
              (p) => p.priceType === "WEB_PRICE"
            );
            if (webPrice) {
              let pCurrency = webPrice.currencyUom;
              if (this.props.currency === pCurrency) {
                priceOfProduct = webPrice.price;
              } else {
                priceOfProduct = parseFloat(
                  (
                    (webPrice.price / this.state.currencyRates[pCurrency]) *
                    this.state.currencyRates[this.props.currency]
                  ).toFixed(2)
                );
              }
            } else {
              let listPrice = res.data.product?.Price.find(
                (p) => p.priceType === "LIST_PRICE"
              );
              if (listPrice) {
                let pCurrency = listPrice.currencyUom;
                if (this.props.currency === pCurrency) {
                  priceOfProduct = listPrice.price;
                } else {
                  priceOfProduct = parseFloat(
                    (
                      (listPrice.price / this.state.currencyRates[pCurrency]) *
                      this.state.currencyRates[this.props.currency]
                    ).toFixed(2)
                  );
                }
              }
            }
            // let priceOfProduct = res.data.product?.Price[0]?.price;
            let selected = "";
            const features = res.data?.product?.Feature;
            const sizeFeatures = features?.filter(
              (feature) => feature.Type.id === "SIZE"
            );
            if (sizeFeatures.length > 0) {
              selected = sizeFeatures[0]._id;
              let price = res.data.product.Price.filter(
                (onePrice) => onePrice.featureId === selected
              );
              if (price.length > 0) {
                let pCurrency = price[0].currencyUom;
                if (this.props.currency === pCurrency) {
                  priceOfProduct = priceOfProduct + price[0].price;
                } else {
                  priceOfProduct =
                    priceOfProduct +
                    parseFloat(
                      (
                        (price[0].price / this.state.currencyRates[pCurrency]) *
                        this.state.currencyRates[this.props.currency]
                      ).toFixed(2)
                    );
                }
              }
            }
            await this.setState({
              details: res.data,
              selectedImage: res.data?.product.images[0],
              showLoading: false,
              price: priceOfProduct,
              selected: selected,
              images: res.data.product.images,
              cities: city.data,
              selectedCity: city.data[0],
              city: city.data?.[0].name,
              deliveryInfo,
              careInfo,
              description,
              productSku,
              relevance: res.data.product.relevance,
            });
            this.handleFeatureSelect();
            let temp = {};
            let productDetails = JSON.parse(
              localStorage.getItem("productDetails")
            );

            if (productDetails) {
              temp = productDetails;
            } else {
              temp = this.props.productDetails;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  render() {
    const {
      selectedImage,
      details,
      city,
      error,
      selectedDate,
      openDeliveryInfo,
      finalDeliveryDate,
      type,
      images,
    } = this.state;
    const { currency, classes } = this.props;
    const { category } = this.props.match.params;
    var upgrades = [];
    const features = details?.product?.Feature;
    const fleavourFeatures = features?.filter(
      (feature) => feature.Type.id == "DROPDOWN"
    );
    const sizeFeatures = features?.filter(
      (feature) => feature.Type.id == "SIZE"
    );
    const egglessFeatures = features?.filter(
      (feature) => feature.Type.id == "RADIO"
    );
    let catname = "";
    if (category) {
      catname = category;
    } else {
      catname = this.state.details?.product?.CategoriesArray?.[0]?.name;
    }
    return (
      <React.Fragment>
        {details?.product?._id && this.state.showAddOn && (
          <Suspense fallback={<p style={{ textAlign: "center" }}>Loading…</p>}>
            <Addons
              city={city}
              finalDeliveryDate={finalDeliveryDate}
              selectedDate={selectedDate}
              type={type}
              user={this.props.user}
              onClosePopup={() => this.onClosePopup()}
              popUp={this.state.showAddOn}
              handleNavigation={(type) => this.handleNavigation(type)}
              currentProduct={details?._id}
              addons={this.state.addons}
              recentlyAdded={this.props.recentlyAdded}
            />
          </Suspense>
        )}
        <main>
          <Loader open={this.state.showLoading} />
          <div className="mt-2 productDetails">
            <Grid container direction={"column"} justify={"center"}>
              {/*Top section starts from here*/}
              <Grid
                direction={"column"}
                alignItems={"center"}
                container
                item
                style={{ backgroundColor: "white", marginBottom: "5px" }}
              >
                {/*Image section*/}
                <Grid item className={"w-100"}>
                  <Carousel>
                    {images.map((image, index) => {
                      return (
                        <Carousel.Item interval={2000}>
                          <img
                            src={image}
                            loading="lazy"
                            alt={this.state.productTitle + " " + catname}
                            width={"100%"}
                            height={"auto"}
                            key={index}
                            className={image === selectedImage ? "active" : ""}
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </Grid>
                {/*Image section ends here*/}

                {/*Details section starts from here*/}

                <Grid item container direction={"column"}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <div
                      style={{ alignItems: "center", display: "flex" }}
                      className={"ml-3"}
                    >
                      <span>{this.state.productTitle}</span>
                    </div>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"end"}
                    >
                      <div className="flexRow mr-3">
                        <h5 className="font-red">{currency}</h5>
                        <h5 className="font-red pl-3">{this.state?.price}</h5>
                      </div>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  direction={"column"}
                  alignItems={"center"}
                  container
                  item
                  xs
                >
                  <Grid
                    direction={"row"}
                    justify={"center"}
                    container
                    item
                    xs={9}
                  >
                    {sizeFeatures?.map((one) => (
                      <Grid
                        item
                        container
                        justify={"center"}
                        alignItems={"center"}
                        direction={"column"}
                        style={{
                          marginleft: "10px",
                          marginRight: "10px",
                          marginTop: "10px",
                        }}
                        xs
                      >
                        <img
                          onClick={async (e) => {
                            await this.setState({ selected: one._id });
                            this.handleFeatureSelect("top", one);
                          }}
                          loading="lazy"
                          alt={this.state.productTitle}
                          src={details?.product?.images[0]}
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "auto",
                            border: `${
                              this.state.selected === one._id
                                ? "3px solid"
                                : "0px"
                            }`,
                            borderColor: `${
                              this.state.selected === one._id ? "green" : ""
                            }`,
                          }}
                        />
                        <span>{one.id}</span>
                        <span>{this.getPrice(one._id)}</span>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                {upgrades.length ? (
                  <div className="upgrade">
                    <h5>Pick an upgrade</h5>
                    <div className="flexRow">
                      {upgrades.map((upgrade, key) => {
                        return (
                          <div className="flexCol _item text-center" key={key}>
                            <img
                              loading="lazy"
                              src={upgrade.image}
                              alt="Upgrades"
                            />
                            <h6 className="m-0 mt-1">{upgrade.size}</h6>
                            <h6 className="mt-1 font-red font-weight-bold">
                              {currency} {upgrade.price}
                            </h6>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </Grid>
              {/* Top section ends here*/}

              <Grid container className="selectP p-3" direction={"column"}>
                <p style={{ fontSize: "16px" }}>Deliver to </p>
                <Grid
                  xs
                  item
                  className={"select " + (error === "city" ? "error" : "")}
                  onClick={() => {
                    this.handelBoolean("destinationOpen");
                    this.setState({
                      openDeliveryInfo: false,
                      finalDeliveryDate: {},
                      selectedDate: "",
                      partyId: "",
                      modalState: true,
                    });
                  }}
                >
                  <label>{city ? city : "Deliver To ?"}</label>
                </Grid>
                <Modal
                  open={this.state.modalState}
                  onBackdropClick={() => this.setState({ modalState: false })}
                  onClose={() => this.setState({ modalState: false })}
                >
                  <div
                    className="myModal"
                    style={{
                      top: `50%`,
                      left: `50%`,
                      transform: `translate(-50%, -50%)`,
                    }}
                    tabIndex="-1"
                    role="dialog"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Select City</h5>
                          <button
                            onClick={() => this.setState({ modalState: false })}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <ul className={"list-group " + "open"}>
                            {this.state.cities.map((city, key) => {
                              return (
                                <li
                                  onClick={() => {
                                    this.handelCityChange("city", city);
                                    this.setState({ modalState: false });
                                  }}
                                  key={key}
                                  className="list-group-item"
                                  style={{ zIndex: 20 }}
                                >
                                  {city.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Grid
                  item
                  xs
                  style={{ marginTop: "10px" }}
                  className={"select " + (error === "schedule" ? "error" : "")}
                  onClick={() => {
                    this.setState({ openDeliveryInfo: true });
                  }}
                >
                  <label>
                    <SelectedDate
                      {...{ ...finalDeliveryDate, currency: currency }}
                    />
                  </label>
                </Grid>

                {this.state.city && this.state.openDeliveryInfo && (
                  <Suspense
                    fallback={<p style={{ textAlign: "center" }}>Loading…</p>}
                  >
                    <DeliveryInfo
                      currency={currency}
                      city={this.state.selectedCity?._id}
                      visible={openDeliveryInfo}
                      methods={this.state.details.product}
                      onFinal={(data) => this.closeDeliveryInfo(data)}
                      onCloseDeliveryInfo={() => this.closeDeliveryInfoBox()}
                      currencyRates={this.state.currencyRates}
                      shippmentMethodIds={details?.product?.shippmentMethodIds}
                    />
                  </Suspense>
                )}

                {details?.product?.messageOption && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      style={{ width: "100%" }}
                      maxLength={25}
                      inputProps={{ maxLength: 25 }}
                      value={this.state.message}
                      onChange={(e) => {
                        this.setState({ message: e.target.value });
                        let productDetails = this.props.productDetails;
                        productDetails.message = e.target.value;
                        this.props.saveSelected(productDetails);
                      }}
                      label="Message"
                      variant="outlined"
                    />
                    {error === "message" && (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        Please add message
                      </p>
                    )}
                  </div>
                )}
                {egglessFeatures && egglessFeatures.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        row
                        value={true}
                      >
                        {egglessFeatures.map((feature) => (
                          <FormControlLabel
                            checked={this.state.eggOption === feature._id}
                            value={feature._id}
                            onClick={async (e) => {
                              await this.setState({ eggOption: feature._id });
                              this.handleFeatureSelect("eggLess", feature);
                            }}
                            control={<Radio />}
                            label={feature.id}
                          />
                        ))}
                      </RadioGroup>
                    </div>
                    {error === "eggless" && (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        Please select a type
                      </p>
                    )}
                  </div>
                )}

                {details?.product?.photoOption && !this.state.image && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "9px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "5px",
                        border: "2px solid",
                        borderColor: "#dedfe3",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          padding: "6px",
                          paddingBottom: "5px",
                        }}
                      >
                        <input
                          accept=""
                          // className={classes.input}
                          onChange={(e) => this.inputHandler(e)}
                          style={{ display: "none" }}
                          id="raised-button-file"
                          ref={(ref) => {
                            this.image = ref;
                          }}
                          type="file"
                        />
                        <div>
                          <label
                            htmlFor="raised-button-file"
                            style={{ marginBottom: "0px" }}
                          >
                            <span>
                              <b>Select Photo</b>
                            </span>
                            <br />

                            <span>Only JPG or PNG.</span>
                          </label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            class="material-icons"
                            style={{ display: "flex" }}
                          >
                            keyboard_arrow_right
                          </span>
                        </div>
                      </div>
                    </div>
                    {this.state.show && (
                      <span style={{ color: "red", marginLeft: "5px" }}>
                        File Formate Not supported
                      </span>
                    )}
                    {error === "photo" && (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        Please upload a photo
                      </p>
                    )}
                  </div>
                )}

                {this.state.image && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      width: "100%",
                      padding: "5px",
                      paddingBottom: "4px",
                      borderRadius: "5px",
                      border: "2px solid",
                      marginTop: "10px",
                      borderColor: "#dedfe3",
                    }}
                    onClick={(e) => this.setState({ image: "" })}
                  >
                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      htmlFor="raised-button"
                    >
                      <input
                        accept=""
                        // className={classes.input}
                        onChange={(e) => this.inputHandler(e)}
                        style={{ display: "none" }}
                        id="raised-button"
                        ref={(ref) => {
                          this.image = ref;
                        }}
                        type="file"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{ color: "green" }}
                          class="material-icons"
                          htmlFor="raised-button"
                        >
                          check_circle_outline
                        </span>
                        <span style={{ color: "green" }}>Photo Uploaded</span>
                      </div>

                      <span>Change Photo</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={this.state.image}
                        loading="lazy"
                        style={{
                          display: "flex",
                          width: "44px",
                          height: "34px",
                        }}
                        alt={"Uploaded Image"}
                      />
                      <span class="material-icons" style={{ display: "flex" }}>
                        keyboard_arrow_right
                      </span>
                    </div>
                  </div>
                )}
                {fleavourFeatures && fleavourFeatures.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "48%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        width: "100%",
                        padding: "5px",
                        borderRadius: "5px",
                        marginTop: "5px",
                        borderColor: "#dedfe3",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        // className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Select a Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.typeFeatures}
                          //onChange={handleChange}
                          onChange={async (e) => {
                            await this.setState({
                              typeFeatures: e.target.value,
                            });
                            this.handleFeatureSelect("dropDown", e);
                          }}
                          label="Age"
                        >
                          {fleavourFeatures?.map((one) => (
                            <MenuItem value={one._id}>{one.id}</MenuItem>
                          ))}

                          {/* <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                    {error === "feature" && (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        please select one of the types
                      </p>
                    )}
                  </div>
                )}
              </Grid>
              <Grid item xs>
                <AppBar
                  elevation={10}
                  color={"transparent"}
                  position={"fixed"}
                  style={{ bottom: "0", top: "auto" }}
                >
                  {/*<Toolbar disableGutters={true}>*/}
                  <Grid container direction={"row"}>
                    <Grid item xs>
                      <button
                        type="button"
                        onClick={() => this.addToCart("cart")}
                        className={"add-to-cart-btn btn p-2 w-100"}
                      >
                        Add To Cart
                      </button>
                    </Grid>
                    <Grid item xs>
                      <button
                        type="button"
                        onClick={() => this.addToCart("buy")}
                        className={"by-now-btn btn btn-warning p-2 w-100"}
                      >
                        Buy Now
                      </button>
                    </Grid>
                  </Grid>
                  {/*</Toolbar>*/}
                </AppBar>

                <Tabs
                  variant={"scrollable"}
                  value={this.state.tabValue}
                  classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabIndicator,
                  }}
                  onChange={(event, newValue) =>
                    this.setState({ tabValue: newValue })
                  }
                >
                  <Tab
                    label={"Description"}
                    value={"Description"}
                    classes={{
                      selected: classes.tabSelected,
                      root: classes.tabRoot,
                    }}
                  />
                  <Tab label={"Delivery Guide"} value={"Delivery Guide"} />
                  <Tab label={"Care Guide"} value={"Care Guide"} />
                </Tabs>
                <TabPanel index={"Description"} value={this.state.tabValue}>
                  <div
                    className="listP"
                    dangerouslySetInnerHTML={{
                      __html: this.state.description,
                    }}
                  />
                </TabPanel>
                <TabPanel index={"Delivery Guide"} value={this.state.tabValue}>
                  <div
                    className="listP"
                    dangerouslySetInnerHTML={{
                      __html: this.state.deliveryInfo,
                    }}
                  />
                </TabPanel>
                <TabPanel index={"Care Guide"} value={this.state.tabValue}>
                  <div
                    className="listP"
                    dangerouslySetInnerHTML={{
                      __html: this.state.careInfo,
                    }}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </div>
        </main>
        <Box className="my-4" boxShadow={2}>
          <Suspense fallback={<p style={{ textAlign: "center" }}>Loading…</p>}>
            <YouMayAlsoLike {...this.props} relevance={this.state.relevance} />
          </Suspense>
          <Suspense fallback={<p style={{ textAlign: "center" }}>Loading…</p>}>
            <WhatOthersAreViewing {...this.props} />
          </Suspense>
        </Box>
      </React.Fragment>
    );
  }

  handelBoolean = (key) => {
    this.setState({ [key]: !this.state[key] });
  };
  handelCityChange = (state, value) => {
    this.setState({ city: value.name, selectedCity: value });
    let productDetails = this.props.productDetails;
    productDetails.selectedCity = value;
    productDetails.city = value.name;

    this.props.saveSelected(productDetails);
  };
  handleNumberChange = (key) => (e) => {
    const value = Number(e.target.value);
    this.setState(() => ({ [key]: value }));
  };

  getPrice(_id) {
    let { typeFeatures, eggOption } = this.state;
    let products = this.state.details.product.virtualProducts;
    let price = 0;
    for (let i = 0; i < products?.length; i++) {
      let product = products?.[i];
      let features = product?.Feature;
      const fleavourFeatures = features?.find(
        (feature) => feature.Type.id == "DROPDOWN"
      );
      const sizeFeatures = features?.find(
        (feature) => feature.Type.id == "SIZE"
      );
      const egglessFeatures = features?.find(
        (feature) => feature.Type.id == "RADIO"
      );
      let flag = false;
      if (_id) {
        if (_id !== sizeFeatures._id) {
          flag = true;
        }
      }
      if (eggOption) {
        if (eggOption !== egglessFeatures._id) {
          flag = true;
        }
      }
      if (typeFeatures) {
        if (typeFeatures !== fleavourFeatures._id) {
          flag = true;
        }
      }
      if (!flag) {
        let productId = product._id;
        let pricesData = this.state?.pricesData;
        let childProduct = pricesData?.virtualProducts.find(
          (a) => a._id == productId
        );

        let webPrice = childProduct.Price.find(
          (onePrice) => onePrice.priceType === "WEB_PRICE"
        );

        if (webPrice) {
          let pCurrency = webPrice.currencyUom;
          if (this.props.currency === pCurrency) {
            price = webPrice.price;
          } else {
            price = parseFloat(
              (
                (webPrice.price / this.state.currencyRates[pCurrency]) *
                this.state.currencyRates[this.props.currency]
              ).toFixed(2)
            );
          }
        } else {
          let listPrice = childProduct.Price.find(
            (onePrice) => onePrice.priceType === "LIST_PRICE"
          );
          if (listPrice) {
            let pCurrency = listPrice.currencyUom;
            if (this.props.currency === pCurrency) {
              price = listPrice.price;
            } else {
              price = parseFloat(
                (
                  (listPrice.price / this.state.currencyRates[pCurrency]) *
                  this.state.currencyRates[this.props.currency]
                ).toFixed(2)
              );
            }
          }
        }
      }
    }
    return price;
  }

  changImage = (image) => {
    this.setState({ selectedImage: image });
  };
  scroll = (element) => {
    var ele = document.getElementById(element);
    window.scrollTo({
      left: ele.offsetLeft,
      top: ele.offsetTop,
      behavior: "smooth",
    });
  };
  addToCart = async (type) => {
    this.setState({ type });
    const { finalDeliveryDate, city, details, selectedDate } = this.state;
    this.setState({ error: null });
    if (!city) {
      this.setState({ error: "city" });
      return false;
    }
    if (
      typeof finalDeliveryDate.time === "undefined" ||
      finalDeliveryDate.time === null
    ) {
      this.setState({ error: "schedule" });
      return false;
    }
    // if (details?.product?.messageOption === true && this.state.message === "") {
    //     this.setState({error: "message"});
    //     setTimeout(() => {
    //         this.setState({error: ""});
    //     }, 4000);
    //     return false;
    // }
    const features = this.state.details?.product?.Feature;
    const egglessFeatures = features?.filter(
      (feature) => feature.Type.id == "INGREDIETNS"
    );
    if (
      egglessFeatures &&
      egglessFeatures.length > 0 &&
      this.state.eggOption === ""
    ) {
      this.setState({ error: "eggless" });
      setTimeout(() => {
        this.setState({ error: "" });
      }, 4000);
      return false;
    }
    if (details?.product?.photoOption && !this.state.image) {
      this.setState({ error: "photo" });
      setTimeout(() => {
        this.setState({ error: "" });
      }, 4000);
      return false;
    }

    const fleavourFeatures = features?.filter(
      (feature) => feature.Type.id == "Flavour"
    );
    if (
      fleavourFeatures &&
      fleavourFeatures.length > 0 &&
      this.state.typeFeatures == ""
    ) {
      this.setState({ error: "feature" });
      setTimeout(() => {
        this.setState({ error: "" });
      }, 4000);
      return false;
    }

    var data = {
      products: this.state.productId,
      customer: this.props?.user,
      amount: this.state.price,
      city: city,
      shippingType: finalDeliveryDate?.type,
      shippingPrice: finalDeliveryDate?.price,
      shippingTime: finalDeliveryDate.time,
      shippingOrignal: finalDeliveryDate.shippingOrignal,
      shippingDate: selectedDate,
      quantity: 1,
      isAddon: false,
    };
    data = this.state.image
      ? { ...data, image: this.state.image }
      : { ...data };

    data =
      this.state.eggOption !== ""
        ? { ...data, eggOption: this.state.eggOption }
        : { ...data };

    data = this.state.message
      ? { ...data, cakeMessage: this.state.message }
      : { ...data };
    data =
      this.state.typeFeatures !== ""
        ? { ...data, cakeType: this.state.typeFeatures }
        : { ...data };
    data = this.state.selected
      ? { ...data, featureId: this.state.selected }
      : { ...data };
    data = this.state.partyId
      ? { ...data, parties: this.state.partyId }
      : { ...data };
    data.currency = this.props.currency;

    let productDetails = this.props.productDetails;
    productDetails = { ...productDetails, ...data };
    productDetails.flag = true;
    productDetails.buttonType = type;
    await this.props.saveSelected(productDetails);

    var addons = await getRequest(
      "products/addons?limit=30&offset=0&currentProduct=" + this.state.productId
    );
    if (addons?.data?.length) {
      this.setState({ showAddOn: true, addons: addons.data });
    } else {
      this.createCart();
    }

    // addons = addons?.data;
    // if (f) {
    //   if (addons.length) {
    //     this.setState({ showAddOn: true, addons: addons });
    //   } else {
    //     this.handleNavigation(type);
    //   }
    // }

    //
  };
  createCart = async () => {
    let cartData = {};
    let productDetails = JSON.parse(localStorage.getItem("productDetails"));
    let token = localStorage.getItem("token");
    if (typeof productDetails !== "undefined" && productDetails !== null) {
      if (Object.keys(productDetails).length) {
        cartData = productDetails;
        localStorage.removeItem("productDetails");
      } else {
        cartData = this.props.productDetails;
      }
    } else {
      cartData = this.props.productDetails;
    }
    if (!token) {
      let orders = this.props.orders;
      let carts = [];
      if (orders?.carts) {
        carts = orders?.carts;
      }

      let products = [];
      let productObj = {};
      productObj.title = this.state.productTitle;
      productObj.images = this.state?.details?.product?.images;
      products.push(productObj);
      cartData.products = products;
      cartData.productId = this.state.productId;
      cartData.addons = this.props.addonsData;
      cartData.orignalAmount = cartData.amount;
      this.props.saveAddons([]);
      carts.push(cartData);

      carts = this.saveOrignalAmounts(carts);

      let { totalProductAmount, totalShipping } = this.calculate(carts);

      totalProductAmount = totalProductAmount.toFixed(2);
      totalShipping = totalShipping.toFixed(2);
      let grandTotal = (
        parseFloat(totalShipping) + parseFloat(totalProductAmount)
      ).toFixed(2);
      //  let {grandTotal,totalAmount,totalShippingPrice}=this.calculate(carts);
      await this.props.saveCartInState({
        carts: carts,
        totalProductAmount,
        totalShipping,
        grandTotal,
      });
      if (this.state.type === "buy") {
        this.props.validate();
      } else {
        this.props.history.push("/");
      }
      return;
    }
    if (cartData) {
      this.setState({ type: cartData.buttonType });
      let pCurrency = cartData.currency;
      let price = cartData.price;
      let finalDeliveryDate = cartData.finalDeliveryDate;
      price = parseFloat(
        (
          (price / this.state.currencyRates[pCurrency]) *
          this.state.currencyRates[this.props.currency]
        ).toFixed(2)
      );
      if (finalDeliveryDate?.price) {
        let shippingPrice = parseFloat(
          (
            (finalDeliveryDate?.price / this.state.currencyRates[pCurrency]) *
            this.state.currencyRates[this.props.currency]
          ).toFixed(2)
        );
        finalDeliveryDate = {
          ...finalDeliveryDate,
          price: shippingPrice,
        };
      }
      cartData.finalDeliveryDate = finalDeliveryDate;
      cartData.price = price;
      cartData.customer = this.props.user;
      cartData.currency = this.props.currency;
      let f = await this.props.makeOrder(cartData);
      if (f) {
        cartData.flag = false;
        await this.props.saveSelected(cartData);
      } else {
        return;
      }
    }
    if (this.props.addonsData) {
      let recentlyAdded = this.props.recentlyAdded;
      let user = this.props.user;
      let addons = this.props.addonsData;
      for (let i = 0; i < addons.length; i++) {
        let pCurrency = addons[i].currency;
        let price = addons[i].price;
        price = parseFloat(
          (
            (price / this.state.currencyRates[pCurrency]) *
            this.state.currencyRates[this.props.currency]
          ).toFixed(2)
        );
        addons[i].currency = this.props.currency;
        addons[i].price = price;
        let obj = {
          ...addons[i],
          parentCart: recentlyAdded?._id,
          customer: user,
        };
        this.props.makeOrder(obj);
      }
    }
    this.props.saveAddons([]);
    this.handleNavigation(this.state.type);
  };
  onClosePopup = () => {
    this.setState({ showAddOn: false });
    this.createCart();
    //this.handleNavigation(this.state.type);
  };
  handleNavigation = (type) => {
    var router = this.props.history;
    if (type === "cart") {
      router.push("/");
    } else {
      this.props.validate();
    }
    // type === "cart" ? router.push("/") : router.push(`/address/${this.props.recentlyAdded._id}`);
    this.props.getOrders();
  };

  closeDeliveryInfoBox = async () => {
    await this.setState({ openDeliveryInfo: false });
  };

  closeDeliveryInfo = ({ finalDeliveryDate, selectedDate, partyId }) => {
    let dateToShow = moment(selectedDate).utc(true).format("DD");
    finalDeliveryDate.date = dateToShow;
    this.setState({
      finalDeliveryDate,
      selectedDate,
      openDeliveryInfo: false,
      partyId,
    });
    let productDetails = this.props.productDetails;
    productDetails.finalDeliveryDate = finalDeliveryDate;
    this.props.saveSelected(productDetails);
  };

  componentWillUnmount() {
    let productDetails = this.props.productDetails;
    if (productDetails?.flag === false) {
      let obj = {};
      this.props.saveSelected(obj);
    }
  }
}

const SelectedDate = ({ day, date, month, type, time, price, currency }) => {
  return time ? (
    <div className="dateSelect">
      <div className="flexRow">
        <span className="date">{date}</span>
        <div className="flexCol do">
          <span>{month}</span>
          <span>{day}</span>
        </div>
        <div className="flexCol do">
          <span>
            {type}{" "}
            <span>
              {currency} {price}
            </span>
          </span>
          <span>{time}</span>
        </div>
      </div>
    </div>
  ) : (
    "Select Delivery Date"
  );
};
const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user?._id,
    currency: state.auth.currency,
    recentlyAdded: state?.cart?.recentlyAdded,
    productDetails: state?.orderOption?.productDetails,
    addonsData: state?.others?.addons,
    orders: state?.cart?.orders,
  };
};
const mapDispatchToProps = (dispatch, router) => {
  return {
    makeOrder: (data) => dispatch(createOrder(data, router)),
    getOrders: () => dispatch(getActiveOrders()),
    saveSelected: (data) => dispatch({ type: "SAVE_DETAIL", payload: data }),
    saveAddons: (data) => dispatch(saveAddons(data)),
    saveCartInState: (data) =>
      dispatch({ type: "ACTIVE_ORDERS", payload: data }),
    d: () => dispatch({ type: "DELETING_CART", payload: false }),
    validate: () => dispatch(validate(router)),
    getOtherProductData: (id) => dispatch(getOtherProductData(id, router)),
    getOtherProductPrices: (id) => dispatch(getOtherProductPrices(id, router)),
    metaApi: (data) => dispatch(getFindMeta(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyels)
)(React.memo(ProductDetails));
