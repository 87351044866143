import React from "react";
import { Icon, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { deleteOrder, updateQuantity } from "../../store/actions/cartActions";
class CartAddons extends React.Component {
  // debugger;
  // console.log("these are addons with images", addons);
  componentDidUpdate(prevProps, prevState) {
    console.log("addons", this.props.addons);
  }
  componentWillReceiveProps() {
    console.log("addons", this.props.addons);
  }
  render() {
    const {
      addons,
      currency,
      sec,
      deleteCart,
      handleQuantity,
      loading,
      disable_increase_button,
      currencyRates,
      cartIndex,
    } = this.props;
    return addons?.length ? (
      <div
        className={"addonsP " + sec}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {addons.map(
          (
            { amount, products, quantity, _id, currency: orderCurrency },
            key
          ) => {
            var product = products?.[0];

            return (
              <React.Fragment key={"addon" + key}>
                {loading ? (
                  <div className="addonLoading">
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="addon" style={{ marginTop: "10px" }}>
                      <div className="img">
                        <img
                          loading="lazy"
                          src={product?.images?.[0]}
                          className="rounded thumbP"
                          alt={product?.title}
                        />
                      </div>
                      <div className="others">
                        <div className="quantity">
                          <span className="p-text item">{product?.title}</span>
                          <span className="p-text item">
                            Price : {currency} {amount * quantity}
                          </span>
                          <div className="quantityHandlerP item">
                            <span className="p-text">Qty:</span>
                            <div className="handler">
                              <button
                                disabled={disable_increase_button}
                                onClick={() =>
                                  handleQuantity({
                                    type: "decrease",
                                    _id,
                                    quantity,
                                    cartIndex,
                                    key
                                  })
                                }
                                className={`btn ${
                                  quantity <= 1 ? "disabled" : ""
                                }`}
                              >
                                -
                              </button>
                              <span>{quantity}</span>
                              <button
                                disabled={disable_increase_button}
                                onClick={() =>
                                  handleQuantity({
                                    type: "increase",
                                    _id,
                                    quantity,
                                    cartIndex,
                                    key
                                  })
                                }
                                className="btn"
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="delete"
                          onClick={(e) => {
                            deleteCart(_id, cartIndex, key);
                          }}
                        >
                          <Icon className="p-text-icon">delete</Icon>
                          {/*{sec === "checkout" && "Delete"}*/}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          }
        )}
      </div>
    ) : (
      ""
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state?.cart?.updating_quantity,
  disable_increase_button: state?.cart?.disable_increase_button,
  currencyRates: state?.others?.currencyRates,
  currency: state?.auth?.currency,
});

const mapDispatchToProps = (dispatch, router) => {
  return {
    deleteCart: (id, indexOfCart, indexOfAddon) =>
      dispatch(deleteOrder(id, router, indexOfCart, indexOfAddon)),
    handleQuantity: (type, id, quantity,cartIndex,key) =>
      dispatch(updateQuantity(type, id, quantity,cartIndex,key)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartAddons);
