import React, {Component} from "react";
import styles from "../OrderDetails.module.css";
import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    Grid,
    Icon,
    IconButton,
    Modal,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import letter from "../../../../assets/images/images.png";
import DeliveryInfo from "../../../common/DeliveryInfo";
import {connect} from "react-redux";
import moment from "moment";
import Carousel from "react-elastic-carousel";
import CartAddons from "../../../common/CartAddons";
import DeliveryAddress from "../../../common/DeliveryAddress";
import GiftMessage from "./GiftMessage";
import {
    updateAddon,
    createAddon,
    deleteOrder,
    updateQuantity,
    deleteAddon,
    updateCart,
} from "../../../../store/actions/cartActions";
import {withRouter} from "react-router-dom";
import placeHolderImg from "../../../../assets/placeHolders/placeholder1.jpg";
import {getRequest} from "../../../../store/services";

class CartItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDeliveryInfo: false,
            addons: [],
            quantityState: [],
            cartState: [],
            newProduct: [],
            city: null,
            show: false,
            showSaved: false,
            cart: "",
        };
    }

    componentDidMount = async () => {
        if(this.props.product._id){
            await getRequest(
                "products/get/" + this.props.prods?.products[0]?._id + "/false"
            ).then((res) => {
                getRequest("cities/get/" + res.data.product?.metaData?.country).then(
                    (cities) => {
                        let newCity = cities.data.filter(
                            (city) => city.name === this.props.prods.city
                        );
                        this.setState({ newProduct: res.data, city: newCity[0]._id });
                    }
                );
            });
            var addons = await getRequest(
                "products/addons?limit=30&offset=0&currentProduct=" +
                this.props.product._id
            );
            addons = addons?.data;
            this.setState({ addons: addons });
            let quantityState = [];
            for (let i = 0; i < addons.length; i++) {
                quantityState.push(0);
            }
            let cartState = [];
            for (let i = 0; i < addons.length; i++) {
                cartState.push(0);
            }
            this.setState({ cartState, quantityState });
        }
    };
    changeButton = () => {
        this.setState({openDeliveryInfo: true});
    };
    closeDeliveryInfo = (data) => {
        let finalDeliveryDate = data.finalDeliveryDate;
        let partyId = data.partyId;
        let selectedDate = data.selectedDate;
        let newData = {
            shippingType: finalDeliveryDate?.type,
            shippingPrice: finalDeliveryDate?.price,
            shippingTime: finalDeliveryDate.time,
            shippingDate: selectedDate,
            partyId: partyId,
            _id: this.props.prods._id,
        };
        this.setState({openDeliveryInfo: false});
        this.props.updateCart(newData);
    };
    closeWindow = () => {
        this.setState({openDeliveryInfo: false});
    };
    handleSave = () => {
        this.setState({show: false, showSaved: true});
    };
    handleClose = () => {
        this.setState({show: false});
    };
    openModal = () => {
        this.setState({show: true, cart: this.props.prods});
    };

    componentDidUpdate=async(prevProps)=> {
        if (prevProps.prods !== this.props.prods) {
            this.setState({ cart: this.props.prods });
            await getRequest(
                "products/get/" + this.props.prods?.products[0]?._id + "/false"
            ).then((res) => {
                getRequest("cities/get/" + res.data.product?.metaData?.country).then(
                    (cities) => {
                        let newCity = cities.data.filter(
                            (city) => city.name === this.props.prods.city
                        );
                        this.setState({ newProduct: res.data, city: newCity[0]._id });
                    }
                );
            });
            var addons = await getRequest(
                "products/addons?limit=30&offset=0&currentProduct=" +
                this.props.product._id
            );
            addons = addons?.data;
            this.setState({ addons: addons });
            let quantityState = [];
            for (let i = 0; i < addons.length; i++) {
                quantityState.push(0);
            }
            let cartState = [];
            for (let i = 0; i < addons.length; i++) {
                cartState.push(0);
            }
            this.setState({ cartState, quantityState });
        }
    }

    render() {
        const {
            prods,
            dateP,
            product,
            index,
            onDelete,
            currency,
            addons,
        } = this.props;
        const shippingDate = new Date(prods?.shippingDate).setHours(0, 0, 0, 0);
        const currentDate = new Date().setHours(0, 0, 0, 0);
        let isDateInvalid = false;
        if (shippingDate < currentDate) {
            isDateInvalid = true;
        }
        // let price = 0;
        // let pCurrency = prods.currency;
        // if (this.props.currency === pCurrency) {
        //   price = prods.amount;
        // } else {
        //   price = parseFloat(
        //     (
        //       (prods.amount / this.props.currencyRates[pCurrency]) *
        //       this.props.currencyRates[this.props.currency]
        //     ).toFixed(2)
        //   );
        // }
        // let shipping = 0;
        // if (this.props.currency === pCurrency) {
        //   shipping = prods.shippingPrice;
        // } else {
        //   shipping = parseFloat(
        //     (
        //       (prods.shippingPrice / this.props.currencyRates[pCurrency]) *
        //       this.props.currencyRates[this.props.currency]
        //     ).toFixed(2)
        //   );
        // }
        const {openDeliveryInfo} = this.state;
        return (
            <div>
                <GiftMessage
                    show={this.state.show}
                    handleClose={this.handleClose}
                    handleSave={this.handleSave}
                    cart={this.state.cart}
                />
                <div
                    className={`bg_secondary badgeE text-white rounded-top ${styles.giftDiv} mt-3`}
                >
                    <Grid direction="row" container className="py-1">
                        <Icon className="mx-2">redeem</Icon>
                        <span className="font-weight-bold mr-2">{index + 1}</span>
                    </Grid>
                </div>
                <Divider variant="fullWidth" orientation="horizontal"/>
                {isDateInvalid && (
                    <div>
                        <p className="text-danger font-weight-bold text-center my-2">
                            The delivery date of this cart item needs to be changed before
                            going further.
                        </p>
                    </div>
                )}
                {/*order details*/}
                <div className="mt-4 position-relative">
                    <Grid container spacing={3} direction="column">
                        <Grid item xs>
                            <Grid container direction="column">


                                <Grid item container xs direction={"row"}>
                                    <Grid item>
                                        <img
                                            loading="lazy"
                                            src={
                                                product?.images && product.images.length > 0
                                                    ? product.images[0]
                                                    : ""
                                            }
                                            className="rounded imgPC"
                                            alt={product?.title}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <div className={`ml-3 ${styles.detailsFont}`}>
                                            <p>{product?.title}</p>
                                            <p>
                                                {currency} {prods?.amount} x {prods?.quantity}
                                            </p>
                                        </div>
                                    </Grid>

                                </Grid>
                                <Grid item>
                                    <div
                                        className="handler"
                                        style={{
                                            border: "1px solid #dadada",
                                            //borderBlockColor: "#dadada",
                                            marginTop: "5px",
                                            borderRadius: "3px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <button
                                            disabled={false}
                                            onClick={() =>
                                                this.props.handleQuantity({
                                                    type: "decrease",
                                                    _id: prods._id,
                                                    quantity: prods.quantity,
                                                })
                                            }
                                            className={`btn ${prods.quantity <= 1 ? "disabled" : ""}`}
                                        >
                                            -
                                        </button>
                                        <>{prods.quantity}</>
                                        <button
                                            disabled={false}
                                            onClick={() =>
                                                this.props.handleQuantity({
                                                    type: "increase",
                                                    _id: prods._id,
                                                    quantity: prods.quantity,
                                                })
                                            }
                                            className="btn"
                                        >
                                            +
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>


                            <div className="position-absolute" style={{top: "5px", right: '5px'}}>
                                <FormControlLabel
                                    control={
                                        <IconButton>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onDelete(prods._id);
                                    }}
                                />
                            </div>
                        </Grid>

                        <Grid item xs>
                            <div>
                                <div className={`${styles.deliveryTime} `}>

                                    <div style={{width: "35%", display: 'flex', alignItems: 'center'}}
                                         className="d-flex justify-content-around">
                                        <div style={{fontSize: '40px'}}>
                                            {dateP.date}
                                        </div>
                                        <div style={{fontSize: '14px'}}>
                                            {dateP.day} <br/> {dateP.month}
                                        </div>
                                    </div>
                                    <div
                                        style={{width: "65%", marginLeft: '2%', display: "flex", alignItems: 'center'}}>
                                        <div className="d-flex justify-content-start align-items-center">
                                            <div>{prods.shippingType}<br/>
                                                {prods.shippingTime}
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div onClick={(e) => this.changeButton(isDateInvalid)}
                                     className={`${styles.changeDeliveryDate}`}>

                                    Change <Icon>keyboard_arrow_right</Icon>
                                </div>
                            </div>
                        </Grid>

                        {!addons.length > 0 && this.state.addons.length > 0 && (
                            <div className={"w-100"}>
                                {" "}
                                <h4 style={{color: "green"}}>Frequently Bought Addons</h4>
                                <div style={{width: "100%", backgroundColor: "#fff9d8"}}>
                                    <div style={{width: "100%"}}>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            // justifyContent={"center"}
                                            alignItems={'stretch'}
                                            style={{width: "100%", overflow: 'scroll'}}
                                        >
                                            {this.state.addons?.map((addon, index) => {
                                                let bPrice = 0;
                                                if (addon?.Price) {
                                                    let basePrice = addon.Price.find(
                                                        (onePrice) => onePrice.priceType === "WEB_PRICE"
                                                    );

                                                    if (basePrice) {
                                                        let pCurrency = basePrice.currencyUom;
                                                        if (this.props.currency === pCurrency) {
                                                            bPrice = basePrice.price;
                                                        } else {
                                                            bPrice = parseFloat(
                                                                (
                                                                    (basePrice.price /
                                                                        this.props.currencyRates[pCurrency]) *
                                                                    this.props.currencyRates[this.props.currency]
                                                                ).toFixed(2)
                                                            );
                                                        }
                                                    } else {
                                                        let listPrice = addon.Price.find(
                                                            (onePrice) => onePrice.priceType === "LIST_PRICE"
                                                        );
                                                        if (listPrice) {
                                                            let pCurrency = listPrice.currencyUom;
                                                            if (this.props.currency === pCurrency) {
                                                                bPrice = listPrice.price;
                                                            } else {
                                                                bPrice = parseFloat(
                                                                    (
                                                                        (listPrice.price /
                                                                            this.props.currencyRates[pCurrency]) *
                                                                        this.props.currencyRates[this.props.currency]
                                                                    ).toFixed(2)
                                                                );
                                                            }
                                                        }
                                                    }
                                                }
                                                return (
                                                    <div
                                                        style={{
                                                            width: "200px",
                                                            minWidth:"130px",
                                                            display: "flex",
                                                            // marginTop: "15px",
                                                            flexDirection: 'column',
                                                            justifyContent: "space-between",
                                                            backgroundColor: "white",
                                                            borderRadius: '4px',
                                                            alignItems: 'center',
                                                        }}
                                                        className={"m-2 p-2 MuiPaper-elevation4"}
                                                    >
                                                        <div style={{textAlign: "center"}}>
                                                            <img
                                                                loading="lazy"
                                                                src={addon.images ? addon.images[0] : ""}
                                                                height="80px"
                                                                width="60px"
                                                                alt={addon?.title}
                                                            />
                                                        </div>
                                                        <div style={{maxWidth: "89px"}}>
                                                            <div>
                                                                <p style={{
                                                                    // maxWidth: "89px",
                                                                    // overflow: 'hidden',
                                                                    whiteSpace: 'wrap',
                                                                    textAlign:'center',
                                                                    textOverflow: 'ellipsis',
                                                                    fontSize:"10px",
                                                                    marginBottom:"0px"
                                                                }}>{addon?.title}</p>
                                                                <p style={{textAlign:"center",fontSize:'10px',marginBottom:'0px',color:'#b379c5',fontWeight:'bold'}}>Price:{bPrice}</p>
                                                            </div>
                                                            <div
                                                                className="handler"
                                                                style={{
                                                                    border: "1px solid #dadada",
                                                                    //borderBlockColor: "#dadada",
                                                                    //marginLeft: "5px",
                                                                    backgroundColor: "white",
                                                                    borderRadius: "3px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <button
                                                                    disabled={false}
                                                                    className="btn"
                                                                    onClick={async (e) => {
                                                                        if (this.state.cartState[index] > 0) {
                                                                            if (this.state.quantityState[index] > 1) {
                                                                                let cart = await this.props.handleAddonQuantity(
                                                                                    {
                                                                                        type: "decrease",
                                                                                        _id: this.state.cartState[index],
                                                                                        quantity: this.state.quantityState[
                                                                                            index
                                                                                            ],
                                                                                    }
                                                                                );
                                                                                let quantityState = this.state
                                                                                    .quantityState;
                                                                                quantityState[index] = cart.quantity;
                                                                                this.setState({quantityState});
                                                                                let grandTotal = this.props.grandTotal;
                                                                                grandTotal = grandTotal - bPrice;
                                                                                let totalProductPrice = this.props
                                                                                    .totalProductPrice;
                                                                                totalProductPrice =
                                                                                    totalProductPrice - bPrice;
                                                                                this.props.updatePrice(true);
                                                                                await this.props.updatePricing(
                                                                                    grandTotal,
                                                                                    totalProductPrice
                                                                                );
                                                                                this.props.updatePrice(false);
                                                                            } else {
                                                                                let grandTotal = this.props.grandTotal;
                                                                                grandTotal = grandTotal - bPrice;

                                                                                let totalProductPrice = this.props
                                                                                    .totalProductPrice;
                                                                                totalProductPrice =
                                                                                    totalProductPrice - bPrice;
                                                                                this.props.updatePrice(true);
                                                                                await this.props.updatePricing(
                                                                                    grandTotal,
                                                                                    totalProductPrice
                                                                                );
                                                                                this.props.updatePrice(false);

                                                                                this.props.deleteAddon(
                                                                                    this.state.cartState[index]
                                                                                );
                                                                                let cartState = this.state.cartState;
                                                                                cartState[index] = 0;

                                                                                let quantityState = this.state
                                                                                    .quantityState;
                                                                                quantityState[index] = 0;
                                                                                this.setState({
                                                                                    cartState,
                                                                                    quantityState,
                                                                                });
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    -
                                                                </button>
                                                                <>{this.state.quantityState[index]}</>
                                                                <button
                                                                    disabled={false}
                                                                    onClick={async (e) => {
                                                                        if (this.state.cartState[index] > 0) {
                                                                            let cart = await this.props.handleAddonQuantity(
                                                                                {
                                                                                    type: "increase",
                                                                                    _id: this.state.cartState[index],
                                                                                    quantity: this.state.quantityState[
                                                                                        index
                                                                                        ],
                                                                                }
                                                                            );
                                                                            let quantityState = this.state
                                                                                .quantityState;
                                                                            quantityState[index] = cart.quantity;
                                                                            let grandTotal = this.props.grandTotal;
                                                                            grandTotal = grandTotal + bPrice;
                                                                            let totalProductPrice = this.props
                                                                                .totalProductPrice;
                                                                            totalProductPrice =
                                                                                totalProductPrice + bPrice;
                                                                            this.props.updatePrice(true);
                                                                            await this.props.updatePricing(
                                                                                grandTotal,
                                                                                totalProductPrice
                                                                            );
                                                                            this.props.updatePrice(false);

                                                                            this.setState({quantityState});
                                                                        } else {
                                                                            var data = {
                                                                                products: addon?._id,
                                                                                customer: prods.customer,
                                                                                amount: bPrice,
                                                                                city: prods.city,
                                                                                currency: this.props.currency,
                                                                                shippingType: prods.shippingType,
                                                                                shippingPrice: prods.shippingPrice,
                                                                                shippingTime: prods.shippingTime,
                                                                                shippingDate: prods.shippingDate,
                                                                                quantity: 1,
                                                                                isAddon: true,
                                                                                parentCart: prods?._id,
                                                                            };
                                                                            let cart = await this.props.makeOrder(data);
                                                                            let cartState = this.state.cartState;
                                                                            cartState[index] = cart._id;
                                                                            let quantityState = this.state
                                                                                .quantityState;
                                                                            quantityState[index] = cart.quantity;

                                                                            this.setState({cartState, quantityState});
                                                                            let grandTotal = this.props.grandTotal;
                                                                            grandTotal = grandTotal + bPrice;
                                                                            let totalProductPrice = this.props
                                                                                .totalProductPrice;
                                                                            totalProductPrice =
                                                                                totalProductPrice + bPrice;
                                                                            this.props.updatePrice(true);
                                                                            await this.props.updatePricing(
                                                                                grandTotal,
                                                                                totalProductPrice
                                                                            );
                                                                            this.props.updatePrice(false);

                                                                            // this.setState({ quantityState });
                                                                            //
                                                                        }
                                                                    }}
                                                                    className="btn"
                                                                >
                                                                    +
                                                                </button>
                                                            </div>
                                                            <button
                                                                className="btn"
                                                                style={{
                                                                    marginTop: "10px",
                                                                    color: "white",
                                                                    backgroundColor: "#dd771f",
                                                                    borderRadius: "3px",
                                                                    marginBottom: "5px",
                                                                    border: "0px",
                                                                }}
                                                                onClick={async (e) => {
                                                                    if (this.state.quantityState[index] > 0) {
                                                                        let grandTotal = this.props.grandTotal;
                                                                        grandTotal =
                                                                            grandTotal -
                                                                            this.state.quantityState[index] * bPrice;

                                                                        let totalProductPrice = this.props
                                                                            .totalProductPrice;
                                                                        totalProductPrice =
                                                                            totalProductPrice -
                                                                            this.state.quantityState[index] * bPrice;
                                                                        this.props.updatePrice(true);
                                                                        await this.props.updatePricing(
                                                                            grandTotal,
                                                                            totalProductPrice
                                                                        );
                                                                        this.props.updatePrice(false);
                                                                        this.props.deleteAddon(
                                                                            this.state.cartState[index]
                                                                        );
                                                                        let cartState = this.state.cartState;
                                                                        cartState[index] = 0;

                                                                        let quantityState = this.state.quantityState;
                                                                        quantityState[index] = 0;
                                                                        this.setState({cartState, quantityState});
                                                                    } else {
                                                                        var data = {
                                                                            products: addon?._id,
                                                                            customer: prods.customer,
                                                                            amount: bPrice,
                                                                            currency: this.props.currency,
                                                                            city: prods.city,
                                                                            shippingType: prods.shippingType,
                                                                            shippingPrice: prods.shippingPrice,
                                                                            shippingTime: prods.shippingTime,
                                                                            shippingDate: prods.shippingDate,
                                                                            quantity: 1,
                                                                            isAddon: true,
                                                                            parentCart: prods?._id,
                                                                        };
                                                                        let cart = await this.props.makeOrder(data);
                                                                        let cartState = this.state.cartState;
                                                                        cartState[index] = cart._id;
                                                                        let quantityState = this.state.quantityState;
                                                                        quantityState[index] = cart.quantity;

                                                                        this.setState({cartState, quantityState});
                                                                        let grandTotal = this.props.grandTotal;
                                                                        grandTotal = grandTotal + bPrice;
                                                                        let totalProductPrice = this.props
                                                                            .totalProductPrice;
                                                                        totalProductPrice =
                                                                            totalProductPrice + bPrice;
                                                                        this.props.updatePrice(true);
                                                                        await this.props.updatePricing(
                                                                            grandTotal,
                                                                            totalProductPrice
                                                                        );
                                                                        this.props.updatePrice(false);
                                                                    }
                                                                }}
                                                            >
                                                                {this.state.quantityState[index] > 0
                                                                    ? "Remove"
                                                                    : "Add Now"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        )}
                        {openDeliveryInfo && (
                            <DeliveryInfo
                                currency={currency}
                                city={this.state.city}
                                methods={this.state.newProduct.product}
                                visible={openDeliveryInfo}
                                flag={true}
                                closeWindow={this.closeWindow}
                                onFinal={(data) => this.closeDeliveryInfo(data)}
                                currencyRates={this.props.currencyRates}
                                shippmentMethodIds={this.state.newProduct.product.shippmentMethodIds}
                            />
                        )}
                    </Grid>
                    {/* Addons */}
                    {addons && <CartAddons addons={addons} sec="checkout"/>}
                </div>
                {/*address*/}
                <div className="mt-4">
                    <DeliveryAddress
                        onSave={this.props.saveAddress}
                        onSelect={this.props.selectAddress}
                        city={prods?.city}
                        cart={prods._id}
                        onHourDelivery={this.state.newProduct.oneHourDelivery}
                        twoHoursDelivery={this.state.newProduct.twoHoursDelivery}
                        delivery={prods.deliveryAddress?._id}
                        prods={this.props.prods}
                    />
                </div>
                <div
                    className="mt-4"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={(e) => this.openModal(prods?._id)}
                >
                    <img loading="lazy" src={letter} height="50px" width="70px" alt={'letter'}/>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <h6>FREE MESSAGE CARD</h6>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <EditIcon fontSize="small"/>
                                <p style={{marginBottom: "0px"}}>Edit</p>
                            </div>
                            {this.props.prods?.giftCard && (
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <CheckCircleIcon
                                        fontSize="small"
                                        style={{color: "green"}}
                                    />
                                    <p style={{marginBottom: "0px"}}>Saved</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/*address ends here*/}
            </div>
        );
    }

    addNewAddon(addon) {
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, router) => {
    return {
        handleQuantity: (type, id, quantity) =>
            dispatch(updateQuantity(type, id, quantity)),
        handleAddonQuantity: (type, id, quantity) =>
            dispatch(updateAddon(type, id, quantity)),
        makeOrder: (data) => dispatch(createAddon(data, router)),
        delete: (id) => dispatch(deleteOrder(id, router)),
        deleteAddon: (id) => dispatch(deleteAddon(id)),
        updateCart: (data) => dispatch(updateCart(data)),
    };
};
// export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CartItem)
);
