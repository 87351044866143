const initialState = {
  navigations: [],
};
const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HEADER_NAVIGATION_LIST_SUCCESS":
      return { ...state, navigations: action.payload };
    default:
      return state;
  }
};
export default navigationReducer;
