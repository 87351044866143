import { getRequest } from "../services";

/** GET HEADER NAVIGATION LIST */

export const getHeaderNavigationList = () => (dispatch) => {
  dispatch({ type: "GET_HEADER_NAVIGATION_LIST_REQUEST" });
  return getRequest(`navigation`)
    .then((response) => {
      dispatch({
        type: "GET_HEADER_NAVIGATION_LIST_SUCCESS",
        payload: response.data.navigation,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_HEADER_NAVIGATION_LIST_FAILURE",
        payload: "Something Went Wrong",
      });
      throw error;
    });
};
