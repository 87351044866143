import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/App.scss";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ComponentNotFound from "./Components/ComponentNotFound";
import Layout from "./Components/Layout";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ScrollTop from "./ScrollTop";
import ReactGA from 'react-ga';
const history = createBrowserHistory();
ReactGA.initialize(`UA-199713724-1`,{
  debug: false,
});
function App() {
  React.useEffect(() => {
    // Pageview: Google analytics
      ReactGA.pageview(
        `${history.location.pathname}${history.location.search}${history.location.hash}`
      );
      history.listen((location, action) => {
        const { historyWatch } = window;
        if (historyWatch && Array.isArray(historyWatch)) {
          historyWatch.push({
            action,
            URL: `${location.pathname}${location.search}${location.hash}`,
          });
        } else {
          window.historyWatch = [];
        }
        ReactGA.pageview(
          `${location.pathname}${location.search}${location.hash}`
        );
      });
  }, []);
  return (
    <div>
      <ReactNotification />
      <BrowserRouter>
      <ScrollTop>

        <Switch>
          <Route path="/" component={Layout} />
          <Route component={ComponentNotFound} />
        </Switch>
        </ScrollTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
