import React, { useState, useCallback } from "react";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import { slugifyText } from "../../../constants/utils";
import CircularProgress from "@material-ui/core/CircularProgress";
function CategoryProduct(props) {
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const handleLoaded = useCallback(() => {
    setLoaded((prevState) => !prevState);
  }, [props?.slug]);

  const handleError = useCallback(() => {
    setError((prevState) => !prevState);
  }, [props?.slug]);
  // var to =
  //   `/` +
  //   props.catalog +
  //   "/" +
  //   slugifyText(props.category) +
  //   "/" +
  //   "product/" + props.catId + "/" + slugifyText(props.title);
  // var to ="/" + slugifyText(props.title);

  let price = 0;
  let prices = props?.product?.Price;
  let strikePrice = 0;
  let percentageOff = 0;
  if (prices.length > 0) {
    let webPrice = prices.find((p) => p.priceType === "WEB_PRICE");
    let listPrice = prices.find((p) => p.priceType === "LIST_PRICE");
    if (webPrice) {
      let pCurrency = webPrice.currencyUom;
      if (props.selectedCurrency === pCurrency) {
        price = webPrice.price;
        if (webPrice.price < listPrice.price) {
          strikePrice = listPrice.price;
          let pr = listPrice.price - webPrice.price;
          percentageOff = Math.round((pr / listPrice.price) * 100)
        }
      } else {
        price = (
          (webPrice.price / props.currencyRates[pCurrency]) *
          props.currencyRates[props.selectedCurrency]
        ).toFixed(2);
      }
    } else {

      if (listPrice) {
        let pCurrency = listPrice.currencyUom;
        if (props.selectedCurrency === pCurrency) {
          price = listPrice.price;
        } else {
          price = (
            (listPrice.price / props.currencyRates[pCurrency]) *
            props.currencyRates[props.selectedCurrency]
          ).toFixed(2);
        }
      }
    }
  }
  return (
    // <Link to={{ pathname: to + "/" + props.slug.toString() }} ref={props.productRef}>
    <Link to={{ pathname:"/gift/" + props.slug }} ref={props.productRef}>

      <Card
        className="text-center h-100 d-flex flex-column justify-content-between align-items-center"
        elevation={1}
        style={{ margin: "0px", width: "100%" }}
      >
        {!isLoaded && !error && (
          <CircularProgress
            color="primary"
            size={20}
            style={{
              position: "absolute",
              top: "40%",
              left: "40%"
            }}
          // className={classes.spinner}
          />
        )}
        <img
          src={props.image}
          height="auto"
          onLoad={handleLoaded}
          onError={handleError}
          // height={"auto"}
          loading="lazy"
          width={"96%"}
          alt={props?.title}
          style={{ marginTop: "5px" }}
        />
        <div className={'d-flex flex-column justify-content-between h-100'}>
          <div>
            <p className="mb-0 mt-1" style={{
              display: "-webkit-box",
              maxWidth: "100%",
              webkitLineClamp: "1",
              webkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}> {props.title} </p>
            <p className="mb-0 mt-1 font-weight-bold">
              {" "}
              {props.selectedCurrency} {price}{" "}
            </p>
          </div>

          {percentageOff > 0 && <div className={'d-flex justify-content-center'}>
            <div className={'d-flex flex-row justify-content-center'}>
              <span style={{ marginRight: '5px' }}>{props.selectedCurrency}<span><s>{strikePrice}</s></span></span>
              <span style={{ color: '#b379c5', marginLeft: '5px' }}>{percentageOff}% OFF</span>
            </div>
          </div>}
          <p className="mb-0 mt-3 mb-3" style={{ fontSize: 12 }}>
            Earliest Delivery:
            <span className="color_primary">{props.delivery.toUpperCase()}</span>
          </p>
        </div>
      </Card>
    </Link>
  );
}

export default CategoryProduct;
