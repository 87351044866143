import React, {Component} from "react";
import {compose} from "redux";
import {Grid, Card, CardContent, Box, Avatar, withStyles, createStyles, Typography} from "@material-ui/core";
import AuthSideBar from "../common/AuthSideBar";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import "./Profile.scss";

const useStyles = createStyles({
    cardRoot: {
        backgroundColor: '#B379C5',
        color: 'white'
    },
    avatarStyle: {
        backgroundColor: '#a162b5',
        fontSize: '60px',
        width: '100px',
        height: '100px',
        textShadow: '4px 2px 4px black',
        border: '1px solid white'


    }
});

class Profile extends Component {
    renderChildSection({email, name, mobile, countryCode}) {
        const {classes} = this.props;
                return (
                    <div>
                        <Card square classes={{root: classes.cardRoot}} className='position-relative' elevation={2}>
                            <CardContent>
                                <Grid container justify='center' alignItems='center' direction='column'>
                                    <Grid item>
                                        <Box display="flex" flexDirection='column' justifyContent="space-between"
                                             alignItems={'center'}>
                                            <Avatar classes={{root: classes.avatarStyle}}
                                                    className='mb-4'>{(name? name[0]: '')}</Avatar>
                                            <h5 style={{fontSize: '22px'}}> {name} </h5>
                                        </Box>
                                    </Grid>
                                    <Grid xs item direction='row' alignItems='center' justify='center'>
                                        <Typography component={'div'} className="mt-2">
                                            Mobile No: {countryCode? countryCode?.toString():''}{mobile? mobile?.toString():''}
                                        </Typography>
                                    </Grid>
                                    <Grid xs item direction='row' alignItems='center' justify='center'>
                                        <Typography component={'div'} className="mt-2">{email}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                          <Box style={{position:'absolute', bottom:'21px', right:'15px'}}>
                            <Link to="/profile/profile-edits">
                                <EditIcon/>
                            </Link>
                          </Box>
                        </Card>

                    </div>
                );

    }

    render() {
        const {user} = this.props;

        return (
            <div>
                <Grid container xs direction="column" justify="center">
                    <Grid item xs>
                        {this.renderChildSection(user)}
                    </Grid>
                    <Grid item xs>
                        <AuthSideBar/>
                    </Grid>
                    {/*nav ends here*/}
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {user: state.auth.user};
};
export default compose(connect(mapStateToProps), withStyles(useStyles))(React.memo(Profile));
