import { getRequest } from "../services";

/** GET BEST SELLING PRODUCTS */

export const getBestSellingProducts = () => (dispatch) => {
  dispatch({ type: "GET_BEST_SELLING_PRODUCTS_REQUEST" });
  return getRequest(`products/bestSellingProducts`)
    .then((response) => {
      dispatch({
        type: "GET_BEST_SELLING_PRODUCTS_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_BEST_SELLING_PRODUCTS_FAILURE",
        payload: "Something Went Wrong",
      });
      throw error;
    });
};
