import React, { Component } from "react";
import { Collapse } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { withStyles } from "@material-ui/core/styles";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import { connect } from "react-redux";
import { getFindMeta } from "../store/actions/metaAction";

const styles = (theme) => ({
  collapseable: {
    //display: "flex",
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "10px",
    marginRight: "10px",
    textAlign: "justify",
  },
});
class PrivacyPolicy extends Component {
  state = {
    myAccount: true,
    placeOrder: false,
    deliveryInfo: false,
    orderModification: false,
    returnPolicy: false,
    unhappyWithOrder: false,
    voucherDiscount: false,
    subscriptions: false,
    whatIsMyAccount: false,
    forgotPassword: false,
    loginId: false,
    shipmentOfOrders: false,
    removeProduct: false,
    whereDelivery: false,
    deliveryTime: false,
    chooseTime: false,
    midnight: false,
    shippingCharges: false,
    modes: false,
    noRecipientDetails: false,
    orderDelivered: false,
    statusMean: false,
    sameDay: false,
    courierDelivery: false,
    cancel: false,
    time: false,
    modesOfRefund: false,
    companyReturnPolicy: false,
    defective: false,
    cases: false,
    amountCredited: false,
    unhappy: false,
    discount: false,
    eGift: false,
    buyVoucher: false,
    makePurchase: false,
    usePromotion: false,
    limit: false,
    terms: false,
    newsLetter: false,
    subNewsLetter: false,
    canSub: false,
    unSub: false,
    cart: false,
    infoRequired: false,
    differentProducts: false,
    customization: false,
  };
  componentDidMount() {
    this.props.metaApi("faq");
  }
  handleChange = (e) => {
    let obj = {
      myAccount: false,
      placeOrder: false,
      deliveryInfo: false,
      orderModification: false,
      returnPolicy: false,
      unhappyWithOrder: false,
      voucherDiscount: false,
      subscriptions: false,
    };
    switch (e) {
      case "myAccount":
        obj.myAccount = true;
        break;
      case "placeOrder":
        obj.placeOrder = true;
        break;
      case "deliveryInfo":
        obj.deliveryInfo = true;
        break;
      case "orderModification":
        obj.orderModification = true;
        break;
      case "returnPolicy":
        obj.returnPolicy = true;
        break;
      case "subscriptions":
        obj.subscriptions = true;
        break;
      case "unhappyWithOrder":
        obj.unhappyWithOrder = true;
        break;
      case "voucherDiscount":
        obj.voucherDiscount = true;
        break;
      default:
        return;
    }
    this.setState({ ...this.state, ...obj });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className="homeMargin mx-4">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
            marginBottom: "30px",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              backgroundColor: "black",
              width: "100px",
              height: "1px",
            }}
          ></div>
          <h3 style={{ color: "#d6286d" }}>Frequently</h3>
          <h3 style={{ marginLeft: "5px" }}>Asked Questions</h3>
          <div
            style={{
              backgroundColor: "black",
              width: "100px",
              height: "1px",
            }}
          ></div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              marginLeft: "50px",
            }}
          >
            <p
              style={{
                cursor: "pointer",
                color: this.state.myAccount ? "#7abbe1" : "",
              }}
              name="myAccount"
              onClick={() => this.handleChange("myAccount")}
            >
              My Account & My Cart
            </p>
            <p
              style={{
                cursor: "pointer",
                color: this.state.placeOrder ? "#7abbe1" : "",
              }}
              name="placeOrder"
              onClick={(e) => this.handleChange("placeOrder")}
            >
              How can i place an order?
            </p>
            <p
              style={{
                cursor: "pointer",
                color: this.state.deliveryInfo ? "#7abbe1" : "",
              }}
              name="deliveryInfo"
              onClick={(e) => this.handleChange("deliveryInfo")}
            >
              Delivery Information
            </p>
            <p
              style={{
                cursor: "pointer",
                color: this.state.orderModification ? "#7abbe1" : "",
              }}
              name="orderModification"
              onClick={(e) => this.handleChange("orderModification")}
            >
              Order Modification/Cancellation
            </p>
            <p
              style={{
                cursor: "pointer",
                color: this.state.returnPolicy ? "#7abbe1" : "",
              }}
              name="returnPolicy"
              onClick={(e) => this.handleChange("returnPolicy")}
            >
              Return and Refund Policy
            </p>
            <p
              style={{
                cursor: "pointer",
                color: this.state.unhappyWithOrder ? "#7abbe1" : "",
              }}
              name="returnPolicy"
              onClick={(e) => this.handleChange("unhappyWithOrder")}
            >
              What if I am unhappy with my order?
            </p>
            <p
              style={{
                cursor: "pointer",
                color: this.state.voucherDiscount ? "#7abbe1" : "",
              }}
              name="returnPolicy"
              onClick={(e) => this.handleChange("voucherDiscount")}
            >
              Vouchers/Discounts/Points
            </p>
            <p
              style={{
                cursor: "pointer",
                color: this.state.subscriptions ? "#7abbe1" : "",
              }}
              name="subscriptions"
              onClick={(e) => this.handleChange("subscriptions")}
            >
              Subscriptions
            </p>
          </div>
          <div style={{ display: "flex", flex: 2 }}>
            {this.state.myAccount && (
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      whatIsMyAccount: !this.state.whatIsMyAccount,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What is my Account?
                  </p>
                  {!this.state.whatIsMyAccount && <AddIcon />}
                  {this.state.whatIsMyAccount && <RemoveIcon />}
                </div>
                <Collapse in={this.state.whatIsMyAccount}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <p className={classes.collapseable}>
                      Registering to My Account service is free and allows you
                      to recall your personal information when visiting our
                      website again, making it faster to place future orders.
                    </p>
                    <p className={classes.collapseable}>
                      Once you have registered with us, you can sign in by using
                      your user name and password. On the website we'll provide
                      you with an address book and a reminder section, to store
                      all your recipients details, together with a log of orders
                      previously placed.{" "}
                    </p>
                  </div>
                </Collapse>

                {/*forgot password*/}
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      forgotPassword: !this.state.forgotPassword,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    I forgot my password!
                  </p>
                  {!this.state.forgotPassword && <AddIcon />}
                  {this.state.forgotPassword && <RemoveIcon />}
                </div>
                <Collapse in={this.state.forgotPassword}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <p className={classes.collapseable}>
                      Don't worry. We are here to help you. Here's what you need
                      to do.
                    </p>
                    <p className={classes.collapseable}>
                      <ul>
                        <li>Click here to go to the login page</li>
                        <li>Enter your e-mail ID</li>
                        <li>Click on the Forgot Password? link</li>
                      </ul>
                    </p>
                    <p className={classes.collapseable}>
                      A new password will be sent to your registered email
                      address. You can use that password to login to your
                      Account and then reset it in the My Account section.
                    </p>
                  </div>
                </Collapse>
                {/*Sorry! I do not remember my login ID and password.*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      loginId: !this.state.loginId,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Sorry! I do not remember my login ID and password.
                  </p>
                  {!this.state.loginId && <AddIcon />}
                  {this.state.loginId && <RemoveIcon />}
                </div>
                <Collapse in={this.state.loginId}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",

                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Don’t Worry! Please share your order number to &nbsp;
                      <a href="mailto:support@giftabliss.com">
                        support@giftabliss.com
                      </a>
                      &nbsp;with a request for your login ID & password. We will
                      provide you with your login credentials.
                    </span>
                  </div>
                </Collapse>

                {/*What is My Cart?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      cart: !this.state.cart,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What is My Cart?
                  </p>
                  {!this.state.cart && <AddIcon />}
                  {this.state.cart && <RemoveIcon />}
                </div>
                <Collapse in={this.state.cart}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",

                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Its same like shopping carts in a retail store, and here
                      My Cart helps you keep the things you want at one place,
                      while you continue browsing or leave the site to think
                      about it.
                    </span>
                  </div>
                </Collapse>

                {/*Can I remove product in my cart?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      removeProduct: !this.state.removeProduct,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Can I remove product in my cart?
                  </p>
                  {!this.state.removeProduct && <AddIcon />}
                  {this.state.removeProduct && <RemoveIcon />}
                </div>
                <Collapse in={this.state.removeProduct}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",

                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Of course yes, if you wish to remove a particular product
                      from your Cart, just click on 'Manage Your Cart'. You will
                      be able to see all the products in your cart. Click on the
                      ‘Remove’ button next to the item you wish to remove from
                      your Cart.
                    </span>
                  </div>
                </Collapse>

                {/*Where can i track my shipment of the orders?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      shipmentOfOrders: !this.state.shipmentOfOrders,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Where can i track my shipment of the orders?
                  </p>
                  {!this.state.shipmentOfOrders && <AddIcon />}
                  {this.state.shipmentOfOrders && <RemoveIcon />}
                </div>
                <Collapse in={this.state.shipmentOfOrders}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      You can track your order on the website for the latest
                      information on delivery. You will see a 'Track Order'
                      option on the top right corner of the page. Enter your
                      order number & click on the ‘Track Now’ to see the current
                      status of your order.
                    </span>
                  </div>
                </Collapse>
              </div>
            )}
            {this.state.placeOrder && (
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      orderOptions: !this.state.orderOptions,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What are the options of placing an order with GIFTABLISS?
                  </p>
                  {!this.state.orderOptions && <AddIcon />}
                  {this.state.orderOptions && <RemoveIcon />}
                </div>
                <Collapse in={this.state.orderOptions}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <p className={classes.collapseable}>
                      Following are the options in which you can place an order
                      with us.
                    </p>
                    <p className={classes.collapseable}>
                      <ul>
                        <li>
                          You can log on to www.giftabliss.com from your mobile
                          as well.
                        </li>
                        <li>
                          Online - Log on to www.giftabliss.com and have fun
                          shopping on our website!
                        </li>
                        <li>
                          You can call us at +971-55 523 1077 (08:00 AM- 12:00
                          AM, Monday-Sunday) and we will be more than happy to
                          assist you in placing your order.
                        </li>
                        <li>
                          You can mail us all the details at{" "}
                          <a href="mailto:support@giftabliss.com">
                            support@giftabliss.com
                          </a>{" "}
                          and we will place an order for you.
                        </li>
                      </ul>
                    </p>
                  </div>
                </Collapse>
                {/*What are the information required from my end to submit the order? */}
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      infoRequired: !this.state.infoRequired,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What are the information required from my end to submit the
                    order?
                  </p>
                  {!this.state.infoRequired && <AddIcon />}
                  {this.state.infoRequired && <RemoveIcon />}
                </div>
                <Collapse in={this.state.infoRequired}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <p className={classes.collapseable}>
                      We would require the below information from you :
                    </p>
                    <p className={classes.collapseable}>
                      <ul>
                        <li>Recipient’s name</li>
                        <li>Complete delivery address</li>
                        <li>Contact number of the recipient</li>
                        <li>Date of delivery</li>
                        <li>
                          Time of delivery will be needed in case of products
                          that are delivered by hand
                        </li>
                        <li>Gift message</li>
                        <li>Sender’s name</li>
                        <li>Shipping option</li>
                        <li>Billing information</li>
                      </ul>
                    </p>
                  </div>
                </Collapse>

                {/*How do I find different type of products on your site? */}
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      differentProducts: !this.state.differentProducts,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    How do I find different type of products on your site?
                  </p>
                  {!this.state.differentProducts && <AddIcon />}
                  {this.state.differentProducts && <RemoveIcon />}
                </div>
                <Collapse in={this.state.differentProducts}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <p className={classes.collapseable}>
                      To make shopping easy and hassle-free for you, we have
                      categorised our products in different categories. You have
                      the option to choose products from categories like{" "}
                      <a href="/">Flowers</a>, <a href="/">Cakes</a>,{" "}
                      <a href="/">Chocolates</a> and <a href="/">Gifts</a> etc.
                      You can also choose products based on an occasion and
                      relation. Enjoy shopping!
                    </p>
                  </div>
                </Collapse>

                {/*How do I find different type of products on your site? */}
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      customization: !this.state.customization,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Can customization of product is possible?
                  </p>
                  {!this.state.differentProducts && <AddIcon />}
                  {this.state.differentProducts && <RemoveIcon />}
                </div>
                <Collapse in={this.state.customization}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <p className={classes.collapseable}>
                      Sure you can! Call us at +971-55 523 1077 (08:00 AM- 12:00
                      AM, Monday-Sunday) or write to us at{" "}
                      <a href="mailto:support@giftabliss.com">
                        support@giftabliss.com
                      </a>{" "}
                      and let us know what you want and what are your
                      requirements.
                    </p>
                  </div>
                </Collapse>
              </div>
            )}
            {this.state.deliveryInfo && (
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                {/*Where all do you delivery?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      whereDelivery: !this.state.whereDelivery,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Where all do you delivery?
                  </p>
                  {!this.state.whereDelivery && <AddIcon />}
                  {this.state.whereDelivery && <RemoveIcon />}
                </div>
                <Collapse in={this.state.whereDelivery}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      // marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      We delivery all Across UAE. Delivery to the remote
                      location and offshore is not possible.
                    </span>
                  </div>
                </Collapse>

                {/*Is there any particular time of delivery?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      deliveryTime: !this.state.deliveryTime,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Is there any particular time of delivery?
                  </p>
                  {!this.state.deliveryTime && <AddIcon />}
                  {this.state.deliveryTime && <RemoveIcon />}
                </div>
                <Collapse in={this.state.deliveryTime}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //  marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      All orders are delivered during business hours (8 AM - 10
                      PM), unless you have opted for a Midnight or a early
                      morning delivery.
                    </span>
                  </div>
                </Collapse>

                {/*Is there any particular time of delivery?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      chooseTime: !this.state.chooseTime,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Do i have an option to choose a time of delivery?
                  </p>
                  {!this.state.chooseTime && <AddIcon />}
                  {this.state.chooseTime && <RemoveIcon />}
                </div>
                <Collapse in={this.state.chooseTime}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //  marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Yes! By paying additional delivery charge, you have the
                      option to choose a Fixed Time slot or a one hour delivery
                      shipping option for delivery.
                    </span>
                  </div>
                </Collapse>

                {/*Can I get the order delivered in the Midnight?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      midnight: !this.state.midnight,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Can I get the order delivered in the Midnight?
                  </p>
                  {!this.state.midnight && <AddIcon />}
                  {this.state.midnight && <RemoveIcon />}
                </div>
                <Collapse in={this.state.midnight}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //  marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Of course Yes! We understand the emotions of celebrating
                      an occasion on its eve. That’s why we have an option of a
                      Midnight delivery for our customers. With an additional
                      delivery charge, you can get your order delivered between
                      23:00 to 23:59 hours on the date you choose.
                    </span>
                  </div>
                </Collapse>

                {/*Are there any shipping charges?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      shippingCharges: !this.state.shippingCharges,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Are there any shipping charges?
                  </p>
                  {!this.state.shippingCharges && <AddIcon />}
                  {this.state.shippingCharges && <RemoveIcon />}
                </div>
                <Collapse in={this.state.shippingCharges}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Yes there are charges for the delivery.
                    </span>
                    <span className={classes.collapseable}>
                      Standard Delivery- AED 30
                    </span>
                    <span className={classes.collapseable}>
                      One Hour Delivery- AED 49
                    </span>
                    <span className={classes.collapseable}>
                      Two Hour Delivery - AED 49
                    </span>
                    <span className={classes.collapseable}>
                      Early Morning Delivery - AED 49
                    </span>
                    <span className={classes.collapseable}>
                      Midnight Delivery - AED 79
                    </span>
                  </div>
                </Collapse>

                {/*What are the modes of delivery?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      modes: !this.state.modes,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What are the modes of delivery?
                  </p>
                  {!this.state.modes && <AddIcon />}
                  {this.state.modes && <RemoveIcon />}
                </div>
                <Collapse in={this.state.modes}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //  marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      We have following 2 modes of delivery –
                    </span>
                    <span className={classes.collapseable}>Hand Delivery.</span>
                    <span className={classes.collapseable}>
                      Courier Delivery.
                    </span>
                  </div>
                </Collapse>

                {/*I don’t want to disclose my details to the recipient, how do I place the order?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      noRecipientDetails: !this.state.noRecipientDetails,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    I don’t want to disclose my details to the recipient, how do
                    I place the order?
                  </p>
                  {!this.state.noRecipientDetails && <AddIcon />}
                  {this.state.noRecipientDetails && <RemoveIcon />}
                </div>
                <Collapse in={this.state.noRecipientDetails}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //  marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      We totally understand the surprise element. You can write
                      to us at{" "}
                      <a href="mailto:support@giftabliss.com">
                        support@giftabliss.com
                      </a>{" "}
                      or call us at +971-55 523 1077 (08:00 AM- 12:00 AM,
                      Monday-Sunday) and let us know.
                    </span>
                  </div>
                </Collapse>

                {/*How do I know my order has been confirmed or delivered?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      orderDelivered: !this.state.orderDelivered,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    How do I know my order has been confirmed or delivered?
                  </p>
                  {!this.state.orderDelivered && <AddIcon />}
                  {this.state.orderDelivered && <RemoveIcon />}
                </div>
                <Collapse in={this.state.orderDelivered}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      As soon as your order is confirmed or delivered, you will
                      receive an email & SMS along with your order number or the
                      status of the order.{" "}
                    </span>
                  </div>
                </Collapse>

                {/*What do the different order statuses mean?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      statusMean: !this.state.statusMean,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What do the different order statuses mean?
                  </p>
                  {!this.state.statusMean && <AddIcon />}
                  {this.state.statusMean && <RemoveIcon />}
                </div>
                <Collapse in={this.state.statusMean}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //    marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      <ul>
                        <li>
                          Payment received: Which means we have received the
                          payment of your order and your order is ready to be
                          executed.
                        </li>
                        <li>
                          Payment declined: This means the payment for your
                          order has failed.
                        </li>
                        <li>
                          Order confirmed: Your order is confirmed. It will be
                          delivered as per the date and time slot selected by
                          you
                        </li>
                        <li>
                          Out for delivery: Your order is dispatched and out for
                          delivery.
                        </li>
                        <li>Order delivered: Your order has been delivered.</li>
                        <li>
                          Delivery attempted: We tried to attempt to deliver
                          your order but could not because of the following
                          reasons:
                          <ul>
                            <li>the recipient was not available</li>
                            <li>recipient’s number was not reachable</li>
                            <li>premises was locked</li>
                            <li>address was incomplete/incorrect. </li>
                          </ul>
                        </li>
                        <li>
                          Returned to origin: Our courier partner tried to
                          deliver your order thrice but could not because of the
                          following reasons:
                          <ul>
                            <li>the recipient was not available</li>
                            <li>recipient’s number was not reachable</li>
                            <li>premises was locked</li>
                            <li>address was incomplete/incorrect.</li>
                          </ul>
                        </li>
                      </ul>
                    </span>
                  </div>
                </Collapse>

                {/*What is Same Day Delivery Means?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      sameDay: !this.state.sameDay,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What is Same Day Delivery Means?
                  </p>
                  {!this.state.sameDay && <AddIcon />}
                  {this.state.sameDay && <RemoveIcon />}
                </div>
                <Collapse in={this.state.sameDay}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //  marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      We’re more than happy to take your last minute orders and
                      guarantee same day delivery.
                    </span>
                    <span className={classes.collapseable}>
                      For same-day Standard Time Delivery, orders must be placed
                      before 7:00 pm, Monday - Sunday.
                    </span>
                    <span className={classes.collapseable}>
                      For same-day One Hour Time Delivery, orders must be placed
                      before 9:00 pm, Monday - Sunday.
                    </span>
                    <span className={classes.collapseable}>
                      For same-day Two Hour Time Delivery, orders must be placed
                      before 9:00 pm, Monday - Sunday.
                    </span>
                    <span className={classes.collapseable}>
                      For same-day Midnight Delivery, orders must be placed
                      before 9:00 pm, Monday - Sunday.
                    </span>
                  </div>
                </Collapse>

                {/*What is a normal courier delivery?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                    marginBottom: !this.state.courierDelivery ? "30px" : "0px",
                  }}
                  onClick={() =>
                    this.setState({
                      courierDelivery: !this.state.courierDelivery,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What is a normal courier delivery?
                  </p>
                  {!this.state.courierDelivery && <AddIcon />}
                  {this.state.courierDelivery && <RemoveIcon />}
                </div>
                <Collapse in={this.state.courierDelivery}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Your order will be delivered through our courier delivery
                      partners Aramex & First Flight. For products that are
                      shipped using the services of our courier delivery
                      partners, the date of delivery chosen by you is an
                      estimate. Your order may be delivered a day prior or a day
                      after the chosen date of delivery.
                    </span>
                  </div>
                </Collapse>
              </div>
            )}
            {this.state.orderModification && (
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                {/*How do I make a change in my order or cancel it?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      cancel: !this.state.cancel,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    How do I make a change in my order or cancel it?
                  </p>
                  {!this.state.cancel && <AddIcon />}
                  {this.state.cancel && <RemoveIcon />}
                </div>
                <Collapse in={this.state.cancel}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      If you need to make changes or cancel your order, please
                      call us at +971-55 523 1077(08:00 AM- 12:00 AM,
                      Monday-Sunday) or email us at{" "}
                      <a href="mailto:support@giftabliss.com">
                        support@giftabliss.com
                      </a>
                    </span>
                    <span className={classes.collapseable}>
                      Note: Order modification/cancellation in a domestic order,
                      please give us at least 24 hours’ notice before the
                      requested delivery date. If your order has already been
                      shipped or delivered, we won't be able to cancel it or
                      make any changes. Order modification/cancellation in an
                      international order, please give us at least 48 hours’
                      notice. If your order has already been shipped or
                      delivered, we won't be able to cancel it or make any
                      changes.{" "}
                    </span>
                  </div>
                </Collapse>

                {/*How much time will it take to process my cancellation request?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      time: !this.state.time,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    How much time will it take to process my cancellation
                    request?
                  </p>
                  {!this.state.time && <AddIcon />}
                  {this.state.time && <RemoveIcon />}
                </div>
                <Collapse in={this.state.time}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      // marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      It will take up to 24 hours’ time to process a
                      cancellation request.
                    </span>
                    <span className={classes.collapseable}></span>
                  </div>
                </Collapse>

                {/*What are the possible modes of refund available after cancellation?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      modesOfRefund: !this.state.modesOfRefund,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What are the possible modes of refund available after
                    cancellation?
                  </p>
                  {!this.state.modesOfRefund && <AddIcon />}
                  {this.state.modesOfRefund && <RemoveIcon />}
                </div>
                <Collapse in={this.state.modesOfRefund}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Your refund amount shall be credited to the same Debit
                      card/Credit card/Mobile wallet/Bank Account through which
                      the payment was done.
                    </span>
                    <span className={classes.collapseable}></span>
                  </div>
                </Collapse>
              </div>
            )}
            {this.state.returnPolicy && (
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                {/*What is GIFTABLISS Return Policy?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      companyReturnPolicy: !this.state.companyReturnPolicy,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What is GIFTABLISS Return Policy?
                  </p>
                  {!this.state.companyReturnPolicy && <AddIcon />}
                  {this.state.companyReturnPolicy && <RemoveIcon />}
                </div>
                <Collapse in={this.state.companyReturnPolicy}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      No returns are allowed. However, in case if a wrong or a
                      defective product has been sent, do let us know at the
                      earliest. You can write to us at{" "}
                      <a href="mailto:support@giftabliss.com">
                        support@giftabliss.com
                      </a>{" "}
                      or call us at +971-55 523 1077 (08:00 AM-12:00 AM,
                      Monday-Sunday).
                    </span>
                  </div>
                </Collapse>

                {/*How can i return a defective or damaged product for a new replacement?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      defective: !this.state.defective,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    How can i return a defective or damaged product for a new
                    replacement?
                  </p>
                  {!this.state.defective && <AddIcon />}
                  {this.state.defective && <RemoveIcon />}
                </div>
                <Collapse in={this.state.defective}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      We take stringent measures to ensure that the items
                      delivered to you are in the perfect shape and condition.
                      However, there is a remote possibility that the item may
                      be damaged during transit or that a wrong item is
                      delivered to you. Don’t worry we are here to help you! You
                      can write to us at{" "}
                      <a href="mailto:support@giftabliss.com">
                        support@giftabliss.com
                      </a>{" "}
                      or call us at +971-55 523 1077 (8:00 AM- 12:00 AM,
                      Monday-Sunday) and we will take care of it.{" "}
                    </span>
                  </div>
                </Collapse>

                {/*What are the possible cases where a refund will not be made?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      cases: !this.state.cases,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What are the possible cases where a refund will not be made?
                  </p>
                  {!this.state.cases && <AddIcon />}
                  {this.state.cases && <RemoveIcon />}
                </div>
                <Collapse in={this.state.cases}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      We do not give guarantee refund for cases where:
                      <ul>
                        <li>
                          Flowers, cakes, chocolates, plants, fruits, sweets,
                          gift products and preserved items which are delivered
                          to an incorrect address provided by the sender.
                        </li>
                        <li>
                          Unsuccessful deliveries arising from the recipient not
                          being present at the time of delivery at the address
                          provided by the sender or the recipient refusing to
                          accept the delivery.
                        </li>
                        <li>
                          Product quality problems caused by improper handling
                          of the product by the recipient.
                        </li>
                        <li>
                          Cancellation/modification request in a Domestic order
                          made less than 24 hours’ prior to the date of delivery
                          and Cancellation/modification request in an
                          International order made 48 hours’ prior to the date
                          of delivery. In the event that we are unable to supply
                          all or part of your order (the product or any
                          substitute product to you at all), we shall notify you
                          as soon as possible and reimburse your payment in
                          full.
                        </li>
                      </ul>
                    </span>
                  </div>
                </Collapse>

                {/*When will the refunded amount be credited back to my account?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      amountCredited: !this.state.amountCredited,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    When will the refunded amount be credited back to my
                    account?
                  </p>
                  {!this.state.amountCredited && <AddIcon />}
                  {this.state.amountCredited && <RemoveIcon />}
                </div>
                <Collapse in={this.state.amountCredited}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Online refund is credited directly through the same mode
                      from which the customer has paid the money within 24
                      working hours. Once processed by us, refund takes 5-7
                      business days to reflect in your account.
                    </span>
                    <span className={classes.collapseable}>
                      Offline refund is credited once the customer shares the
                      bank details post which it takes 5-7 working days.
                    </span>
                  </div>
                </Collapse>
              </div>
            )}

            {this.state.unhappyWithOrder && (
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                {/*What if I am unhappy with my order?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      unhappy: !this.state.unhappy,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What if I am unhappy with my order?
                  </p>
                  {!this.state.unhappy && <AddIcon />}
                  {this.state.unhappy && <RemoveIcon />}
                </div>
                <Collapse in={this.state.unhappy}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      We are sorry to hear that you are not happy. Your smile is
                      our priority and a promise to you from us! No frowns. If
                      any, we'll surely tilt the curve for you.
                    </span>
                    <span className={classes.collapseable}>
                      Just let us know how we failed to meet your requirements
                      and expectations and we will fix it at the earliest. You
                      can write to us at{" "}
                      <a href="mailto:support@giftabliss.com">
                        support@giftabliss.com
                      </a>{" "}
                      or call us at +971-55 523 1077 (8:00 AM- 12:00 AM,
                      Monday-Sunday).
                    </span>
                  </div>
                </Collapse>
              </div>
            )}

            {this.state.voucherDiscount && (
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                {/*How do I redeem my Gift Certificate/Discount Voucher?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      discount: !this.state.discount,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    How do I redeem my Gift Certificate/Discount Voucher?
                  </p>
                  {!this.state.discount && <AddIcon />}
                  {this.state.discount && <RemoveIcon />}
                </div>
                <Collapse in={this.state.discount}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Log on to
                      <a href="www.giftabliss.com"> www.giftabliss.com</a>
                    </span>
                    <span className={classes.collapseable}>
                      After you have selected your products and filled in the
                      shipping details, you will get an option to redeem your
                      coupon in the ‘Order Summary’ page. Click on ‘I want to
                      redeem my discount/gift coupon’. Coupon code needs to be
                      entered in this section and then click on the submit
                      button.
                    </span>
                  </div>
                </Collapse>

                {/*What is the difference between a Discount Voucher and an E-Gift Voucher?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      eGift: !this.state.eGift,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What is the difference between a Discount Voucher and an
                    E-Gift Voucher?
                  </p>
                  {!this.state.eGift && <AddIcon />}
                  {this.state.eGift && <RemoveIcon />}
                </div>
                <Collapse in={this.state.eGift}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Gift Vouchers - These vouchers help you to redeem a
                      certain amount in the total amount of your purchase, like
                      AED 100 off on any purchase.
                    </span>
                    <span className={classes.collapseable}>
                      Discount Vouchers - These vouchers help you to redeem a
                      certain percentage off on your order value, like 10% off
                      or 15% off
                    </span>
                    <span className={classes.collapseable}>
                      Both of these have alphanumeric codes.
                    </span>
                  </div>
                </Collapse>

                {/*How do you I buy/gift an E-Gift Voucher?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      buyVoucher: !this.state.buyVoucher,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    How do you I buy/gift an E-Gift Voucher?
                  </p>
                  {!this.state.buyVoucher && <AddIcon />}
                  {this.state.buyVoucher && <RemoveIcon />}
                </div>
                <Collapse in={this.state.buyVoucher}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      You can buy our E-Gift Vouchers, either for yourself or
                      your loved ones. These vouchers will be alphanumeric
                      e-codes which can be mailed or can be printed on the gift
                      vouchers and presented. To buy one, please write to{" "}
                      <a href="mailto:support@giftabliss.com">
                        support@giftabliss.com
                      </a>
                    </span>
                  </div>
                </Collapse>

                {/*How do I make a purchase with an E-Gift Voucher?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      makePurchase: !this.state.makePurchase,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    How do I make a purchase with an E-Gift Voucher?
                  </p>
                  {!this.state.makePurchase && <AddIcon />}
                  {this.state.makePurchase && <RemoveIcon />}
                </div>
                <Collapse in={this.state.makePurchase}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Log on to{" "}
                      <a href="mailto:support@giftabliss.com">
                        support@giftabliss.com
                      </a>
                    </span>
                    <span className={classes.collapseable}>
                      After you have selected your products and filled in the
                      shipping details, you will get an option to redeem your
                      coupon in the ‘Order Summary’ page. Click on ‘I want to
                      redeem my discount/gift coupon’. Coupon code needs to be
                      entered in this section and then click on the submit
                      button.
                    </span>
                  </div>
                </Collapse>

                {/*Can I use promotional codes with E-Gift Vouchers?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      usePromotion: !this.state.usePromotion,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Can I use promotional codes with E-Gift Vouchers?
                  </p>
                  {!this.state.usePromotion && <AddIcon />}
                  {this.state.usePromotion && <RemoveIcon />}
                </div>
                <Collapse in={this.state.usePromotion}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Sorry! unfortunately two offers cannot be clubbed
                      together, which means you can either use the promotional
                      code or the e-Gift voucher.
                    </span>
                  </div>
                </Collapse>

                {/*Is there a limit on how many E-Gift vouchers I can use in one order?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      limit: !this.state.limit,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Is there a limit on how many E-Gift vouchers I can use in
                    one order?
                  </p>
                  {!this.state.limit && <AddIcon />}
                  {this.state.limit && <RemoveIcon />}
                </div>
                <Collapse in={this.state.limit}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: this.state.terms ? "50px" : "0px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      Yes, you can use only 1 E-Gift Voucher on a single order.
                    </span>
                  </div>
                </Collapse>

                {/*What Terms & Conditions apply to E-Gift Vouchers?*/}

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    marginBottom: !this.state.terms ? "50px" : "0px",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      terms: !this.state.terms,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What Terms & Conditions apply to E-Gift Vouchers?
                  </p>
                  {!this.state.terms && <AddIcon />}
                  {this.state.terms && <RemoveIcon />}
                </div>
                <Collapse in={this.state.terms}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      The offer can’t be combined with any other ongoing offers
                      of GIFTABLISS. The benefits under the offer are
                      non-negotiable.
                    </span>
                  </div>
                </Collapse>
              </div>
            )}

            {this.state.subscriptions && (
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      newsLetter: !this.state.newsLetter,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    What are Newsletters?
                  </p>
                  {!this.state.newsLetter && <AddIcon />}
                  {this.state.newsLetter && <RemoveIcon />}
                </div>
                <Collapse in={this.state.newsLetter}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      A newsletter is a timely notification of the website
                      updates, featured products, special offers, promotions,
                      new arrivals etc.
                    </span>
                  </div>
                </Collapse>

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      subNewsLetter: !this.state.subNewsLetter,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    Why should I subscribe to Newsletters?
                  </p>
                  {!this.state.subNewsLetter && <AddIcon />}
                  {this.state.subNewsLetter && <RemoveIcon />}
                </div>
                <Collapse in={this.state.subNewsLetter}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      <ul>
                        <li>
                          To receive special offers and promotions from
                          www.giftabliss.com
                        </li>
                        <li>See latest products and gifts</li>
                      </ul>
                    </span>
                  </div>
                </Collapse>

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      canSub: !this.state.canSub,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    How can I subscribe to Newsletters?
                  </p>
                  {!this.state.canSub && <AddIcon />}
                  {this.state.canSub && <RemoveIcon />}
                </div>
                <Collapse in={this.state.canSub}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      //   marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      All you need to do is to enter your email id in the
                      Newsletter box on our website. Upon entering your email
                      id, you will get a verification email to your email
                      address. Click on that link and activate your
                      subscription.
                    </span>
                  </div>
                </Collapse>

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #e5e5e5",
                    justifyContent: "space-between",
                    //marginBottom: !this.state.unSub ? "50px" : "0px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    this.setState({
                      unSub: !this.state.unSub,
                    })
                  }
                >
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "10px",
                      color: "#84cae8",
                    }}
                  >
                    How can I Unsubscribe to Newsletters?
                  </p>
                  {!this.state.unSub && <AddIcon />}
                  {this.state.unSub && <RemoveIcon />}
                </div>
                <Collapse in={this.state.unSub}>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "50px",
                      border: " 1px solid #e5e5e5",
                      flexDirection: "column",
                    }}
                  >
                    <span className={classes.collapseable}>
                      To stop receiving promotional email from us, click
                      directly on the "Unsubscribe" link within the email to
                      cancel your subscription.
                    </span>
                  </div>
                </Collapse>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flex: 1, marginLeft: "50px" }}>
            <div>
              <h5>Contact Us</h5>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <PhoneIphoneIcon />
                <p>+971555231077</p>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CallIcon />
                <p>+97145653810</p>
              </div>

              <a
                style={{
                  display: "flex",
                  flexdirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                href="mailto:support@giftabliss.com"
              >
                <MailIcon />
                Support@giftabliss.com
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, router) => ({
  metaApi: (data) => dispatch(getFindMeta(data)),
});
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(PrivacyPolicy))
);
