import {  putRequest } from "../services";
export const saveAllCats = (data) => (dispatch) => {
  dispatch({ type: "STORE_CATEGORIES", payload: JSON.stringify(data) });
};
export const saveCorporateCats = (data) => (dispatch) => {
  dispatch({ type: "STORE_CORPORATE_CATS", payload: JSON.stringify(data) });
};
export const contactUs = (data) => (dispatch) => {
  putRequest("subscribe/contactUs/", data, true)
    .then((response) => {
      // dispatch({ type: "SAVE_LAST_ADDRESS", payload: response.data });
    })
    .catch((error) => console.error(error));
};
export const trackOrder = (data) => (dispatch) => {
  return putRequest("orders/trackOrder/", data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
};
export const saveCatalog = (data) => async (dispatch) => {
  await dispatch({ type: "CATALOG", payload: data.catalog });
};

export const saveAddons = (data) => async (dispatch) => {
  await dispatch({ type: "SAVE_ADDONS", payload: data });
};
