import React from "react";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import { slugifyText } from "../../../constants/utils";

function CategoryCard(props) {
  const { category } = props;
  // const url = `/${slugifyText(category.catalog.name)}/${slugifyText(
  //   category.name
  // )}`;
  const url = `/${slugifyText(
    category.name
  )}`;

  return (
    <Link to={{ pathname: url+ "/" + category._id }}>
      <Card className="text-center" elevation={4}>
        <img 
          loading="lazy"
          src={category?.categoryImage}
          height={"300px"}
          width={"320px"}
          alt={category?.name}
        />

        <p className="my-2" style={{ fontSize: "20px" }}>
          {" "}
          {category?.name?.toUpperCase()}{" "}
        </p>
      </Card>
    </Link>
  );
}

export default CategoryCard;
