import React, {Component} from 'react';
import {connect} from 'react-redux';
import {TextField, Grid, MenuItem, Select, FormControl, InputLabel, Button} from '@material-ui/core';

class AgentProduct extends Component {
    render() {
        return (
            <Grid container direction='row' justify='center'>

                <Grid item className='my-3'>
                    <h3 className='text-center'>Customize Product</h3>
                    <form>
                        <table className='table table-striped'>
                            <tbody>
                            <tr>
                                <td>
                                    Product Name : *
                                </td>

                                <td>
                                    <TextField type='text' variant='outlined' size='small' value='Customized Product'
                                               label='Product name'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Product Desc : *
                                </td>

                                <td>
                                    <TextField type='text' variant='outlined' size='small' label='Description'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Primary Category : *
                                </td>

                                <td>
                                    <FormControl variant='outlined'>
                                        <InputLabel>Select Category</InputLabel>
                                        <Select label='Select Category' variant='outlined'>
                                            <MenuItem value="CAKE">Cake</MenuItem>
                                            <MenuItem value="COURIER">Courier</MenuItem>
                                            <MenuItem value="EXPRESS">Express</MenuItem>
                                            <MenuItem value="INTERNATIONAL">International</MenuItem>
                                            <MenuItem value="NONE">NONE</MenuItem>
                                            <MenuItem value="PERSONALIZED">Personalized</MenuItem>
                                        </Select>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Quantity : *
                                </td>

                                <td>
                                    <TextField type='number' inputProps={{min: 0}} variant='outlined' size='small' label='Quantity'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Web Price : *
                                </td>

                                <td>
                                    <TextField type='number' inputProps={{min: 0}} variant='outlined' size='small' label='Web Price'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Purchase Price : *
                                </td>

                                <td>
                                    <TextField type='number' inputProps={{min: 0}} variant='outlined' size='small' label='Purchase Price'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Supplier : *
                                </td>

                                <td>
                                    <TextField type='text' variant='outlined' size='small' label='Supplier'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Shipping City : *
                                </td>

                                <td>
                                    <TextField type='text' variant='outlined' size='small' label='Shipping City'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Delivery Date : *
                                </td>

                                <td>
                                    <TextField type='date' variant='outlined' size='small'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Shipping Mode : *
                                </td>

                                <td>
                                    <FormControl variant='outlined'>
                                        <InputLabel>Select Shipping</InputLabel>
                                        <Select label='Select Shipping' variant='outlined'>
                                            <MenuItem value="By air">By air</MenuItem>
                                            <MenuItem value="courier">Courier</MenuItem>
                                        </Select>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Delivery Time Slot Id : *
                                </td>

                                <td>
                                    <FormControl variant='outlined'>
                                        <InputLabel>Select Time Slot</InputLabel>
                                        <Select label='Select Time Slot' variant='outlined'>
                                            <MenuItem value={1}>1:00 - 2:00</MenuItem>
                                            <MenuItem value={2}>2:00 - 3:00</MenuItem>
                                        </Select>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Special Instructions : *
                                </td>

                                <td>
                                    <TextField type='text' variant='outlined' size='small'
                                               label='Special Instructions'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Upload Ref. Image : *
                                </td>

                                <td>
                                    <FormControl>
                                        <input type='file' accept='image/*'/>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                </td>

                                <td>
                                    <Button type='submit' variant='contained' className='bg_secondary text-white'>
                                        Add to cart
                                    </Button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </Grid>

            </Grid>
        );
    }
}


const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AgentProduct);
