import {
  GET_META_REQUEST,
  GET_META_SUCCESS,
  GET_META_FAILURE,
} from "../actions/metaAction";

const initialState = {
  data: {},
};

export default function metaReducer(state = initialState, action) {
  switch (action.type) {
    case GET_META_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_META_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case GET_META_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
