const initialState = {
  lastAddressId: "",
  addressData: null,
  reminders: [],
  address: [],
  profileOrders: [],
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LAST_ADDRESS":
      return { ...state, addressData: null, lastAddressId: action.payload };
    case "SAVE_LAST_ADDRESS":
      return { ...state, addressData: action.payload };
    case "ADD_REMINDERS":
      return { ...state, reminders: [...state.reminders, action.payload] };
    case "FETCH_REMINDERS":
      return { ...state, reminders: action.payload };
    case "DELETE_REMINDERS":
      return {
        ...state,
        reminders: state.reminders.filter((r) => r._id !== action.payload),
      };
    case "UPDATE_REMINDERS":
      return {
        ...state,
        reminders: [
          ...state.reminders.filter((r) => r._id !== action.payload._id),
          action.payload,
        ],
      };
    case "GET_ALL_ADDRESS":
      return { ...state, address: action.payload };
    case "PROFILE_ORDERS":
      return { ...state, profileOrders: action.payload };
    default:
      return state;
  }
};

export default profileReducer;
