import React, { Component } from "react";
import TopNav from "./TopNav/TopNav";
import { Route, Redirect, Switch } from "react-router-dom";
import queryString from "query-string";
import jwtDecode from "jwt-decode";
import CategoriesNavBar from "./CategoriesNavBar/CategoriesNavBar";
import Home from "./Home/Home";
import ContactUs from "./ContactUs";
import Address from "./common/SavedAddress";
import Login from "./Login/Login";
import { connect } from "react-redux";
import { logout, storeUser } from "../store/actions/authActions";
import { getActiveOrders } from "../store/actions/cartActions";
import ProductsLayout from "./Home/ProductsLayout/ProductsLayout";
import ProductDetails from "./Product/Details.js";
import Checkout from "./Checkout/Checkout";
import Footer from "./common/Footer";
import SocialMediaLogin from "./Login/SocialMediaLogin";
import ResetPassword from "./ResetPassword/ResetPassword";
import Corporate from "./Corporate/Corporate";
import CorporateOrder from "./Corporate/CorporateOrder/CorporateOrder";
import CorporateNav from "./Corporate/CorporateNav/CorporateNav";
import { parseJson } from "../constants/utils";
import AgentProduct from "./AgentProduct/AgentProduct";
import { getRequest } from "../store/services";
import PrivacyPolicy from "./Privacy-Policy";
import OrderConfirmation from "./OrderConfirmation/OrderConfirmation";
import TermsOfUse from "./TermsOfUse";
import AboutUs from "./AboutUs";
import { AppBar } from "@material-ui/core";
import FAQ from "./FAQs";
import CareGuide from "./CareGuide";
import CareGuideDetail from "./CareGuidDetail";
import HideOnScroll from "./HideOnScroll";
import BottomNavBar from "./BottomNavBar/BottomNavBar";
import SecondaryNav from "./TopNav/SecondaryNav";
// import categories from "./BottomNavBar/CategoriesTabs";
import Information from "./Profile/Information";
import OnlySearchNav from "./TopNav/OnlySearchNav";
import TopNavCart from "./TopNav/TopNavCart";
import CheckoutPaymentFailed from "./Checkout/CheckoutFaildPayment";
import { Helmet } from "react-helmet";
import PageNotFound from "./PageNotFound";
import ReactHelmet from "./ReactHelmet";

class Layout extends Component {
  // componentDidUpdate = async (prevProps) => {
  //   if (prevProps.currency !== this.props.currency) {
  //
  //     await this.props.getActiveOrders();
  //   }
  // };
  componentDidMount = async () => {
    const { location, history } = this.props;
    history.listen(() => {
      this.props.clearMeta(); // clear message when changing location
    });
    this.props.getActiveOrders();
    var pathParts = location.pathname.split("/");
    let query = queryString.parse(location.search);
    let ress = await getRequest("currencies/getCurrency");
    if (ress.data?.currencyRates) {
      // this.setState({ currencyRates: ress.data.currencyRates });
      this.props.currencyRates(ress.data?.currencyRates);
    }
    if (query.meta) {
      const decoded = jwtDecode(query.meta);
      getRequest("users/get/" + decoded.id, null, true)
        .then((response) => {
          var user = { ...response?.data, token: query.meta };
          this.props.storeUser(user, null, false);
          // router.history.push('/profile/my-account')
        })
        .catch((error) => console.error(error));
    }

    let prevScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", () => {
      const mainNav = document.getElementById("hide-topNav");
      const secondaryNav = document.getElementById("hide-secondaryNav");
      const productsLayout = document.getElementById("hide-onScrollY");
      const currentScrollPosition = window.pageYOffset;
      if (mainNav !== null && secondaryNav !== null) {
        if (window.pageYOffset > 528) {
          mainNav.style.top = "-300px";
          secondaryNav.style.top = "0px";
        } else {
          mainNav.style.top = "0px";
          secondaryNav.style.top = "-100px";
        }
      }
      if (productsLayout !== null) {
        const currentScrollPositionFinal =
          currentScrollPosition < 0 ? 0 : currentScrollPosition;
        if (prevScrollPosition >= currentScrollPositionFinal) {
          productsLayout.style.top = "0px";
        } else {
          productsLayout.style.top = "-100px";
        }
        prevScrollPosition = currentScrollPositionFinal;
      }
    });
  };

  render() {
    //Redirection from old urls to home page
    let path = window.location.pathname;
    let filterPath = path.split("/");
    if (filterPath?.[1] === "uae") {
      return <Redirect to="/" />;
    }
    const { token, user, logoutNow, location, corpCats } = this.props;
    var pathParts = location.pathname.split("/");
    let query = queryString.parse(location.search);
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          token !== null ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    );
    const isCorporate = pathParts?.[1] === "corporate" ? true : false;
    const meta = this.props.metaData;
    return (
      <div>
        <Route
          render={() => {
            if (
              pathParts?.[1] !== "profile" &&
              pathParts?.[1] !== "login" &&
              pathParts?.[1] !== "checkout" &&
              pathParts?.[1] !== "uae" &&
              pathParts?.[1] !== "order-confirmation" &&
              pathParts?.[1] !== "contact-us" &&
              pathParts?.[1] !== "categories" &&
              pathParts?.[1] !== "checkout-page" &&
              !isCorporate
            ) {
              return (
                <>
                  <AppBar
                    color="transparent"
                    elevation={0}
                    position="sticky"
                    id={"hide-topNav"}
                    style={{ boxShadow: "none !important" }}
                  >
                    <TopNav
                      token={this.props.token}
                      name={user && user.name}
                      logout={() => logoutNow()}
                    />
                    <AppBar position="static">
                      <Route
                        render={() => {
                          if (
                            pathParts?.[1] !== "profile" &&
                            pathParts?.[1] !== "login" &&
                            pathParts?.[1] !== "checkout" &&
                            pathParts?.[1] !== "order-confirmation" &&
                            !isCorporate
                          ) {
                            return (
                              <CategoriesNavBar logout={() => logoutNow()} />
                            );
                          } else if (isCorporate) {
                            return (
                              <CorporateNav categories={parseJson(corpCats)} />
                            );
                          }
                        }}
                      />
                    </AppBar>
                  </AppBar>
                  <Route
                    render={() => {
                      if (pathParts?.[1] !== "/") {
                        return <OnlySearchNav />;
                      }
                    }}
                  />
                </>
              );
            } else {
              return <SecondaryNav token={token} />;
            }
          }}
        ></Route>

        <Route
          render={() => {
            if (
              pathParts?.[1] !== "cartsummary" &&
              pathParts?.[1] !== "profile" &&
              pathParts?.[2] !== "my-account" &&
              pathParts?.[1] !== "corporate" &&
              pathParts?.[1] !== "contact-us" &&
              pathParts?.[1] !== "categories" &&
              pathParts?.[1] !== "address"
            ) {
              return (
                <HideOnScroll>
                  <AppBar elevation={0} position="fixed">
                    <BottomNavBar />
                  </AppBar>
                </HideOnScroll>
              );
            }
          }}
        />
        <Helmet>
          <link
            rel="canonical"
            href={`https://giftabliss.com${window.location.pathname}${
              window.location.hash ? window.location.hash : ""
            }`}
          />
        </Helmet>
        <ReactHelmet title={meta?.title} description={meta?.description} />
        <Switch>
          <Route path="/" component={Home} exact={true} {...this.props} />
          <Route path="/login" component={Login} exact={true} />
          <Route
            path="/sociallogin"
            component={SocialMediaLogin}
            exatc={true}
          />
          <Route path="/contact-us" component={ContactUs} exact={true} />
          <Route
            path="/privacy-policy"
            component={PrivacyPolicy}
            exact={true}
          />
          <Route path="/termsOfUse" component={TermsOfUse} exact={true} />
          <Route path="/AboutUs" component={AboutUs} exact={true} />
          <Route path={"/address/:cartId"} component={Address} exact />
          <Route path="/FAQ" component={FAQ} exact={true} />
          <Route path="/search" component={ProductsLayout} exact={true} />
          <Route path="/corporate" component={Corporate} exact />
          <Route path="/forget-pass" component={ResetPassword} />
          <Route path="/care-guide" component={CareGuide} />
          <Route path="/care-guide-detail" component={CareGuideDetail} />
          <Route path="/createAgentCustomProduct" component={AgentProduct} />
          {/* <Route path="/categories" component={categories} exact /> */}
          <PrivateRoute path="/profile/:sec/" component={Information} />
          <PrivateRoute path="/checkout" component={Checkout} />
          <Route path="/order-confirmation/:id" component={OrderConfirmation} />
          <Route path="/checkout-page" component={CheckoutPaymentFailed} />
          <Route path="/cartsummary" component={TopNavCart} exact />
          <Route
            path="/gift/:id"
            component={isCorporate ? CorporateOrder : ProductDetails}
            exact={true}
          />
          {pathParts?.[1] !== "profile" &&
            pathParts?.[1] !== "order-confirmation" &&
            pathParts?.[1] !== "address" &&
            pathParts?.[1] !== "gift" && (
              <React.Fragment>
                <Switch>
                  <Route
                    path="/:pcategory"
                    component={ProductsLayout}
                    exact={true}
                  />
                  <Route
                    path="/:pcategory/:cCategory"
                    component={ProductsLayout}
                    exact={true}
                  />
                  <Route
                    path="/:pcategory/:cCategory/:ccCategory"
                    component={ProductsLayout}
                    exact={true}
                  />
                  {/* <Route
                            path="/:category/:temp/:catId/:product/:id"
                            component={isCorporate ? CorporateOrder : ProductDetails}
                            exact={true}
                        /> */}
                  <Route path={"*"} component={PageNotFound} />
                </Switch>
              </React.Fragment>
            )}

          <Route path={"/404"} component={PageNotFound} />
          <Route path={"*"} component={PageNotFound} />
        </Switch>
        <Route
          render={() => {
            if (
              pathParts?.[1] !== "categories" &&
              pathParts?.[1] !== "profile" &&
              pathParts?.[1] !== "checkout" &&
              pathParts?.[1] !== "address" &&
              pathParts?.[1] !== "cartsummary"
            ) {
              return <Footer />;
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state?.auth?.token,
  user: state?.auth?.user,
  corpCats: state?.others?.corpCats,
  currency: state?.auth?.currency,
  metaData: state?.meta?.data,
});
const mapDispatchToProps = (dispatch, router) => {
  return {
    logoutNow: () => dispatch(logout(router)),
    storeUser: (data) => dispatch(storeUser(data, router, false)),
    currencyRates: (data) =>
      dispatch({ type: "CURRENCY_RATES", payload: data }),
    getActiveOrders: () => dispatch(getActiveOrders()),
    clearMeta: () => dispatch({ type: "GET_META_SUCCESS", payload: null }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
