import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./PaymentDetails.module.css";
import { withRouter } from "react-router-dom";
import { sha256 } from "js-sha256";
import { Notify } from "../../../constants/utils";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  Radio,
  Icon,
  ExpansionPanelSummary,
  Grid,
  TextField,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import paypalLogo from "../../../assets/images/Paypal.webp";
import Paypal from "./Paypal";
import Loader from "../../common/Loader";
import cod from "../../../assets/images/cod.PNG";
import {
  finalizeCheckout,
  payment,
  convertUsingApi,
  finalizeCheckout2,
  updateOrder,
  decreaseAmount,
  sendEmail,
  createDuplicateOrder
} from "../../../store/actions/cartActions";
import axios from "axios";

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      showPaypalLoading: false,
      html: "",
      visible: false,
      orderId: "",
      signature: "",
      rate: "",
      amount: 20.392929,
      cardNoDebit: "",
      nameDebit: "",
      yearDebit: "",
      monthDebit: "",
      cvvDebit: "",
      cardNoCredit: "",
      nameCredit: "",
      yearCredit: "",
      expanded: "credit/debit",
      ip:'',
    };
    this.formRef = React.createRef();
  }
  makeTokenizationRequest = async (data) => {
    let passPhrase = process.env.REACT_APP_SHA_REQUEST_PHRASE;

    let parameterArray = {
      service_command: "TOKENIZATION",
      language: "en",
      merchant_identifier: process.env.REACT_APP_MERCHANT_IDENTIFIER,
      access_code: process.env.REACT_APP_ACCESS_CODE,
      merchant_reference: data.orderId,
      // token_name:"7de51dfe116140c38658b458e4f6f269"
    };

    const keys = Object.keys(parameterArray).map((key) => key);
    const sortedKeys = keys.sort();
    const values = sortedKeys.map((key) => {
      return parameterArray[key];
    });
    let stringSig = "";
    for (let i = 0; i < keys.length; i++) {
      stringSig = stringSig + sortedKeys[i] + "=" + values[i];
    }

    stringSig = passPhrase + stringSig + passPhrase;
    const signature = sha256(stringSig);
    this.setState({signature},()=>{
      this.formRef.current.submit();
    });
    //
    // let dataObj = await axios.post(
    //     "https://sbcheckout.PayFort.com/FortAPI/paymentPage/",
    //     {},
    //     {
    //       headers: { "Access-Control-Allow-Origin": "*" },
    //       params: {
    //         service_command,
    //         access_code,
    //         merchant_identifier,
    //         merchant_reference,
    //         language,
    //         expiry_date,
    //         card_number,
    //         card_security_code,
    //         signature,
    //       },
    //     }
    // );
    // let strippedString = dataObj.data.replace(/(<([^>]+)>)/gi, "");
    // strippedString = strippedString.replace(/\s/g, "");
    // strippedString = strippedString.replace("ReturnURLvarreturnUrlParams=", "");
    // strippedString = strippedString.replace(";", "");
    // let newObject = JSON.parse(strippedString);
    // return newObject;
  };
  onButtonReady = () => {
    this.setState({ visible: true });
  };
  paymentHandler = async (details, data) => {
    /** Here you can call your backend API
     endpoint and update the database */

    this.setState({ showPaypalLoading: true });
    let dataObj = {
      transactionId: details.purchase_units[0].payments.captures[0].id,
      paymentStatus: true,
      id: this.state.orderId,
      transactionCreatedBy: details.payer.email_address,
      paymentDate: details.create_time,
    };
    await this.props.updateOrder(dataObj);
    let d = { id: this.state.orderId };
    // await this.props.decreaseAmount(d);
    await this.props.sendEmail({ orderId: this.state.orderId });
    Notify("success", "Order has been placed");
    this.props.history.push({
      pathname: "/order-confirmation/" + this.state.orderId,
      state: { orderId: this.state.orderId },
    });
  };
  onCancel = (data) => {
    let dataObj = {
      transactionId: data.orderID,
      paymentStatus: false,
      id: this.state.orderId,
    };
    this.props.updateOrder(dataObj);
    this.props.sendEmail({ orderId: this.state.orderId });
    this.props.history.push({
      pathname: "/order-confirmation/" + this.state.orderId,
      state: { orderId: this.state.orderId },
    });
    Notify("danger", "Payment Failed");
  };
  onCreate = async (data, actions) => {
    let order = await this.checkOut2("paypal");
    // this.setState({ orderId: order.order._id });

    let price = (
        (this.props.customerData?.amount / this.state.rate[this.props.currency]) *
        this.state.rate?.USD
    ).toFixed(2);
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: price,
          },
        },
      ],
    });
  };
  handleSubmit = async () => {
    //
  };
  componentDidMount = async () => {
    let rate = await this.props.convert();
    this.setState({ rate: rate });
    const query = new URLSearchParams(this.props.location.search);
    let orderId = query.get("orderId");
    this.setState({ orderId: orderId });
  };
  // componentDidUpdate(nextProps) {
  //   console.log("nextProps amount", nextProps.customerData.amount);
  // }
  componentDidUpdate() {
    let baseAmountList = { currency: [{ amount: 200, code: "AED" }] };
    let convertToCurrencyList = { currencyCode: "USD" };
    let requestEnvelope = { errorLanguage: "en_US" };
    //
    // this.props.convertCurrency({
    //   baseAmountList,
    //   convertToCurrencyList,
    //   requestEnvelope,
    // });
    // const form = document.getElementById("form1");
    // form.addEventListener("submit", this.handleSubmit);
  }
  render() {
    const { channelSource, expOpen, customerData } = this.props;
    let price = parseFloat(
        (
            (customerData.amount / this.props.currencyRates[this.props.currency]) *
            this.props.currencyRates["AED"]
        ).toFixed(2)
    );

    if (channelSource === "website") {
      return (
          <>
            <ExpansionPanel elevation={2} expanded={expOpen}>
              <ExpansionPanelSummary>
                <Grid container justify="space-between">
                  <div
                      className={`bg_primary badgeE text-white ${styles.numbering}`}
                  >
                    <span className="mx-3">3</span>
                  </div>

                  <Grid item xs={5}>
                    <h5>PAYMENT OPTIONS</h5>
                  </Grid>

                  <Grid item xs={3}></Grid>

                  <Grid item xs={3}></Grid>
                </Grid>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {/*credit card*/}
                {/* <ExpansionPanel elevation={3}>
                <ExpansionPanelSummary expandIcon={<Radio size="small" />}>
                  <h5>AMEX</h5>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Grid container direction="row" justify="space-between">
                    <Grid item xs={5}>
                      <TextField
                        type="number"
                        label="Card Number"
                        onChange={(e) =>
                          this.setState({ cardNumberCredit: e.target.value })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon>credit_card</Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        type="text"
                        label="Name on Card"
                        onChange={(e) =>
                          this.setState({ nameCredit: e.target.value })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon>credit_card</Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    className="mt-4"
                  >
                    <Grid item xs={5} container>
                      <FormControl className={`${styles.cardSelect} mr-4`}>
                        <InputLabel>Month</InputLabel>
                        <Select
                          onChange={(e) =>
                            this.setState({ monthCredit: e.target.value })
                          }
                        >
                          <MenuItem value={"01"}>01</MenuItem>
                          <MenuItem value={"02"}>02</MenuItem>
                          <MenuItem value={"03"}>03</MenuItem>
                          <MenuItem value={"04"}>04</MenuItem>
                          <MenuItem value={"05"}>05</MenuItem>
                          <MenuItem value={"06"}>06</MenuItem>
                          <MenuItem value={"07"}>07</MenuItem>
                          <MenuItem value={"08"}>08</MenuItem>
                          <MenuItem value={"09"}>09</MenuItem>
                          <MenuItem value={"10"}>10</MenuItem>
                          <MenuItem value={"11"}>11</MenuItem>
                          <MenuItem value={"12"}>12</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl className={`${styles.cardSelect} mr-4`}>
                        <InputLabel>Year</InputLabel>
                        <Select
                          onChange={(e) =>
                            this.setState({ yearCredit: e.target.value })
                          }
                        >
                          <MenuItem value={"20"}>2020</MenuItem>
                          <MenuItem value={"21"}>2021</MenuItem>
                          <MenuItem value={"22"}>2022</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        type="text"
                        label="CVV"
                        onChange={(e) =>
                          this.setState({ cvvCredit: e.target.value })
                        }
                        className={styles.cardInput}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon>help_outline</Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      {this.state.errorCredit && (
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          Please fill all the fields
                        </p>
                      )}
                      <Button
                        startIcon={<Icon>locked</Icon>}
                        type="button"
                        variant="contained"
                        className="bg_secondary text-white"
                        onClick={async (e) => {
                          // this.checkOut("credit")
                          console.log("make payment");
                          //await this.makePayment("credit");
                        }}
                      >
                        <span className="mx-4 my-1">
                          PAY AED {this.props.customerData?.amount}
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel> */}
                {/*credit card ends here*/}

                {/*debit card*/}
                <ExpansionPanel
                    elevation={3}
                    className="mt-4"
                    expanded={this.state.expanded === "credit/debit" ? true : false}
                    onClick={() => this.setState({ expanded: "credit/debit" })}
                >
                  <ExpansionPanelSummary
                      expandIcon={
                        <Radio
                            size="small"
                            checked={
                              this.state.expanded === "credit/debit" ? true : false
                            }
                        />
                      }
                  >
                    <h5>Credit/Debit</h5>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails>
                    <Grid container direction="row" justify="space-between">
                      <Grid item xs={5}>
                        <TextField
                            type="number"
                            label="Card Number"
                            value={this.state.cardNumberDebit}
                            onChange={async (e) => {
                              let a = this.state.cardNumberDebit + "";
                              if (a.length < 16) {
                                await this.setState({cardNumberDebit: e.target.value})
                              }

                            }
                            }
                            InputProps={{
                              endAdornment: (
                                  <InputAdornment position="end">
                                    <Icon>credit_card</Icon>
                                  </InputAdornment>
                              ),
                            }}
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <TextField
                            type="text"
                            label="Name on Card"
                            onChange={(e) =>
                                this.setState({ nameDebit: e.target.value })
                            }
                            InputProps={{
                              endAdornment: (
                                  <InputAdornment position="end">
                                    <Icon>credit_card</Icon>
                                  </InputAdornment>
                              ),
                            }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        className="mt-4"
                    >
                      <Grid item xs={5} container>
                        <FormControl className={`${styles.cardSelect} mr-4`}>
                          <InputLabel>Month</InputLabel>
                          <Select
                              onChange={(e) =>
                                  this.setState({ monthDabit: e.target.value })
                              }
                          >
                            <MenuItem value={"01"}>01</MenuItem>
                            <MenuItem value={"02"}>02</MenuItem>
                            <MenuItem value={"03"}>03</MenuItem>
                            <MenuItem value={"04"}>04</MenuItem>
                            <MenuItem value={"05"}>05</MenuItem>
                            <MenuItem value={"06"}>06</MenuItem>
                            <MenuItem value={"07"}>07</MenuItem>
                            <MenuItem value={"08"}>08</MenuItem>
                            <MenuItem value={"09"}>09</MenuItem>
                            <MenuItem value={"10"}>10</MenuItem>
                            <MenuItem value={"11"}>11</MenuItem>
                            <MenuItem value={"12"}>12</MenuItem>
                          </Select>
                        </FormControl>

                        <FormControl className={`${styles.cardSelect} mr-4`}>
                          <InputLabel>Year</InputLabel>
                          <Select
                              onChange={(e) =>
                                  this.setState({ yearDabit: e.target.value })
                              }
                          >
                            <MenuItem value={"20"}>2020</MenuItem>
                            <MenuItem value={"21"}>2021</MenuItem>
                            <MenuItem value={"22"}>2022</MenuItem>
                            <MenuItem value={"23"}>2023</MenuItem>
                            <MenuItem value={"24"}>2024</MenuItem>
                            <MenuItem value={"25"}>2025</MenuItem>
                            <MenuItem value={"26"}>2026</MenuItem>
                            <MenuItem value={"27"}>2027</MenuItem>
                            <MenuItem value={"28"}>2028</MenuItem>
                            <MenuItem value={"29"}>2029</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                            type="text"
                            label="CVV"
                            className={styles.cardInput}
                            onChange={(e) =>
                                this.setState({ cvvDebit: e.target.value })
                            }
                            InputProps={{
                              endAdornment: (
                                  <InputAdornment position="end">
                                    <Icon>help_outline</Icon>
                                  </InputAdornment>
                              ),
                            }}
                        />
                      </Grid>

                      <Grid item xs={5}>
                        {this.state.errorDebit && (
                            <p style={{ color: "red", fontWeight: "bold" }}>
                              Please fill all the fields
                            </p>
                        )}
                        <Button
                            startIcon={<Icon>locked</Icon>}
                            type="button"
                            variant="contained"
                            className="bg_secondary text-white"
                            onClick={async (e) => {
                              // this.handleSubmit();
                              await this.makePayment("debit");
                            }}
                        >
                          <Loader open={this.state.showLoading} />
                          <span className="mx-4 my-1">PAY AED {price}</span>
                        </Button>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {/*credit card ends here*/}
                <div>
                  <form
                      METHOD="post"
                      ACTION={process.env.REACT_APP_TOKENIZATION_API}
                      id="form1"
                      ref={this.formRef}
                      onSubmit={this.submitPyamentForm}
                      name="form1"
                  >
                    <input
                        type="hidden"
                        NAME="service_command"
                        value="TOKENIZATION"
                    />
                    <input type="hidden" NAME="language" value="en" />
                    <input
                        type="hidden"
                        NAME="merchant_identifier"
                        value={process.env.REACT_APP_MERCHANT_IDENTIFIER}
                    />
                    <input
                        type="hidden"
                        NAME="access_code"
                        value={process.env.REACT_APP_ACCESS_CODE}
                    />
                    <input
                        type="hidden"
                        NAME="signature"
                        value={this.state.signature}
                    />
                    <input type="hidden" NAME="merchant_reference" value={this.state.orderId} />
                    <input
                        type="hidden"
                        NAME="card_number"
                        value={this.state.cardNumberDebit}
                    />
                    {/*<input*/}
                    {/*    type="hidden"*/}
                    {/*    NAME="token_name"*/}
                    {/*    value={"7de51dfe116140c38658b458e4f6f269"}*/}
                    {/*/>*/}

                    <input type="hidden" NAME="expiry_date" value={this.state.yearDabit+this.state.monthDabit} />
                    <input type="hidden" NAME="card_security_code" value={this.state.cvvDebit} />
                    {/*<input type="submit" value="submit" id="submit2" name="" />*/}
                  </form>
                </div>

                {/*paypal*/}
                <ExpansionPanel
                    elevation={3}
                    className="mt-4"
                    expanded={this.state.expanded === "paypal" ? true : false}
                    onClick={() => this.setState({ expanded: "paypal" })}
                >
                  <ExpansionPanelSummary
                      expandIcon={
                        <Radio
                            size="small"
                            checked={this.state.expanded === "paypal" ? true : false}
                        />
                      }
                  >
                    <h5>PayPal</h5>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails>
                    <Button
                        variant="outlined"
                        type="button"
                        startIcon={<Radio size="small" checked />}
                        disabled
                    >
                      <img loading="lazy" alt="Paypal" src={paypalLogo} />
                    </Button>

                    <div className="mt-4">
                      <Button
                          startIcon={<Icon>locked</Icon>}
                          type="button"
                          disabled={!this.state.visible}
                          variant="contained"
                          //style={{ width: " %" }}
                          className="bg_secondary text-white"
                          // onClick={(e) => this.checkOut("paypal")}
                      >
                        <Loader open={this.state.showPaypalLoading} />
                        <div
                            style={{
                              zIndex: 999,
                              opacity: 0,
                              width: "100%",
                              position: "absolute",
                            }}
                        >
                          <Paypal
                              // amount={

                              //   // this.state.amount
                              // }
                              onButtonReady={this.onButtonReady}
                              onCancel={this.onCancel}
                              onCreate={this.onCreate}
                              //currency={"USD"}
                              onSuccess={this.paymentHandler}
                          />
                        </div>
                        <span className="mx-4 my-1">PAY AED {price}</span>
                      </Button>
                    </div>
                    {/* <button>

                    Pay something
                  </button> */}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {/*paypal ends here*/}

                {/*COD*/}
                <ExpansionPanel
                    elevation={3}
                    className="mt-4"
                    expanded={this.state.expanded === "cod" ? true : false}
                    onClick={() => this.setState({ expanded: "cod" })}
                >
                  <ExpansionPanelSummary
                      expandIcon={
                        <Radio
                            size="small"
                            checked={this.state.expanded === "cod" ? true : false}
                        />
                      }
                  >
                    <h5>Cash On Delivery</h5>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails>
                    <Button
                        variant="outlined"
                        type="button"
                        startIcon={<Radio size="small" checked />}
                        disabled
                    >
                      <img loading="lazy" alt="COD" src={cod} />
                    </Button>

                    <div className="mt-4">
                      <Button
                          startIcon={<Icon>locked</Icon>}
                          type="button"
                          variant="contained"
                          className="bg_secondary text-white"
                          onClick={(e) => this.checkOut("cod")}
                      >
                        <span className="mx-4 my-1">PAY AED {price}</span>
                      </Button>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {/*COD ends here*/}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {this.state.html && (
                <span dangerouslySetInnerHTML={{ __html: this.state.html }}></span>
            )}
          </>
      );
    } else {
      return (
          <ExpansionPanel elevation={2} expanded={expOpen}>
            <ExpansionPanelSummary>
              <Grid container justify="space-between">
                <div
                    className={`bg_primary badgeE text-white ${styles.numbering}`}
                >
                  <span className="mx-3">3</span>
                </div>

                <Grid item xs={5}>
                  <h5>PAYMENT OPTIONS</h5>
                </Grid>

                <Grid item xs={3}></Grid>

                <Grid item xs={3}></Grid>
              </Grid>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              {/*payment link*/}
              <ExpansionPanel elevation={3} className="mt-4">
                <ExpansionPanelSummary expandIcon={<KeyboardArrowDown />}>
                  <h5>Payment Link</h5>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails className="customizedTable">
                  <table className="table">
                    <tbody>
                    <tr>
                      <td>Cart No: 36541286</td>
                      <td>Customer Name: Test</td>
                    </tr>
                    <tr>
                      <td>EMAIL to</td>
                      <td>CC to</td>
                    </tr>
                    <tr>
                      <td>kln40066@zzrrgg.com</td>
                      <td>
                        <TextField
                            size="small"
                            type="email"
                            variant="outlined"
                            placeholder="Enter alternate email"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>SMS to</td>
                      <td>CC to</td>
                    </tr>
                    <tr>
                      <td>685423658954</td>
                      <td>
                        <TextField
                            size="small"
                            type="number"
                            variant="outlined"
                            placeholder="Enter alternate mobile number"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Button
                            type="button"
                            variant="contained"
                            className="text-white bg-danger"
                        >
                          CANCEL
                        </Button>
                      </td>
                      <td>
                        <Button
                            type="button"
                            variant="contained"
                            className="text-white bg_secondary"
                            onClick={() => this.props.finalizeCheckout("payl")}
                        >
                          SEND PAYMENT LINK
                        </Button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/*payment link ends here*/}

              {/*moto*/}
              <ExpansionPanel elevation={3} className="mt-4">
                <ExpansionPanelSummary expandIcon={<KeyboardArrowDown />}>
                  <h5>Moto Payment</h5>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails className="customizedTable">
                  <table className="table">
                    <tbody>
                    <tr>
                      <td>Cart No: 36541286</td>
                      <td>Customer Name: Test</td>
                    </tr>
                    <tr>
                      <td>Moto Payment</td>
                      <td>Total Amount: AED 248</td>
                    </tr>
                    <tr>
                      <td>
                        <TextField
                            size="small"
                            type="email"
                            variant="outlined"
                            placeholder="Transaction ID"
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <Button
                            type="button"
                            variant="contained"
                            className="text-white bg-danger"
                        >
                          CANCEL
                        </Button>
                      </td>
                      <td>
                        <Button
                            type="button"
                            variant="contained"
                            className="text-white bg_secondary"
                            onClick={() => this.props.finalizeCheckout("moto")}
                        >
                          MAKE PAYMENT
                        </Button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/*moto ends here*/}

              {/*COD*/}
              <ExpansionPanel elevation={3} className="mt-4">
                <ExpansionPanelSummary expandIcon={<KeyboardArrowDown />}>
                  <h5>Cash on Delivery</h5>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails className="customizedTable">
                  <table className="table">
                    <tbody>
                    <tr>
                      <td>Cart No: 36541286</td>
                      <td>Customer Name: Test</td>
                    </tr>
                    <tr>
                      <td>Total Amount: AED 248</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <Button
                            type="button"
                            variant="contained"
                            className="text-white bg-danger"
                        >
                          CANCEL
                        </Button>
                      </td>
                      <td>
                        <Button
                            type="button"
                            variant="contained"
                            className="text-white bg_secondary"
                            onClick={() => this.checkOut}
                        >
                          MAKE PAYMENT
                        </Button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              {/*COD ends here*/}
            </ExpansionPanelDetails>
          </ExpansionPanel>
      );
    }
  }
  checkOut = async (type) => {
    let data = {
      ...this.props.customerData,
      currency: this.props.currency,
      paymentMethod: type,
      id:this.state.orderId
    };
    await this.props.updateOrder(data);
    // await this.props.decreaseAmount(d);
    await this.props.sendEmail({ orderId: this.state.orderId });
    this.props.history.push({
      pathname: "/order-confirmation/" + this.state.orderId,
      state: { orderId: this.state.orderId },
    });
  };
  checkOut2 =async(type) => {
    let el = document.getElementById("device_fingerprint");
    let dF= el.value;
    await this.getIp();
    let data = {
      ipAddress:this.state.ip,
      paymentMethod: type,
      deviceFingerPrint:dF,
      currency: this.props.currency,
      id:this.state.orderId
    };
    return await this.props.updateOrder(data);
  };
  makePayment = async (type) => {
    this.setState({ showLoading: true });
    let data = {};
    if (type === "credit") {
      if (
          this.state.nameCredit.trim() === "" ||
          this.state.cardNumberCredit.trim() === "" ||
          this.state.cvvCredit.trim() === "" ||
          this.state.yearCredit === "" ||
          this.state.monthCredit === ""
      ) {
        this.setState({ showLoading: false });
        this.setState({ errorCredit: true });
        setTimeout(() => {
          this.setState({ errorCredit: false });
        }, 3000);
        return;
      } else {
        data.name = this.state.nameCredit;
        data.cardNumber = this.state.cardNumberCredit;
        data.cvv = this.state.cvvCredit;
        data.expiryDate = this.state.yearCredit + this.state.monthCredit;
      }
    } else {
      if (
          this.state.nameDebit.trim() === "" ||
          this.state.cardNumberDebit.trim() === "" ||
          this.state.cvvDebit.trim() === "" ||
          this.state.yearDabit === "" ||
          this.state.monthDabit === ""
      ) {
        this.setState({ showLoading: false });
        this.setState({ errorDebit: true });
        setTimeout(() => {
          this.setState({ errorDebit: false });
        }, 3000);
        return;
      } else {
        data.name = this.state.nameDebit;
        data.cardNumber = this.state.cardNumberDebit;
        data.cvv = this.state.cvvDebit;
        data.expiryDate = this.state.yearDabit + this.state.monthDabit;
      }
    }
    let amount = parseFloat(
        (
            (this.props.customerData.amount /
                this.props.currencyRates[this.props.currency]) *
            this.props.currencyRates["AED"]
        ).toFixed(2)
    );


    let order = await this.checkOut2("Payfort");
    let newOrder = await this.props.createDuplicateOrder(this.state.orderId);
    await this.setState({orderId:newOrder._id})
    data.amount = amount;
    data.orderId = newOrder._id;
    data.customerEmail = this.props.customerData.senderEmail;
    await this.makeTokenizationRequest(data);

    // await this.props.payment(data);
  };
  submitPyamentForm=(e)=>{
    e.preventDefault();
    return false;
  }
  getIp=async ()=>{
    let apiKey = "1eb1845701bc4709bc6dad9cd7736a66";
    let data =  await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key="+apiKey);

    await this.setState({ip:data.data['ip_address']});
  };
}

const mapStateToProps = (state) => ({
  channelSource: state?.auth?.user?.channelSource,
  currency: state?.auth.currency,
  currencyRates: state?.others?.currencyRates,
});

const mapDispatchToProps = (dispatch, router) => ({
  finalizeCheckout: (data) => dispatch(finalizeCheckout(data, router)),
  payment: (data) => dispatch(payment(data, router)),
  convert: () => dispatch(convertUsingApi()),
  createPaypalOrder: (data) => dispatch(finalizeCheckout2(data, router)),
  updateOrder: (data) => dispatch(updateOrder(data, router)),
  decreaseAmount: (data) => dispatch(decreaseAmount(data)),
  sendEmail: (data) => dispatch(sendEmail(data)),
  createDuplicateOrder:(id)=>dispatch(createDuplicateOrder(id))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PaymentDetails)
);
