import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { Notify } from "../../constants/utils";
import {
  setLastAddress,
  deleteAdress,
  getAllAdress,
} from "../../store/actions/profileAction";
import { Collapse } from "@material-ui/core";
import img from "../../assets/images/Logo.webp";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class CustomOrderView extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {
  //         addresses: []
  //     };
  // }
  state = {
    flag: [],
    recipientFlag: [],
    deliveryFlag: [],
    order: [],
    productData: [],
    shippingPrice: "",
    totalProductPrice: "",
    timeSlots: [],
    renderFlag: false,
    grandTotal: "",
  };

  async componentDidMount() {
    let id = "";
    if (this.props.location.search) {
      id = this.props.location.search.split("=")[1];
    }

    try {
      // let id = 1437;
      const token = localStorage.getItem("token");
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}orders/get/` + id,
        {
          headers: { "access-token": token },
        }
      );
      if (res) {
        let timeSlots = [];
        let arr = new Array(res.data.data.length).fill(false);
        let grandTotal = 0;
        await this.setState({
          order: res.data.data,
          renderFlag: true,
          flag: JSON.parse(JSON.stringify(arr)),
          recipientFlag: JSON.parse(JSON.stringify(arr)),
          deliveryFlag: JSON.parse(JSON.stringify(arr)),
        });
      }
    } catch (err) {
      console.log(err);
      Notify("danger", "Error while fetching Order", "Error");
    }
  }

  render() {
    return (
      <div className={"w-100"}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p>{this.state.order._id}</p>
        </div>

        {this.state.renderFlag && this.renderAllOrders(this.state.order)}

        <div
          style={{
            display: "flex",
            marginTop: "20px",
            border: "1px solid #d9dcdc",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            justifyContent: "space-between",
            paddingRight: "20px",
            alignItems: "center",
            // boxShadow: "0px 0px 3px 0.00px #eff0f1",
          }}
        >
          <p
            style={{
              fontSize: 20,
              marginTop: "10px",
              marginBottom: "10px",
              marginLeft: "10px",
              color: "#333",
            }}
          >
            Grand Total
          </p>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              border: "1px solid #d9dcdc",
              flexDirection: "column",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "20px",
              paddingBotom: "20px",
              marginBottom: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p>Grand Total:</p>
              <p>
                {this.props.currency}{" "}
                {this.state.order.length > 0 &&
                  parseFloat(
                    (
                      (this.state.order[0][0].order.amount /
                        this.props.currencyRates["AED"]) *
                      this.props.currencyRates[this.props.currency]
                    ).toFixed(2)
                  )}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  getSubOrder = (order) => {
    let ORDER = order;
    let count = 1;
    let counter = 0;
    //let amount = order?.amount;
    let id = order?._id;
    let index;
    let list = [];
    let carts = order?.carts && JSON.parse(JSON.stringify(order.carts));
    let arrayDates = [];
    if (carts) {
      for (let i = 0; i < carts.length; i++) {
        let filetered = arrayDates.filter(
          (date) =>
            date === moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
        );
        if (filetered.length > 0) {
        } else {
          arrayDates.push(
            moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
          );
        }
      }
    }
    if (arrayDates.length > 0) {
      for (let j = 0; j < arrayDates.length; j++) {
        let dateCarts = carts.filter(
          (cart) =>
            moment(cart.shippingDate).utc(true).format("Y-MM-DD") ===
            arrayDates[j]
        );

        let addressArray = [];
        for (let k = 0; k < dateCarts.length; k++) {
          let addressCarts = addressArray.filter(
            (address) => address === dateCarts[k].address._id
          );
          if (addressCarts.length > 0) {
          } else {
            addressArray.push(dateCarts[k].address._id);
          }
        }
        for (let m = 0; m < addressArray.length; m++) {
          let addressCarts = dateCarts.filter(
            (dateCart) => dateCart.address._id === addressArray[m]
          );
          let timeSlotsArray = [];
          for (let n = 0; n < addressCarts.length; n++) {
            let timeCarts = timeSlotsArray.filter(
              (timeSlot) => timeSlot === addressCarts[n].shippingTime
            );
            if (timeCarts.length > 0) {
            } else {
              timeSlotsArray.push(addressCarts[n].shippingTime);
            }
          }

          for (let l = 0; l < timeSlotsArray.length; l++) {
            let timeCarts = addressCarts.filter(
              (addressCart) => addressCart.shippingTime === timeSlotsArray[l]
            );
            let amount = 0;
            let productIds = "";
            let shippingPrice = 0;
            let productDescription = "";
            for (let r = 0; r < timeCarts.length; r++) {
              if (shippingPrice < timeCarts[r].shippingPrice) {
                shippingPrice = timeCarts[r].shippingPrice;
              }
              amount = amount + timeCarts[r].amount * timeCarts[r].quantity;
              productIds = productIds + timeCarts[r].products[0]._id + ",";
              productDescription =
                productDescription + timeCarts[r].products[0].description;
              if (timeCarts[r].addons.length > 0) {
                for (let s = 0; s < timeCarts[r].addons.length; s++) {
                  amount =
                    amount +
                    timeCarts[r].addons[s].amount *
                      timeCarts[r].addons[s].quantity;
                  productIds =
                    productIds + timeCarts[r].addons[s].products[0]._id + ",";
                  productDescription =
                    productDescription +
                    timeCarts[r].addons[s].products[0].description;
                }
              }
            }
            amount = amount + shippingPrice;
            let orderId = ORDER._id;
            let party = null;
            if (timeCarts[0].partyId) {
              let partyId = timeCarts[0].partyId;
              party = timeCarts[0].parties.filter(
                (party) => party._id === partyId
              );
            }

            list.push(
              <div>
                <div>
                  <p>
                    {timeCarts[0]?.deliveryStatus
                      ? timeCarts[0]?.deliveryStatus
                      : "Pending"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    border: "1px solid #d9dcdc",
                    borderBottom: "0px",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    // boxShadow: "0px 0px 3px 0.00px #eff0f1",
                  }}
                >
                  <p
                    style={{
                      fontSize: 20,
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                      color: "#333",
                    }}
                  >
                    Product Details
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    border: "1px solid #d9dcdc",
                    flexDirection: "column",
                  }}
                >
                  {timeCarts &&
                    timeCarts.map((cart) => {
                      return (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",

                              marginLeft: "20px",
                              marginTop: "20px",
                              marginBottom: "20px",
                              marginRight: "20px",
                            }}
                          >
                            <img
                              src={cart.products?.[0].images?.[0]}
                              loading="lazy"
                              width="100px"
                              height="100px"
                              alt={cart?.product?.[0]?.title}
                            />
                            <div style={{ marginLeft: "20px" }}>
                              <p>{cart.products?.[0].title}</p>
                              <p>QTY: {cart.quantity}</p>
                              <p>Price: AED {cart.amount}</p>
                            </div>
                          </div>
                          {cart.addons?.map((addon) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "20px",
                                marginTop: "20px",
                                marginBottom: "20px",
                                justifyContent: "space-between",
                              }}
                            >
                              <img
                                src={addon?.products?.[0].images?.[0]}
                                width="100px"
                                loading="lazy"
                                height="100px"
                                alt={addon?.products?.[0]?.title}
                              />
                              <div style={{ marginLeft: "20px" }}>
                                <p>{addon?.products?.[0].title}</p>
                                <p>QTY: {addon.qty}</p>
                                <p>Price: AED {addon.amount}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                </div>
                <div
                  onClick={(e) => {
                    let flag = this.state.flag;

                    flag[counter] = !flag[counter];

                    this.setState({ flag: flag });
                  }}
                  className={`flag-sel-${counter}`}
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    border: "1px solid #d9dcdc",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    justifyContent: "space-between",
                    paddingRight: "20px",
                    alignItems: "center",
                    // boxShadow: "0px 0px 3px 0.00px #eff0f1",
                  }}
                >
                  <p
                    style={{
                      fontSize: 20,
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                      color: "#333",
                    }}
                  >
                    Price Details
                  </p>
                  {!this.state.flag[counter] && <ChevronRightIcon />}
                  {this.state.flag[counter] && <ExpandMoreIcon />}
                </div>
                <Collapse in={this.state.flag[counter]}>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #d9dcdc",
                      flexDirection: "column",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "20px",
                      paddingBotom: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Shipping Charges:</p>
                      <p>
                        AED: {shippingPrice}
                        {/* 19892 */}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Total:</p>
                      <p>
                        AED: {amount}
                        {/* 12323 */}
                      </p>
                    </div>
                  </div>
                </Collapse>

                <div
                  onClick={(e) => {
                    let deliveryFlag = this.state.deliveryFlag;
                    deliveryFlag[counter] = !deliveryFlag[counter];
                    this.setState({ deliveryFlag: deliveryFlag });
                  }}
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    border: "1px solid #d9dcdc",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    justifyContent: "space-between",
                    paddingRight: "20px",
                    alignItems: "center",
                    // boxShadow: "0px 0px 3px 0.00px #eff0f1",
                  }}
                >
                  <p
                    style={{
                      fontSize: 20,
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                      color: "#333",
                    }}
                  >
                    Delivery Details
                  </p>
                  {!this.state.deliveryFlag[counter] && <ChevronRightIcon />}
                  {this.state.deliveryFlag[counter] && <ExpandMoreIcon />}
                </div>
                <Collapse in={this.state.deliveryFlag[counter]}>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #d9dcdc",
                      flexDirection: "column",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "20px",
                      paddingBotom: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginLeft: "15px",
                      }}
                    >
                      <p>
                        {/* Delivery Date: 1123112 */}
                        {timeCarts[0]?.shippingDate}
                      </p>
                      <p>
                        {/* Delivery Time: 233:4234 */}
                        {timeCarts[0]?.shippingTime}
                      </p>
                      <p>
                        {/* Delivery Mode: Morning */}
                        {timeCarts[0]?.shippingType}
                      </p>
                    </div>
                  </div>
                </Collapse>

                <div
                  onClick={(e) => {
                    let recipientFlag = this.state.recipientFlag;
                    recipientFlag[counter] = !recipientFlag[counter];
                    this.setState({
                      recipientFlag: recipientFlag,
                    });
                  }}
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    border: "1px solid #d9dcdc",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    justifyContent: "space-between",
                    paddingRight: "20px",
                    alignItems: "center",
                    // boxShadow: "0px 0px 3px 0.00px #eff0f1",
                  }}
                >
                  <p
                    style={{
                      fontSize: 20,
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                      color: "#333",
                    }}
                  >
                    Recipient Details
                  </p>
                  {!this.state.recipientFlag[counter] && <ChevronRightIcon />}
                  {this.state.recipientFlag[counter] && <ExpandMoreIcon />}
                </div>
                <Collapse in={this.state.recipientFlag[counter]}>
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #d9dcdc",
                      flexDirection: "column",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "20px",
                      paddingBotom: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{timeCarts[0].address.recipientAddress}</p>
                      <p>
                        {timeCarts[0].address.landmark},{" "}
                        {timeCarts[0].address.city}
                      </p>
                      <p>Mob: {timeCarts[0].address.phone}</p>
                      {/* something */}
                    </div>
                  </div>
                </Collapse>
              </div>
            );
            counter++;
          }
        }
      }
    }
    return list;
  };

  renderAllOrders = () => {
    let aa = JSON.parse(JSON.stringify(this.state.order));
    let list = aa.map((order, index) => {
      let amount = 0;
      let shipping = 0;
      order.map((o) => {
        let a = parseFloat(
          (
            (o.amount / this.props.currencyRates["AED"]) *
            this.props.currencyRates[this.props.currency]
          ).toFixed(2)
        );
        o.amount = a;
        let s = parseFloat(
          (
            (o.shippingPrice / this.props.currencyRates["AED"]) *
            this.props.currencyRates[this.props.currency]
          ).toFixed(2)
        );
        if (shipping < s) {
          shipping = s;
        }
        if (o.addons.length > 0) {
          o.addons.map((addon) => {
            let b = parseFloat(
              (
                (addon.amount / this.props.currencyRates["AED"]) *
                this.props.currencyRates[this.props.currency]
              ).toFixed(2)
            );
            addon.amount = b;
            amount = amount + b * addon.quantity;
          });
        }
        amount = amount + a * o.quantity;
      });
      amount = amount + shipping;

        let status='';
        if(order[0].order?.paymentMethod==='cod'){
            if(order[0].deliveryStatus==='NOT_ALLOCATED'){
                status = 'Not Allocated';
            }
            if(order[0].deliveryStatus===null && order[0]?.partyId===null ){
                status='Allocated'
            }
            if(order[0]?.partyId){
                status='Accepted'
            }
            if(order[0].deliveryStatus!==null && order[0].deliveryStatus!=='NOT_ALLOCATED'){
                status=order[0].deliveryStatus;
            }
        }
        else{
            status='Waiting';
            if(order[0]?.order?.paymentStatus){
                if(order[0].deliveryStatus==='NOT_ALLOCATED'){
                    status = 'Not Allocated';
                }
                if(order[0].deliveryStatus===null && order[0]?.partyId===null ){
                    status='Allocated'
                }
                if(order[0]?.partyId){
                    status='Accepted'
                }
                if(order[0].deliveryStatus!==null && order[0].deliveryStatus!=='NOT_ALLOCATED'){
                    status=order[0].deliveryStatus;
                }
            }
        }


        return (
        <div>
          <div style={{ marginTop: "10px" }}>
            <p>
              {status}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              border: "1px solid #d9dcdc",
              borderBottom: "0px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              // boxShadow: "0px 0px 3px 0.00px #eff0f1",
            }}
          >
            <p
              style={{
                fontSize: 20,
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "10px",
                color: "#333",
              }}
            >
              Product Details
            </p>
          </div>

          <div
            style={{
              display: "flex",
              border: "1px solid #d9dcdc",
              flexDirection: "column",
            }}
          >
            {order &&
              order.map((cart) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "20px",
                      marginTop: "20px",
                      marginBottom: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <img
                      src={cart.products?.[0].images?.[0]}
                      width="100px"
                      height="100px"
                      loading="lazy"
                      alt={cart?.products?.[0]?.title}
                    />
                    <div style={{ marginLeft: "20px" }}>
                    {cart?.order?.orderStatus!=="CANCELED" ?<p>{cart.products?.[0].title}</p>:<strike><p>{cart.products?.[0].title}</p></strike>}
                    {cart?.order?.orderStatus!=="CANCELED" ?<p>QTY: {cart.quantity}</p>:<strike><p>QTY: {cart.quantity}</p></strike>}
                    {cart?.order?.orderStatus!=="CANCELED" ?<p>
                            Price: {this.props.currency} {cart.amount}
                        </p>:<strike>
                            <p>
                                Price: {this.props.currency} {cart.amount}
                            </p>
                        </strike>}
                    </div>
                  </div>
                  {cart.addons?.map((addon) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "20px",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <img
                        src={addon?.products?.[0]?.images?.[0]}
                        width="100px"
                        height="100px"
                        loading="lazy"
                        alt={addon?.products?.[0]?.title}
                      />
                      <div style={{ marginLeft: "20px" }}>
                        <p>{addon?.products?.[0]?.title}</p>
                        <p>QTY: {addon.quantity}</p>
                        <p>
                          Price: {this.props.currency} {addon.amount}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
          <div
            onClick={(e) => {
              let flag = this.state.flag;
              flag[index] = !flag[index];
              this.setState({ flag: flag });
            }}
            style={{
              display: "flex",
              marginTop: "20px",
              border: "1px solid #d9dcdc",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              justifyContent: "space-between",
              paddingRight: "20px",
              alignItems: "center",
              // boxShadow: "0px 0px 3px 0.00px #eff0f1",
            }}
          >
            <p
              style={{
                fontSize: 20,
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "10px",
                color: "#333",
              }}
            >
              Price Details
            </p>
            {!this.state.flag[index] && <ChevronRightIcon />}
            {this.state.flag[index] && <ExpandMoreIcon />}
          </div>
          <Collapse in={this.state.flag[index]}>
            <div
              style={{
                display: "flex",
                border: "1px solid #d9dcdc",
                flexDirection: "column",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "20px",
                paddingBotom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <p>Shipping Charges:</p>
                <p>
                  {this.props.currency}: {shipping}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <p>Total:</p>
                <p>
                  {this.props.currency}: {amount.toFixed(2)}
                  {/* 12323 */}
                </p>
              </div>
            </div>
          </Collapse>

          <div
            onClick={(e) => {
              let deliveryFlag = this.state.deliveryFlag;
              deliveryFlag[index] = !deliveryFlag[index];
              this.setState({ deliveryFlag: deliveryFlag });
            }}
            style={{
              display: "flex",
              marginTop: "20px",
              border: "1px solid #d9dcdc",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              justifyContent: "space-between",
              paddingRight: "20px",
              alignItems: "center",
              // boxShadow: "0px 0px 3px 0.00px #eff0f1",
            }}
          >
            <p
              style={{
                fontSize: 20,
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "10px",
                color: "#333",
              }}
            >
              Delivery Details
            </p>
            {!this.state.deliveryFlag[index] && <ChevronRightIcon />}
            {this.state.deliveryFlag[index] && <ExpandMoreIcon />}
          </div>
          <Collapse in={this.state.deliveryFlag[index]}>
            <div
              style={{
                display: "flex",
                border: "1px solid #d9dcdc",
                flexDirection: "column",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "20px",
                paddingBotom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginLeft: "15px",
                }}
              >
                <p>
                  {/* Delivery Date: 1123112 */}
                  {order[0]?.shippingDate}
                </p>
                <p>
                  {/* Delivery Time: 233:4234 */}
                  {order[0]?.shippingTime}
                </p>
                <p>
                  {/* Delivery Mode: Morning */}
                  {order[0]?.shippingType}
                </p>
              </div>
            </div>
          </Collapse>

          <div
            onClick={(e) => {
              let recipientFlag = this.state.recipientFlag;
              recipientFlag[index] = !recipientFlag[index];
              this.setState({
                recipientFlag: recipientFlag,
              });
            }}
            style={{
              display: "flex",
              marginTop: "20px",
              border: "1px solid #d9dcdc",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              justifyContent: "space-between",
              paddingRight: "20px",
              alignItems: "center",
              // boxShadow: "0px 0px 3px 0.00px #eff0f1",
            }}
          >
            <p
              style={{
                fontSize: 20,
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "10px",
                color: "#333",
              }}
            >
              Recipient Details
            </p>
            {!this.state.recipientFlag[index] && <ChevronRightIcon />}
            {this.state.recipientFlag[index] && <ExpandMoreIcon />}
          </div>
          <Collapse in={this.state.recipientFlag[index]}>
            <div
              style={{
                display: "flex",
                border: "1px solid #d9dcdc",
                flexDirection: "column",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "20px",
                paddingBotom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <p>{order[0].address.recipientAddress}</p>
                <p>
                  {order[0].address.landmark}, {order[0].address.city}
                </p>
                <p>Mob: {order[0].address.phone}</p>
                {/* something */}
              </div>
            </div>
          </Collapse>
        </div>
      );
    });
    return list;
  };
  renderOrders = (orders, selectedTimeSlot) => {
    // let orders = this.state.orders;
    let lastIndex = orders.length - 1;
    let list2 = [];

    list2.push(
      orders &&
        orders.map((order, index) => {
          let count = 1;
          //let amount = order?.amount;
          let id = order?._id;

          let list = [];
          let carts = JSON.parse(JSON.stringify(order.carts));

          while (carts.length > 0) {
            let orderId = id + "" + count;
            count = count + 1;
            let order = carts[0].deliveryAddress._id;
            let dataChunck = carts.filter(
              (e) => e.deliveryAddress._id === order
            );
            dataChunck.forEach((f) =>
              carts.splice(
                carts.findIndex((e) => e._id === f._id),
                1
              )
            );

            let flag = false;
            let amount = 0;
            let shippingPrice = 0;
            dataChunck.map((cart) => {
              if (cart.shippingTime === selectedTimeSlot) {
                amount = amount + cart.shippingPrice;
                shippingPrice = shippingPrice + cart.shippingPrice;
                amount = amount + cart?.amount * cart?.quantity;
                cart.addons &&
                  cart.addons.map((addon) => {
                    amount =
                      amount +
                      addon.products[0]?.Price[0]?.price * addon.quantity;
                  });
                flag = true;
              }
            });
            flag &&
              list.push(
                <div>
                  <div>
                    <p>
                      {dataChunck[0]?.deliveryStatus
                        ? dataChunck[0]?.deliveryStatus
                        : "Pending"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      border: "1px solid #d9dcdc",
                      borderBottom: "0px",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      // boxShadow: "0px 0px 3px 0.00px #eff0f1",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 20,
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "10px",
                        color: "#333",
                      }}
                    >
                      Product Details
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #d9dcdc",
                      flexDirection: "column",
                    }}
                  >
                    {dataChunck &&
                      dataChunck.map(
                        (cart) =>
                          selectedTimeSlot === cart.shippingTime && (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",

                                  marginLeft: "20px",
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  marginRight: "20px",
                                }}
                              >
                                <img
                                  src={cart.products?.[0].images?.[0]}
                                  width="100px"
                                  height="100px"
                                  loading="lazy"
                                  alt={cart?.products?.[0]?.title}
                                />
                                <div style={{ marginLeft: "20px" }}>
                                  <p>{cart.products?.[0].title}</p>
                                  <p>QTY: {cart.quantity}</p>
                                  <p>Price: AED {cart.amount}</p>
                                </div>
                              </div>
                              {cart.addons?.map((addon) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginLeft: "20px",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <img
                                    src={addon?.products?.[0]?.images?.[0]}
                                    width="100px"
                                    loading="lazy"
                                    height="100px"
                                    alt={addon?.products?.[0]?.title}
                                  />
                                  <div style={{ marginLeft: "20px" }}>
                                    <p>{addon?.products?.[0].title}</p>
                                    <p>QTY: {addon.qty}</p>
                                    <p>Price: AED {addon.amount}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                      )}
                  </div>
                  <div
                    onClick={(e) => this.setState({ flag: !this.state.flag })}
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      border: "1px solid #d9dcdc",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      justifyContent: "space-between",
                      paddingRight: "20px",
                      alignItems: "center",
                      // boxShadow: "0px 0px 3px 0.00px #eff0f1",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 20,
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "10px",
                        color: "#333",
                      }}
                    >
                      Price Details
                    </p>
                    {!this.state.flag && <ChevronRightIcon />}
                    {this.state.flag && <ExpandMoreIcon />}
                  </div>
                  <Collapse in={this.state.flag}>
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #d9dcdc",
                        flexDirection: "column",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "20px",
                        paddingBotom: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Shipping Charges:</p>
                        <p>
                          AED: {shippingPrice}
                          {/* 19892 */}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Total:</p>
                        <p>
                          AED: {amount}
                          {/* 12323 */}
                        </p>
                      </div>
                    </div>
                  </Collapse>

                  <div
                    onClick={(e) =>
                      this.setState({ deliveryFlag: !this.state.deliveryFlag })
                    }
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      border: "1px solid #d9dcdc",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      justifyContent: "space-between",
                      paddingRight: "20px",
                      alignItems: "center",
                      // boxShadow: "0px 0px 3px 0.00px #eff0f1",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 20,
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "10px",
                        color: "#333",
                      }}
                    >
                      Delivery Details
                    </p>
                    {!this.state.deliveryFlag && <ChevronRightIcon />}
                    {this.state.deliveryFlag && <ExpandMoreIcon />}
                  </div>
                  <Collapse in={this.state.deliveryFlag}>
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #d9dcdc",
                        flexDirection: "column",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "20px",
                        paddingBotom: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          marginLeft: "15px",
                        }}
                      >
                        <p>
                          {/* Delivery Date: 1123112 */}
                          {dataChunck[0]?.shippingDate}
                        </p>
                        <p>
                          {/* Delivery Time: 233:4234 */}
                          {dataChunck[0]?.shippingTime}
                        </p>
                        <p>
                          {/* Delivery Mode: Morning */}
                          {dataChunck[0]?.shippingType}
                        </p>
                      </div>
                    </div>
                  </Collapse>

                  <div
                    onClick={(e) =>
                      this.setState({
                        recipientFlag: !this.state.recipientFlag,
                      })
                    }
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      border: "1px solid #d9dcdc",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      justifyContent: "space-between",
                      paddingRight: "20px",
                      alignItems: "center",
                      // boxShadow: "0px 0px 3px 0.00px #eff0f1",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 20,
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "10px",
                        color: "#333",
                      }}
                    >
                      Recipient Details
                    </p>
                    {!this.state.recipientFlag && <ChevronRightIcon />}
                    {this.state.recipientFlag && <ExpandMoreIcon />}
                  </div>
                  <Collapse in={this.state.recipientFlag}>
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #d9dcdc",
                        flexDirection: "column",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "20px",
                        paddingBotom: "20px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{dataChunck[0].address.recipientAddress}</p>
                        <p>
                          {dataChunck[0].address.landmark},{" "}
                          {dataChunck[0].address.city}
                        </p>
                        <p>Mob: {dataChunck[0].address.phone}</p>
                        {/* something */}
                      </div>
                    </div>
                  </Collapse>
                </div>
              );
          }
          return list;
        })
    );
    return list2;
  };

  getFlags = (order) => {
    let ORDER = order;
    let count = 1;
    let orderArr = [];
    let counter = 0;
    //let amount = order?.amount;
    let id = order?._id;
    let index;
    let list = [];
    let flag = [];
    let deliveryFlag = [];
    let recipientFlag = [];

    let carts = order?.carts && JSON.parse(JSON.stringify(order.carts));
    let arrayDates = [];
    if (carts) {
      for (let i = 0; i < carts.length; i++) {
        let filetered = arrayDates.filter(
          (date) =>
            date === moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
        );
        if (filetered.length > 0) {
        } else {
          arrayDates.push(
            moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
          );
        }
      }
    }
    if (arrayDates.length > 0) {
      for (let j = 0; j < arrayDates.length; j++) {
        let dateCarts = carts.filter(
          (cart) =>
            moment(cart.shippingDate).utc(true).format("Y-MM-DD") ===
            arrayDates[j]
        );
        let addressArray = [];
        for (let k = 0; k < dateCarts.length; k++) {
          let addressCarts = addressArray.filter(
            (address) => address === dateCarts[k].address._id
          );
          if (addressCarts.length > 0) {
          } else {
            addressArray.push(dateCarts[k].address._id);
          }
        }
        for (let m = 0; m < addressArray.length; m++) {
          let addressCarts = dateCarts.filter(
            (dateCart) => dateCart.address._id === addressArray[m]
          );
          let timeSlotsArray = [];
          for (let n = 0; n < addressCarts.length; n++) {
            let timeCarts = timeSlotsArray.filter(
              (timeSlot) => timeSlot === addressCarts[n].shippingTime
            );
            if (timeCarts.length > 0) {
            } else {
              timeSlotsArray.push(addressCarts[n].shippingTime);
            }
          }

          for (let l = 0; l < timeSlotsArray.length; l++) {
            let timeCarts = addressCarts.filter(
              (addressCart) => addressCart.shippingTime === timeSlotsArray[l]
            );
            let amount = 0;
            let productIds = "";
            let shippingPrice = 0;
            let productDescription = "";
            for (let r = 0; r < timeCarts.length; r++) {
              let s = parseFloat(
                (
                  (timeCarts[r].shippingPrice /
                    this.props.currencyRates["AED"]) *
                  this.props.currencyRates[this.props.currency]
                ).toFixed(2)
              );
              if (shippingPrice < s) {
                shippingPrice = s;
              }
              let a = parseFloat(
                (
                  (timeCarts[r].amount / this.props.currencyRates["AED"]) *
                  this.props.currencyRates[this.props.currency]
                ).toFixed(2)
              );
              timeCarts[r].amount = a;
              amount = amount + a * timeCarts[r].quantity;
              productIds = productIds + timeCarts[r].products[0]._id + ",";
              productDescription =
                productDescription + timeCarts[r].products[0].description;
              if (timeCarts[r].addons.length > 0) {
                for (let s = 0; s < timeCarts[r].addons.length; s++) {
                  let aa = parseFloat(
                    (
                      (timeCarts[r].addons[s].amount /
                        this.props.currencyRates["AED"]) *
                      this.props.currencyRates[this.props.currency]
                    ).toFixed(2)
                  );
                  timeCarts[r].addons[s].amount = aa;
                  amount = amount + aa * timeCarts[r].addons[s].quantity;
                  productIds =
                    productIds + timeCarts[r].addons[s].products[0]._id + ",";
                  productDescription =
                    productDescription +
                    timeCarts[r].addons[s].products[0].description;
                }
              }
            }
            amount = amount + shippingPrice;
            let obj = {};
            obj.timeCarts = timeCarts;
            obj.shippingPrice = shippingPrice;
            obj.amount = amount;
            obj.orderObj = ORDER;
            orderArr.push(obj);
            flag.push(false);
            deliveryFlag.push(false);
            recipientFlag.push(false);
          }
        }
      }
    }
    this.setState({ flag, deliveryFlag, recipientFlag, orderArr: orderArr });
    return orderArr;
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    address: state.profile.address,
    currency: state.auth.currency,
    currencyRates: state.others.currencyRates,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    // setLastAddress: (data) => dispatch(setLastAddress(data, router)),
    // deleteAdress: (data) => dispatch(deleteAdress(data, router)),
    // getAllAdress: (data) => dispatch(getAllAdress(data, router)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomOrderView)
);
