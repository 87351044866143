import React, { Component } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
  Icon,
  Grid,
  Box,
  TextField,
  Button,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";
import { decreaseAmount } from "../../store/actions/cartActions";
import "./orderConfirmation.css"
import { connect } from "react-redux";
import Loader from "../common/Loader";
class OrderConfirmation extends Component {
  state = {
    list: [],
    totalProductPrice: "",
    totalShipping: "",
    status: "",
    orderId: "",
    customer: "",
    data: [],
    showLoading: true,
  };
  componentDidMount = async () => {
    try {
      const token = localStorage.getItem("token");
      let id = this.props.match.params.id;
      // let id = 1455;
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}orders/get/` + id,
        {
          headers: { "access-token": token },
        }
      );
      if (res) {
        if (res?.data?.data) {
          let o = res?.data?.data[0][0].order.paymentMethod;

          let flag = false;
          if (o === "cod") {
            this.props.decreaseAmount({ id });
            flag = true;
          } else if (res?.data?.data[0][0].order.paymentStatus) {
            flag = true;
            this.props.decreaseAmount({ id });
          }
          this.setState({
            data: res.data.data,
            status: flag,
            orderId: res?.data?.data[0][0].order?._id,
            customer: res?.data?.data[0][0]?.user?.name,
            showLoading: false
          });
        }
      }
    } catch (err) { }
  };
  componentDidUpdate(prevProps) {
    // if (prevProps.currency !== this.props.currency) {
    //   this.setState({
    //     list,
    //     totalProductPrice,
    //     totalShipping,
    //     status,
    //     orderId,
    //     customer,
    //   });
    // }
  }
  renderProduct = (carts) => {
    return (
      <div>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <img
              src={carts.products[0].images?.[0]}
              height="102px"
              width="102px"
              className="rounded"
              alt={carts?.products[0]?.title}
            />
          </Grid>

          <Grid item xs={8}>
            <p>{carts.products[0].title}</p>

            <p>Quantity: {carts.quantity}</p>
            <p>
              Price:{" "}
              <span className="color_primary">
                {this.props.currency} {parseFloat(carts.amount.toFixed(2))}
              </span>
            </p>
          </Grid>
        </Grid>
        {carts?.addons?.map((addon) => {
          return this.renderProduct(addon);
        })}
      </div>
    );
  };
  getSubOrder = (order) => {
    let ORDER = order;
    let count = 1;
    //let amount = order?.amount;
    let id = order?._id;
    let index;
    let totalShipping = 0;
    let totalProductPrice = 0;
    let list = [];
    let carts = order?.carts && JSON.parse(JSON.stringify(order.carts));
    let arrayDates = [];
    let status = "Payment Failed";
    let orderId = order._id;
    let customer = order.user.name;
    if (order.paymentMethod === "cod") {
      status = "confirmed";
    } else {
      if (order.paymentStatus === true) {
        status = "confirmed";
      }
    }
    if (carts) {
      for (let i = 0; i < carts.length; i++) {
        let filetered = arrayDates.filter(
          (date) =>
            date === moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
        );
        if (filetered.length > 0) {
        } else {
          arrayDates.push(
            moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
          );
        }
      }
    }
    if (arrayDates.length > 0) {
      for (let j = 0; j < arrayDates.length; j++) {
        let dateCarts = carts.filter(
          (cart) =>
            moment(cart.shippingDate).utc(true).format("Y-MM-DD") ===
            arrayDates[j]
        );
        let addressArray = [];
        for (let k = 0; k < dateCarts.length; k++) {
          let addressCarts = addressArray.filter(
            (address) => address === dateCarts[k].address._id
          );
          if (addressCarts.length > 0) {
          } else {
            console.log("date Carts", dateCarts[k]);
            addressArray.push(dateCarts[k].address._id);
          }
        }
        for (let m = 0; m < addressArray.length; m++) {
          let addressCarts = dateCarts.filter(
            (dateCart) => dateCart.address._id === addressArray[m]
          );
          let timeSlotsArray = [];
          for (let n = 0; n < addressCarts.length; n++) {
            let timeCarts = timeSlotsArray.filter(
              (timeSlot) => timeSlot === addressCarts[n].shippingTime
            );
            if (timeCarts.length > 0) {
            } else {
              timeSlotsArray.push(addressCarts[n].shippingTime);
            }
          }

          for (let l = 0; l < timeSlotsArray.length; l++) {
            let timeCarts = addressCarts.filter(
              (addressCart) => addressCart.shippingTime === timeSlotsArray[l]
            );
            let amount = 0;
            let ordShipping = 0;
            let productIds = "";
            let productDescription = "";
            for (let r = 0; r < timeCarts.length; r++) {
              let a = parseFloat(
                (
                  (timeCarts[r].shippingPrice /
                    this.props.currencyRates["AED"]) *
                  this.props.currencyRates[this.props.currency]
                ).toFixed(2)
              );
              if (ordShipping < a) {
                ordShipping = a;
              }

              let abc = parseFloat(
                (
                  (timeCarts[r].amount / this.props.currencyRates["AED"]) *
                  this.props.currencyRates[this.props.currency]
                ).toFixed(2)
              );
              amount = amount + abc * timeCarts[r].quantity;
              timeCarts[r].amount = abc;

              productIds = productIds + timeCarts[r].products[0]._id + ",";
              productDescription =
                productDescription + timeCarts[r].products[0].description;
              if (timeCarts[r].addons.length > 0) {
                for (let s = 0; s < timeCarts[r].addons.length; s++) {
                  let cdf = parseFloat(
                    (
                      (timeCarts[r].addons[s].amount /
                        this.props.currencyRates["AED"]) *
                      this.props.currencyRates[this.props.currency]
                    ).toFixed(2)
                  );
                  timeCarts[r].addons[s].amount = cdf;
                  amount = amount + cdf * timeCarts[r].addons[s].quantity;
                  productIds =
                    productIds + timeCarts[r].addons[s].products[0]._id + ",";
                  productDescription =
                    productDescription +
                    timeCarts[r].addons[s].products[0].description;
                }
              }
            }
            totalShipping = totalShipping + ordShipping;
            totalProductPrice = totalProductPrice + amount;
            let orderId = ORDER._id;
            let party = null;
            if (timeCarts[0].partyId) {
              let partyId = timeCarts[0].partyId;
              party = timeCarts[0].parties.filter(
                (party) => party._id === partyId
              );
            }
            // let data = this.subOrder({
            //   orderId,
            //   carts: timeCarts,
            //   senderInfo: ORDER.user,
            //   partyInfo: party,
            //   orderObj: ORDER,
            //   addresses: ORDER.user.Addresses,
            //   addressId: timeCarts[0].address._id,
            // });
            let data = {
              orderId,
              carts: timeCarts,
              address: timeCarts[0].address,
              amount: amount,
              shipping: ordShipping,
            };
            list.push(data);
          }
        }
      }
    }
    return {
      list,
      totalProductPrice,
      totalShipping,
      status,
      orderId,
      customer,
    };
  };
  renderOrder = (da) => {
    let aa = JSON.parse(JSON.stringify(da));
    return aa.map((order) => {
      let amount = 0;
      let shipping = 0;
      order.map((o) => {
        let a = parseFloat(
          (
            (o.amount / this.props.currencyRates["AED"]) *
            this.props.currencyRates[this.props.currency]
          ).toFixed(2)
        );
        o.amount = a;
        let s = parseFloat(
          (
            (o.shippingPrice / this.props.currencyRates["AED"]) *
            this.props.currencyRates[this.props.currency]
          ).toFixed(2)
        );
        if (shipping < s) {
          shipping = s;
        }
        if (o.addons.length > 0) {
          o.addons.map((addon) => {
            let b = parseFloat(
              (
                (addon.amount / this.props.currencyRates["AED"]) *
                this.props.currencyRates[this.props.currency]
              ).toFixed(2)
            );
            addon.amount = b;
            amount = amount + b * addon.quantity;
          });
        }
        amount = amount + a * o.quantity;
      });
      amount = amount + shipping;
      return (
        <div>
          {/*product details*/}
          <div className="mt-3">
            <ExpansionPanel expanded elevation={2}>
              <ExpansionPanelSummary>
                <h5>Product Details</h5>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {order?.map((cart) => {
                  let prod = this.renderProduct(cart);
                  return prod;
                })}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          {/*product details ends here*/}

          {/*price details*/}
          <div className="mt-4">
            <ExpansionPanel elevation={2}>
              <ExpansionPanelSummary
                expandIcon={<Icon>keyboard_arrow_down</Icon>}
              >
                <h5>Price Details</h5>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <div>
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td> Shiping Charges :</td>
                        <td>
                          {" "}
                          {this.props.currency}{" "}
                          {parseFloat(shipping.toFixed(2))}
                        </td>
                      </tr>

                      <tr>
                        <td> Total :</td>
                        <td>
                          {" "}
                          {this.props.currency} {parseFloat(amount.toFixed(2))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          {/*price details end here*/}

          {/*delivery details*/}
          <div className="mt-4">
            <ExpansionPanel elevation={2}>
              <ExpansionPanelSummary
                expandIcon={<Icon>keyboard_arrow_down</Icon>}
              >
                <h5>Delivery Details</h5>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <div>
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td> Delivery Date :</td>
                        <td>
                          {" "}
                          {moment(order[0].shippingDate)
                            .utc(true)
                            .format("Y-MM-DD")}
                        </td>
                      </tr>

                      <tr>
                        <td> Delivery Time :</td>
                        <td> {order[0].shippingTime}</td>
                      </tr>

                      <tr>
                        <td> Delivery Mode :</td>
                        <td> {order[0].shippingType}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          {/*delivery details end here*/}

          {/*recipient details*/}
          <div className="mt-4">
            <ExpansionPanel elevation={2}>
              <ExpansionPanelSummary
                expandIcon={<Icon>keyboard_arrow_down</Icon>}
              >
                <h5>Recipient Details</h5>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <p>
                  {order[0]?.address?.name} <br />
                  {order[0]?.address?.recipientAddress},{" "}
                  {order[0]?.address?.recipientCity} <br />
                  {order[0]?.address?.recipientCity} <br />
                  Mobile: {order[0]?.address?.phone}
                </p>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          {/*recipient details end here*/}
        </div>
      );
    });
  };
  render() {
    return (
      <div className="my-4">
        <Loader open={this.state.showLoading} />
        <Grid container direction="row" justify="center">
          <Grid item>
            {this.state.showLoading ? <p>Loading...</p> :
              <React.Fragment>
                {this.state.status && <h4 className="text-primary text-center">
                  Thank you for gifting with us
                </h4>}
                {this.state.status === false && <h4 className="text-primary text-center">
                  Order confirmation failed
                </h4>}
                <p>
                  Dear {this.state.customer},
                  <br />
                  Your order no.{" "}
                  <span className="text-primary">{this.state.orderId}</span>{" "}
                  {this.state.status && (
                    <span>
                      is confirmed and will be delivered on the requested date
                    </span>
                  )}
                  {this.state.status === false && (
                    <span>
                      failed payment step. Please try again or <a className={'contact'} href={"/contact-us"}>contact us</a> for further information.<p> You can try paying again:<a className={'contact'} href={"/checkout-page?orderId=" + this.state.orderId}>Retry</a></p>
                    </span>
                  )}
                </p>
              </React.Fragment>
            }
            {this.renderOrder(this.state.data)}
            {/*grand total*/}
            <div className="mt-4">
              <ExpansionPanel elevation={2}>
                <ExpansionPanelSummary
                  expandIcon={<Icon>keyboard_arrow_down</Icon>}
                >
                  <h5>Grand Total</h5>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <div>
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td> Grand Total :</td>
                          <td>
                            {" "}
                            {this.props.currency}{" "}
                            {this.state.data.length > 0 &&
                              parseFloat(
                                (
                                  (this.state.data[0][0].order.amount /
                                    this.props.currencyRates["AED"]) *
                                  this.props.currencyRates[this.props.currency]
                                ).toFixed(2)
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
            {/*grand total ends here*/}

            {/* gift reminder
            <div className="mt-4">
              <Box boxShadow={3}>
                <div className="text-center bg-primary text-white py-2">
                  <h5>Gift Reminder</h5>
                </div>

                <div className="py-3 w-50 mx-auto">
                  <div>
                    <TextField
                      type="text"
                      label="Recipient's Name"
                      placeholder="Enter name here"
                    />
                  </div>

                  <div className="mt-3">
                    <TextField
                      type="text"
                      label="Ocassion"
                      placeholder="Enter ocassion here"
                    />
                  </div>

                  <div className="mt-4">
                    <TextField type="date" />
                  </div>

                  <div className="mt-4 text-center">
                    <Button
                      variant="contained"
                      className="bg_secondary text-white"
                    >
                      Save Date
                    </Button>
                  </div>
                </div>
              </Box>
            </div> */}
            {/*gift reminder ends here*/}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currency: state.auth.currency,
    currencyRates: state.others.currencyRates,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    decreaseAmount: (data) => dispatch(decreaseAmount(data, router)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);
