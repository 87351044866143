import React, { Component } from "react";
import { AppBar, Grid, Toolbar } from "@material-ui/core";
import "./CategoriesNavBar.scss";
import {
  Drawer,
  Typography,
  createStyles,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";
import ListItemText from "@material-ui/core/ListItemText";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { getRequest } from "../../store/services/index";
import { Link } from "react-router-dom";
// import { slugifyText } from "../../constants/utils";
import MinimizeIcon from "@material-ui/icons/Minimize";
import { saveAllCats, saveCatalog } from "../../store/actions/othersActions";
import { connect } from "react-redux";
import { compose } from "redux";

var _ = require("underscore");

const useStyle = createStyles({
  DrawerHeader: {
    backgroundColor: "#333333",
    color: "white",
    padding: "9px",
  },
  paper: {
    width: "73%",
    borderRadius: "3px",
    border: "1px solid #CACACA",
    backgroundColor: "#F2F2F2",
  },

  accordionStyle: {
    borderBottom: "1px solid #EEEEEE",
    boxShadow: "none !important",
  },
  accordionSummaryStyle: {
    borderBottom: "1px solid #EEEEEE",
    marginBottom: "-1",
  },
  accordionDetailsStyle: {
    padding: " 0 0 0 16px",
  },

  listStyle: {
    display: "block",
    width: "100%",
  },
  accordionPaper: {
    boxShadow: "none",
  },
});

class CategoriesNavBar extends Component {
  // ByEmirates = [
  //   {
  //     name: "By Dubai",
  //     data: [
  //       { text: "All Gifts", link: "/gifts/dubai" },
  //       { text: "Flowers", link: "/flowers/dubai" },
  //       { text: "Cakes", link: "/cakes/dubai" },
  //       { text: "Combos", link: "/combos/dubai" },
  //       { text: "Personalised Gifts", link: "/personalised-gifts/dubai" },
  //       { text: "Plants", link: "/plants/dubai" },
  //     ],
  //   },
  //   {
  //     name: "By Abu Dhabi",
  //     data: [
  //       { text: "All Gifts", link: "/gifts/abu-dhabi" },
  //       { text: "Flowers", link: "/flowers/abu-dhabi" },
  //       { text: "Cakes", link: "/cakes/abu-dhabi" },
  //       { text: "Combos", link: "/combos/abu-dhabi" },
  //       { text: "Personalised", link: "/personalised-gifts/abu-dhabi" },
  //       { text: "Plants", link: "/plants/abu-dhabi" },
  //     ],
  //   },

  //   {
  //     name: "By Sharjah",
  //     data: [
  //       { text: "All Gifts", link: "/gifts/sharjah" },
  //       { text: "Flowers", link: "/flowers/sharjah" },
  //       { text: "Cakes", link: "/cakes/sharjah" },
  //       { text: "Combos", link: "/combos/sharjah" },
  //       { text: "Personalised Gifts", link: "/personalised-gifts/sharjah" },
  //       { text: "Plants", link: "/plants/sharjah" },
  //     ],
  //   },
  //   {
  //     name: "By Ajman",
  //     data: [
  //       { text: "All Gifts", link: "/gifts/ajman" },
  //       { text: "Flowers", link: "/flowers/ajman" },
  //       { text: "Cakes", link: "/cakes/ajman" },
  //       { text: "Combos", link: "/combos/ajman" },
  //       { text: "Personalised Gifts", link: "/personalised-gifts/ajman" },
  //       { text: "Plants", link: "/plants/ajman" },
  //     ],
  //   },
  //   {
  //     name: "By Al Ain",
  //     data: [
  //       { text: "All Gifts", link: "/gifts/al-ain" },
  //       { text: "Flowers", link: "/flowers/al-ain" },
  //       { text: "Cakes", link: "/cakes/al-ain" },
  //       { text: "Combos", link: "/combos/al-ain" },
  //       { text: "Personalised Gifts", link: "/personalised-gifts/al-ain" },
  //       { text: "Plants", link: "/plants/al-ain" },
  //     ],
  //   },
  //   {
  //     name: "By Ras Al Khaimah",
  //     data: [
  //       { text: "All Gifts", link: "/gifts/ras-al-khaimah" },
  //       { text: "Flowers", link: "/flowers/ras-al-khaimah" },
  //       { text: "Cakes", link: "/cakes/ras-al-khaimah" },
  //       { text: "Combos", link: "/combos/ras-al-khaimah" },
  //       {
  //         text: "Personalised Gifts",
  //         link: "/personalised-gifts/ras-al-khaimah",
  //       },
  //       { text: "Plants", link: "/plants/ras-al-khaimah" },
  //     ],
  //   },
  //   {
  //     name: "By Fujairah",
  //     data: [
  //       { text: "All Gifts", link: "/gifts/fujairah" },
  //       { text: "Flowers", link: "/flowers/fujairah" },
  //       { text: "Cakes", link: "/cakes/fujairah" },
  //       { text: "Combos", link: "/combos/fujairah" },
  //       { text: "Personalised Gifts", link: "/personalised-gifts/fujairah" },
  //       { text: "Plants", link: "/plants/fujairah" },
  //     ],
  //   },
  //   {
  //     name: "By Umm Al Quwain",
  //     data: [
  //       { text: "All Gifts", link: "/gifts/umm-al-quwain" },
  //       { text: "Flowers", link: "/flowers/umm-al-quwain" },
  //       { text: "Cakes", link: "/cakes/umm-al-quwain" },
  //       { text: "Combos", link: "/combos/umm-al-quwain" },
  //       {
  //         text: "Personalised Gifts",
  //         link: "/personalised-gifts/umm-al-quwain",
  //       },
  //       { text: "Plants", link: "/plants/umm-al-quwain" },
  //     ],
  //   },
  // ];
  // listOfElements;

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mainCats: [],
      catalogs: [],
      accordionId: null,
      subCatAccordionId: null,
      accordionFlag: false,
    };
  }

  componentDidMount() {
    // this.getData();
    // this.getCatalogs();
  }

  render() {
    // const { mainCats } = this.state;
    const { navigations } = this.props;
    const classes = this.props.classes;
    return (
      <>
        <Drawer
          open={this.props.flag}
          variant="temporary"
          onBackdropClick={this.props.manageDrawer}
          classes={{ paper: classes.paper }}
        >
          <List>
            <div
              className={classNames("d-flex", classes.DrawerHeader)}
              style={{ alignItems: "center", backgroundColor: "#B379c5" }}
            >
              <ListItemIcon style={{ width: "17%" }}>
                <AccountCircleIcon
                  style={{ fontSize: "40px", color: "black" }}
                />
              </ListItemIcon>
              <Typography
                variant="h5"
                style={{ color: "black", fontSize: "16px" }}
              >
                Hi {this.props?.userName || "Guest"}
              </Typography>
            </div>
            <div
              className="login-section"
              style={{ backgroundColor: "#b379c5" }}
            >
              <ul>
                <Link
                  to={"/profile/my-account"}
                  onClick={this.props.manageDrawer}
                  style={{ color: "" }}
                >
                  <li>My Account</li>
                </Link>
                <Link to={"/contact-us"} onClick={this.props.manageDrawer}>
                  <li>Track Order</li>
                </Link>
                <Link to={"/contact-us"} onClick={this.props.manageDrawer}>
                  <li>Help Center</li>
                </Link>
              </ul>
            </div>
          </List>
          <List>
            {navigations?.map((cat, key) => (
              <div key={key}>
                <Accordion
                  square
                  classes={{ root: classes.accordionStyle }}
                  expanded={this.state.accordionId === key}
                  onChange={() => this.manageAccordion(key)}
                >
                  <AccordionSummary
                    expandIcon={
                      this.state.accordionId === key ? (
                        <MinimizeIcon />
                      ) : (
                        <AddIcon />
                      )
                    }
                    aria-controls={key}
                    id={key}
                    classes={{ root: classes.accordionStyle }}
                    onClick={() => this.toggleAccordion(key)}
                  >
                    <Typography
                      className={classes.heading}
                      style={{ textTransform: "capitalize" }}
                    >
                      {cat.name.toLowerCase()}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    classes={{ root: classes.accordionDetailsStyle }}
                  >
                    {cat.menu.length > 0 && (
                      <List classes={{ root: classes.listStyle }}>
                        {cat.menu.map((subCat, index) => {
                          return (
                            <>
                              {subCat.submenu.length > 0 ? (
                                <Accordion
                                  square
                                  expanded={
                                    this.state.subCatAccordionId === index
                                  }
                                  onChange={() =>
                                    this.manageSubAccordion(index)
                                  }
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      this.state.subCatAccordionId === index ? (
                                        <MinimizeIcon />
                                      ) : (
                                        <AddIcon />
                                      )
                                    }
                                    aria-controls="panel1a-content"
                                    id={index}
                                    onClick={() =>
                                      this.toggleSubAccordion(index)
                                    }
                                  >
                                    <Typography style={{ fontSize: "14px" }}>
                                      {subCat.name}
                                    </Typography>
                                  </AccordionSummary>
                                  {subCat.submenu.map((obj, i) => {
                                    const url = "/" + obj?.slug;
                                    return (
                                      <List>
                                        <a
                                          onClick={this.props.manageDrawer}
                                          href={url}
                                          key={i}
                                        >
                                          <ListItem>
                                            <ListItemText>
                                              <Typography
                                                className="pl-2"
                                                style={{ fontSize: "13px" }}
                                              >
                                                {obj.name}
                                              </Typography>
                                            </ListItemText>
                                          </ListItem>
                                        </a>
                                      </List>
                                    );
                                  })}
                                </Accordion>
                              ) : (
                                <List>
                                  <a
                                    onClick={this.props.manageDrawer}
                                    href={"/" + subCat?.slug}
                                    key={index}
                                  >
                                    <ListItem>
                                      <ListItemText>
                                        <Typography
                                          className="pl-2"
                                          style={{ fontSize: "13px" }}
                                        >
                                          {subCat.name}
                                        </Typography>
                                      </ListItemText>
                                    </ListItem>
                                  </a>
                                </List>
                              )}
                            </>
                          );
                        })}
                      </List>
                    )}
                    {/* {cat.subCats.length > 0 && (
                      <List classes={{ root: classes.listStyle }}>
                        {cat.subCats.map((subCat, index) => {
                          if (
                            subCat.length > 1 &&
                            subCat[0] !== "By City" &&
                            subCat[0] !== "Other" &&
                            subCat[0] !== "CATALOG_CATEGORY" &&
                            subCat[0] !== ""
                          ) {
                            return (
                              <Accordion
                                square
                                expanded={
                                  this.state.subCatAccordionId === index
                                }
                                onChange={() => this.manageSubAccordion(index)}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    this.state.subCatAccordionId === index ? (
                                      <MinimizeIcon />
                                    ) : (
                                      <AddIcon />
                                    )
                                  }
                                  aria-controls="panel1a-content"
                                  id={index}
                                  onClick={() => this.toggleSubAccordion(index)}
                                >
                                  <Typography style={{ fontSize: "14px" }}>
                                    {subCat[0]}
                                  </Typography>
                                </AccordionSummary>
                                {subCat.map((obj, i) => {
                                  let url = "";
                                  if (obj._id) {
                                    // url = "/" + obj?.childCategories?.url + "/" + obj?.slug;
                                    url = "/" + obj?.slug;
                                  }
                                  if (i) {
                                    return (
                                      <List>
                                        <a
                                          onClick={this.props.manageDrawer}
                                          href={url}
                                          key={i}
                                        >
                                          <ListItem>
                                            <ListItemText>
                                              <Typography
                                                className="pl-2"
                                                style={{ fontSize: "13px" }}
                                              >
                                                {obj.name}
                                              </Typography>
                                            </ListItemText>
                                          </ListItem>
                                        </a>
                                      </List>
                                    );
                                  } else {
                                    return "";
                                  }
                                })}
                              </Accordion>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </List>
                    )} */}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </List>
          {/* <List>
            <Accordion
              square
              classes={{ root: classes.accordionStyle }}
              expanded={this.state.accordionId === "OutSideUAE"}
              onChange={() => this.manageAccordion("OutSideUAE")}
            >
              <AccordionSummary
                key="OutsideUAE"
                expandIcon={
                  this.state.accordionId === "OutSideUAE" ? (
                    <MinimizeIcon />
                  ) : (
                    <AddIcon />
                  )
                }
                onClick={() => this.toggleAccordion("OutSideUAE")}
                classes={{ root: classes.accordionSummaryStyle }}
              >
                <Typography className={classes.heading}>By Emirates</Typography>
              </AccordionSummary>
              <AccordionDetails
                classes={{ root: classes.accordionDetailsStyle }}
              >
                <List classes={{ root: classes.listStyle }}>
                  {this.ByEmirates?.map((catalog, index) => {
                    return (
                      <Accordion
                        square
                        expanded={
                          this.state.subCatAccordionId === "ByEmirates" + index
                        }
                        onChange={() =>
                          this.manageSubAccordion("ByEmirates" + index)
                        }
                      >
                        <AccordionSummary
                          expandIcon={
                            this.state.subCatAccordionId ===
                            "ByEmirates" + index ? (
                              <MinimizeIcon />
                            ) : (
                              <AddIcon />
                            )
                          }
                          aria-controls="panel1a-content"
                          id={index}
                          onClick={() =>
                            this.toggleSubAccordion("ByEmirates" + index)
                          }
                        >
                          <Typography style={{ fontSize: "14px" }}>
                            {catalog.name}
                          </Typography>
                        </AccordionSummary>
                        {catalog.data.map((subObj, i) => {
                          if (i) {
                            return (
                              <List classes={{ root: classes.listStyle }}>
                                <Link
                                  onClick={this.props.manageDrawer}
                                  to={subObj.link}
                                  key={i}
                                >
                                  <ListItem>
                                    <ListItemText>
                                      <Typography
                                        className="pl-2"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {subObj.text}
                                      </Typography>
                                    </ListItemText>
                                  </ListItem>
                                </Link>
                              </List>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </Accordion>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          </List> */}
          <List>
            <ListItem>
              <ListItemText>
                <Typography variant="body2">Corporate Enquires</Typography>
              </ListItemText>
            </ListItem>
            {this.props.token && (
              <ListItem>
                <ListItemText
                  onClick={() => {
                    this.props.manageDrawer();
                    this.props.logout();
                  }}
                >
                  <Typography variant="body2">Logout</Typography>
                </ListItemText>
              </ListItem>
            )}
            {/*{if (token){*/}
            {/*    return (<ListItem>*/}
            {/*    <ListItemText onClick={()=>{*/}
            {/*    this.props.manageDrawer();*/}
            {/*    this.props.logout();*/}
            {/*}}><Typography variant='body2'>Logout</Typography></ListItemText>*/}
            {/*    </ListItem>)*/}
            {/*}}*/}
          </List>
        </Drawer>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.catalog !== this.props.catalog) {
    //   this.getData();
    // }
  }

  getData = async () => {
    getRequest("categories/allActive/" + this.props.catalog + "/" + true)
      .then((res) => {
        const notCorporateCategories = [];
        res.data.map((cat) => {
          if (cat.catalog.name.toLowerCase() !== "corporate") {
            notCorporateCategories.push(cat);
          }
          return null;
        });
        this.formatCategories(notCorporateCategories);
      })
      .catch((e) => console.warn(e));
  };
  getCatalogs = async () => {
    getRequest("catalogs/allActive").then((res) => {
      if (res.data.length) {
        let length = res.data.length;
        let loops = (length / 10).toFixed(0);
        let numberOfDivs = parseInt(loops);
        let offset = numberOfDivs * 10;
        let global = [];
        for (let i = 0; i < numberOfDivs; i++) {
          let array = [];
          array.push(res.data.slice(i, 10));
          global.push(array);
        }
        let arrayLeft = [];
        arrayLeft.push(res.data.slice(offset, length));
        global.push(arrayLeft);
        this.setState({ catalogs: global });
      }
    });
  };
  toggleAccordion = (index) => {
    if (this.state.accordionId === index) {
      this.setState({ accordionId: null });
    }
  };

  toggleSubAccordion = (index) => {
    if (this.state.subCatAccordionId === index) {
      this.setState({ subCatAccordionId: null });
    }
  };
  manageAccordion = (index) => {
    this.setState({ accordionId: index });
  };
  manageSubAccordion = (index) => {
    this.setState({ subCatAccordionId: index });
  };
  getTextSort = (direction, v1, v2) => {
    if (v1 !== undefined && v2 !== undefined) {
      if (typeof v1 === "string" && typeof v2 === "string") {
        v1 = v1.trim();
        v2 = v2.trim();
        v1 = v1.toLowerCase();
        v2 = v2.toLowerCase();
        v1 = v1.length === 0 ? " " : v1;
        v2 = v2.length === 0 ? " " : v2;
      }
      if (v1 > v2) return direction;
      if (v1 < v2) return -direction;
    }
    return 0;
  };
  formatCategories = (data) => {
    var mainCats = [];
    var subCats = [];

    data.map((obj) => {
      return obj.parentCategoryArray.length
        ? subCats.push(obj)
        : mainCats.push({ ...obj, subCats: [] });
    });
    mainCats.forEach((main) => {
      // let sorted = keys.sort((a,b)=>this.getTextSort(1,a,b));
      // keys.map((key, index) => {
      //     var subCatS = finalSubCats[key];
      //     main.subCats.push([key]);
      //     subCatS.forEach((sub) => {
      //         if (sub.parentCategory._id === main._id) {
      //             main.subCats[index].push(sub);
      //         }
      //     });
      //     return main;
      // });
      let categoryTypes = [];
      let ch = main.childCategoryArray;

      let sequenced = ch.sort((a, b) => {
        let v1 = a.childCategories.sequence;
        let v2 = b.childCategories.sequence;
        return this.getTextSort(1, v1, v2);
      });

      main.childCategoryArray = sequenced;
      main.childCategoryArray.map((cat) => {
        let filtered = categoryTypes.filter(
          (c) => c === cat?.childCategories?.type
        );
        if (!filtered.length) {
          categoryTypes.push(cat.childCategories?.type);
        }
      });
      let subCatArray = [];
      categoryTypes.map((catType) => {
        let filteredCats = main.childCategoryArray.filter(
          (category) => category?.childCategories?.type === catType
        );
        let array = [];
        array.push(catType);
        array = array.concat(filteredCats);

        subCatArray.push(array);
      });
      main.subCats = subCatArray;
    });
    this.setState({ mainCats });
    this.props.saveAllCats(mainCats);
  };
}

const mapStateToProps = (state) => {
  return {
    ...state.others,
    flag: state?.drawer?.flag,
    userName: state?.auth?.user?.name,
    token: state?.auth?.user?.token,
    navigations: state?.navigations?.navigations,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveAllCats: (data) => dispatch(saveAllCats(data)),
    saveCatalog: (data) => dispatch(saveCatalog(data)),
    manageDrawer: () => dispatch({ type: "toggleDrawer" }),
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyle)
)(CategoriesNavBar);
