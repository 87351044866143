import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, TextField, Card, CardContent, Icon, IconButton, Tooltip, Divider, Grid } from '@material-ui/core';
import { addReminder, allReminders, deleteReminders, updateReminders } from '../../store/actions/profileAction'

class Reminders extends Component {
    state = {
        saveState: true,
        name: '',
        occasion: '',
        date: '',
        _id: ''
    }

    componentDidMount() {
        this.props.allReminders();
    }

    reminderAddHandler = () => {
        if(this.state.saveState) {
            this.props.addReminder({
                name: this.state.name,
                occasion: this.state.occasion,
                date: this.state.date
            });
    
            this.setState({
                name: '',
                occasion: '',
                date: '',
                _id: ''
            });
        } else {
            this.reminderUpdateHandler();
        }
    }

    deleteHandler(id) {
        this.props.deleteReminders(id);
    }

    editStateChange(id) {
        const reminder = this.props.reminders.find(r => r._id === id);

        this.setState({
            saveState: false,
            name: reminder.name,
            occasion: reminder.occasion,
            date: moment(reminder.date).format("YYYY-MM-DD"),
            _id: reminder._id
        });
    }

    reminderUpdateHandler() {
        this.props.updateReminders({
            name: this.state.name,
            occasion: this.state.occasion,
            date: this.state.date,
            _id: this.state._id
        });

        this.setState({
            saveState: true,
            name: '',
            occasion: '',
            date: '',
            _id: ''
        });
    }

    render() {
        return (
            <div style={{ width: '100%', display: 'flex' }}>
                <div className='w-50 mr-4'>
                    <h4> My Reminders </h4>

                    <div className='mt-3'>
                        <div>
                            <TextField type='text' label='Name' placeholder='Enter name here'
                                value={this.state.name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                            />
                        </div>

                        <div className='mt-3'>
                            <TextField type='text' label='Occasion' placeholder='Enter occasion here'
                                value={this.state.occasion}
                                onChange={(e) => this.setState({ occasion: e.target.value })}
                            />
                        </div>

                        <div className='mt-4'>
                            <TextField type='date'
                                value={this.state.date}
                                onChange={(e) => this.setState({ date: e.target.value })}
                            />
                        </div>

                        <div className='mt-4 text-center'>
                            <Button
                                variant='contained' className='bg_secondary text-white'
                                onClick={this.reminderAddHandler}
                            >
                                Save Occasion
                        </Button>
                        </div>
                    </div>
                </div>
                <div className='w-50'>
                    <Grid container direction='column' spacing={3}>
                        {
                            this.props.reminders.map((reminder) => {
                                return (
                                    <Grid item xs={12} key={reminder._id}>
                                        <Card elevation={2}>
                                            <CardContent>

                                                <div className='text-right'>

                                                    <Tooltip title={'Delete Address'} placement='top-end'
                                                        onClick={() => this.deleteHandler(reminder._id)}
                                                    >
                                                        <IconButton>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title={'Edit Address'} placement='top-end'
                                                        onClick={() => this.editStateChange(reminder._id)}
                                                    >
                                                        <IconButton>
                                                            <Icon>edit</Icon>
                                                        </IconButton>
                                                    </Tooltip>

                                                </div>

                                                <div>
                                                    <h5>{reminder.name}</h5>
                                                    <Divider />

                                                    <p>Occasion: {reminder.occasion}</p>
                                                    <Divider />

                                                    <p>Date: {moment(reminder.date).format("ll")}</p>
                                                    <Divider />
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })
                        }

                    </Grid>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        reminders: state.profile.reminders
    };
};

const mapDispatchToProps = (dispatch, router) => {
    return {
        addReminder: (data) => dispatch(addReminder(data, router)),
        allReminders: (data) => dispatch(allReminders(data, router)),
        deleteReminders: (data) => dispatch(deleteReminders(data, router)),
        updateReminders: (data) => dispatch(updateReminders(data, router))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Reminders)
);