import React, { Component } from "react";
import {withRouter} from "react-router-dom";

class ScrollTop extends Component{
    componentDidUpdate(prevProps) {
        // if (this.props.location !== prevProps.location) {
        //   const urlParts = document.location.pathname.split("/");
        //   if (whiteListRoutes.indexOf(urlParts?.[1]) !== -1) {
        //     handleLocationChange();
        //   }
        // }
        window.scrollTo(0, 0);
    }
    render() {
        return this.props.children;
    }
  }
    export default withRouter(ScrollTop);