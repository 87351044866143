import React, { Component } from "react";
import PaymentDetails from "./PaymentDetails/PaymentDetailsFailed";
import axios from "axios";
import { Grid } from "@material-ui/core";
class CheckoutFailedPayment extends Component {
  state = {
    amount: "",
  };
  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search);
    let orderId = query.get("orderId");
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}orders/get/` + orderId,
      {
        headers: { "access-token": token },
      }
    );

    if (res?.data?.data) {
      let amount = res?.data?.data[0][0].order.amount;
      let senderEmail = res?.data?.data[0][0].order.senderEmail;
      this.setState({ amount, senderEmail });
    }
  };
  render() {
    return (
      <div className="my-4">
        <Grid container direction="row" justify="center">
          <PaymentDetails
            expOpen={true}
            customerData={{
              //   senderPhone: this.state.senderPhone,
              senderEmail: this.state.senderEmail,
              //   senderName: this.state.senderName,
              //   cartIds: this.state.cartIds,
              amount: this.state.amount,
            }}
          />
        </Grid>
      </div>
    );
  }
}
export default CheckoutFailedPayment;
