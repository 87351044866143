import React, { Component } from "react";
import { postRequest, getRequest } from "../../../../store/services";
import {
    Modal,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    NativeSelect,
} from "@material-ui/core";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { updateMessageInfo } from "../../../../store/actions/cartActions";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import "./GiftMessage.css";
import { withRouter } from "react-router-dom";
class GiftMessage extends Component {
    state = {
        occasion: "",
        to: "",
        message: "",
        from: "",
        occasionMessages: [],
        open: false,
        occasions: [],
        messageFlag: false,
        clonedMessage: "",
        height: window.innerHeight,
        width: window.innerWidth,
    };
    save = async () => {
        let obj = {
            giftCard: true,
            giftCard_occasion: this.state.occasion,
            giftCard_message: this.state.message,
            giftCard_receiver: this.state.to,
            giftCard_sender: this.state.from,
            id: this.props.cart?._id,
        };
        await this.props.updateCart(obj);
        this.props.handleSave();
    };
    componentDidMount = async () => {
        try {
            window.addEventListener("resize", this.setDimensions.bind(this));
            const res = await getRequest("occasion/all");
            let occasion = "";
            let occasionMessages = "";
            let open = false;
            if (res.data.length) {
                occasion = res.data[0]._id;
                occasionMessages = res.data[0].occasionMessages;

                open = true;
            }

            this.setState({
                occasion: occasion,
                occasionMessages: occasionMessages,
                occasions: res.data,
            });
        } catch (error) {
            console.log(error);
        }
    };
    componentDidUpdate(prevProps) {
        if (prevProps.cart !== this.props.cart) {
            let cart = this.props.cart;
            let occasion = "";
            let to = "";
            let message = "";
            let from = "";
            let occ = {};
            if (cart?.giftCard_occasion) {
                occasion = cart?.giftCard_occasion;
                occ.occasion = cart?.giftCard_occasion;
            }
            if (cart?.giftCard_receiver) {
                to = cart?.giftCard_receiver;
            }
            if (cart?.giftCard_message) {
                message = cart?.giftCard_message;
            }
            if (cart?.giftCard_sender) {
                from = cart?.giftCard_sender;
            }
            this.setState({ ...occ, to, message, from });
        }
    }
    handleOccasionSelect = (id) => {
        const occasion = this.state.occasions.find((oc) => oc._id == id);

        this.setState({
            occasion: id,
            occasionMessages: occasion.occasionMessages,
            message: "",
        });
    };
    cancel = () => {
        this.props.handleClose();
    };

    setDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    render() {
        return (
            <div>
                <Modal
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    //   style={{ overflow: "scroll" }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {/* <Dimensions setDimensions={this.setDimensions()} /> */}

                    <div
                        style={{
                            height: "100vh",
                            width: "100vw",
                            backgroundColor: "white",
                            position: "absolute",
                            // top: (this.state.height - 550) / 2,
                            border: "2px solid white",
                            // right: (this.state.width - 600) / 2,
                            alignItems: "center",
                            display: "flex",
                            overflow: "scroll",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            onClick={() => this.props.handleClose()}
                            style={{
                                display: "flex",
                                height: "30px",
                                width: "100%",
                                alignItems: "center",
                                flexDirection: "row",
                                backgroundColor: "#ee63a4",
                                justifyContent: "flex-end",
                                cursor: "pointer",
                            }}
                        >
                            <CloseIcon
                                style={{
                                    border: "1px solid",
                                    borderTopLeftRadius: "5px",
                                    borderTopRightRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                    borderBottomRightRadius: "5px",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                marginTop: "20px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                            }}
                        >
                            <FormControl style={{ width: "50%" }}>
                                <InputLabel>SELECT OCCASION</InputLabel>
                                <NativeSelect
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={this.state.occasion}
                                    onClick={() => this.setState({ open: false })}
                                    onChange={(e) => this.handleOccasionSelect(e.target.value)}
                                    label="Age"
                                >
                                    {this.state.occasions?.map((occansion) => (
                                        <option value={occansion._id}>
                                            {occansion.occasionName}
                                        </option>
                                    ))}
                                    {/* <MenuItem value="Anniversary">Anniversary</MenuItem>
                  <MenuItem value="Thank You">Thank You</MenuItem> */}
                                </NativeSelect>
                            </FormControl>

                            {/* <FormControl style={{ marginLeft: "10px" }}>
                <InputLabel>MESSAGE ON CARD</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Template Message"
                  value={this.state.message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                >
                  <MenuItem
                    // dense={true}
                    style={{ width: "100%", display: "flex" }}
                    value="I wish you a hearty congratulations on this pleasant occasion. May your life shower you with such happy and successful moments. Well Done. Congratulations"
                  >
                    I wish you a hearty congratulations on this pleasant
                    occasion. May your life shower you with such happy and
                    successful moments. Well Done. Congratulations
                  </MenuItem>
                  {/* <MenuItem value="You have made everyone extremely proud and proved your worth! I wish you success in everything that you do in life. Congratulations on your big day!">
                    You have made everyone extremely proud and proved your
                    worth! I wish you success in everything that you do in life.
                    Congratulations on your big day!
                  </MenuItem>
                  <MenuItem value="Facing challenges with strength, determination and confidence is what matters and you have done it! Congratulations on this amazing achievement!">
                    Facing challenges with strength, determination and
                    confidence is what matters and you have done it!
                    Congratulations on this amazing achievement!
                  </MenuItem> }
                </Select>
              </FormControl> */}
                            <div
                                style={{
                                    marginTop: "10px",
                                    width: "48%",
                                    justifyContent: "space-between",
                                    display: "flex",
                                    flexDirection: "row",
                                    borderBottom: "1px solid #757575",
                                    alignItems: "center",
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                    height: "38px",
                                }}
                                onClick={() => {
                                    if (this.state.occasion === "") {
                                    } else {
                                        this.setState({ open: !this.state.open });
                                    }
                                }}
                            >
                                <p
                                    style={{
                                        marginLeft: "5px",
                                        marginBottom: "0px",
                                        width: "90%",
                                        position: "relative",
                                        height: "20px",
                                        overflow: "hidden",
                                        lineHeight: "25px",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {this.state.message === "" || this.state.messageFlag === false
                                        ? "Select Message in Card"
                                        : this.state.clonedMessage}
                                </p>
                                {!this.state.open && (
                                    <ArrowDropDownIcon
                                        style={{
                                            marginRight: "10px",
                                            position: "sticky",
                                            right: "3px",
                                            top: "5px",
                                        }}
                                    />
                                )}
                                {this.state.open && (
                                    <ArrowDropUpIcon
                                        style={{
                                            marginRight: "10px",
                                            marginBottom: "10px",
                                            position: "sticky",
                                            right: "3px",
                                        }}
                                    />
                                )}
                            </div>

                            {this.state.open && (
                                <div
                                    style={{
                                        zIndex: "999",
                                        position: "fixed",
                                        top: "94px",
                                        right: 0,
                                        backgroundColor: "white",
                                        boxShadow: "0px 0px 4px",
                                        width: "48%",
                                        height: "400px",
                                        overflow: "auto",
                                    }}
                                >
                                    <ul class="manue">
                                        {this.state.occasionMessages.map((occasionMessage) => (
                                            <li
                                                className="manueItem"
                                                value={occasionMessage.message}
                                                onClick={(e) => {
                                                    this.setState({
                                                        message: e.target.innerText,
                                                        open: false,
                                                        messageFlag: true,
                                                        clonedMessage: e.target.innerText,
                                                    });
                                                }}
                                            >
                                                {occasionMessage.message}
                                            </li>
                                        ))}

                                        {/* <li
                      className="manueItem"
                      value={
                        "You have made everyone extremely proud and proved your worth! I wish you success in everything that you do in life. Congratulations on your big day!"
                      }
                      onClick={(e) => {
                        this.setState({
                          message: e.target.innerText,
                          open: false,
                        });
                      }}
                    >
                      You have made everyone extremely proud and proved your
                      worth! I wish you success in everything that you do in
                      life. Congratulations on your big day!
                    </li>
                    <li
                      className="manueItem"
                      value={
                        "I wish you a hearty congratulations on this pleasant occasion. May your life shower you with such happy and successful moments. Well Done. Congratulations"
                      }
                      onClick={(e) => {
                        this.setState({
                          message: e.target.innerText,
                          open: false,
                        });
                      }}
                    >
                      I wish you a hearty congratulations on this pleasant
                      occasion. May your life shower you with such happy and
                      successful moments. Well Done. Congratulations
                    </li> */}
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div
                            style={{
                                display: "flex",
                                marginTop: "15px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                width: "100%",
                            }}
                        >
                            <TextField
                                id="standard-basic"
                                label="To"
                                variant="outlined"
                                value={this.state.to}
                                onChange={(e) => this.setState({ to: e.target.value })}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "15px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                width: "100%",
                            }}
                        >
                            <TextField
                                id="standard-basic"
                                variant="outlined"
                                label="Message Body"
                                value={this.state.message}
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        this.setState({
                                            message: e.target.value,
                                            messageFlag: false,
                                        });
                                    } else {
                                        this.setState({ message: e.target.value });
                                    }
                                }}
                                multiline
                                rows={8}
                                inputProps={{ maxLength: 250 }}
                                rowsMax={14}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                marginTop: "15px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                width: "100%",
                            }}
                        >
                            <TextField
                                id="standard-basic"
                                label="From"
                                variant="outlined"
                                value={this.state.from}
                                onChange={(e) => this.setState({ from: e.target.value })}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "15px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                flexDirection: "row",
                                width: "100%",
                            }}
                        >
                            <Button
                                style={{ backgroundColor: "#f99d33", crusor: "pointer" }}
                                onClick={(e) => this.save()}
                            >
                                Save
                            </Button>
                            <Button
                                style={{ marginLeft: "20px", crusor: "pointer" }}
                                onClick={(e) => this.cancel()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, router) => {
    return {
        updateCart: (data) => dispatch(updateMessageInfo(data, router)),
    };
};
// export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(GiftMessage)
);
