import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";

import rootReducer from "./reducers";
import thunk from "redux-thunk";

export let store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
export let persistor = persistStore(store);
