import axios from "axios";
import { Notify, redirect } from "../../constants/utils";
const SERVICE_URL = process.env.REACT_APP_BACKEND_URL;
export const dispatchAction = (action, payload, route = null) => (
  dispatch,
  router
) => {
  dispatch({ type: action, payload: payload });
  if (route) {
    redirect(router, route);
  }
};
export const postRequest = async (
  url,
  body = null,
  notify = true,
  previousRoute
) => {
  var finalUrl = SERVICE_URL + url;
  var token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios
      .post(finalUrl, body, { headers: { "access-token": token } })
      .then(function (response) {
        resolve(response.data);
        if (notify) {
          Notify("success", response?.data?.message);
        }
      })
      .catch(function (error) {
        //
        // if (error.response?.data.error) {
        //   localStorage.removeItem("token");
        //   localStorage.removeItem("currentUser");
        //   window.location.href = "/login?redirect=" + previousRoute;
        // }
        // Notify("danger", error.response?.data?.message);
        reject(error);
      });
  });
};
export const getRequest = async (
  url,
  body = null,
  notify = true,
  headers = {}
) => {
  console.warn("getBody", body);
  var finalUrl = SERVICE_URL + url;
  return new Promise((resolve, reject) => {
    axios
      .get(finalUrl, { params: { ...body }, headers: { ...headers } })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (notify && error.response) {
          Notify("danger", error.response?.data?.message);
        }
        reject(error);
      });
  });
};
export const putRequest = async (url, body = null, notify = true, headers) => {
  console.warn("putBody", body);
  var finalUrl = SERVICE_URL + url;
  return new Promise((resolve, reject) => {
    axios
      .put(finalUrl, body, { headers: { ...headers } })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (notify && error.response) {
          Notify("danger", error.response?.data?.message);
        }
        reject(error);
      });
  });
};
