import React from "react";
import TopSearchBox from "./TopSearchBox";
import {AppBar, Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";

const useStyles=makeStyles({
    root:{
        backgroundColor:'white',
    }

})

const OnlySearchNav = () =>{
    const classes= useStyles()
    return(
        <AppBar elevation={0} classes={{root:classes.root}} position={"fixed"} id={'hide-secondaryNav'}>
            <Toolbar>
                <TopSearchBox/>
            </Toolbar>
        </AppBar>
        )
}

export default React.memo(OnlySearchNav);
