import React, { Component } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  Button,
  Icon,
  Card,
  CardContent,
} from "@material-ui/core";
import { Notify } from "../constants/utils";
import { connect } from "react-redux";
import { putRequest } from "../store/services";
import { contactUs, trackOrder } from "../store/actions/othersActions";
import EmailIcon from "@material-ui/icons/Email";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import StayPrimaryPortraitIcon from "@material-ui/icons/StayPrimaryPortrait";
import img from "../../src/assets/images/Logo.webp";
import { getFindMeta } from "../store/actions/metaAction";

class ContactUs extends Component {
  state = {
    file1: "",
    file2: "",
    file1Text: "",
    file2Text: "",
    queryType: "",
    orderNumber: "",
    inputRef1: "",
    inputRef2: "",
    name: "",
    email: "",
    phone: "",
    contactType: "",
    comments: "",
    trackingOrderId: "",
    trackingEmail: "",
    orders: [],
  };
  componentDidMount() {
    this.props.metaApi("contact");
  }
  submitForm = (e) => {
    const {
      file1,
      file2,
      queryType,
      orderNumber,
      file1Text,
      file2Text,
      name,
      email,
      phone,
      contactType,
      comments,
    } = this.state;
    let dataObj = {
      file1,
      file2,
      queryType,
      orderNumber,
      file1Text,
      file2Text,
      name,
      email,
      phone,
      contactType,
      comments,
    };
    this.props.contactUs(dataObj);
    Notify(
      "success",
      "Thank you for contacting us. We will get back to you as soon as possible."
    );
    this.setState({
      file1: "",
      file2: "",
      file1Text: "",
      file2Text: "",
      inputRef: "",
      queryType: "",
      orderNumber: "",
      name: "",
      email: "",
      phone: "",
      contactType: "",
      comments: "",
    });
  };
  readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };
  trackOrder = async () => {
    if (this.state.trackingOrderId === "" || this.state.trackingEmail === "") {
      this.setState({ orders: [] });
      Notify("danger", "Please fill both fields.", "Error");
      return;
    }
    let data = {
      email: this.state.trackingEmail,
      orderNo: this.state.trackingOrderId,
    };
    let order = await this.props.trackOrder(data);
    let orderData = [];
    if (order.data === 0) {
      Notify("danger", "No order with such Info.", "Error");
      return;
    } else {
      for (let i = 0; i < order.data.Cart.length; i++) {
        let cart = order.data.Cart[i];
        let temp = {};
        temp.img = cart.products[0].images?.[0];
        temp.title = cart.products[0].title;
        temp.quantity = cart.quantity;
        temp.status = cart.deliveryStatus
          ? cart.deliveryStatus
          : "Not Allocated";
        orderData.push(temp);
      }
      this.setState({ orders: orderData });
    }
  };
  inputHandler = async (event, attachment) => {
    const file = event.target.files[0];
    const fileType = file.type;
    if (fileType !== "image/jpeg" && fileType !== "image/png") {
      Notify("danger", "File format is not supported", "Error");
      if (attachment === "1") {
        this.setState({ file1: "" });
      }
      if (attachment === "2") {
        this.setState({ file2: "" });
      }
    } else {
      let formData = new FormData();
      formData.append("image", file);
      let image = await putRequest("cart/uploadImages", formData);
      let location = image.data;
      if (attachment === "1") {
        this.setState({
          file1: location,
          file1Text: file.name,
        });
      }
      if (attachment === "2") {
        this.setState({
          file2: location,
          file2Text: file.name,
        });
      }
    }
  };

  render() {
    return (
      <div className="homeMargin mx-4">
        <Grid container direction="row">
          {/*form*/}
          <Grid item xs={12} className="">
            <h1
              className="display-4 font-weight-bolder"
              style={{ fontSize: "36px" }}
            >
              {" "}
              Get in Touch{" "}
            </h1>
            <p className="mt-4">
              Please fill out the quick form and we will be in touch with you in
              lightning speed.
            </p>

            {/*fields*/}
            <div>
              <div>
                <FormControl variant="outlined">
                  <InputLabel>Select your query type</InputLabel>
                  <Select
                    label="Select your query type"
                    variant="outlined"
                    value={this.state.queryType}
                    onChange={(e) =>
                      this.setState({ queryType: e.target.value })
                    }
                  >
                    <MenuItem value={"complaint/Feedbck"}>
                      Complaint / Feedback
                    </MenuItem>
                    <MenuItem value={"preOrderInquiry"}>
                      Pre Order Inquiry
                    </MenuItem>
                    <MenuItem value={"postOrderInquirey"}>
                      Post Order Inquiry
                    </MenuItem>
                    <MenuItem value={"appericiation"}>Appreciation</MenuItem>
                    <MenuItem value={"issue"}>Webiste Issue</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="mt-4">
                <TextField
                  type="text"
                  variant="outlined"
                  value={this.state.orderNumber}
                  onChange={(e) =>
                    this.setState({ orderNumber: e.target.value })
                  }
                  label="Order number"
                />
              </div>

              <div className="mt-4">
                <TextField
                  type="text"
                  variant="outlined"
                  label="Your Name"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>

              <div className="mt-4">
                <TextField
                  type="email"
                  variant="outlined"
                  label="Your email"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>

              <div className="mt-4">
                <TextField
                  type="number"
                  variant="outlined"
                  label="Your phone"
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
              </div>

              <div className="mt-4">
                <RadioGroup
                  row
                  value={this.state.contactType}
                  onChange={(e) =>
                    this.setState({ contactType: e.target.value })
                  }
                >
                  <FormControlLabel
                    value="Email"
                    control={<Radio />}
                    label="Contact me by email"
                  />
                  <FormControlLabel
                    value="Phone"
                    control={<Radio />}
                    label="Contact me by phone"
                  />
                </RadioGroup>
              </div>

              <div className="mt-4">
                <TextField
                  type="text"
                  multiline
                  rows={4}
                  value={this.state.comments}
                  onChange={(e) => this.setState({ comments: e.target.value })}
                  variant="outlined"
                  label="Comments"
                />
              </div>

              <div className="mt-4">
                <p>Please upload pictures. (Optional)</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ display: "flex" }}
                    //   type="file"
                    //   accept=""
                    //   ref={this.state.inputRef}
                    //   onChange={(e) => this.inputHandler(e, "1")}
                    color="primary"
                    startIcon={<Icon>attachment</Icon>}
                  >
                    <input
                      type="file"
                      style={{
                        zIndex: 999,
                        opacity: 0,
                        width: "100%",
                        position: "absolute",
                      }}
                      ref={(ref) => {
                        this.image = ref;
                      }}
                      accept=""
                      onChange={(e) => this.inputHandler(e, "1")}
                    />
                    Attach Photo
                  </Button>
                  <p
                    style={{
                      display: "flex",
                      marginBottom: "0px",
                      marginLeft: "5px",
                    }}
                  >
                    {this.state.file1Text}
                  </p>
                </div>
              </div>

              <div className="mt-4">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    //   onChange={(e) => this.inputHandler(e, "2")}
                    color="primary"
                    startIcon={<Icon>attachment</Icon>}
                  >
                    <input
                      type="file"
                      style={{
                        zIndex: 999,
                        opacity: 0,
                        width: "100%",
                        position: "absolute",
                      }}
                      ref={(ref) => {
                        this.image = ref;
                      }}
                      accept=""
                      onChange={(e) => this.inputHandler(e, "2")}
                    />
                    Attach Photo
                  </Button>
                  <p
                    style={{
                      display: "flex",
                      marginBottom: "0px",
                      marginLeft: "5px",
                    }}
                  >
                    {this.state.file2Text}
                  </p>
                </div>
              </div>

              <div className="mt-3">
                <Button
                  variant="contained"
                  className="bg_secondary w-100 text-white"
                  onClick={(e) => this.submitForm(e)}
                >
                  Submit
                </Button>
              </div>
            </div>
            {/*fields end here*/}

            <div className="mt-4">
              <h4
                className="display-4 font-weight-bolder"
                style={{ fontSize: "36px" }}
              >
                Our Corporate Office
              </h4>

              <Grid container direction="row" spacing={2}>
                {/* <Grid item xs>
                  <Card>
                    <CardContent>
                      <h4>Abu Dhabi</h4>
                      <p>
                        U D M A N Floral Trading LLC Branch of
                        Abudhabi,Street-9, Plot 97, M 17 Mussafah - Abu Dhabi
                      </p>
                      <FormControlLabel
                        control={<Icon>smartphone</Icon>}
                        label="+971 56 74 27 222"
                      />
                    </CardContent>
                  </Card>
                </Grid> */}
                <Grid item xs={12}>
                  <Card className="mt-2 mb-5 w-100">
                    <CardContent>
                      <h4>Dubai</h4>
                      <p>
                        Office #307, Dusseldorf Business Point, Al Barsha 1
                        <br />
                        Dubai, Near Mall of the Emirates, P.O. Box - 454396,
                        Dubai, UAE
                      </p>
                      <FormControlLabel
                        control={<LocalPhoneIcon />}
                        label="+971- 04 565 3810"
                      />
                      <FormControlLabel
                        control={<Icon>smartphone</Icon>}
                        label="+971 555231077"
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* <Grid item xs>
                  <Card>
                    <CardContent>
                      <h4>Dubai</h4>
                      <p>
                        P.O BOX 393728, U D M A N Floral Trading LLC Warehouse
                        No-4, Street 6a, Al Quoz Industrial Area-3 - Dubai
                      </p>
                      <FormControlLabel
                        control={<Icon>smartphone</Icon>}
                        label="+971 56 74 27 222"
                      />
                    </CardContent>
                  </Card>
                </Grid> */}
              </Grid>
            </div>
          </Grid>
          {/*form ends here*/}

          <Grid item xs={12}>
            <div className="text-center mt-3">
              <Card>
                <CardContent>
                  <h4> Track Order </h4>
                  <div className="mt-3">
                    <TextField
                      type="text"
                      variant="outlined"
                      value={this.state.trackingOrderId}
                      onChange={(e) =>
                        this.setState({ trackingOrderId: e.target.value })
                      }
                      label="Order number"
                    />
                  </div>

                  <div className="mt-3">
                    <TextField
                      type="email"
                      variant="outlined"
                      value={this.state.trackingEmail}
                      onChange={(e) =>
                        this.setState({ trackingEmail: e.target.value })
                      }
                      label="Email id"
                    />
                  </div>

                  <div className="mt-3">
                    <Button
                      variant="contained"
                      className="bg_secondary w-100 text-white"
                      onClick={(e) => this.trackOrder()}
                    >
                      Track Order
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>
                      {this.state.orders.length > 0 && <h6>Gift(s)</h6>}
                      {this.state.orders.length > 0 && (
                        <p style={{ marginBottom: "0px" }}>Status</p>
                      )}
                    </div>
                    {this.state.orders?.map((order) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <img
                          src={order.img}
                          alt={order.title}
                          height="70px"
                          width="70px"
                          loading="lazy"
                        />
                        <p
                          style={{
                            marginBottom: "0px",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          {order.title}
                        </p>
                        <p
                          style={{
                            marginBottom: "0px",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          Quantity:{order.quantity}
                        </p>
                        <p
                          style={{
                            marginBottom: "0px",
                          }}
                        >
                          {order.status}
                        </p>
                      </div>
                    ))}
                    {this.state.orders.length > 0 && (
                      <p>For more Information please Login</p>
                    )}
                  </div>
                </CardContent>
              </Card>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                  alignItems: "flex-start",
                }}
              >
                <h5>Connect with us</h5>
                <p style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                  For Support or Inquiry:
                </p>
                <h6 style={{ marginTop: "20px" }}>Call us</h6>
                <span style={{ flexDirection: "row", display: "flex" }}>
                  <LocalPhoneIcon />
                  <p style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                    +971- 04 565 3810
                  </p>
                </span>
                <span
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: "10px",
                  }}
                >
                  <StayPrimaryPortraitIcon />
                  <p style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                    +971 555231077
                  </p>
                </span>
                <h6 style={{ marginTop: "20px" }}>Email Us</h6>
                <span
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: "10px",
                  }}
                >
                  <EmailIcon />
                  <a
                    href="mailto:support@giftabliss.com"
                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                  >
                    support@giftabliss.com
                  </a>
                </span>
                <p>P.O BOX – 454396</p>
                <span
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: "10px",
                  }}
                >
                  <ContactSupportIcon />
                  <a
                    href="/FAQ"
                    style={{ marginBottom: "0px", paddingBottom: "0px" }}
                  >
                    Frequently asked Question
                  </a>
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    contactUs: (data) => dispatch(contactUs(data)),
    trackOrder: (data) => dispatch(trackOrder(data)),
    metaApi: (data) => dispatch(getFindMeta(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
