const initialState = {
  mainCats: [],
  corpCats: [],
  catalog: "uae",
  currencyRates: {},
  addons: [],
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_CATEGORIES":
      var payload = action.payload;
      var data = {
        ...state,
        mainCats: payload,
      };
      return data;
    case "STORE_CORPORATE_CATS":
      return {
        ...state,
        corpCats: action.payload,
      };
    case "CATALOG":
      return { ...state, catalog: action.payload };
    case "CURRENCY_RATES":
      return { ...state, currencyRates: action.payload };
    case "SAVE_ADDONS":
      return { ...state, addons: action.payload };
    default:
      return state;
  }
};
export default authReducer;
