import React, { Component } from "react";
import banner from "../../assets/corporate/Corporate-01.webp";
import banner2 from "../../assets/corporate/Corporate-02.webp";
import style from "./Corporate.module.scss";
import {
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  AppBar,
  Toolbar,
  Button,
  TextField,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { compose } from "redux";
import CategoryCard from "./CategoryCard/CategoryCard";
import axios from "axios";
import { Notify } from "../../constants/utils";
import step1 from "../../assets/images/step1.png";
import step2 from "../../assets/images/step2.png";
import step3 from "../../assets/images/step3.png";
import "react-multi-carousel/lib/styles.css";
import p1 from "../../assets/images/logos/p1.png";
import p2 from "../../assets/images/logos/p2.png";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import p3 from "../../assets/images/logos/p3.png";
import p4 from "../../assets/images/logos/p4.png";
import p5 from "../../assets/images/logos/p5.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  saveCorporateCats,
  contactUs,
} from "../../store/actions/othersActions";
import { getFindMeta } from "../../store/actions/metaAction";

const useStyles = createStyles({
  label: {
    whiteSpace: "nowrap",
  },
});

class Corporate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      name: "",
      email: "",
      phone: "",
      comments: "",
    };
  }

  async componentDidMount() {
    this.props.metaApi("corporate");
    try {
      const token = localStorage.getItem("token");
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}categories/allActive`,
        { headers: { "access-token": token } }
      );

      if (res) {
        const apiCategories = [];
        const apiSubCategories = [];

        // separating
        res.data.data.map((cat) => {
          if (
            !cat.parentCategory &&
            cat.catalog.name.toLowerCase() === "corporate"
          ) {
            cat.subCategories = [];
            apiCategories.push(cat);
          } else if (cat.catalog.name.toLowerCase() === "corporate") {
            apiSubCategories.push(cat);
          }
          return {};
        });

        // merging
        apiSubCategories.forEach((sub) => {
          const categoryToModify = apiCategories.find(
            (cat) => cat._id === sub.parentCategory._id
          );

          if (categoryToModify) {
            categoryToModify.subCategories.push(sub);
          }
        });

        this.setState({ categories: apiCategories });
        this.props.saveCorporateCats(apiCategories);
        Notify("success", "Categories fetched successfully ", "Success");
      }
    } catch (err) {
      console.log(err);
    }
  }
  handleSubmit = async () => {
    let { name, email, phone, comments } = this.state;
    if (name === "" || email === "" || phone === "" || comments === "") {
      Notify("error", "Please fill all the fields.", "error");
      return;
    }
    let obj = {
      name,
      email,
      phone,
      comments,
    };

    await this.props.contactUs(obj);
    this.setState({ name: "", email: "", phone: "", comments: "" });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {/*banner*/}
        <div style={{ marginTop: "-16px" }} className="text-center">
          <img loading="lazy" alt="banner" src={banner} className="img-fluid" />
        </div>
        {/*banner ends here*/}

        <AppBar
          position="static"
          elevation={6}
          className="bg-white text-dark navBar"
        >
          <Toolbar>
            <Grid
              container
              wrap={"nowrap"}
              style={{ overflowY: "hidden" }}
              justify="space-around"
            >
              <Grid item>
                <FormControlLabel
                  control={
                    <IconButton
                      size="small"
                      className="bg_primary text-white mr-2"
                    >
                      <Icon>check_circle</Icon>
                    </IconButton>
                  }
                  classes={{ label: classes.label }}
                  label=" Delivering Smiles Across UAE"
                  labelPlacement="end"
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <IconButton
                      size="small"
                      className="bg_primary text-white mr-2"
                    >
                      <Icon>alarm_on</Icon>
                    </IconButton>
                  }
                  classes={{ label: classes.label }}
                  label="Emotions Deliver, On Time – Every Time!"
                  labelPlacement="end"
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <IconButton
                      size="small"
                      className="bg_primary text-white mr-2"
                    >
                      <Icon>account_circle</Icon>
                    </IconButton>
                  }
                  classes={{ label: classes.label }}
                  label="500+ Clients | 6 Million Happy Customers"
                  labelPlacement="end"
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <IconButton
                      size="small"
                      className="bg_primary text-white mr-2"
                    >
                      <Icon>store</Icon>
                    </IconButton>
                  }
                  classes={{ label: classes.label }}
                  label="Coverage across UAE"
                  labelPlacement="end"
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <IconButton
                      size="small"
                      className="bg_primary text-white mr-2"
                    >
                      <ThumbUpIcon fontSize="small" />
                    </IconButton>
                  }
                  classes={{ label: classes.label }}
                  label="100% Happiness Guaranteed"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        {/*categories*/}
        {this.state.categories.map((parentCat) => {
          return (
            <div className="mt-5">
              <h2 className="text-center mb-3">{parentCat.name}</h2>

              <Grid container direction="row" justify="space-around">
                {parentCat.subCategories.map((cat) => {
                  return (
                    <Grid item>
                      <CategoryCard category={cat} />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          );
        })}
        {/*categories end here*/}

        <div className="text-center mt-5 mx-4">
          <img
            loading="lazy"
            alt="banner"
            src={banner2}
            className="img-fluid"
          />
        </div>

        <div className={`mt-4 ${style.corporateSteps}`}>
          <h2 className="text-center">
            Start Corporate Gifting in 3 Simple Steps
          </h2>

          <div className="mt-5">
            <Grid container direction="row" justify={"space-around"}>
              <Grid
                item
                xs={4}
                className="text-center"
                style={{ minWidth: "280px" }}
              >
                <img loading="lazy" alt="Call Icon" src={step1} width="90%" />
                <div className="mt-1">
                  <span>Call us at +971 555231077</span>
                  <p>and share requirements</p>
                </div>
              </Grid>

              <Grid
                item
                xs={4}
                className="text-center"
                style={{ minWidth: "280px" }}
              >
                <img loading="lazy" alt="Gifts" src={step2} width="90%" />
                <div className="mt-1">
                  <span>Our gifting expert will</span>
                  <p>share best proposal</p>
                </div>
              </Grid>

              <Grid
                item
                xs={4}
                className="text-center"
                style={{ minWidth: "280px" }}
              >
                <img loading="lazy" alt="Finalize" src={step3} width="90%" />
                <div className="mt-1">
                  <span>Once you finalize the product</span>
                  <p>we are good to go</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        {/*slider*/}
        {/*<div className="mt-4 mx-4 text-center">*/}
        {/*  <Carousel*/}
        {/*    showDots={true}*/}
        {/*    responsive={responsive}*/}
        {/*    infinite={true}*/}
        {/*    autoPlay={this.props.deviceType !== "mobile" ? true : false}*/}
        {/*    autoPlaySpeed={4000}*/}
        {/*    keyBoardControl={true}*/}
        {/*    customTransition="transform 1000ms ease-in-out"*/}
        {/*    containerClass="carousel-container"*/}
        {/*    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}*/}
        {/*    deviceType={this.props.deviceType}*/}
        {/*    dotListClass="custom-dot-list-style"*/}
        {/*    itemClass={"carousel-item-padding-40-px"}*/}
        {/*  >*/}
        {/*    <img alt="" src={logo1} className="py-5" />*/}
        {/*    <img alt="" src={logo2} className="py-5" />*/}
        {/*    <img alt="" src={logo3} className="py-5" />*/}
        {/*    <img alt="" src={logo4} className="py-5" />*/}
        {/*    <img alt="" src={logo5} className="py-5" />*/}
        {/*    <img alt="" src={logo6} className="py-5" />*/}
        {/*    <img alt="" src={logo7} className="py-5" />*/}
        {/*    <img alt="" src={logo8} className="py-5" />*/}
        {/*    <img alt="" src={logo9} className="py-5" />*/}
        {/*  </Carousel>*/}
        {/*</div>*/}
        {/*slider ends here*/}

        <div className={`${style.corporateSteps} mt-4`}>
          <h2 className="text-center">Benefit to Partners</h2>

          <Grid
            container
            direction="row"
            style={{ fontSize: "16px" }}
            className="mt-4"
            justify="space-around"
          >
            <Grid item className="text-center" xs={6} md={2}>
              <img loading="lazy" alt="Gift" src={p1} />
              <p className="mt-2">Access to GAB 4000 product catalogue</p>
            </Grid>

            <Grid item className="text-center" xs={6} md={2}>
              <img loading="lazy" alt="Delivery" src={p2} />
              <p className="mt-2">Same day delivery at 450+ cities</p>
            </Grid>

            <Grid item className="text-center" xs={6} md={2}>
              <img loading="lazy" alt="Thumbs Up" src={p3} />
              <p className="mt-2">Decades of GAB experience</p>
            </Grid>

            <Grid item className="text-center" xs={6} md={2}>
              <img loading="lazy" alt="Smiling Face" src={p4} />
              <p className="mt-2">
                Experience or delivering more than 6 Million gifts
              </p>
            </Grid>

            <Grid item className="text-center" xs={6} md={2}>
              <img loading="lazy" alt="Customization" src={p5} />
              <p className="mt-2">Product customization as per request</p>
            </Grid>
          </Grid>

          <div className="my-5 text-center">
            <Button
              type={"button"}
              variant="contained"
              className={style.partnerButton}
            >
              <span className="mx-3 my-2">Become a Partner</span>
            </Button>
          </div>
        </div>

        {/*contact details*/}
        <div className={`${style.contactDiv} py-5 text-center mb-5 b-grey`}>
          <h2>GET IN TOUCH WITH US</h2>

          <FormControlLabel
            control={<Icon>phone</Icon>}
            label={"Sales: +971 555231077"}
          />

          <div className="mt-4">
            <Grid
              container
              direction="column"
              style={{ gap: "10px" }}
              className={"p-2"}
              justify="space-around"
            >
              <Grid item xs>
                <TextField
                  type="text"
                  variant="outlined"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                  placeholder="Enter Name"
                  className="bg-white"
                />
              </Grid>

              <Grid item xs>
                <TextField
                  type="email"
                  variant="outlined"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  placeholder="Email Id"
                  className="bg-white"
                />
              </Grid>

              <Grid item xs>
                <TextField
                  type="number"
                  variant="outlined"
                  value={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  placeholder="Phone No."
                  className="bg-white"
                />
              </Grid>

              <Grid item xs>
                <TextField
                  type="text"
                  multiline
                  rows={2}
                  variant="outlined"
                  value={this.state.comments}
                  onChange={(e) => this.setState({ comments: e.target.value })}
                  placeholder="Message"
                  className="bg-white"
                />
              </Grid>
            </Grid>
          </div>

          <div className="mt-5 px-2">
            <Button
              className={`bg_secondary text-white ${style.sendButton}`}
              variant="text"
              onClick={(e) => this.handleSubmit(e)}
              type="button"
            >
              Send
            </Button>
          </div>

          <p className="my-4">
            For more details please write in at{" "}
            <Link to={"#"} className="text-primary">
              support@giftabliss.com
            </Link>
          </p>
        </div>
        {/*contact details end here*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => state?.others?.corporate;
const mapDispatchToProps = (dispatch) => {
  return {
    saveCorporateCats: (data) => dispatch(saveCorporateCats(data)),
    contactUs: (data) => dispatch(contactUs(data)),
    metaApi: (data) => dispatch(getFindMeta(data)),
  };
};
connect(mapStateToProps, mapDispatchToProps);
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles)
)(Corporate);
