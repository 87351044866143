import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { Backdrop } from "@material-ui/core";

function Loader(props) {
  return (
    <Backdrop open={props.open} className="loaderP">
      <div>
        <Grid container direction="row" justify="center">
          <Grid item xs={12}>
            <div className="text-center">
              <CircularProgress
                className="text-white"
                size={100}
                thickness={6}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <h4 className="text-white text-center mt-2">Loading...</h4>
          </Grid>
        </Grid>
      </div>
    </Backdrop>
  );
}

export default React.memo(Loader);
