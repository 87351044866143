const initialState = {
  items: [],
  orders: [],
  discount: "",
  recentlyAdded: null,
  addresses: [],
  updating_quantity: false,
  disable_increase_button: false,
  deleting_cart: false,
  source:'MOBILESITE',
  channel:'Direct',
  medium:"Online"
  // productDetails: {},
};
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_TO_CART":
      var final = { ...state, recentlyAdded: action.payload };
      return final;
    case "ACTIVE_ORDERS":
      var orders = { ...state, orders: action.payload };
      return orders;
    case "CLEAR_STORE":
      return { items: [], orders: [] };
    case "DELETE_ORDER":
      var array = state.orders?.carts;
      var filtered = array.filter(function (value, index, arr) {
        return value._id !== action.payload;
      });
      var newData = { ...state, orders: { ...state.orders, carts: filtered } };
      return newData;
    case "STORE_ADDRESSES":
      return { ...state, addresses: action.payload };
    case "UPDATING_QUANTITY":
      return { ...state, updating_quantity: action.payload };
    case "DELETING_CART":
      return { ...state, deleting_cart: action.payload };
    case "COUPON_SUCCESSFUL":
      return { ...state, discount: action.payload };
    case "COUPON_SUBMIT":
      return { ...state, disable_increase_button: action.payload };
    case "SET_CHANNEL":
      return{...state,channel: action.payload};
    case "SET_SOURCE":
      return {...state,source:action.payload };
    case "SET_MEDIUM":
      return {...state,medium: action.payload};
    // case "SAVE_DETAIL":
    //   return { ...state, productDetails: action.payload };
    // case "DELETING_ADDRESS":
    //   console.log(state);
    //   return { ...state, addresses: state.addresses.filter(id => id !== action.payload) };
    default:
      return state;
  }
};
export default cartReducer;
