import React from "react";
import { Helmet } from "react-helmet";
function ReactHelmet({ title = "Gift A Bliss", description = "Gift A Bliss" }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
}

export default ReactHelmet;
