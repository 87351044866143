import React,{useState} from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { currencies } from "../../constants/strings";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { connect } from "react-redux";
import { changeCurrency } from "../../store/actions/authActions";
import Modal from "@material-ui/core/Modal";
function TopHeader({ changeCurrency, currency }) {
    const [modalState, setModalState] = useState(false)
  const [currencyOpen, setCurrencyOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div className={'w-100'}>
        <div
          className="currencyS"
          onClick={() => setModalState(true)}
        >
          <span
            style={{
              paddingTop: "3px",
              paddingBottom: currencyOpen ? "0px" : "3px",
                color:'#666666',
            }}
          >
            <AccountBalanceWalletIcon/> Currency:{currency}
          </span>
          <ExpandMoreIcon style={{color:'#666666'}}/>
        </div>
      </div>
      <Modal open={modalState} onClose={() => setModalState(false)} className="currencyNav">
        <div className="myModal"
             style={{
               top: `50%`,
               left: `50%`,
                 zIndex:'1400',
               transform: `translate(-50%, -50%)`,
             }} tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Select Currency</h5>
                <button onClick={() => setModalState(false)}
                        type="button" className="close" data-dismiss="modal"
                        aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className={"menu-container active"}>
                  <ul className="user-menu">
                    {currencies.map(({ short, long }, key) => {
                      return (
                          <li
                              className="user-menu__item"
                              onClick={() => {
                                changeCurrency(short);
                                setModalState(false);
                              }}
                              key={key}
                          >
                  <span className="user-menu-link">
                    {short} - {long}
                  </span>
                          </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
const mapsStateToProps = (state) => ({ currency: state?.auth?.currency });
const mapDispatchToProps = (dispatch, router) => ({
  changeCurrency: (currency) => dispatch(changeCurrency(currency)),
});
export default connect(mapsStateToProps, mapDispatchToProps)(React.memo(TopHeader));
