import React from "react";
import { connect } from "react-redux";
import {
  AppBar,
  Toolbar,
  IconButton,
  Icon,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Divider,
  Slide,
  makeStyles,
  Badge,
} from "@material-ui/core";
import logo from "../../assets/images/Logo.webp";
import { Link } from "react-router-dom";
import "./TopNav.css";
import TopHeader from "./TopHeader";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import TopSearchBox from "./TopSearchBox";
import { useLocation } from "react-router";
import { getHeaderNavigationList } from "../../store/actions/navigationAction";
import { getBestSellingProducts } from "../../store/actions/bestSellingAction";

export const NavBarActions = ({ token, order }) => {
  const ordersl = order?.carts?.length;
  const location = useLocation();
  const useStyles = makeStyles((theme) => ({
    menuRoot: {
      top: "-10px !important",
      right: "16px",
      left: "auto !important",
      borderRadius: "0",
      width: "200px",
    },
  }));
  const classes = useStyles();
  const styles = {
    appBar: {
      fontFamily: "Calibri",
      backgroundColor: "#e4c4b9",
    },
    search: {
      backgroundColor: "#FFFFFF",
    },
    icons: {
      color: "#FFFFFF",
      padding: "0px",
    },
    searchIcon: {
      borderRadius: "0px 5px 5px 0px",
      padding: "8px",
    },
  };
  // account menu handlers
  const [accountAnchorEl, setAnchorEl] = React.useState(null);
  const hideMenu = () => {
    setAnchorEl(null);
  };
  return (
    <Grid
      item
      container
      xs
      direction="row"
      justify="flex-end"
      style={{ marginTop: "23px" }}
    >
      {location.pathname !== "/profile/my-account" &&
        location.pathname !== "/contact-us" && (
          <>
            <Grid item>
              <div>
                <Link
                  onClick={() => (window.location.href = "tel:+971555231077")}
                >
                  <FormControlLabel
                    className="text-black"
                    control={
                      <IconButton style={{ padding: "0px" }}>
                        <PhoneIcon />
                      </IconButton>
                    }
                    style={{
                      color: "black",
                      marginBottom: "0px",
                      paddingBottom: "0px",
                    }}
                    labelPlacement="bottom"
                  />
                </Link>
              </div>
            </Grid>

            <Grid item style={{ marginBottom: "0px", paddingBottom: "0px" }}>
              <Link to={"/corporate"} className="text-white text-black m-0">
                <div>
                  <FormControlLabel
                    control={
                      <IconButton style={{ padding: "0px" }}>
                        <Icon fontSize="small" style={styles?.icons}>
                          location_city
                        </Icon>
                      </IconButton>
                    }
                    // label="Corporate"
                    labelPlacement="bottom"
                  />
                </div>
              </Link>
            </Grid>
          </>
        )}

      {location.pathname !== "/corporate" && (
        <>
          <Grid style={{ marginBottom: "0px", paddingBottom: "0px" }}>
            <Link to={"/cartsummary"}>
              <FormControlLabel
                style={{ color: "black" }}
                control={
                  <IconButton style={{ padding: "0px" }}>
                    <Badge badgeContent={ordersl} color="error">
                      <Icon fontSize="small" style={styles?.icons}>
                        shopping_cart
                      </Icon>
                    </Badge>
                  </IconButton>
                }
                // label="Cart"
                labelPlacement="bottom"
              />
            </Link>
          </Grid>
          <Grid
            style={{
              marginBottom: "0px",
              paddingBottom: "0px",
              display: "flex",
            }}
          >
            <div
              className="showhidelogintile"
              style={{ width: "100%", position: "relative" }}
            >
              <FormControlLabel
                labelPlacement="bottom"
                style={{ color: "black" }}
                control={
                  <IconButton style={{ padding: "0px" }}>
                    <MoreVertIcon fontSize="16" />
                  </IconButton>
                }
                onClick={(e) => setAnchorEl(e.currentTarget)}
              />
              <Menu
                classes={{ paper: classes.menuRoot }}
                className="accountMenu MuiPaper-elevation8"
                style={{
                  width: token == null ? "140" : "200",
                  right: "-16px",
                  top: "-30px",
                  height: token == null ? "195" : "275",
                }}
                anchorEl={accountAnchorEl}
                open={Boolean(accountAnchorEl)}
                onClose={hideMenu}
                transitionDuration={500}
                TransitionComponent={Slide}
              >
                {/* <div className="menuArrowDiv">
                        <Icon className="menuArrow" fontSize="large">
                          arrow_drop_up
                        </Icon>
                  </div> */}
                <Link to={"/profile/my-account"}>
                  <MenuItem
                    onClick={hideMenu}
                    style={{ padding: "14px", color: "#666666" }}
                    className="border-bottom d-flex align-items-center"
                  >
                    <AccountCircleIcon className={"mr-1"} />{" "}
                    <span>My Profile</span>
                  </MenuItem>
                </Link>
                <Link to={"/contact-us"}>
                  <MenuItem
                    onClick={hideMenu}
                    style={{ padding: "14px", color: "#666666" }}
                    className="border-bottom"
                  >
                    <LocationOnIcon className={"mr-1"} /> Track Order
                  </MenuItem>
                </Link>
                <Link to={"/profile/orders"}>
                  <MenuItem
                    onClick={hideMenu}
                    className="border-bottom"
                    style={{ padding: "14px", color: "#666666" }}
                  >
                    My Orders
                  </MenuItem>
                </Link>
                <Link to={"/profile/address-book"}>
                  <MenuItem
                    onClick={hideMenu}
                    style={{ padding: "14px", color: "#666666" }}
                  >
                    Address Book
                  </MenuItem>
                </Link>
                {token !== null && (
                  <React.Fragment>
                    <Divider />
                    <Link to={"/profile/change-password"}>
                      <MenuItem
                        style={{ padding: "14px", color: "#666666" }}
                        onClick={hideMenu}
                      >
                        Change Password
                      </MenuItem>
                    </Link>
                  </React.Fragment>
                )}
                <Divider />
                <MenuItem style={{ padding: "14px" }}>
                  <TopHeader />
                </MenuItem>
              </Menu>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};

function TopNav({
  token,
  name,
  logout,
  order,
  getHeaderNavigation,
  getBestSellProducts,
}) {
  React.useEffect(() => {
    getHeaderNavigation();
    getBestSellProducts();
  }, []);
  return (
    <div>
      {/*<TopHeader />*/}
      <AppBar elevation={0} position={"relative"} className="appBar">
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="space-between"
            // justify={navBarJustify}
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Link to={""}>
                {/*Logo Image*/}

                <Grid item xs>
                  <img
                    src={logo}
                    className="my-1"
                    loading="lazy"
                    alt={"logo"}
                    height="55px"
                    width="60px"
                  />
                </Grid>
              </Link>

              <NavBarActions token={token} order={order} />
            </Grid>

            <Grid item container direction="row" justify="flex-start">
              <TopSearchBox />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state) => ({
  order: state?.cart?.orders,
});

const mapDispatchToProps = (dispatch, router) => ({
  ToggleDrawer: () => dispatch({ type: "toggleDrawer" }),
  getHeaderNavigation: () => dispatch(getHeaderNavigationList()),
  getBestSellProducts: () => dispatch(getBestSellingProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TopNav));
