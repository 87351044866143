import React from 'react';

function Redemptions() {
    return (
        <div>
            <h4>Redemptions</h4>

            <p>No redemptions</p>
        </div>
    );
}

export default Redemptions;
