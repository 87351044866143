import React, {Component} from 'react';
import {TextField, Grid, Button} from '@material-ui/core';
import axios from 'axios';
import {Notify} from '../../constants/utils';

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordError: false,
            passwordErrorMsg: ''
        };
    }

    inputHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    validationCheck = () => {
        let isError = false;
        const errors = {};

        if (this.state.password === '') {
            isError = true;
            errors.passwordErrorMsg = 'Password should have minimum 6 characters';
            errors.passwordError = true;
        }

        if (isError) {
            this.setState({
                ...errors,
            });
        }

        return isError;
    };

    submitForm = async (event) => {
        event.preventDefault();
        const valid = this.validationCheck();

        if (!valid) {
            this.setState({
                passwordError: false,
                passwordErrorMsg: ''
            });

            try {
                const query = new URLSearchParams(this.props.location.search);
                const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}users/passwordupdate`,
                    {password: this.state.password},
                    {headers: {'access-token': query.get('id')}}
                );

                if (res) {
                    Notify('success', 'The password has been changed successfully', 'Success');
                    this.props.history.push('/');
                }

            } catch (err) {
                console.log(err);
            }

        }

    };

    render() {
        return (
            <div className='my-5 mx-4'>
                <form onSubmit={this.submitForm}>
                    <Grid container direction='row' justify='center'>

                        <Grid item className='text-center'>
                            <p className='display-4'> Enter your new password below </p>

                            <div className='mt-4'>
                                <TextField
                                    type="password"
                                    label="Password"
                                    placeholder="Enter your new password here"
                                    value={this.state.email}
                                    name="password"
                                    onChange={this.inputHandler}
                                    error={this.state.passwordError}
                                    helperText={this.state.passwordErrorMsg}
                                    className='w-50'
                                />

                                <div>
                                    <Button className='bg_secondary text-white mt-4' type='submit' variant='contained'>
                                        <span className='mx-4'>Submit</span>
                                    </Button>
                                </div>
                            </div>

                        </Grid>

                    </Grid>
                </form>
            </div>
        );
    }
}

export default ResetPassword;
