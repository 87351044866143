import React, { useEffect } from "react";
import {
  IconButton,
  Icon,
  Grid,
  Divider,
  Card,
  CardContent,
  CircularProgress, Fab,
} from "@material-ui/core";
import {validate} from "../../store/actions/authActions"
import { Link, withRouter } from "react-router-dom";
import "./TopNav.css";
import { getDateParts } from "../../constants/utils";
import CartAddons from "../common/CartAddons";
import { connect } from "react-redux";
import {
  getActiveOrders,
  deleteOrder,
  updateCart,
} from "../../store/actions/cartActions";
import {WhatsApp} from "@material-ui/icons";
function TopNavCart({
  styles,
  orders,
  deleteOrder,
  currency,
  loading,
  getOrders,
  recentlyAdded,
  currencyRates,
  updateCarts,
    validate,
}) {
  useEffect(() => {
    getOrders();
  }, [recentlyAdded]);

  const ordersL = orders?.carts?.length;
  return (
    <React.Fragment>
        <div className="">
          <a
              href="https://wa.me/971555231077"
              target="_blank"
              rel="noopener noreferrer"
          >
            <Fab className="whatsApp">
              <WhatsApp fontSize="large"/>
            </Fab>
          </a>
          {ordersL && (
            <React.Fragment>
              <Grid
                container
                direction="row"
                justify="space-between"
                className="pl-3 pr-3 pt-3"
              >
                <p className="font-weight-bold"> My Cart ({ordersL}) </p>
              </Grid>
              <Divider />
            </React.Fragment>
          )}
          <div className="content">
            {!ordersL ? (
              <React.Fragment>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  className="pl-3 pr-3 pt-3"
                >
                  <p className="font-weight-bold"> My Cart </p>
                </Grid>
                <Divider />
                <div className="text-center mt-4">
                  <Icon fontSize="large">shopping_cart</Icon>
                  <h5 className="mt-3">Your cart is empty</h5>
                  <p>But it does not have to be.</p>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {loading ? (
                  <div className="navCartLoader">
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <React.Fragment>
                    {orders?.carts.map((order, key) => {
                      var product = order?.products?.[0];
                      return (
                        <React.Fragment key={"cart" + key}>
                          <div className="cartIP">
                            <div className="bg_primary text-white cartItemNumber">
                              <span className="mx-3">{key + 1}</span>
                            </div>
                            <Card>
                              <CardContent>
                                {/*cart items*/}
                                <Grid
                                  container
                                  direction="row"
                                  justify="space-between"
                                >
                                  <Grid item xs={1}>
                                    <img
                                      src={product?.images?.[0]}
                                      height="60px"
                                      width="60px"
                                      loading="lazy"
                                      className="rounded"
                                      alt={product?.title}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs
                                    container
                                    justify="center"
                                    alignItems="center"
                                    direction="column"
                                  >
                                    <span className="p-text" style={{
                                      maxWidth: "100px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}>
                                      {product?.title}
                                    </span>
                                    <p
                                      className="p-text"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      <span className="font-weight-bold">
                                        {currency}
                                      </span>{" "}
                                      {order?.amount} x{" "}
                                      {order?.quantity ? order?.quantity : 1}
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: "1px solid rgb(218, 218, 218)",
                                        borderRadius: "3px",
                                      }}
                                    >
                                      <button
                                        className="btn"
                                        style={{
                                          paddingTop: "0px",
                                          paddingBottom: "0px",
                                        }}
                                        onClick={(e) =>
                                          handleQuantityUpdate(
                                            "subtract",
                                            order?._id,
                                            order?.quantity,
                                            updateCarts,
                                              key,
                                          )
                                        }
                                        disabled={
                                          order?.quantity > 1 ? false : true
                                        }
                                      >
                                        -
                                      </button>
                                      <p style={{ marginBottom: "0px" }}>
                                        {order?.quantity}
                                      </p>
                                      <button
                                        className="btn"
                                        style={{
                                          paddingTop: "0px",
                                          paddingBottom: "0px",
                                        }}
                                        onClick={(e) =>
                                          handleQuantityUpdate(
                                            "add",
                                            order?._id,
                                            order?.quantity,
                                            updateCarts,
                                              key,
                                          )
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </Grid>
                                  <Grid item style={{textAlign:'end'}} xs={1}>
                                    <IconButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        deleteOrder(order._id, key);
                                      }}
                                      className="p-0"
                                    >
                                      <Icon>delete</Icon>
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                {/*cart items end here*/}
                                {/*  addons starts from here */}
                                <CartAddons
                                  addons={order?.addons}
                                  sec="navbar"
                                  cartIndex={key}
                                />
                                {/*  addons ends here */}
                                <span className="address d-flex align-items-center">
                                  <Icon className="p-text-icon">
                                    location_on
                                  </Icon>
                                  <span className="p-text">{order.city}</span>
                                </span>
                                {/*date info*/}
                                <OrderDate
                                  {...{
                                    date: order.shippingDate,
                                    price: order?.shippingPrice,
                                    time: order.shippingTime,
                                    type: order.shippingType,
                                    currency: currency,
                                  }}
                                />
                                {/*date info ends here*/}
                              </CardContent>
                            </Card>
                          </div>
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                    <div className="stickyItemCart">
                      {/*total amount*/}
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        className="pl-3 pr-3 pt-3 total"
                      >
                        <h5> CART TOTAL </h5>
                        <h5 className="color_primary font-weight-bold">
                          {currency} {orders?.grandTotal}
                        </h5>
                      </Grid>
                      <Divider />
                      {/*total amount ends here*/}
                      <Link onClick={(e) =>{
                        checkOut(validate,e);
                      }}>
                        <h5 className="py-3 bg_secondary text-white text-center mb-0">
                          PROCEED TO CHECKOUT
                        </h5>
                      </Link>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
    </React.Fragment>
  );
}
const checkOut = (validate,e) =>{
  e.preventDefault();
  validate();
}
const handleQuantityUpdate = (type, id, q, cb, key) => {
  let quantity = q;
  if (type === "add") {
    quantity = quantity + 1;
  } else {
    quantity = quantity - 1;
  }

  let obj = { _id: id, quantity };
  cb(obj, key);
};

const OrderDate = ({ date, price, type, time, currency }) => {
  var dateP = getDateParts(date);
  return (
    <div className="bg-light py-1 px-2 cartFont">
      <Grid container direction="row">
        <Grid item xs={2} className="dateFont">
          <span>{dateP.date}</span>
        </Grid>
        <Grid item className="mt-2">
          <table>
            <tbody>
              <tr>
                <td>{dateP.month}</td>
                <td>
                  {type}{" "}
                  <span className="font-weight-bold color_primary">
                    {currency} {price}
                  </span>
                </td>
              </tr>
              <tr>
                <td>{dateP.day}</td>
                <td>{time}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state?.cart?.orders,
  currency: state?.auth?.currency,
  loading: state?.cart?.deleting_cart,
  recentlyAdded: state?.cart?.recentlyAdded,
  currencyRates: state?.others?.currencyRates,
});
const mapDispatchToProps = (dispatch, router) => {
  return {
    getOrders: () => dispatch(getActiveOrders()),
    deleteOrder: (id, indexOfCart) =>
        dispatch(deleteOrder(id, router, indexOfCart)),
    updateCarts: (data,key) => dispatch(updateCart(data,key)),
    validate:()=>dispatch(validate(router))
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(TopNavCart))
);
