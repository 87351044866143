import React, {Component} from "react";
import ReactCodeInput from "react-code-input";
import {
    Button,
    Card,
    CardContent,
    TextField,
    Checkbox,
    FormControlLabel,
    FormGroup,
    withStyles,
    Typography,
    Grid,
    Box,
    Snackbar,
    Radio,
    AccordionDetails,AccordionSummary,Accordion
} from "@material-ui/core";
import InputMask from 'react-input-mask';
import MuiAlert from "@material-ui/lab/Alert";
import FacebookIcon from "@material-ui/icons/Facebook";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./Login.css";
import gPlus from "../../assets/images/gplus.png";
import {Link} from "react-router-dom";
import axios from "axios";
import {
    storeUser,
    loginNow,
    verifyPinCode,
    updatePassword,
    sendOtp,
    saveRout,
    saveData,
    loginWithoutPassword
} from "../../store/actions/authActions";
import ReplayIcon from "@material-ui/icons/Replay";
import {connect} from "react-redux";
import {Notify} from "../../constants/utils";
import {compose} from "redux";

const CustomCheckbox = withStyles({
    root: {
        color: "#000000",
        "&$checked": {
            color: "orange",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
const service_url = process.env.REACT_APP_BACKEND_URL;

const useStyles={
    radioColor:{
        color:'#B379C5 !important'
    }
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formTitle: "LOGIN / REGISTER",
            userType: "firstTime", // existing and new
            // snackbar
            showSnack: false,
            snack: false,
            snackMsg: "",
            snackType: "",
            // form controls
            email: "",
            password: "",
            name: "",
            phone: "",
            countryCode: "",
            // form controls validation errors
            emailError: false,
            emailErrorMsg: "",
            passwordError: false,
            passwordErrorMsg: "",
            nameError: false,
            nameErrorMsg: "",
            phoneError: false,
            phoneErrorMsg: "",
            countryCodeError: false,
            countryCodeErrorMsg: "",
            forgotPassword: false,
            interval: 30,
            setDisable: true,
            passwordBox: false,
            resetPassword: "",
            resetPasswordConfirm: "",
            showPhoneNo: "",
            expanded:'login',
        };
    }

    async componentDidMount() {
        await this.props.saveRoute();
        await this.props.saveData();
    }

    // form state manager
    formStateUpdate = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    loginValidationCheck = (field) => {
        let isError = false;
        const errors = {};

        if (this.state.email.indexOf("@") === -1 && field === "email") {
            isError = true;
            errors.emailErrorMsg = "Invalid email format";
            errors.emailError = true;
        }

        if (this.state.password.length < 6 && field === "password") {
            isError = true;
            errors.passwordErrorMsg = "Password must be at least 6 characters long";
            errors.passwordError = true;
        }

        if (isError) {
            this.setState({
                ...errors,
            });
        }

        return isError;
    };

    // checking for sign up/sign in or (letting the user to sign in)
    submitLoginForm = async (event) => {
        event.preventDefault();

        const validateEmail = this.loginValidationCheck("email");
        const validatePassword = this.loginValidationCheck("password");

        if (this.state.userType === "firstTime" && !validateEmail) {
            // removing un necessary error
            this.setState({
                emailError: false,
                emailErrorMsg: "",
                passwordError: false,
                passwordErrorMsg: "",
            });

            const check = await axios.put(service_url + `auth/search`, {
                email: this.state.email,
            });

            if (check.data.data.length) {
                let countryCode = check.data.data[0].countryCode;
                let mobile = check.data.data[0].mobile;
                this.setState({
                    userType: "existing",
                    formTitle: "LOGIN",
                    showSnack: true,
                    snack: true,
                    snackMsg: "Welcome back!",
                    snackType: "success",
                    showPhoneNo: mobile,
                });
            } else {
                this.setState({
                    userType: "new",
                    formTitle: "REGISTER",
                    showSnack: true,
                    snack: true,
                    snackType: "info",
                    snackMsg: "You can register here",
                });
            }
        } else if (
            this.state.userType === "existing" &&
            !validateEmail &&
            !validatePassword
        ) {
            // removing un necessary errors
            this.setState({
                emailError: false,
                emailErrorMsg: "",
                passwordError: false,
                passwordErrorMsg: "",
            });

            //user logs in here
            try {
                const obj = {email: this.state.email, password: this.state.password};
                const login = await axios.post(service_url + `auth/login`, obj);

                if (login) {
                    this.props.saveUser(login.data.data);
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    showSnack: true,
                    snack: true,
                    snackType: "error",
                    snackMsg: "Email or password is incorrect",
                });
            }
        }
    };
    setPassword = async () => {
        let {resetPasswordConfirm, resetPassword, email} = this.state;
        if (resetPasswordConfirm === "" || resetPassword === "") {
            Notify("danger", "Password cannot be empty.", "Error");
            return;
        }
        if (resetPassword !== resetPasswordConfirm) {
            Notify("danger", "Passwords do not match.", "Error");
            return;
        }
        let data = {password: resetPassword, email: email};
        let resData = await this.props.updatePassword(data);
        this.props.loginNow(data);
        this.setState({forgotPassword: false, passwordBox: false});
    };
    doLater=()=>{
        let data = {email:this.state.email}
        this.props.loginWithoutPassword(data);
        this.setState({ forgotPassword: false, passwordBox: false });
    }
    varify = async () => {
        let {email, otpCode} = this.state;
        if (otpCode === "") {
            Notify(
                "danger",
                "please enter the pincode received on your mobile number and email.",
                "Error"
            );
            return;
        }

        let data = {email, otpCode};
        let resData = await this.props.verifyPinCode(data);
        if (resData.data) {
            this.setState({
                passwordBox: true,
                forgotPassword: false,
                resetPassword: "",
                resetPasswordConfirm: "",
            });
        } else {
            Notify("danger", resData.message, "Error");
            return;
        }
    };
    enableForgot = () => {
        this.setState({forgotPassword: true, interval: 30});
        this.props.sendOtp({email: this.state.email});
        this.interval = setInterval(this.dec, 1000);
    };
    goBack = () => {
        clearInterval(this.interval);
        this.setState({forgotPassword: false});
    };
    loginWithSocialMedia = async (socialMedia) => {
        window.location.href = service_url + "auth/" + socialMedia;
    };
    renderEmail = () => {
        let email = this.state.email;
        let emailParts = email.split("@");
        let length = emailParts[0].length;
        let str = "";
        for (let i = 0; i < length; i++) {
            if (i < length - 3) {
                str = str + "X";
            } else {
                str = str + emailParts[0][i];
            }
        }
        str = str + "@" + emailParts[1];
        return str;
    };

    loginComponent() {
        return (
            <Grid xs={12}>
            <form onSubmit={this.submitLoginForm}>
                {!this.state.forgotPassword && !this.state.passwordBox && (
                    <div>
                        <TextField
                            placeholder="Enter email here"
                            variant="outlined"
                            label="Email ID"
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.formStateUpdate}
                            error={this.state.emailError}
                            helperText={this.state.emailErrorMsg}
                        />
                    </div>
                )}

                {this.state.userType === "existing" &&
                !this.state.forgotPassword &&
                !this.state.passwordBox && (
                    <div>
                        <div className="mt-4">
                            <TextField
                                placeholder="Enter password here"
                                variant="outlined"
                                label="Password"
                                type="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.formStateUpdate}
                                error={this.state.passwordError}
                                helperText={this.state.passwordErrorMsg}
                            />
                        </div>

                        <div className="row mt-3">
                            <div className="col">
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<CustomCheckbox/>}
                                        label="Remember me"
                                    />
                                </FormGroup>
                            </div>

                            <div className="col text-right pt-2">
                                <Link onClick={() => this.enableForgot()}>
                                    {" "}
                                    Forgot password?{" "}
                                </Link>
                            </div>
                        </div>
                    </div>
                )}

                {!this.state.forgotPassword && !this.state.passwordBox && (
                    <div className="mt-4 text-center">
                        <Button
                            type="submit"
                            variant="contained"
                            className={`w-100 bg_secondary text-white`}
                        >
                            <span className="my-1">Continue</span>
                        </Button>
                    </div>
                )}

                {this.state.userType === "existing" &&
                !this.state.forgotPassword &&
                !this.state.passwordBox && (
                    <div className="mt-3 text-center">
                        <Button
                            variant="contained"
                            className={`otpButton w-100`}
                            onClick={() => this.enableForgot()}
                        >
                            <span className="my-1">Login with OTP</span>
                        </Button>
                    </div>
                )}
                {this.state.forgotPassword && !this.state.passwordBox && (
                    <div className="mt-4">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer",
                            }}
                            onClick={() => this.goBack()}
                        >
                            <ArrowBackIcon/>
                            <h5>OTP Verification</h5>
                        </div>
                        <div
                            style={{
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "20px",
                            }}
                        >
                            <h5>Enter OTP sent to:</h5>
                            <span
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                {" "}
                                <h5 style={{color: "#0f9d68"}}>{this.renderEmail()}</h5>
                <p style={{marginBottom: "0px"}}>and</p>
                <h5 style={{color: "#0f9d68"}}>{this.state.showPhoneNo}</h5>
              </span>
                            <ReactCodeInput
                                type="number"
                                fields={6}
                                onChange={(e) => this.setState({otpCode: e})}
                            />
                            <Button
                                style={{backgroundColor: "#ff9212", marginTop: "20px"}}
                                onClick={() => this.varify()}
                            >
                                Continue
                            </Button>
                            <div
                                style={{
                                    marginTop: "20px",
                                    pointerEvents: this.state.setDisable ? "none" : "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    cursor: "pointer",
                                }}
                                onClick={(e) => this.reSend()}
                            >
                                <ReplayIcon/>
                                <p style={{marginBotton: "0px", cursor: "pointer"}}>
                                    {" "}
                                    Resend OTP ({this.state.interval}S)
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {!this.state.forgotPassword && this.state.passwordBox && (
                    <div className="mt-4">
                        <h5>Set new password</h5>
                        <div>
                            <TextField
                                placeholder="Enter password here"
                                variant="outlined"
                                label="Password"
                                type="password"
                                name="password"
                                value={this.state.resetPassword}
                                onChange={(e) =>
                                    this.setState({resetPassword: e.target.value})
                                }
                                error={this.state.passwordError}
                                helperText={this.state.passwordErrorMsg}
                            />
                            <TextField
                                placeholder="Enter password here"
                                variant="standard"
                                label="Confirm Password"
                                type="password"
                                name="password"
                                value={this.state.resetPasswordConfirm}
                                onChange={(e) =>
                                    this.setState({resetPasswordConfirm: e.target.value})
                                }
                                error={this.state.passwordError}
                                helperText={this.state.passwordErrorMsg}
                            />
                        </div>
                        <Button
                            style={{
                                backgroundColor: "#FF9212",
                                marginTop: "20px",
                                width: "100%",
                            }}
                            onClick={() => this.setPassword()}
                        >
                            Save Password
                        </Button>
                        <Button
                            style={{
                                backgroundColor: "#555555",
                                marginTop: "20px",
                                width: "100%",
                            }}
                            onClick={() => this.doLater()}
                        >
                            I'LL DO IT LATER
                        </Button>
                    </div>
                )}
            </form>
            </Grid>
        );
    }

    reSend = () => {
        this.setState({interval: 30, setDisable: true});
        this.props.sendOtp({email: this.state.email});
        this.interval = setInterval(this.dec, 1000);
    };
    dec = () => {
        if (this.state.interval > 0) {
            this.setState({interval: this.state.interval - 1});
        } else {
            this.setState({setDisable: false});
            clearInterval(this.interval);
        }
    };

    signUpValidationCheck = () => {
        let isError = false;
        const errors = {};

        if (!this.state.name.length) {
            isError = true;
            errors.nameErrorMsg = "Name is required";
            errors.nameError = true;
        } else {
            errors.nameErrorMsg = "";
            errors.nameError = false;
        }
        if (!this.state.countryCode.length) {
            isError = true;
            errors.countryCodeErrorMsg = "Country Code is required";
            errors.countryCodeError = true;
        } else if (this.state.countryCode.length > 5) {
            isError = true;
            errors.countryCodeErrorMsg =
                "Country Code cannot be more then 5 characters";
            errors.countryCodeError = true;
        } else {
            errors.countryCodeErrorMsg = "";
            errors.countryCodeError = false;
        }

        if (!this.state.phone.length) {
            isError = true;
            errors.phoneErrorMsg = "Phone no is required";
            errors.phoneError = true;
        } else if (this.state.phone.length > 10) {
            isError = true;
            errors.phoneErrorMsg = "You can only enter 10 digits";
            errors.phoneError = true;
        } else {
            errors.phoneErrorMsg = "";
            errors.phoneError = false;
        }
        if (this.state.password.length < 6 || this.state.password === "") {
            isError = true;
            errors.passwordErrorMsg = "Password must be at least 6 characters long";
            errors.passwordError = true;
        } else {
            errors.passwordErrorMsg = "";
            errors.passwordError = false;
        }

        if (this.state.email.indexOf("@") === -1) {
            isError = true;
            errors.emailErrorMsg = "Invalid email format";
            errors.emailError = true;
        } else {
            errors.emailErrorMsg = "";
            errors.emailError = false;
        }

        if (isError) {
            this.setState({
                ...errors,
            });
        }

        return isError;
    };

    // sign up form submitting
    submitSignUpForm = async (event) => {
        event.preventDefault();

        const valid = this.signUpValidationCheck();

        if (!valid) {
            try {
                this.setState({
                    emailError: false,
                    emailErrorMsg: "",
                    passwordError: false,
                    passwordErrorMsg: "",
                    nameError: false,
                    nameErrorMsg: "",
                    phoneError: false,
                    phoneErrorMsg: "",
                    countryCodeError: false,
                    countryCodeErrorMsg: "",
                });
                const {email, password, phone, name, countryCode} = this.state;
                const obj = {
                    email: email,
                    password: password,
                    name: name,
                    mobile: phone,
                    countryCode: countryCode,
                };
                const save = await axios.post(service_url + `auth/createUser`, obj);
                if (save) {
                    var data = {email, password};
                    this.props.loginNow(data);
                    // place snackbar
                }
            } catch (err) {
                console.log(err);
                // place a snackbar
            }
        }
    };


    signUpComponent() {
        return (
            <Grid xs={12}>
            <form onSubmit={this.submitSignUpForm}>

                <div>
                    <TextField
                        placeholder="Enter your name here"
                        variant="outlined"
                        label="Name"
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.formStateUpdate}
                        error={this.state.nameError}
                        helperText={this.state.nameErrorMsg}
                    />
                </div>

                <div className="mt-4">
                    <Grid container direction="row" justify='space-between' alignItems='center' spacing={2}>
                        <Grid
                            item
                            xs={6}
                            className="abc"
                            style={{paddingLeft: "0px", paddingRight: "0px !important"}}
                        >
                            <InputMask
                                value={this.state.countryCode}
                                onChange={this.formStateUpdate}
                                mask={"+999"}
                                maskChar={null}
                            >
                                {()=><TextField
                                    placeholder="Country Code"
                                    variant="outlined"
                                    label="Country Code"
                                    type="text"
                                    inputProps={{
                                        min: 0,
                                        display: "flex",
                                        flex: 1,
                                        padding: "0px",
                                    }}
                                    name="countryCode"

                                    error={this.state.countryCodeError}
                                    helperText={this.state.countryCodeErrorMsg}
                                />}
                            </InputMask>


                        </Grid>

                        <Grid item xs={6}>
                            <div>
                                <TextField
                                    placeholder="Enter your mobile here"
                                    variant="outlined"
                                    label="Mobile Number"
                                    type="number"
                                    inputProps={{min: 0}}
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.formStateUpdate}
                                    error={this.state.phoneError}
                                    helperText={this.state.phoneErrorMsg}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div className="mt-4">
                    <TextField
                        placeholder="Enter your email here"
                        variant="outlined"
                        label="Email"
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.formStateUpdate}
                        error={this.state.emailError}
                        helperText={this.state.emailErrorMsg}
                    />
                </div>

                <div className="mt-4">
                    <TextField
                        placeholder="Enter your password here"
                        variant="outlined"
                        label="Create password"
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.formStateUpdate}
                        error={this.state.passwordError}
                        helperText={this.state.passwordErrorMsg}
                    />
                </div>

                <div className="mt-4">
                    <FormGroup>
                        <FormControlLabel
                            control={<CustomCheckbox/>}
                            label={"Remember me"}
                        />
                    </FormGroup>
                </div>

                <div className="mt-4 text-center">
                    <Button
                        variant="contained"
                        type="submit"
                        className={` w-100 bg_secondary text-white`}
                    >
                        <span className="my-1">Continue</span>
                    </Button>
                </div>
            </form>
            </Grid>
        );
    }

    closeSnackbar = () => {
        this.setState({snack: false});
    };

    render() {
        const manageAccordion = (value)=>{
            this.setState({expanded:value})
        };

        const {classes} =  this.props;
        return (
            <>
            <Grid container justify='center' className={'py-5'} style={{backgroundColor: '#F2F2F2'}} alignItems='center'>
                <Grid item xs={11}>
                    <Card elevation={4}>
                        <CardContent>
                                {/*Signup part*/}
                                <Accordion expanded={this.state.expanded ==='signup'} square onChange={() => manageAccordion('signup')}>
                                    <AccordionSummary style={{color:' rgb(102, 102, 102)'}}>

                                        <Box display='flex' justifyContent='start' alignItems='center'>
                                            <Radio  checked={this.state.expanded ==='signup'} classes={{checked:classes.radioColor}}/>
                                        <Typography variant='body1' style={this.state.expanded === 'signup'? {fontWeight:'bold'}:{}}>
                                              New Customer
                                        </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {this.signUpComponent()}
                                    </AccordionDetails>
                                </Accordion>
                            {/*Signup part ends here*/}

                            {/*login part*/}
                            <Accordion square expanded={this.state.expanded === 'login'} onChange={() => manageAccordion('login')}>
                                <AccordionSummary style={{color:' rgb(102, 102, 102)'}}>
                                <Box display='flex' justifyContent='start' alignItems='center'>
                                    <Radio  checked={this.state.expanded === 'login'} classes={{checked:classes.radioColor}}/>
                                    <Typography variant='body1' style={this.state.expanded === 'login'? {fontWeight:'bold'}:{}}>
                                        Existing Customer
                                    </Typography>
                                </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.loginComponent()}
                                </AccordionDetails>
                            </Accordion>

                            {/*login part ends here*/}
                                    {/*left side*/}
                                    {/*<div className="col-12 col-sm-5">*/}
                                    {/*    {!this.state.forgotPassword && !this.state.passwordBox && (*/}
                                    {/*        <h5 className="font-weight-bold">{this.state.formTitle}</h5>*/}
                                    {/*    )}*/}

                                    {/*    {(this.state.userType === "existing" ||*/}
                                    {/*        this.state.userType === "firstTime") &&*/}
                                    {/*    this.loginComponent()}*/}

                                    {/*    {this.state.userType === "new" && this.signUpComponent()}*/}
                                    {/*</div>*/}
                        </CardContent>

                        {this.state.showSnack && (
                            <Snackbar
                                open={this.state.snack}
                                autoHideDuration={3000}
                                onClose={this.closeSnackbar}
                                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                transitionDuration={1000}
                            >
                                <MuiAlert
                                    elevation={6}
                                    variant="filled"
                                    onClose={this.closeSnackbar}
                                    severity={this.state.snackType}
                                >
                                    {this.state.snackMsg}
                                </MuiAlert>
                            </Snackbar>
                        )}
                    </Card>
                    <Grid container item direction='column' alignItems='center' className='mt-3'>
                        <Grid item xs={6} className={'w-100'}>
                    <Typography component='div' style={{color:'#666'}} align='center' className='social-login w-100 mb-2 position-relative'>
                        <Typography component='div' className='w-100'>
                            <Typography component='span'>
                                OR
                            </Typography>

                        </Typography>

                    </Typography>
                        </Grid>
                        <Grid item xs={9} className={'w-100'}>
                    {/*right side*/}
                    <Box display='flex' selfAlign={'flex-end'} justifyContent={'space-evenly'} alignItems={'center'} flexDirection={'row'}>
                        <div>
                            <Button
                                type="button"
                                onClick={() => this.loginWithSocialMedia("facebook")}
                                variant={"contained"}
                                color={"primary"}
                                startIcon={<FacebookIcon/>}
                            >
                                <span className="mx-1 my-1">Facebook</span>
                            </Button>
                        </div>

                        <div>
                            <Button
                                type="button"
                                onClick={() => this.loginWithSocialMedia("google")}
                                variant={"contained"}
                                className={"googleButton"}
                                startIcon={<img loading="lazy" src={gPlus} height="30px" alt={"google"}/>}
                            >
                                <span className="mx-2">Google</span>
                            </Button>
                        </div>
                    </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
                </>
        );
    }
}

const mapStateToProps = (state) => state.auth;
const mapDispatchToProps = (dispatch, router) => {
    return {
        saveUser: (data) => dispatch(storeUser(data, router)),
        loginNow: (data) => dispatch(loginNow(data, router)),
        verifyPinCode: (data) => dispatch(verifyPinCode(data, router)),
        updatePassword: (data) => dispatch(updatePassword(data, router)),
        sendOtp: (data) => dispatch(sendOtp(data, router)),
        saveRoute: () => dispatch(saveRout(router)),
        saveData: () => dispatch(saveData(router)),
        loginWithoutPassword:(data)=>dispatch(loginWithoutPassword(data,router))

    };
};
export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(useStyles))(React.memo(Login));
