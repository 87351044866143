const initialState = {
  bestSellingProducts: [],
  loading: false,
  message: "",
};
const bestSellingProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BEST_SELLING_PRODUCTS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_BEST_SELLING_PRODUCTS_SUCCESS":
      return {
        ...state,
        bestSellingProducts: action.payload,
        loading: false,
      };
    case "GET_BEST_SELLING_PRODUCTS_FAILURE":
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    default:
      return state;
  }
};
export default bestSellingProductsReducer;
