import { getRequest, putRequest, postRequest } from "../services";

export const setLastAddress = (data, router) => (dispatch) => {
  dispatch({ type: "USER_LAST_ADDRESS", payload: data });

  router.history.push("/profile/address-book/edit");
};

export const saveLastAdress = (data, router) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  getRequest("address/get/" + data, null, true, {
    "access-token": token,
  })
    .then((response) => {
      console.log(response);
      dispatch({ type: "SAVE_LAST_ADDRESS", payload: response.data });
    })
    .catch((error) => console.error(error));
};

export const getAllAdress = (data, router) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  getRequest(
    "address/allActive/user/" + getState().auth?.user?._id,
    null,
    true,
    {
      "access-token": token,
    }
  )
    .then((response) => {
      console.log(response);
      dispatch({ type: "GET_ALL_ADDRESS", payload: response.data });
    })
    .catch((error) => console.error(error));
};

export const updateLastAdress = (data, router) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  putRequest("address/update/" + data._id, data, true, {
    "access-token": token,
  })
    .then((response) => {
      // dispatch({ type: "SAVE_LAST_ADDRESS", payload: response.data });
      router.history.push("/profile/address-book");
    })
    .catch((error) => console.error(error));
};

export const deleteAdress = (data, router) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  putRequest(
    "address/update/" + data,
    { isDeleted: true, isActive: false },
    true,
    {
      "access-token": token,
    }
  )
    .then((response) => {
      dispatch({ type: "DELETING_ADDRESS", payload: data });
      console.log(response);
      router.history.push("/profile/address-book");
    })
    .catch((error) => console.error(error));
};

export const addReminder = (data, router) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  data.user = getState().auth.user._id;
  postRequest("reminders/create/", data, true, {
    "access-token": token,
  })
    .then((response) => {
      dispatch({ type: "ADD_REMINDERS", payload: response.data });
    })
    .catch((error) => console.error(error));
};

export const allReminders = (data, router) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  getRequest(
    "reminders/allActive/user/" + getState().auth?.user?._id,
    null,
    true,
    {
      "access-token": token,
    }
  )
    .then((response) => {
      console.log(response);
      dispatch({ type: "FETCH_REMINDERS", payload: response.data });
    })
    .catch((error) => console.error(error));
};

export const deleteReminders = (data, router) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  putRequest(
    "reminders/update/" + data,
    { isDeleted: true, isActive: false },
    true,
    {
      "access-token": token,
    }
  )
    .then((response) => {
      dispatch({ type: "DELETE_REMINDERS", payload: data });
      console.log(response);
    })
    .catch((error) => console.error(error));
};

export const updateReminders = (data, router) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  putRequest("reminders/update/" + data._id, data, true, {
    "access-token": token,
  })
    .then((response) => {
      dispatch({ type: "UPDATE_REMINDERS", payload: data });
    })
    .catch((error) => console.error(error));
};

export const getProfileOrders = (data, router) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  var user = getState().auth?.user?._id;
  putRequest("orders/profileOrders/" + user, {}, true, {
    "access-token": token,
  })
    .then((response) => {
      dispatch({ type: "PROFILE_ORDERS", payload: response.data });
    })
    .catch((error) => console.error(error));
};
