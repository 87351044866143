import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import fb from "../../assets/images/Facebook.webp";
import pt from "../../assets/images/Pinterest.webp";
import ig from "../../assets/images/Instagram.webp";
import tw from "../../assets/images/Twitter.webp";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import axios from "axios";
import { Notify } from "../../constants/utils";
import Loader from "./Loader";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: false,
      emailErrorMsg: "",
      showLoading: false,
    };
  }

  inputHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validationCheck = () => {
    let isError = false;
    const errors = {};

    if (this.state.email === "") {
      isError = true;
      errors.emailErrorMsg = "Invalid email format";
      errors.emailError = true;
    }

    if (isError) {
      this.setState({
        ...errors,
      });
    }

    return isError;
  };

  submitForm = async (event) => {
    event.preventDefault();

    const valid = this.validationCheck();

    if (!valid) {
      // resetting
      this.setState({
        emailError: false,
        emailErrorMsg: "",
      });
      try {
        this.setState({ showLoading: true });
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}subscribe`,
          { email: this.state.email }
        );
        if (res) {
          Notify(
            "success",
            "You have been subscribed to our newsletter",
            "Success"
          );
          this.setState({ showLoading: false, email: "" });
        }
      } catch (err) {
        console.log(err);
        Notify("danger", err?.message, "Error");
        this.setState({ showLoading: false });
      }
    }
  };
  render() {
    const footerMenus = [
      { name: "About Us", url: "/AboutUs" },
      { name: "Contact Us", url: "/contact-us" },
      { name: "Terms & Conditions", url: "/termsOfUse" },
      { name: "Privacy Policy", url: "/privacy-policy" },
      { name: "FAQs", url: "/FAQ" },
      { name: "Blog", url: "/" },
      { name: "Care Guide", url: "/care-guide" },
      { name: "Flower Astrology", url: "/" },
      { name: "Articles Hub", url: "/" },
      { name: "Wishes n Messages", url: "/" },
      { name: "HTML sitemap", url: "/" },
    ];

    return (
      <footer style={{ backgroundColor: "#f2f2f2" }}>
        <Loader open={this.state.showLoading} />
        <Grid container direction="column" justify="space-around">

          <Grid item xs={12}>
            <Box display='flex' style={{ color: '#666666' }} flexDirection='column' justifyContent={'center'} alignItems={'center'} className="my-3">
              <VerifiedUserIcon />
              <Typography variant='body1' component='small'>100% Safe and secure payments.</Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            direction='row'
          >
            <Grid xs container item direction='column' alignItems='center' justify='center' className="my-5" style={{ color: '#666666' }}>
              <Typography>Follow us on:</Typography>
              <Grid container item direction='row' className='mt-3' justify='space-evenly'>
                <a
                  href="https://www.facebook.com/GABUAE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img loading="lazy" alt="Facebook" src={fb} height="55px" width="55px" />
                </a>

                <a
                  href="https://twitter.com/Giftabliss"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img loading="lazy" alt="Twitter" src={tw} height="55px" width="55px" />
                </a>

                <a
                  href="https://www.pinterest.com/giftabliss/_created/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img loading="lazy" alt="Pinterest" src={pt} height="55px" width="55px" className="mr-1" />
                </a>

                <a
                  href="https://www.instagram.com/giftablissuae/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img loading="lazy" alt="Instagram" src={ig} height="55px" width="55px" />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    );
  }
}

export default React.memo(Footer);
