import { postRequest, putRequest, getRequest } from "../services";
import axios from "axios";
import { redirect, Notify, slugifyText } from "../../constants/utils";
import moment from "moment";
const _ = require("underscore");
export const clearStore = () => (dispatch) => {
  dispatch({ type: "CLEAR_STORE" });
};
export const createOrder = (data, router) => async (dispatch, getState) => {
  var user = getState().auth?.user;
  let token = getState().auth?.token
  var location = router?.location;
  var headers = { "access-token": token };
  var previousRoute = location?.pathname + location?.hash;
  if (!token) {
    redirect(router, "/login?redirect=" + previousRoute);
    Notify("danger", "You Need To Login In Term To Place Order");
    return false;
  }
  return await postRequest("cart/create", data, false)
      .then((res) => {
        if (!res?.data?.isAddon) {
          dispatch({ type: "SAVE_TO_CART", payload: res.data });
          return true;
        }
      })
      .catch((err) => {
        console.log(err.message);
        localStorage.removeItem("token");
        localStorage.removeItem("currentUser");
        redirect(router, "/login?redirect=" + previousRoute);
      });
};
export const finalizeOrder = (data, router) => async (dispatch, getState) => {
  var user = getState().auth.user;
  var location = router?.location;
  var previousRoute = location?.pathname + location?.hash;
  let source = getState()?.cart.source;
  let medium = getState()?.cart.medium;
  let channel = getState()?.cart.channel;
  data.source=source;
  data.medium = medium;
  data.channel = channel;
  if (!user?._id) {
    redirect(router, "/login?redirect=" + previousRoute);
    Notify("danger", "You Need To Login In Term To Place Order");
    return false;
  }
  await postRequest("orders/create", data);
};
export const getActiveOrders = (curr) => async (dispatch, getState) => {
  var state = getState().auth;
  var token = state?.token;
  var user_id = state?.user?._id;
  var headers = { "access-token": token };
  var data = { customer: user_id, isActive: true };
  let selectedCurrency = getState().auth.currency;
  if (selectedCurrency) {
    data.selectedCurrency = selectedCurrency;
  } else {
    data.selectedCurrency = "AED";
  }

  if (user_id) {
    return await putRequest("cart/search", data, false, headers)
        .then((res) => {
          dispatch({ type: "ACTIVE_ORDERS", payload: res.data });
          return res?.data;
        })
        .catch((err) => console.error(err));
  }
};
export const calculate = (cart) => {
  //let amount = order?.amount;
  let totalProductAmount = 0;
  let totalShipping = 0;
  let carts = JSON.parse(JSON.stringify(cart));
  let arrayDates = [];
  if (carts) {
    for (let i = 0; i < carts.length; i++) {
      let filetered = arrayDates.filter(
          (date) =>
              date === moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
      );
      if (filetered.length > 0) {
      } else {
        arrayDates.push(
            moment(carts[i].shippingDate).utc(true).format("Y-MM-DD")
        );
      }
    }
  }
  if (arrayDates.length > 0) {
    for (let j = 0; j < arrayDates.length; j++) {
      let dateCarts = carts.filter(
          (cart) =>
              moment(cart.shippingDate).utc(true).format("Y-MM-DD") ===
              arrayDates[j]
      );
      // let addressArray = [];
      // for (let k = 0; k < dateCarts.length; k++) {
      //   let addressCarts;
      //   if (!dateCarts[k].deliveryAddress ) {
      //     addressCarts = addressArray.filter((address) => address === null);
      //   } else {
      //     addressCarts = addressArray.filter(
      //       (address) => address === dateCarts[k].deliveryAddress._id
      //     );
      //   }
      //   if (addressCarts.length > 0) {
      //   } else {
      //     if (!dateCarts[k].deliveryAddress ) {
      //       addressArray.push(null);
      //     } else {
      //       addressArray.push(dateCarts[k].deliveryAddress._id);
      //     }
      //   }
      // }

      // for (let m = 0; m < addressArray.length; m++) {
      let addressCarts;
      // if (addressArray[m] === null) {
      //   addressCarts = dateCarts.filter(
      //     (dateCart) => dateCart.deliveryAddress === addressArray[m]
      //   );
      // } else {
      //   addressCarts = dateCarts.filter(
      //     (dateCart) =>
      //       dateCart.deliveryAddress !== null &&
      //       dateCart.deliveryAddress._id === addressArray[m]
      //   );
      // }
      addressCarts = dateCarts;

      let timeSlotsArray = [];
      for (let n = 0; n < addressCarts.length; n++) {
        let timeCarts = timeSlotsArray.filter(
            (timeSlot) => timeSlot === addressCarts[n].shippingTime
        );
        if (timeCarts.length > 0) {
        } else {
          timeSlotsArray.push(addressCarts[n].shippingTime);
        }
      }
      for (let l = 0; l < timeSlotsArray.length; l++) {
        let timeCarts = addressCarts.filter(
            (addressCart) => addressCart.shippingTime === timeSlotsArray[l]
        );
        let amount = 0;
        let productIds = "";
        let shippingPrice = 0;
        let productDescription = "";
        for (let r = 0; r < timeCarts.length; r++) {
          if (shippingPrice < timeCarts[r].shippingPrice) {
            shippingPrice = timeCarts[r].shippingPrice;
          }
          amount = amount + timeCarts[r].amount * timeCarts[r].quantity;
          productIds = productIds + timeCarts[r].products[0]._id + ",";
          productDescription =
              productDescription + timeCarts[r].products[0].description;
          if (timeCarts[r].addons.length > 0) {
            for (let s = 0; s < timeCarts[r].addons.length; s++) {
              amount =
                  amount +
                  timeCarts[r].addons[s].amount * timeCarts[r].addons[s].quantity;
              productIds =
                  productIds + timeCarts[r].addons[s].products[0]._id + ",";
              productDescription =
                  productDescription +
                  timeCarts[r].addons[s].products[0].description;
            }
          }
        }
        totalProductAmount = totalProductAmount + amount;
        totalShipping = totalShipping + shippingPrice;
      }
      // }
    }
  }

  return { totalProductAmount, totalShipping };
};

export const deleteOrder = (
    id,
    router,
    indexOfCart,
    indexOfAddon = null
) => async (dispatch, getState) => {
  dispatch({ type: "DELETING_CART", payload: true });
  var state = getState().auth;
  var token = state?.token;
  let route = window.location.pathname;
  var user_id = state?.user?._id;
  var headers = { "access-token": token };
  var data = { isDeleted: true, isActive: false };
  if (user_id) {
    // dispatch({ type: "DELETE_ORDER", payload: id });
    await putRequest("cart/update/" + id, data, true, headers)
        .then(() => {
          dispatch(getActiveOrders());
        })
        .catch((error) => {
          // redirect(router, "/login?redirect=" + route);
        });
  } else {
    var orders = getState().cart?.orders;
    orders = JSON.parse(JSON.stringify(orders));
    if (indexOfAddon !== null) {
      let addons = orders.carts[indexOfCart].addons.splice(indexOfAddon, 1);

      //orders.carts[indexOfCart].addons = addons;

      let { totalProductAmount, totalShipping } = calculate(orders.carts);

      totalProductAmount = totalProductAmount.toFixed(2);
      totalShipping = totalShipping.toFixed(2);

      let grandTotal = (
          parseFloat(totalShipping) + parseFloat(totalProductAmount)
      ).toFixed(2);

      orders.totalProductAmount = totalProductAmount;
      orders.totalShipping = totalShipping;
      orders.grandTotal = grandTotal;

      dispatch({ type: "ACTIVE_ORDERS", payload: orders });
      dispatch({ type: "DELETING_CART", payload: false });
      return;
    } else {
      let carts = orders.carts.splice(indexOfCart, 1);
      let { totalProductAmount, totalShipping } = calculate(orders.carts);
      totalProductAmount = totalProductAmount.toFixed(2);
      totalShipping = totalShipping.toFixed(2);
      let grandTotal = (
          parseFloat(totalShipping) + parseFloat(totalProductAmount)
      ).toFixed(2);
      orders.totalProductAmount = totalProductAmount;
      orders.totalShipping = totalShipping;
      orders.grandTotal = grandTotal;
      if (orders.carts.length < 1) {
        dispatch({ type: "ACTIVE_ORDERS", payload: {} });
      } else {
        dispatch({ type: "ACTIVE_ORDERS", payload: orders });
      }

      dispatch({ type: "DELETING_CART", payload: false });
      return;
    }
  }
  dispatch({ type: "DELETING_CART", payload: false });
};
export const updateQuantity = (input) => async (dispatch, getState) => {
  dispatch({ type: "UPDATING_QUANTITY", payload: true });
  var state = getState().auth;
  var token = state?.token;
  var user_id = state?.user?._id;
  var headers = { "access-token": token };
  let { type, _id, quantity,cartIndex,key } = input;
  if (type === "increase") {
    quantity = quantity + 1;
  } else {
    quantity = quantity - 1;
  }
  var data = { quantity };
  if (user_id) {
    // dispatch({ type: "DELETE_ORDER", payload: id });
    await putRequest("cart/update/" + _id, data, true, headers).then(() => {
      dispatch(getActiveOrders());
    }).catch(()=>{
      dispatch({ type: "UPDATING_QUANTITY", payload: false });
    });
    dispatch({ type: "UPDATING_QUANTITY", payload: false });
  }
  else{

    var orders = getState().cart?.orders;
    orders = JSON.parse(JSON.stringify(orders));

    orders.carts[cartIndex].addons[key].quantity=data.quantity;

    //orders.carts[indexOfCart].addons = addons;

    let { totalProductAmount, totalShipping } = calculate(orders.carts);

    totalProductAmount = totalProductAmount.toFixed(2);
    totalShipping = totalShipping.toFixed(2);

    let grandTotal = (
        parseFloat(totalShipping) + parseFloat(totalProductAmount)
    ).toFixed(2);

    orders.totalProductAmount = totalProductAmount;
    orders.totalShipping = totalShipping;
    orders.grandTotal = grandTotal;

    dispatch({ type: "ACTIVE_ORDERS", payload: orders });
    dispatch({ type: "UPDATING_QUANTITY", payload: false });
    return;
  }
};
//
export const updateCart = (data,key) => (dispatch, getState) => {
  var state = getState().auth;
  var token = state?.token;
  var user_id = state?.user?._id;
  var headers = { "access-token": token };
  var id = data?._id;
  delete data?._id;
  if (user_id) {
    putRequest("cart/update/" + id, data, false, headers).then(() =>
        dispatch(getActiveOrders())
    );
  }
  else {
    var orders = getState().cart?.orders;
    orders = JSON.parse(JSON.stringify(orders));
    orders.carts[key].quantity=data.quantity;

    //orders.carts[indexOfCart].addons = addons;

    let { totalProductAmount, totalShipping } = calculate(orders.carts);

    totalProductAmount = totalProductAmount.toFixed(2);
    totalShipping = totalShipping.toFixed(2);

    let grandTotal = (
        parseFloat(totalShipping) + parseFloat(totalProductAmount)
    ).toFixed(2);

    orders.totalProductAmount = totalProductAmount;
    orders.totalShipping = totalShipping;
    orders.grandTotal = grandTotal;

    dispatch({ type: "ACTIVE_ORDERS", payload: orders });
    dispatch({ type: "DELETING_CART", payload: false });
    return;
  }
};
export const updateCartAddress = (data) => (dispatch, getState) => {
  var state = getState().auth;
  var token = state?.token;
  var user_id = state?.user?._id;
  var headers = { "access-token": token };
  var id = data?._id;
  delete data?._id;
  if (user_id) {
    putRequest("cart/update/" + id, data, false, headers).then(() => {});
  }
};
export const finalizeCheckout = (data, router) => (dispatch, getState) => {
  var state = getState().auth;
  var token = state?.token;
  var user_id = state?.user?._id;
  var headers = { "access-token": token };
  //  data = { ...data,customer: user_id };
  data.customer = user_id;
  let source = getState()?.cart.source;
  let medium = getState()?.cart.medium;
  let channel = getState()?.cart.channel;
  data.source=source;
  data.medium = medium;
  data.channel = channel;
  if (user_id) {
    return postRequest("orders/create", data, false, headers).then((res) => {
      //console.log('this is response from orders Create', res.data);
      dispatch(getActiveOrders());
      // redirect(router, "/");
      Notify("success", "Your order has been placed.");
      return res.data?.order?._id;
    });
  }
};
export const finalizeCheckout2 = (data, router) => (dispatch, getState) => {
  var state = getState().auth;
  var token = state?.token;
  var user_id = state?.user?._id;
  var headers = { "access-token": token };
  //  data = { ...data,customer: user_id };
  data.customer = user_id;
  let source = getState()?.cart.source;
  let medium = getState()?.cart.medium;
  let channel = getState()?.cart.channel;
  data.source=source;
  data.medium = medium;
  data.channel = channel;
  if (user_id) {
    return postRequest("orders/create", data, false, headers).then((res) => {
      //console.log('this is response from orders Create', res.data);
      return res.data;
      // dispatch(getActiveOrders());
      // console.log("this is router", router);
      // redirect(router, "/");
      // Notify("success", "Your order has been placed.");
    });
  }
};
export const createAddress = (data, router) => async (dispatch) => {
  return await postRequest("address/createAndAttach", data)
      .then((res) => {
        dispatch(searchAddress());
        return res?.data;
      })
      .catch(() => {
        redirect(router, "login?redirect=/checkout");
      });
};

export const searchAddress = (data) => async (dispatch, getState) => {
  var user = getState().auth?.user;
  var token = user?.token;
  user = user?._id;
  var headers = { "access-token": token };
  data = { ...data, user: user };
  return await putRequest("address/search", data, true, headers).then((res) => {
    const data = _.groupBy(res.data, function (val) {
      return slugifyText(val.recipientCity, "_");
    });
    dispatch({ type: "STORE_ADDRESSES", payload: data });
  });
};

export const applyCoupons = (data) => async (dispatch, getState) => {
  var user = getState().auth?.user;

  var token = user?.token;

  user = user?._id;
  var headers = { "access-token": token };

  let id = data.promoCode;

  data = { ...data, user: user };
  dispatch({ type: "COUPON_SUBMIT", payload: true });
  let res = await putRequest("promoCodes/validate/" + id, data, true, headers);

  //     .then((res) => {
  //   console.log('this is response',res.data);
  //   return res.data;
  //   //dispatch({ type: "COUPON_SUCCESSFUL", payload: res.data });
  // });
  return res.data;
};
export const enableButton = (data) => async (dispatch, getState) => {
  dispatch({ type: "COUPON_SUBMIT", payload: false });
};
export const deleteAddress = (data, router) => async (dispatch, getState) => {
  var user = getState().auth?.user;
  var token = user?.token;
  user = user?._id;
  var headers = { "access-token": token };
  putRequest(
      "address/update/" + data,
      {
        isActive: false,
        isDeleted: true,
      },
      true,
      headers
  )
      .then(() => {
        dispatch(searchAddress(data));
      })
      .catch(() => {
        redirect(router, "login?redirect=/checkout");
      });
};
export const updateAddress = (data, _id, router) => async (
    dispatch,
    getState
) => {
  var user = getState().auth?.user;
  var token = user?.token;
  user = user?._id;
  var headers = { "access-token": token };
  putRequest("address/update/" + _id, data, true, headers)
      .then((res) => {
        dispatch(searchAddress(_id));
      })
      .catch(() => {
        // dispatch(clearStore());
        // dispatch({ type: "LOGOUT", payload: {} });
        // localStorage.clear();
        redirect(router, "login?redirect=/checkout");
      });
};
// where:{ deleted: 0 }
// include: [{model: User,}, {model: Item,required: true, include: [{model: Image}] }, {model: Table2, include: [{model: Table3, where:{ deleted: 0 }include: [{model: Table4, where:{ deleted: 0 }}]}]}]}
//
export const payment = (data, router) => async (dispatch, getState) => {
  var user = getState().auth?.user;
  var token = user?.token;
  user = user?._id;
  let orderId = data.orderId;
  var headers = { "access-token": token };
  await postRequest("paymentGateways/makePayment", data, false, headers).then(
      (response) => {
        if (response.data.ds_url) {
          let url = response.data.ds_url;
          window.location.href = url;
        } else {
          Notify("danger", "Payment Unsuccessful");
          dispatch(sendEmail({ orderId: orderId }));
          dispatch(getActiveOrders());
          redirect(router, "/order-confirmation/" + orderId);
        }

        // dispatch(searchAddress(_id));
      }
  );
};

export const createAddon = (data, router) => async (dispatch, getState) => {
  var user = getState().auth.user;
  var location = router?.location;
  var previousRoute = location?.pathname + location?.hash;
  data.isActive = true;
  data.isDeleted = false;
  return await postRequest("cart/create", data).then((res) => {
    return res.data;
  });
};
export const updateAddon = (input) => async (dispatch, getState) => {
  dispatch({ type: "UPDATING_QUANTITY", payload: true });
  var state = getState().auth;
  var token = state?.token;
  var user_id = state?.user?._id;
  var headers = { "access-token": token };
  let { type, _id, quantity } = input;
  if (type === "increase") {
    quantity = quantity + 1;
  } else {
    quantity = quantity - 1;
  }
  var data = { quantity };
  if (user_id) {
    // dispatch({ type: "DELETE_ORDER", payload: id });
    return await putRequest("cart/update/" + _id, data, true, headers).then(
        (res) => {
          dispatch({ type: "UPDATING_QUANTITY", payload: false });
          return res.data;
        }
    );
  }
};

export const deleteAddon = (id) => async (dispatch, getState) => {
  dispatch({ type: "DELETING_CART", payload: true });
  var state = getState().auth;
  var token = state?.token;
  var user_id = state?.user?._id;
  var headers = { "access-token": token };
  var data = { isDeleted: true, isActive: false };
  if (user_id) {
    // dispatch({ type: "DELETE_ORDER", payload: id });
    await putRequest(
        "cart/update/" + id,
        data,
        true,
        headers
    ).then((res) => {});
  }
  dispatch({ type: "DELETING_CART", payload: false });
};
export const convertUsingApi = (data, router) => async (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  return await getRequest("paymentGateways/exchangeRate/", null, false, {
    "access-token": token,
  })
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.error(error));
};

export const updateOrder = (data, router) => async (dispatch, getState) => {
  var user = getState().auth?.user;
  var token = user?.token;
  var headers = { "access-token": token };

  await putRequest("orders/update/" + data.id, data, true, headers).then(
      (res) => {
        dispatch(getActiveOrders());
      }
  );
};
export const decreaseAmount = (data, router) => async (dispatch, getState) => {
  var user = getState().auth?.user;
  var token = user?.token;
  var headers = { "access-token": token };

  await putRequest("products/updateAmount/" + data.id, {}, true, headers).then(
      (res) => {
        // dispatch(getActiveOrders());
      }
  );
};

export const reRout = (router) => async (dispatch, getState) => {
  redirect(router, "/");
};

export const updateMessageInfo = (data, router) => async (
    dispatch,
    getState
) => {
  var user = getState().auth?.user;
  var token = user?.token;
  var headers = { "access-token": token };

  await putRequest(
      "cart/updateMessageInfo/" + data.id,
      data,
      true,
      headers
  ).then((res) => {
    dispatch(getActiveOrders());
  });
};

export const setPhone = (data, router) => async (dispatch, getState) => {
  var user = getState().auth?.user;
  var token = user?.token;
  data.id = user._id;

  var headers = { "access-token": token };
  await putRequest("users/setPhoneNo/", data, true, headers).then((res) => {
    dispatch(getActiveOrders());
  });
};

export const sendEmail = (data, router) => async (dispatch, getState) => {
  var user = getState().auth?.user;
  var token = user?.token;
  data.id = user._id;

  var headers = { "access-token": token };
  await putRequest("orders/sendEmail/", data, false, headers).then((res) => {});
};
export const createDuplicateOrder=(id,router)=>async(dispatch,getState)=>{
  let response = await putRequest('orders/createDuplicateOrder/'+id,{},false);
  return response.data;
}
export const getOtherProductData=(id,router)=>async(dispatch,getState)=>{
  let date1 = new Date();
  let response = await getRequest('products/otherInformation/'+id,{},false);
  let date2 = new Date();
  return response.data;
}
export const getOtherProductPrices=(id,router)=>async(dispatch,getState)=>{
  let response = await getRequest(`products/otherProductPricesBySlug?slug=${id}`,{},false);
  return response.data;
}
export const getCatInfo=(id,router)=>async(dispatch,getState)=>{
  let response = await getRequest('categories/getCategoryInformation/'+id,{},false);
  return response.data;
}