import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Box, Fab, Grid, withStyles, createStyles } from "@material-ui/core";
import axios from "axios";
import Loader from "../common/Loader";
import { Notify, slugifyText } from "../../constants/utils";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";
import { storeUser } from "../../store/actions/authActions";
import "./YouMayAlsoLike.scss";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { WhatsApp } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { getFindMeta } from "../../store/actions/metaAction";
const HomeAbout = lazy(() => import("./HomeAbout"));
const BestSellingProducts = lazy(() => import("./BestSellingProducts"));
const Category = lazy(() => import("./Category"));
const HomeShopByCategory = lazy(() => import("./HomeShopByCategory"));
const HomeBestSeller = lazy(() => import("./HomeBestSeller"));
const HomeShopByShipping = lazy(() => import("./HomeShopByShipping"));

const useStyles = createStyles({
  paper: {
    backgroundColor: "#F2F2F2",
  },
  accordionSummaryContent: {
    justifyContent: "center",
  },
  accordionDetails: {
    display: "inline-block",
    backgroundColor: "#F2F2F2",
  },
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      categories: [],
      personalized: {},
      edible: {},
    };
  }

  bannerImgStyle = { width: "100%", height: "80px" };

  serveStyle = {
    fontSize: "80px",
    fontWeight: "bolder",
    fontFamily: "cursive",
  };

  serveConStyle = { width: "100%", textAlign: "center" };

  // fetching categories
  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const query_token = query.get("tkn");
    this.props.metaApi("home");
    if (query_token) {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}users/get`,
          { headers: { "access-token": query_token } }
        );

        if (res) {
          const data = res.data.data[0];
          data.token = query_token;
          this.props.storeUser(data);
        }
      } catch (err) {
        Notify("danger", "Token not correct", "Error");
        console.log(err);
      }
    }

    try {
      this.setState({ showLoading: true });
      const categories = [];
      const subCategories = [];

      const get = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}general`
      );
      // separating
      get.data.data.categories.map((cat) => {
        if (
          !cat.parentCategory &&
          cat.catalog.name.toLowerCase() !== "corporate" &&
          slugifyText(cat.name) !== "best-selling" &&
          cat.name !== "Banners" &&
          cat.name !== "PERSONALISED" &&
          slugifyText(cat.name) !== "wedding-decor" &&
          slugifyText(cat.name) !== "send-personalised-gifts" &&
          slugifyText(cat.name) !== "edible-gifts" &&
          slugifyText(cat.name) !== "uae" &&
          slugifyText(cat.name) !== "gifts"
        ) {
          cat.subCategory = [];
          categories.push(cat);
        } else {
          subCategories.push(cat);
        }
        return {};
      });

      // merging
      categories.forEach((sub) => {
        // const categoryToModify = categories?.find(
        //     (cat) => cat._id === sub?.parentCategory?._id
        // );
        //
        // if (categoryToModify) {
        //     categoryToModify.subCategory.push(sub);
        // }
        let child = sub.childCategoryArray;
        child = child.filter((c) => c.sequence !== null);
        let sequenced = child.sort((a, b) => {
          let v1 = a.sequence;
          let v2 = b.sequence;
          return this.getTextSort(1, v1, v2);
        });

        sub.subCategory = sequenced;
      });
      this.setState({ categories: categories, showLoading: false });
    } catch (err) {
      console.error(err);
      this.setState({ showLoading: false });
    }
  }
  getTextSort = (direction, v1, v2) => {
    if (v1 !== undefined && v2 !== undefined) {
      if (typeof v1 === "string" && typeof v2 === "string") {
        v1 = v1.trim();
        v2 = v2.trim();
        v1 = v1.toLowerCase();
        v2 = v2.toLowerCase();
        v1 = v1.length === 0 ? " " : v1;
        v2 = v2.length === 0 ? " " : v2;
      }
      if (v1 > v2) return direction;
      if (v1 < v2) return -direction;
    }
    return 0;
  };

  render() {
    const { classes } = this.props;

    const params = {
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    };

    return (
      <Box classes={{ root: classes.paper }}>
        <Loader open={this.state.showLoading} />
        <a
          href="https://wa.me/971555231077"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Fab className="whatsApp">
            <WhatsApp fontSize="large" />
          </Fab>
        </a>
        {/*main div*/}
        <div style={{ marginTop: "0.9rem" }}>
          {/*1st row*/}
          <div className="">
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Box boxShadow={0}>
                  <Swiper {...params}>
                    <div>
                      <Link to="/gifts/birthday">
                        <img
                          alt={"Birthday"}
                          loading="lazy"
                          src={require("../../assets/Platinum/platinum-01.webp")}
                          height="auto"
                          className="w-100"
                        />
                      </Link>
                    </div>

                    <div>
                      <Link to="/combos">
                        <img
                          alt={"Best seller"}
                          loading="lazy"
                          src={require("../../assets/Platinum/platinum-02.webp")}
                          height="auto"
                          className="w-100"
                        />
                      </Link>
                    </div>
                  </Swiper>
                </Box>
              </Grid>
            </Grid>
          </div>
          {/*1st row ends here*/}

          {/*Valentine’s Day Gifts*/}
          <Suspense fallback={<p>Loading…</p>}>
            <HomeBestSeller />
          </Suspense>
          {/*Valentine’s Day Gifts ends here*/}

          {/*Shop by Category row*/}

          <Suspense fallback={<p>Loading…</p>}>
            <HomeShopByCategory />
          </Suspense>

          {/*Shop by Shipping start*/}
          <Suspense fallback={<p>Loading…</p>}>
            <HomeShopByShipping />
          </Suspense>
          {/*Shop by Shipping ends here*/}

          {/*Shop by Category row ends here*/}

          {/*  valentines-day row */}
          <Card square elevation={0}>
            <Grid item xs={12} className="mt-1">
              <Box boxShadow={0}>
                <div>
                  <Link to="new-arrival-gifts">
                    <img
                      alt="New Arrival Gifts"
                      loading="lazy"
                      src={require("../../assets/Platinum/middle.webp")}
                      height="auto"
                      className="w-100"
                    />
                  </Link>
                </div>
              </Box>
            </Grid>

            <CardContent>
              {this.state.categories.map((cat, index) => {
                if (cat._id === 1044) {
                  return (
                    <Suspense fallback={<p>Loading…</p>}>
                      <Category key={cat._id} category={cat} {...this.props} />
                    </Suspense>
                  );
                }
              })}
            </CardContent>
          </Card>
          <Suspense fallback={<p>Loading…</p>}>
            <BestSellingProducts
              mainCategoryUrl={"/flowers"}
              categoryId={"flowers"}
              categoryName={"Flower"}
              bestSellingCategory={true}
              data={
                this.props.bestSellProducts?.bestSellingProducts?.["flowers"] ||
                []
              }
            />
          </Suspense>
          <Suspense fallback={<p>Loading…</p>}>
            <HomeAbout classes={this.props.classes} />
          </Suspense>
        </div>
        {/*main div ends here*/}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  bestSellProducts: state?.bestSellProducts,
});

const mapDispatchToProps = (dispatch, router) => ({
  storeUser: (data) => dispatch(storeUser(data, router, false)),
  metaApi: (data) => dispatch(getFindMeta(data)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles)
)(React.memo(Home));
