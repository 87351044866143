import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    TextField, Grid, Button, Card, CardContent, Checkbox, FormControlLabel,
    FormControl, FormGroup, withStyles, InputLabel, Select, MenuItem, Snackbar
} from '@material-ui/core';
import { saveLastAdress, updateLastAdress } from '../../store/actions/profileAction'

class UpdateAddress extends Component {
    state = {
        _id: 0,
        address: '',
        email: '',
        name: '',
        countryCode: '',
        recipientCity: '',
        phone: ''
    };

    componentDidMount() {
        this.props.saveLastAdress(this.props.profile.lastAddressId);

        const addressData = this.props.profile.addressData;

        if (addressData) {
            this.setState({
                _id: addressData._id,
                address: addressData.recipientAddress,
                email: addressData.email,
                name: addressData.name,
                countryCode: addressData.countryCode,
                phone: addressData.phone,
                recipientCity: addressData.recipientCity
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profile.lastAddressId !== this.state._id) {
            this.props.saveLastAdress(this.props.profile.lastAddressId);

            const addressData = this.props.profile.addressData;

            if (addressData) {
                this.setState({
                    _id: addressData._id,
                    address: addressData.recipientAddress,
                    email: addressData.email,
                    name: addressData.name,
                    countryCode: addressData.countryCode,
                    phone: addressData.phone,
                    recipientCity: addressData.recipientCity
                })
            }
        }
    }

    addressUpateHandler = () => {
        this.props.updateLastAdress({
            _id: this.state._id,
            recipientAddress: this.state.address,
            email: this.state.email,
            name: this.state.name,
            countryCode: this.state.countryCode,
            phone: this.state.phone
        })
    }

    render() {
        return (
            <div className="p-2">
                <div className="mt-3">
                    <div>
                        <TextField
                            type="text"
                            label="Name"
                            placeholder="Name"
                            name='name'
                            value={this.state.name}
                            onChange={e => this.setState({ name: e.target.value })}
                        />
                    </div>

                    <div className="mt-3">
                        <TextField
                            type="text"
                            label="Address"
                            placeholder="Address"
                            name='address'
                            value={this.state.address}
                            onChange={e => this.setState({ address: e.target.value })}
                        />
                    </div>

                    <div className="mt-3">
                        <TextField
                            type="text"
                            label="City"
                            placeholder="Recipient City"
                            name='city'
                            value={this.state.recipientCity}
                            onChange={e => this.setState({ recipientCity: e.target.value })}
                        />
                    </div>

                    <div className="mt-4">
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={4}>
                                <FormControl>
                                    <InputLabel>Country code</InputLabel>
                                    <Select
                                        onChange={(e) => this.setState({ countryCode: e.target.value })}
                                        value={this.state.countryCode}
                                    >
                                        <MenuItem value={91}>+91</MenuItem>
                                        <MenuItem value={92}>+92</MenuItem>
                                        <MenuItem value={93}>+93</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={8}>
                                <div>
                                    <TextField
                                        placeholder="Enter your mobile here"
                                        variant="standard"
                                        label="Mobile"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        name="phone"
                                        value={this.state.phone}
                                        onChange={e => this.setState({ phone: e.target.value })}
                                    // error={this.state.phoneError}
                                    // helperText={this.state.phoneErrorMsg}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="mt-3">
                        <TextField
                            type="email"
                            label="Recipient's email"
                            placeholder="Recipient's email"
                            name='email'
                            value={this.state.email}
                            onChange={e => this.setState({ username: e.target.email })}
                        />
                    </div>

                    <div className="mt-4 text-center">
                        <Button type='submit' variant="contained" className="bg_secondary text-white"
                            onClick={this.addressUpateHandler}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
};

const mapDispatchToProps = (dispatch, router) => {
    return {
        saveLastAdress: (data) => dispatch(saveLastAdress(data, router)),
        updateLastAdress: (data) => dispatch(updateLastAdress(data, router))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UpdateAddress)
);
