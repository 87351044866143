import React, {Component} from 'react';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';

class ComponentNotFound extends Component {

    styles = {
        fontSize: '100px'
    };

    render() {
        return (
            <div className="text-center pt-5">
                <h1 style={this.styles}> 404!!! </h1>
                <h4>Page not found </h4>

                <Button variant="contained" color='secondary' className="mt-3">
                    <Link to="/dashboard" className="text-white"> Go to home </Link>
                </Button>
            </div>
        );
    }

}

export default ComponentNotFound;
