import React, { Component } from "react";
import {Fab, Grid} from "@material-ui/core";
import ContactDetails from "./ContactDetails/ContactDetails";
import OrderDetails from "./OrderDetails/OrderDetails";
import PaymentDetails from "./PaymentDetails/PaymentDetails";
import CheckoutTotal from "./OrderDetails/CheckoutTotal";
import { connect } from "react-redux";
import {validate} from "../../store/actions/authActions";
import { getActiveOrders } from "../../store/actions/cartActions";
import {WhatsApp} from "@material-ui/icons";

class Checkout extends Component {
  componentDidMount() {
    this.props.getOrders();

    if (this.props.location.search !== "") {
      const urlParams = new URLSearchParams(this.props.location.search);
      const myParam = urlParams.get("order");
      if (myParam === "true") {
        this.setState({ modalFlag: true });
      }
    }
  }

  render() {
    const { activePanel } = this.state;
    return (
      <div className="my-4 p-3">
        <Grid container direction="column" justify="center">
          <a
              href="https://wa.me/971555231077"
              target="_blank"
              rel="noopener noreferrer"
          >
            <Fab className="whatsApp">
              <WhatsApp fontSize="large"/>
            </Fab>
          </a>
          {/*left side*/}
          <Grid item xs>
            {/*<div>*/}
            {/*  <ContactDetails />*/}
            {/*</div>*/}

            <div className="mt-4">
              <OrderDetails
                onCheckout={this.activatePanel}
                expOpen={activePanel === "orders"}
                setDetails={this.setDetails}
                setSenderInfo={this.setSenderInfo}
                updatePricing={this.updatePricing}
                updatePrice={this.updatePrice}
                grandTotal={this.state.grandTotal}
                totalProductPrice={this.state.totalProductPrice}
                modalFlag={this.state.modalFlag}
              >
                <Grid item className="mt-4">
                  <CheckoutTotal
                      priceUpdate={this.state.priceUpdate}
                      grandTotal={this.state.grandTotal}
                      totalProductPrice={this.state.totalProductPrice}
                      updatePricing={this.updatePricing}
                  />
                </Grid>
              </OrderDetails>
            </div>

            <div className="mt-4">
              <PaymentDetails
                expOpen={activePanel === "payment"}
                customerData={{
                  senderPhone: this.state.senderPhone,
                  senderEmail: this.state.senderEmail,
                  senderName: this.state.senderName,
                  cartIds: this.state.cartIds,
                  amount: this.state.grandTotal,
                }}
              />

            </div>
          </Grid>
          {/*left side ends here*/}

          {/*right side*/}

          {/*right side ends here*/}
        </Grid>
      </div>
    );
  }
  constructor(props) {
    super(props);
    this.state = {
      modalFlag: false,
      activePanel: "orders",
      grandTotal: "",
      shippingPrice: "",
      cartIds: [],
      totalProductPrice: "",
      senderName: "",
      senderEmail: "",
      senderPhone: "",
      priceUpdate: false,
    };
    this.updatePricing = this.updatePricing.bind(this);
    this.setDetails = this.setDetails.bind(this);
    this.setSenderInfo = this.setSenderInfo.bind(this);
    this.updatePrice = this.updatePrice.bind(this);
  }
  setSenderInfo(info) {
    if (info.senderName) {
      this.setState({ senderName: info.senderName });
    }
    if (info.senderEmail) {
      this.setState({ senderEmail: info.senderEmail });
    }
    if (info.senderPhone) {
    }
    this.setState({ senderPhone: info.senderPhone });
  }
  setDetails(cartIds) {
    if (cartIds) {
      this.setState({ cartIds });
    }
  }
  updatePricing(grandTotal, totalProductPrice, shippingPrice) {
    if (typeof grandTotal === "number") {
      this.setState({ grandTotal: parseFloat(grandTotal?.toFixed(2)) });
    }
    if (shippingPrice) {
      this.setState({ shippingPrice: parseFloat(shippingPrice?.toFixed(2)) });
    }
    if (totalProductPrice) {

      this.setState({
        totalProductPrice: parseFloat(parseFloat(totalProductPrice)?.toFixed(2)),
      });
    }
  }
  updatePrice(flag) {
    this.setState({ priceUpdate: flag });
  }
  activatePanel = (to) => {
    this.setState({ activePanel: to });
  };
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    getOrders: () => dispatch(getActiveOrders()),
    validate: () => dispatch(validate(router)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
