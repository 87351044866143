import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
    Icon,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Grid,
    Typography,
    Card,
    CardContent,
    Box
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {logout} from '../../store/actions/authActions';
import {Notify} from '../../constants/utils';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const customLinkStyle = {
    color: '#69B5D7',
};

const customTypographyStyle = {
    color: '#74746B',
    fontWeight: 'bold',
};

class AuthSideBar extends Component {
    componentDidMount() {
        const {token} = this.props;
        if (!token) {
            this.props.history.push('/login');
            return false;
        }
    }

    render() {
        const {logoutNow} = this.props;

        return (
            <Grid container justify={'center'} alignItems='center' direction={"row"}>
                <Grid container item className=" m-2" xs={12} md={8} spacing={3} direction={'column'}
                      justify={"center"}>
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography className='p-2' style={customTypographyStyle}>My Orders </Typography>
                                <Divider/>
                                <Link to={'/profile/orders'}>
                                    <Typography className={'p-2'} style={customLinkStyle}> View All Orders</Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography className={'p-2'} style={customTypographyStyle}>My Address
                                    Book </Typography>
                                <Divider/>
                                <Link to={'address-book'}>
                                    <Typography className={'p-2'} style={customLinkStyle}> All My Address
                                        Book</Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography className={'p-2'} style={customTypographyStyle}>My Reminders </Typography>
                                <Divider/>
                                <Link to={'/profile/reminders'}>
                                    <Typography className={'p-2'} style={customLinkStyle}> My All Reminders</Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography className={'p-2'} style={customTypographyStyle}>Redemptions </Typography>
                                <Divider/>
                                <Link to={'/profile/redemptions'}>
                                    <Typography className={'p-2'} style={customLinkStyle}> All Redemptions</Typography>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Item
                            route="change-password"
                            label="Change Password"
                        />
                    </Grid>
                    <Grid item>

                        <Card elevation={0} onClick={() => logoutNow()}>
                            <CardContent className={'p-3'}>
                                <Box display={'flex'} justifyContent={'space-between'} style={{cursor:'pointer'}}>
                                    <Typography style={customTypographyStyle}>Sign Out</Typography>
                                    <ChevronRightIcon style={{color: '#69B5D7'}}/>

                                </Box>
                            </CardContent>

                        </Card>
                    </Grid>

                </Grid>
            </Grid>
        );
    }
}

const Item = ({label, route}) => {
    return (
        <Link to={route}>
            <Card elevation={0} className={'w-100'}>
                <CardContent className={'p-3'}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography style={customTypographyStyle}>
                            {label}
                        </Typography>
                        <ChevronRightIcon style={{color: '#69B5D7'}}/>
                    </Box>
                </CardContent>
            </Card>

        </Link>
    );
};
const mapStateToProps = (state) => ({token: state.auth.token});

const mapDispatchToProps = (dispatch, router) => {
    return {
        logoutNow: () => {
            dispatch(logout(router));
            Notify('success', 'You are logged out', 'Success');
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AuthSideBar)
);
