import React, { Component } from "react";
import { AppBar, Grid, Icon, Toolbar } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import "./CorporateNav.scss";
import { slugifyText } from "../../../constants/utils";

class CorporateNav extends Component {
  render() {
    const { categories } = this.props;

    return (
      <AppBar position="sticky" className="corporateNav">
        <Toolbar>
          <Grid container direction="row" spacing={4}>
            {categories.map((cat) => {
              return (
                <Grid item>
                  <Grid container direction="row" className="hoveringMenuDiv">
                    <p> {cat.name} </p>
                    <Icon>keyboard_arrow_down</Icon>

                    {/*drop down menu*/}
                    <div className="w-25 navOptions shadow hoveringMenu">
                      <Grid container direction="row">
                        <Grid item>
                          <ul className="nav flex-column">
                            {cat.subCategories.map((sub) => {
                              return (
                                <li className="nav-item">
                                  <NavLink
                                    className="nav-link active"
                                    // to={{
                                    //   pathname: `/${slugifyText(
                                    //     sub.catalog.name
                                    //   )}/${slugifyText(sub.name)}/${sub._id.toString()}`,
                                    // }}
                                    to={{
                                      pathname: `/${slugifyText(sub.name)}/${sub.slug}`,
                                    }}
                                  >
                                    {sub.name}
                                  </NavLink>
                                </li>
                              );
                            })}
                          </ul>
                        </Grid>
                      </Grid>
                    </div>
                    {/*drop down menu ends here*/}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

export default React.memo(CorporateNav);
