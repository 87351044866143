import React, { useState, useEffect } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Grid,
} from "@material-ui/core";
import {
    slugifyText,
    // titleCase 
} from "../../../constants/utils";
import CategoryProduct from "./CategoryProduct";
function InfiniteScrollComponent({ prods, products, fetchMoreData, totalCount, catName, catalog, category, currencyRates, selectedCurrency, isMount }) {
    const [loader, setLoader] = useState(true)
    useEffect(() => {
        if (isMount === true) {
            setLoader(false)
        }
    }, [isMount])
    return (
        <div className="my-4" style={{ zIndex: "-1" }}>
            {loader &&
                <h4 style={{
                    textAlign: "center", maxWidth: 200, margin: "0 auto", right: 0, left: 0, zIndex: "2",
                    position: "absolute"
                }}>
                    <CircularProgress />
                </h4>
            }
            {isMount && <InfiniteScroll
                style={{ overflow: 'inherit' }}
                dataLength={products?.products?.length || 20}
                next={fetchMoreData}
                hasMore={totalCount === products?.products?.length ? false : true}
                loader={<h4 style={{
                    textAlign: "center", maxWidth: 200, margin: "0 auto", right: 0, left: 0, zIndex: "2",
                    position: "absolute"
                }}>
                    <CircularProgress />
                </h4>}
            >
                <Grid container direction="row">
                    {/*products of the category    */}
                    {prods?.products?.map((prod, key) => {
                        return (
                            <Grid
                                item
                                xs={6}
                                key={key}
                                className="card-padding"
                                alignItems={"stretch"}
                                style={{ padding: 4 }}
                            // spacing={10}
                            // md={3}
                            >
                                <CategoryProduct
                                    image={
                                        prod.images?.[0] !== "1" ? prod.images?.[0] : prod.images
                                    }
                                    title={prod?.title}
                                    price={prod.Price?.price}
                                    category={
                                        catName ||
                                        slugifyText(prod?.CategoriesArray?.[0]?.name)
                                    }
                                    catalog={catalog || "uae"}
                                    catId={
                                        category?.slug ||
                                        prod?.CategoriesArray?.[0]?.slug
                                    }
                                    currencyRates={currencyRates}
                                    selectedCurrency={selectedCurrency}
                                    product={prod}
                                    slug={prod.slug}
                                    delivery="today"
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </InfiniteScroll>}
            <br />
        </div>

    )
}

export default React.memo(InfiniteScrollComponent)
