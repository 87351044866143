import React, { Component } from "react";
import { connect } from "react-redux";
import { getProfileOrders } from "../../store/actions/profileAction";
import moment from "moment";
import './Order.scss';
export class Orders extends Component {
  state = {
    array: [],
  };
  componentDidMount() {
    this.props.getProfileOrders();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.profileOrders !== this.props.profileOrders) {
      let array = [];
      let subOrderIds = [];
      for (let i = 0; i < this.props.profileOrders.length; i++) {
        let cart = this.props.profileOrders[i];
        subOrderIds.push(cart?.subOrderId);
      }
      let unique = subOrderIds.filter((item, i, ar) => ar.indexOf(item) === i);

      for (let j = 0; j < unique.length; j++) {
        let cart = this.props.profileOrders.filter(
          (a) => a.subOrderId === unique[j]
        );
        let amount = 0;
        let shipping = 0;

        for (let k = 0; k < cart.length; k++) {
          amount =
            amount +
            parseFloat(
              (
                (cart[k].amount / this.props.currencyRates["AED"]) *
                this.props.currencyRates[this.props.currency]
              ).toFixed(2)
            ) *
              cart[k].quantity;
          let sh = parseFloat(
            (
              (cart[k].shippingOrignal / this.props.currencyRates["AED"]) *
              this.props.currencyRates[this.props.currency]
            ).toFixed(2)
          );
          if (shipping < sh) {
            shipping = sh;
          }
          if (cart[k].addons.length > 0) {
            for (let r = 0; r < cart[k].addons.length; r++) {
              let addons = cart[k].addons;
              amount =
                amount +
                parseFloat(
                  (
                    (addons.amount / this.props.currencyRates["AED"]) *
                    this.props.currencyRates[this.props.currency]
                  ).toFixed(2)
                ) *
                  addons.quantity;
            }
          }
        }
        let obj = {};
        obj.subOrderId = cart[0]?.subOrderId;
        obj.name = cart[0]?.address?.name;
        obj.amount = (amount + shipping).toFixed(2);
        obj.orderDate = cart[0].order.createdDate;
        obj.deliveryDate = cart[0].shippingDate;
        // obj.deliveryStatus = cart[0].deliveryStatus;
        if (cart[0].order.paymentMethod === "cod") {
          obj.payments = "Varified";
          if (cart[0].deliveryStatus === null) {
            if (cart[0].partyId === null) {
              obj.deliveryStatus = "Allocated";
            }
            if (cart[0].partyId) {
              obj.deliveryStatus = "Accepted";
            }
            if (cart[0].deliveryBoyId) {
              obj.deliveryStatus = "Accepted";
            }
          } else {
            obj.deliveryStatus = cart[0].deliveryStatus;
          }
        } else {
          if (cart[0].order.paymentStatus) {
            obj.payments = "Varified";
            if (cart[0].deliveryStatus === null) {
              if (cart[0].partyId === null) {
                obj.deliveryStatus = "Allocated";
              }
              if (cart[0].partyId) {
                obj.deliveryStatus = "Accepted";
              }
              if (cart[0].deliveryBoyId) {
                obj.deliveryStatus = "Accepted";
              }
            } else {
              obj.deliveryStatus = cart[0].deliveryStatus;
            }
          } else {
            obj.payments = "Not Verified";
            obj.deliveryStatus='Waiting'
          }
        }

        array.push(obj);
      }
      this.setState({ array });
    }
  }
  render() {
    const { profileOrders } = this.props;
    return (
      <div className={'p-2'}>
        <h4> My Orders </h4>
        <div className="mt-4">
          <table className="table table-striped table-responsive-lg">
            <thead>
              <tr>
                <th>Order No.</th>
                <th>Recipient</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th>Delivery Date</th>
                <th>Status</th>
                <th>Payments</th>
              </tr>
            </thead>
            <tbody>
              {this.state.array?.map((obj, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <a
                        className="order__link"
                          href={
                            "/profile/customerorder-view?orderId=" +
                            obj.subOrderId
                          }
                      >
                        {obj.subOrderId}
                      </a>
                    </td>
                    <td>{obj.name}</td>
                    <td>{obj.amount}</td>
                    <td>{moment(obj.orderDate).utc(true).format("Y-MM-DD")}</td>
                    <td>
                      {moment(obj.deliveryDate).utc(true).format("Y-MM-DD")}
                    </td>
                    <td>{obj?.deliveryStatus}</td>
                    <td>{obj.payments}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileOrders: state.profile?.profileOrders,
    currencyRates: state?.others?.currencyRates,
    currency: state?.auth?.currency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileOrders: () => dispatch(getProfileOrders()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
