const initialState = {
  productDetails: {},
};

const orderOptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_DETAIL":
      return { ...state, productDetails: action.payload };
    // case "DELETING_ADDRESS":
    //   console.log(state);
    //   return { ...state, addresses: state.addresses.filter(id => id !== action.payload) };
    default:
      return state;
  }
};
export default orderOptionReducer;
