import { combineReducers } from "redux";
import authReducer from "./authReducer";
import cartReducer from "./cartReducer";
import othersReducer from "./othersReducer";
import profileReducer from "./profileReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import orderOptionReducer from "./orderOptionsReducer";
import { DrawerReducer } from "./drawerReducer";
import navigationReducer from "./navigationReducer";
import metaReducer from "./metaReducer";
import bestSellingReducer from "./bestSellingReducer";

const persistConfig = {
  key: "auth",
  storage,
};
const cartConfig = {
  key: "cart",
  storage,
};
const othersConfig = {
  key: "others",
  storage,
};
const profileConfig = {
  key: "profile",
  storage,
};

const toggleDrawerConfig = {
  key: "drawer",
  storage,
};

const navigationReducerConfig = {
  key: "navigations",
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  cart: persistReducer(cartConfig, cartReducer),
  others: persistReducer(othersConfig, othersReducer),
  profile: persistReducer(profileConfig, profileReducer),
  drawer: persistReducer(toggleDrawerConfig, DrawerReducer),
  navigations: persistReducer(navigationReducerConfig, navigationReducer),
  orderOption: orderOptionReducer,
  meta: metaReducer,
  bestSellProducts: bestSellingReducer,
});

export default rootReducer;
