import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFindMeta } from "../store/actions/metaAction";

const AboutUs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFindMeta("about"));
  }, [dispatch]);

  return (
    <div className="homeMargin">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            width: "100px",
            height: "1px",
          }}
        ></div>
        <h2 style={{ color: "#d6286d" }}>About</h2>
        <h2 style={{ marginLeft: "5px" }}>us</h2>
        <div
          style={{
            backgroundColor: "black",
            width: "100px",
            height: "1px",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "100px",
          flexDirection: "column",
          marginRight: "100px",
        }}
      >
        <div style={{ flexDirection: "row", display: "flex" }}>
          <h5 style={{ color: "#E63AD6" }}>Gift a Bliss </h5>
          <h5 style={{ color: "#7030A0" }}>
            {" "}
            - Your Happiness Destination of All The Gifting Needs!
          </h5>
        </div>
        <p style={{ textAlign: "justify" }}>
          Having vital experience in the concept of social expression through
          flowers, cakes and gifts in UAE and India, Our Prime focus to make
          Gift a Bliss a name which is synonymous with love, emotions and
          celebrations. We started our journey as an executive in the field of
          delivering emotions and smiles in India and end up in the managerial
          level in UAE.
        </p>
        <p style={{ textAlign: "justify" }}>
          GIFTABLISS has a focus to pioneered the concept of branded chain of
          flowers, cakes and gifts and want to contribute in spreading of
          digitally influenced modern culture, increasing urbanization and
          improving standards of living. With the boom in digital shopping and
          consumer behaviour to go online for all there needs and changing
          lifestyle of the people in all the areas around the world. GIFTABLISS
          is constantly aiming to become a brand which exist in major cities and
          countries to small towns around the world.
        </p>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <h5 style={{ color: "#E63AD6" }}>
            A Strong and Focused Step In UAE{" "}
          </h5>
          <h5 style={{ color: "#7030A0" }}>
            {" "}
            – A Gift Delivery Portal For the People, To the People and By the
            People.
          </h5>
        </div>
        <p style={{ textAlign: "justify" }}>
          Leaving our wellness position we are here to serve the customers and
          make their every occasion beautiful and memorable. As per our
          experience putting a smile on the face of people and gifting them look
          forward to the day is an art which requires lots of passion and
          efforts. At GIFTABLISS, not only are we focussed but also committed in
          making your special moments worth cherishing and remembering for a
          lifetime. We are determined to serve the best in services and offer
          the best variety of gifts. Our business goal is not just practise to
          deliver fresh-from-the-field flowers, cakes and gifts but also
          delivering smiles and happiness. Our vigorous delivery network in
          Dubai and all across UAE makes it simple and easy for us to manage the
          orders. We as a TEAM believe in maintaining our customer satisfaction
          graph to the highest levels and we are dedicated in providing
          exceptional customer service to you. Let GIFTABLISS be your happiness
          destination of all your gifting needs for all your occasions and let
          us help you celebrate every reason with a smile on your face! Happy
          Gifting!
        </p>
        <p style={{ textAlign: "justify" }}>
          We believe UAE is our 2nd home and we are proud member of this amazing
          country. Our goal is to reach out to all the people around the world
          who wants to share their feelings and gift to their loved ones. Our
          company is based in Dubai and our people’s website{" "}
          <a href="/">www.giftabliss.com</a> is owned and managed by GIFTABLISS
          GIFTS TRADING LLC.
        </p>
      </div>
    </div>
  );
};
export default React.memo(AboutUs);
