import React, { Component } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Icon,
  Button,
  IconButton,
  TextField,
  FormControlLabel,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import styles from "./OrderDetails.module.css";
import moment from "moment";
import { getDateParts, Notify } from "../../../constants/utils";
import { withRouter } from "react-router-dom";
import CartItem from "./CartItem/CartItem";
import { connect } from "react-redux";
import { getRequest } from "../../../store/services/index";
import {
  deleteOrder,
  createAddress,
  searchAddress,
  updateCart,
  reRout,
  setPhone,
} from "../../../store/actions/cartActions";
import { storeUser } from "../../../store/actions/authActions";
import Loader from "../../common/Loader";
import { Modal } from "@material-ui/core";

const _ = require("underscore");
class OrderDetails extends Component {
  handleOpen = () => {
    this.setState({ modalOpen: true });
  };
  handleClose = () => {
    let mobile = this.state.mobile;
    let countryCode = this.state.countryCode;
    if (mobile.length > 10) {
      Notify(
        "danger",
        "Mobile number cannot contain more than 10 characters.",
        "Error"
      );
      return;
    }
    if (mobile.length === 0) {
      Notify("danger", "Mobile number is required.", "Error");
      return;
    }
    if (countryCode === "") {
      Notify("danger", "Please add country code.", "Error");
      return;
    }
    if (countryCode[0] !== "+") {
      Notify(
        "danger",
        "Please write country code in correct format. e.g +92",
        "Error"
      );
      return;
    }
    this.setState({ modalOpen: false });

    this.props.setPhone({ mobile, countryCode });;
  };
  render() {
    const { order, currency, expOpen, loading, customer, children } = this.props;
    return (
      <ExpansionPanel elevation={2} expanded={expOpen}>
        <ExpansionPanelSummary onClick={() => this.props.onCheckout("orders")}>
          <Grid container justify="space-between">
            <div
              className={`bg_secondary badgeE text-white ${styles.numbering}`}
            >
              <span className="mx-3">2</span>
            </div>

            <Grid item xs>
              <h5>ORDER & DELIVERY DETAILS</h5>
            </Grid>

          </Grid>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Loader open={loading} />
          {/* <form> */}
          {order?.map((prods, index) => {
            var product = prods?.products?.[0];
            var dateP = getDateParts(moment(prods.shippingDate).utc(true));
            return (
              <CartItem
                key={"cart_" + prods._id}
                prods={prods}
                dateP={dateP}
                onDelete={this.onCartDelete}
                grandTotal={this.props.grandTotal}
                totalProductPrice={this.props.totalProductPrice}
                updatePricing={this.props.updatePricing}
                updatePrice={this.props.updatePrice}
                product={product}
                addons={prods?.addons}
                index={index}
                addressInputHandler={this.addressInputHandler}
                currency={currency}
                saveAddress={this.saveAddress}
                selectAddress={this.selectAddress}
                currencyRates={this.state.currencyRates}
              />
            );
          })}

          {/*sender details*/}

          <Modal
            open={this.state.modalOpen}
            onClose={() => console.log("somethign")}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              style={{
                height: "300px",
                width: "400px",
                backgroundColor: "white",
                position: "absolute",
                top: "200px",
                border: "2px solid #7abbe1",
                left: "600px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p style={{ color: "red", fontWeight: "bold" }}>
                Please enter your phone no.
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <TextField
                  type="text"
                  size="small"
                  label="+93"
                  inputProps={{ maxLength: 5 }}
                  value={this.state.countryCode}
                  onChange={(e) =>
                    this.setState({ countryCode: e.target.value })
                  }
                  style={{ display: "flex", flex: 1 }}
                  name="Country Code"
                  variant="outlined"
                />
                <TextField
                  type="text"
                  size="small"
                  label="XXXXXXXXXX"
                  inputProps={{ maxLength: 10 }}
                  name="phone no"
                  value={this.state.mobile}
                  onChange={(e) => this.setState({ mobile: e.target.value })}
                  style={{ display: "flex", flex: 4 }}
                  variant="outlined"
                />
              </div>

              <Button
                variant="contained"
                type="button"
                style={{ marginTop: "50px" }}
                className="bg_secondary text-white"
                onClick={() => this.handleClose()}
              >
                <span className="mx-3 my-2">Save</span>
              </Button>
            </div>
          </Modal>
          {customer ? (
            <div className="mt-5">
              <div>
                <Grid container justify={"space-between"} direction="row">
                  <Grid item>
                  <p>Sender's Details</p>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Edit Details" placement="top-start">
                      <IconButton
                          size="small"
                          onClick={(e) => this.setState({ isDisabled: !this.state.isDisabled })}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                {/*fields*/}
                <Grid container direction="column" spacing={3}>
                  <Grid item >
                    <TextField
                      disabled={this.state.isDisabled}
                      type="text"
                      size="small"
                      label="Name"
                      name="senderName"
                      onChange={(e) => this.changeCustomerData(e)}
                      variant="outlined"
                      value={this.state.senderName}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      disabled={this.state.isDisabled}
                      type="email"
                      size="small"
                      label="Email"
                      onChange={(e) => this.changeCustomerData(e)}
                      name="senderEmail"
                      variant="outlined"
                      value={this.state.senderEmail}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      disabled={this.state.isDisabled}
                      type="text"
                      size="small"
                      onChange={(e) => this.changeCustomerData(e)}
                      label="Mobile number"
                      variant="outlined"
                      name="senderPhone"
                      value={this.state.senderPhone}
                    />
                  </Grid>

                  {children}

                </Grid>
                {/*fields end here*/}
              </div>
            </div>
          ) : null}
          {/*sender details end here*/}

          <div className="mt-5">
            <FormControlLabel
              control={<Checkbox checked className="color_secondary" />}
              label="I agree to Terms & Conditions / Disclaimer"
            />

            <div className="mt-4 w-100">
              <Button
                variant="contained"
                type="button"
                className="bg_secondary text-white w-100"
                onClick={() => this.handleCheckout()}
              >
                <span className="mx-3 my-2">Proceed to Checkout</span>
              </Button>
            </div>
          </div>
          {/* </form> */}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  state = {
    isDisabled: true,
    senderName: "",
    senderEmail: "",
    senderPhone: "",
    modalOpen: false,
    currencyRates: {},
    countryCode: "",
    mobile: "",
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.modalFlag !== prevProps.modalFlag) {
      this.setState({ modalOpen: this.props.modalFlag });
    }
    if (this.props.customer !== prevProps.customer && this.props.order) {
      this.setState({
        senderPhone:
            this.props.customer.countryCode + this.props.customer.phone,
        senderName:this.props.customer.name,
        senderEmail:this.props.customer.email,
      });
      if(this.props?.customer?.countryCode && this.props?.customer?.phone){
        this.setState({modalOpen:false});
      }
    }
  }
  componentDidMount = async () => {
    if (this.props.modalFlag) {
      this.setState({ modalOpen: true });
    }
    let ress = await getRequest("currencies/getCurrency");
    if (ress.data?.currencyRates) {
      this.setState({ currencyRates: ress.data.currencyRates });
    }
    this.props.getAddresses();
    const { order, customer } = this.props;

    let cartIds = [];
    order &&
      order.map((cart) => {
        cartIds.push(cart._id);
      });
    this.setState({ cartIds: cartIds });
    if (order && (!customer?.phone || !customer?.countryCode)) {
      this.setState({ modalOpen: true });
    }
    this.setState({
      senderName: customer?.name,
      senderEmail: customer?.email,
      senderPhone: customer?.countryCode + customer?.phone,
    });
    this.props.setDetails(cartIds);
    if (customer) {
      this.props.setSenderInfo({
        senderName: customer?.name,
        senderEmail: customer?.email,
        senderPhone: customer?.phone,
      });
    }
  };
  changeCustomerData(e) {
    let key = e.target.name;
    let value = e.target.value;
    if ([key] == "senderName") {
      this.setState({ senderName: value });
      this.props.setSenderInfo({
        senderName: value,
        senderEmail: this.state.senderEmail,
        senderPhone: this.state.senderPhone,
      });
    }
    if ([key] == "senderPhone") {
      this.setState({ senderPhone: value });
      this.props.setSenderInfo({
        senderName: this.state.senderName,
        senderEmail: this.state.senderEmail,
        senderPhone: value,
      });
    }
    if ([key] == "senderEmail") {
      this.setState({ senderEmail: value });
      this.props.setSenderInfo({
        senderName: this.state.senderName,
        senderEmail: value,
        senderPhone: this.state.senderPhone,
      });
    }
  }
  saveAddress = async (data) => {
    var res = await this.props.createAddress(data);
    return res;
  };
  selectAddress = (data) => {
    this.props.updateCart(data);
  };
  onCartDelete = (id) => {
    this.props.deleteCart(id);
    let cartIds = this.state.cartIds;
    cartIds.filter((value, index, arr) => {
      return value != id;
    });
    this.setState({ cartIds });
    this.props.setDetails(cartIds);
  };
  handleCheckout = async () => {
    const { order } = this.props;
    var res = _.where(order, { deliveryAddress: null });
    if (res.length) {
      Notify(
        "danger",
        `Please Provide Delivery Address For ${
          res?.[0]?.products?.[0]?.title
            ? res?.[0]?.products?.[0]?.title
            : "All Orders"
        }`
      );
      return;
    } else {
      this.props.onCheckout("payment");
    }
  };
}
const mapStateToProps = (state) => {
  return {
    user: state?.auth?.user,
    currency: state?.auth?.currency,
    order: state?.cart?.orders?.carts,
    customer: state?.cart?.orders?.customer,
    loading: state?.cart?.deleting_cart,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    deleteCart: (id) => dispatch(deleteOrder(id, router)),
    updateCart: (data) => dispatch(updateCart(data)),
    storeUser: (data) => dispatch(storeUser(data, router, false)),
    createAddress: (data) => dispatch(createAddress(data, router)),
    getAddresses: () => dispatch(searchAddress()),
    reRout: () => dispatch(reRout(router)),
    setPhone: (data) => dispatch(setPhone(data, router)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(OrderDetails))
);
