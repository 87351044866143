import React, { Component } from "react";
import { getDateParts } from "../../constants/utils";
import Calendar from "react-calendar";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import { getRequest } from "../../store/services/index";
class DeliveryInfo extends Component {
  UNSAFE_componentWillReceiveProps() {
    this.openModal(this.props.visible);
  }

  async componentDidMount() {
    getRequest("bulkCreate/allActive")
        .then((res) => {
          let blockDates = [];

          if (res.data.length > 0) {
            res.data.forEach((block) => {
              if (block.date !== "ALL") {
                const list = block.date.split(";");

                blockDates = [...blockDates, ...list];
              }
            });

            this.setState({
              blockDates: blockDates,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    let methods = [];
    let timeSlots = [];
    let methodTypes = [];
    this.props.methods.party.forEach((party) => {
      if (party.shippingStateProvinceGeoId === this.props.city) {
        party.carrierShipmentMethods.forEach((method) => {
          methods.push({
            label: method.methodType,
            timeSlot: method.timeSlots,
            val: method._id,
            price: method.shipmentMethodsType.price,
            partyId: party._id,
            shipmentMethodsType: method.shipmentMethodsType,
          });
        });
      }
    });
    let temp = [];
    for (let i = 0; i < methods.length; i++) {
      let flag = temp.filter((item) => item === methods[i].label);
      if (flag.length > 0) {
        for (let j = 0; j < methodTypes.length; j++) {
          if (methodTypes[j].label === methods[i].label) {
            for (let k = 0; k < methods[i].timeSlot.length; k++) {
              let filteredTimeSlot = methodTypes[j].timeSlot.filter(
                  (slot) =>
                      slot.startTime == methods[i].timeSlot[k].startTime &&
                      slot.endTime == methods[i].timeSlot[k].endTime
              );
              if (filteredTimeSlot.length > 0) {
              } else {
                methodTypes[j].timeSlot.push(methods[i].timeSlot[k]);
              }
            }
          }
        }
      } else {
        temp.push(methods[i].label);
        methodTypes.push(methods[i]);
      }
    }
    this.setState({
      methods,
      timeSlots,
      methodTypes,
      orignalArray: methodTypes,
    });
  }

  handtileDisable = ({ activeStartDate, date, view }) => {
    const fullDate = `${date.getFullYear()}-${
        date.getMonth() + 1 > 10 ? "" : 0
    }${date.getMonth() + 1}-${date.getDate()}`;

    return this.state.blockDates.includes(fullDate);
  };
  unique = (a) =>
      a.filter(function (itm, i, a) {
        return i == a.indexOf(itm);
      });
  render() {
    const { stepName, openDeliveryInfo } = this.state;
    const { currency } = this.props;

    const today = new Date();
    const top = 50;
    const left = 50;
    return (
        <div>
          <React.Fragment>
            <Modal
                open={openDeliveryInfo}
                onClose={() => {
                  this.props.onCloseDeliveryInfo();
                }}
            >
              <div
                  className="myModal"
                  style={{
                    top: `${top}%`,
                    left: `${left}%`,
                    transform: `translate(-${top}%, -${left}%)`,
                  }}
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header text-center">
                      {this.state.showBack && (
                          <i
                              onClick={() => this.handleBack()}
                              className="material-icons back-arrow"
                          >
                            arrow_back
                          </i>
                      )}
                      <h4 className="modal-title" style={{fontSize:'18px'}}>{this.state.modalTitle}</h4>
                      <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            if (this.props.flag && this.props.flag === true) {
                              this.props.closeWindow();
                            } else {
                              this.props.onCloseDeliveryInfo();
                            }
                            this.setState({ openDeliveryInfo: false });
                          }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div id="calendar">
                        <Calendar
                            minDate={today}
                            showNeighboringMonth={false}
                            onChange={(date) => this.handleDateSelect(date)}
                            value={this.state.startDate}
                            tileDisabled={this.handtileDisable}
                        />
                      </div>
                      <div
                          id="shipMethod"
                          className={stepName === "shipMethod" ? "show" : "hidden"}
                      >
                        <div className="scroll">
                          <ul className="list-group">
                            {/* {console.log(
                            "this.state.methods",
                            this.state.methods
                          )} */}
                            {this.state.methodTypes.map(
                                ({ label, price, val, partyId }, key) => {
                                  price = parseFloat(
                                      (
                                          (price / this.props.currencyRates.AED) *
                                          this.props.currencyRates[this.props.currency]
                                      ).toFixed(2)
                                  );
                                  return (
                                      <CheckBox
                                          label={label}
                                          price={price}
                                          key={key}
                                          currency={currency}
                                          checked={
                                            key === this.state.selectedShipping && true
                                          }
                                          onClick={() => this.shippingSelect(label)}
                                      />
                                  );
                                }
                            )}
                          </ul>
                        </div>
                        <div
                            onClick={() => this.backToCalendar()}
                            className="backtc"
                        >
                          Back To Calendar
                        </div>
                      </div>
                      <div
                          id="timeSlot"
                          className={stepName === "timeSlot" ? "show" : "hidden"}
                      >
                        <div className="scroll">
                          <h4 className="text-center m-2">
                            {this.state.shippingType +
                            " - " +
                            currency +
                            " " +
                            this.state.shippingPrice}
                          </h4>
                          <ul className="list-group">
                            {this.state.selectedTimeSlot.map(
                                ({ startTime, endTime, _id }, key) => {
                                  return (
                                      <CheckBox
                                          label={startTime + " - " + endTime + " hrs"}
                                          key={key + 2}
                                          sec="time"
                                          onClick={() =>
                                              this.slotSelect(key, startTime, endTime)
                                          }
                                      />
                                  );
                                }
                            )}
                          </ul>
                        </div>
                        <div
                            onClick={() => this.backToCalendar()}
                            className="backtc"
                        >
                          Back To Calendar
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </React.Fragment>
        </div>
    );
  }
  constructor(props) {
    super(props);

    this.state = {
      stepName: "calendar",
      showBack: false,
      modalTitle: this.select_date,
      selectedShipping: "",
      shippingPrice: "",
      shippingType: "",
      openDeliveryInfo: true,
      finalDeliveryDate: {
        date: null,
        day: null,
        month: null,
        type: null,
        price: null,
        time: null,
      },
      startDate: new Date(),
      partyId: "",
      selectedDate: null,
      methods: [],
      timeSlots: [],
      selectedTimeSlot: [],
      methodTypes: [],
      blockDates: [],
      orignalArray: [],
      shippingOrignal: "",
    };
  }

  openModal = (open) => {
    const { openDeliveryInfo } = this.state;
    if (openDeliveryInfo !== open) {
      this.setState({
        openDeliveryInfo: open,
        stepName: "calendar",
        showBack: false,
        modalTitle: this.select_date,
      });
    }
  };
  select_date = "Select Date";
  select_shipping = "Select Shipping Method";
  select_timeSlot = "Select Time Slot";
  handleDateSelect = async (date) => {
    let selectedDate = moment(date).format("Y-MM-DD");
    let formateTime = moment(date).format("HH:mm");
    let newDate = new Date();
    let todayDate = moment(newDate).format("Y-MM-DD");
    let tomorrowDate = moment().add(1, "d").format("Y-MM-DD");
    let todayTime = moment(newDate).format("HH:mm");
    let methodTypes = JSON.parse(JSON.stringify(this.state.orignalArray));
    let shippmentMethodIds = this.props.shippmentMethodIds
        .split(";")
        .map((x) => +x);
    if (selectedDate === todayDate) {
      let morningDelivery = methodTypes.filter(
          (m) => m.label === "Early Morning Delivery"
      );
      morningDelivery.forEach((f) =>
          methodTypes.splice(
              methodTypes.findIndex((e) => e.label === f.label),
              1
          )
      );
      let nextDayDelivery = methodTypes.filter(
          (m) => m.label === "Next Day Delivery"
      );
      nextDayDelivery.forEach((f) =>
          methodTypes.splice(
              methodTypes.findIndex((e) => e.label === f.label),
              1
          )
      );
      let finalMethodTypes = [];
      for (let j = 0; j < methodTypes.length; j++) {
        let method = methodTypes[j];

        if (method.label === "One hour Delivery") {
          let currentTime = moment().add(10, "m").format("HH:mm");
          let allowedTimeSlots = [];
          for (let n = 0; n < method.timeSlot.length; n++) {
            let timeSlot = method.timeSlot[n];
            if (currentTime < timeSlot.startTime) {
              allowedTimeSlots.push(timeSlot);
            }
          }
          if (allowedTimeSlots.length > 0) {
            // let slots = this.filterTimeSlots(allowedTimeSlots);
            method.timeSlot = allowedTimeSlots;
            finalMethodTypes.push(method);
          }
        } else if (method.label === "Midnight Delivery") {
          let currentTime = moment().add(2, "h").format("HH:mm");
          let allowedTimeSlots = [];
          for (let l = 0; l < method.timeSlot.length; l++) {
            let timeSlot = method.timeSlot[l];
            if (currentTime < timeSlot.startTime) {
              allowedTimeSlots.push(timeSlot);
            }
          }
          if (allowedTimeSlots.length > 0) {
            // let slots = this.filterTimeSlots(allowedTimeSlots);
            method.timeSlot = allowedTimeSlots;
            finalMethodTypes.push(method);
          }
        } else if (method.label === "Standard Delivery") {
          let currentTime = moment().add(1, "h").format("HH:mm");
          let allowedTimeSlots = [];
          for (let m = 0; m < method.timeSlot.length; m++) {
            let timeSlot = method.timeSlot[m];
            if (currentTime < timeSlot.startTime) {
              allowedTimeSlots.push(timeSlot);
            }
          }
          if (allowedTimeSlots.length > 0) {
            // let slots = this.filterTimeSlots(allowedTimeSlots);
            method.timeSlot = allowedTimeSlots;
            finalMethodTypes.push(method);
          }
        }
        else if (method.label === "Two Hour Delivery") {
          let currentTime = moment().add(15, "m").format("HH:mm");
          let allowedTimeSlots = [];
          for (let m = 0; m < method.timeSlot.length; m++) {
            let timeSlot = method.timeSlot[m];
            if (currentTime < timeSlot.startTime) {
              allowedTimeSlots.push(timeSlot);
            }
          }
          if (allowedTimeSlots.length > 0) {
            // let slots = this.filterTimeSlots(allowedTimeSlots);
            method.timeSlot = allowedTimeSlots;
            finalMethodTypes.push(method);
          }
        }
        else {
          // let slots = this.filterTimeSlots(method.timeSlot);
          // method.timeSlot = method.timeSlot;
          finalMethodTypes.push(method);
        }
      }
      finalMethodTypes = finalMethodTypes.filter((a) =>
          shippmentMethodIds.some((b) => b === a.shipmentMethodsType._id)
      );
      let sorted = finalMethodTypes.sort((a,b)=>this.getTextSort(1,a.shipmentMethodsType.sequence,b.shipmentMethodsType.sequence))
      await this.setState({ methodTypes: sorted });
    } else if (tomorrowDate === selectedDate) {

      let finalMethodTypes = [];
      for (let j = 0; j < methodTypes.length; j++) {
        let method = methodTypes[j];

        if (method.label === "Early Morning Delivery") {
          let allowedTimeSlots = [];
          for (let n = 0; n < method.timeSlot.length; n++) {
            let timeSlot = method.timeSlot[n];
            if (todayTime < "19:00") {
              allowedTimeSlots.push(timeSlot);
            }
          }
          if (allowedTimeSlots.length > 0) {
            // let slots = this.filterTimeSlots(allowedTimeSlots);
            method.timeSlot = allowedTimeSlots;
            finalMethodTypes.push(method);
          }
        }
        else if (method.label === "Next Day Delivery") {
          let currentTime = moment().format("HH:mm");
          let allowedTimeSlots = [];
          for (let m = 0; m < method.timeSlot.length; m++) {
            let timeSlot = method.timeSlot[m];
            if (timeSlot.endTime === '14:00:00') {
              if (currentTime < timeSlot.endTime) {
                allowedTimeSlots.push(timeSlot);
              }
            } else {
              if (currentTime < timeSlot.endTime) {
                allowedTimeSlots.push(timeSlot);
              }
            }
          }
          if (allowedTimeSlots.length > 0) {
            // let slots = this.filterTimeSlots(allowedTimeSlots);
            method.timeSlot = allowedTimeSlots;
            finalMethodTypes.push(method);
          }
        }
        else {
          // let slots = this.filterTimeSlots(method.timeSlot);
          // method.timeSlot = method.timeSlot;
          finalMethodTypes.push(method);
        }
      }
      finalMethodTypes = finalMethodTypes.filter((a) =>
          shippmentMethodIds.some((b) => b === a.shipmentMethodsType._id)
      );
      let sorted = finalMethodTypes.sort((a,b)=>this.getTextSort(1,a.shipmentMethodsType.sequence,b.shipmentMethodsType.sequence))
      await this.setState({ methodTypes: sorted });
      await this.setState({ methodTypes: finalMethodTypes });

    } else {
      // this.filterTimeSlots(allowedTimeSlots);
      let array = JSON.parse(JSON.stringify(this.state.orignalArray));
      array = array.filter((a) =>
          shippmentMethodIds.some((b) => b === a.shipmentMethodsType._id)
      );

      // array.forEach((method)=>{
      //   let slots = this.filterTimeSlots(method.timeSlot);
      //   method.timeSlot=slots;
      // })
      let sorted = array.sort((a,b)=>this.getTextSort(1,a.shipmentMethodsType.sequence,b.shipmentMethodsType.sequence))
      await this.setState({ methodTypes: sorted });
      this.setState({ methodTypes: array });
    }
    this.setState(
        {
          selectedDate: date,
          showBack: true,
          modalTitle: this.select_shipping,
          stepName: "shipMethod",
        },
        () => {
          this.showShipMethod();
        }
    );
  };
  showShipMethod = () => {
    animateToLeft(document.getElementById("calendar"), 0, 504);
  };
  showTimeStep = () => {
    animateToLeft(document.getElementById("shipMethod"), 0, 504);
  };
  backToCalendar = () => {
    this.setState(
        {
          showBack: true,
          modalTitle: this.select_date,
          stepName: "calendar",
        },
        () => {
          animateToRight(document.getElementById("calendar"), 0, 504);
        }
    );
  };
  backToShip = () => {
    this.setState(
        {
          showBack: true,
          modalTitle: this.select_shipping,
          stepName: "shipMethod",
        },
        () => {
          animateToRight(document.getElementById("shipMethod"), 0, 504);
        }
    );
  };
  handleBack = () => {
    const { stepName } = this.state;
    if (stepName === "shipMethod") {
      this.backToCalendar();
    } else if (stepName === "timeSlot") {
      this.backToShip();
    }
  };
  getTextSort=(direction, v1, v2)=> {
    if (v1 !== undefined && v2 !== undefined) {
      if (typeof v1 === "string" && typeof v2 === "string") {
        v1 = v1.trim();
        v2 = v2.trim();
        v1 = v1.toLowerCase();
        v2 = v2.toLowerCase();
        v1 = v1.length === 0 ? " " : v1;
        v2 = v2.length === 0 ? " " : v2;
      }
      if (v1 > v2) return direction;
      if (v1 < v2) return -direction;
    }
    return 0;
  }
  shippingSelect = (label) => {
    let shipping = this.state.methodTypes.filter((m) => m.label == label);
    let price = parseFloat(
        (
            (shipping[0].price / this.props.currencyRates.AED) *
            this.props.currencyRates[this.props.currency]
        ).toFixed(2)
    );
    this.setState(
        {
          selectedShipping: label,
          showBack: true,
          modalTitle: this.select_timeSlot,
          stepName: "timeSlot",
          shippingType: shipping[0].label,
          shippingPrice: price,
          shippingOrignal: shipping[0].price,
        },
        () => {
          this.showTimeStep();
        }
    );
    this.setState({
      selectedTimeSlot: shipping ? shipping[0].timeSlot : [],
    });
  };
  slotSelect = (key, startTime, endTime) => {
    const {
      selectedDate,
      shippingType,
      shippingPrice,
      shippingOrignal,
    } = this.state;
    const { date,  month } = getDateParts(selectedDate);
    let day = moment(selectedDate).utc(true).format('ddd');

    let selectedShipping = this.state.selectedShipping;
    let shipping = this.state.methods.filter(
        (m) => m.label == selectedShipping
    );
    let parties = [];
    shipping.map((method) => {
      method.timeSlot.map((timeSlot) => {
        if (timeSlot.startTime == startTime && timeSlot.endTime == endTime) {
          parties.push(method.partyId);
        }
      });
    });
    var final = {
      date: date,
      day: day,
      month: month,
      type: shippingType,
      price: shippingPrice,
      shippingOrignal,
      time: `${startTime} - ${endTime} hrs`,
    };
    this.setState(
        {
          openDeliveryInfo: false,
          finalDeliveryDate: final,
          partyId: parties,
        },
        () => this.props.onFinal(this.state)
    );
  };
}
export default  React.memo(DeliveryInfo)
const CheckBox = ({
                    price=null,
                    label,
                    checked,
                    onClick,
                    sec = "ship",
                    currency,
                  }) => {
  return (
      <li className="list-group-item" onClick={onClick}>
        <div className={"item"}>
          <div className={"flexRow " + sec + (checked ? " checked" : "")}>
            <input type="radio" name="method" checked={checked}  />
            <span className="radio" style={{margin:"4px"}}></span>
            <span style={{fontSize:'13px',fontWeight:'bold'}}>{label}</span>
          </div>
          {price!==null ?price!==0? (
              <div className="price">
                <h6>
                  <b>
                    {currency} {price}
                  </b>
                </h6>
              </div>
          ):(
              <div className="price">
                <h6>
                  <b>
                    Free
                  </b>
                </h6>
              </div>
          ):""}
        </div>
      </li>
  );
};
const animateToLeft = (obj, from, to) => {
  if (from >= to) {
    obj.style.display = "none";
    return;
  } else {
    var box = obj;
    box.style.transform = `translate(${-from + "px"})`;
    animateToLeft(obj, from + 4, to);
  }
};
const animateToRight = (obj, from, to) => {
  if (from >= to) {
    obj.style.display = "contents";
    return;
  } else {
    var box = obj;
    box.style.transform = `translate(${from - to + 4 + "px"})`;
    animateToRight(obj, from + 4, to);
  }
};
