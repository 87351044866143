import * as React from "react";
import { useScrollTrigger, Fade } from "@material-ui/core";
const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();
  return (
      <Fade
          appear={true}
          direction="down"
          in={!trigger}
      >
        {children}
      </Fade>
  );
};
export default HideOnScroll;
