import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getFindMeta } from "../store/actions/metaAction";

class CareGuide extends Component {
  componentDidMount() {
    this.props.metaApi("careguide");
  }
  handleClick = (type) => {
    this.props.history.push({
      pathname: "/care-guide-detail",
      state: {
        type: type,
      },
    });
  };
  render() {
    return (
      <div
        className="homeMargin mx-4"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft: "7%",
            marginRight: "7%",
          }}
        >
          <h4>Taking Care of Gifts You Received</h4>
          <p>
            Gifts are not just a token of love and affection. A lot of human
            emotions are attached to them. When you gift something to someone,
            you expect the person to treasure that gift and take care of it.
            With a little care, you can take care of your gift. Here, we will
            tell you how to take care of flowers, cakes and plants.
          </p>
          <div
            style={{ marginTop: "5%", flexDirection: "row", display: "flex" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
              onClick={() => this.handleClick("plant")}
            >
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  borderRadius: "125px",
                  backgroundColor: "#5af89f",
                  marginBottom: "-25%",
                }}
              ></div>
              <div style={{ padding: "6% 7%", backgroundColor: "white" }}>
                <p style={{ fontSize: "20px" }}>Plant Care Guide</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "100px",
                marginRight: "100px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  borderRadius: "125px",
                  backgroundColor: "#f8e45a",
                  marginBottom: "-25%",
                }}
                onClick={() => this.handleClick("flower")}
              ></div>
              <div style={{ padding: "6% 7%", backgroundColor: "white" }}>
                <p style={{ fontSize: "20px" }}>Flower Care Guide</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
              onClick={() => this.handleClick("cake")}
            >
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  borderRadius: "125px",
                  backgroundColor: "#5dddd3",
                  marginBottom: "-25%",
                }}
              ></div>
              <div style={{ padding: "6% 7%", backgroundColor: "white" }}>
                <p style={{ fontSize: "20px" }}>Cake Care Guide</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, router) => {
  return { metaApi: (data) => dispatch(getFindMeta(data)) };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(CareGuide)));
