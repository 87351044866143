import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    TextField, Grid, Button, Card, CardContent, Checkbox, FormControlLabel,
    FormControl, FormGroup, withStyles, InputLabel, Select, MenuItem, Snackbar
} from '@material-ui/core';
import moment from 'moment';
import { updateUser } from "../../store/actions/authActions";

class UpdateProfile extends Component {
    state = {
        _id: 0,
        username: '',
        email: '',
        mobile: '',
        countryCode: '',
        gender: '',
        address: '',
        city: '',
        dob: '',
        anniversary: ''
    }

    componentDidMount() {
        const { user } = this.props;
        console.log(user);
        this.setState({
            _id: user._id,
            username: user.name,
            email: user.email,
            mobile: user.mobile,
            countryCode: user.countryCode,
            gender: user.gender,
            address: user.address,
            city: user.city,
            dob: moment(user.dob).format("YYYY-MM-DD"),
            anniversary: moment(user.anniversary).format("YYYY-MM-DD"),
        })
    }

    userUpateHandler = () => {
        this.props.updateUser({
            _id: this.state._id,
            name: this.state.username,
            email: this.state.email,
            mobile: this.state.mobile,
            countryCode: this.state.countryCode,
            gender: this.state.gender,
            address: this.state.address,
            city: this.state.city,
            dob: this.state.dob,
            anniversary: this.state.anniversary
        });
    }

    render() {

        return (
            <div className={'p-2'}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <h4>My Profile</h4>

                    <div className="mt-3">
                        <div>
                            <TextField
                                type="text"
                                label="Name"
                                placeholder="Name"
                                name='username'
                                value={this.state.username}
                                onChange={e => this.setState({ username: e.target.value })}
                            />
                        </div>

                        <div className="mt-4">
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl>
                                        <InputLabel>Country code</InputLabel>
                                        <Select
                                            onChange={(e) => this.setState({ countryCode: e.target.value })}
                                            value={this.state.countryCode}
                                        >
                                            <MenuItem value={91}>+91</MenuItem>
                                            <MenuItem value={92}>+92</MenuItem>
                                            <MenuItem value={93}>+93</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={8}>
                                    <div>
                                        <TextField
                                            placeholder="Enter your mobile here"
                                            variant="standard"
                                            label="Mobile"
                                            type="number"
                                            inputProps={{ min: 0 }}
                                            name="phone"
                                            value={this.state.mobile}
                                            onChange={e => this.setState({ mobile: e.target.value })}
                                        // error={this.state.phoneError}
                                        // helperText={this.state.phoneErrorMsg}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div className="mt-3">
                            <TextField
                                type="email"
                                label="Email"
                                placeholder="Email"
                                name='email'
                                value={this.state.email}
                                onChange={e => this.setState({ username: e.target.value })}
                            />
                        </div>

                        <div className="mt-3">
                            <FormControl>
                                <InputLabel>Gender</InputLabel>
                                <Select
                                    onChange={(e) => this.setState({ gender: e.target.value })}
                                    value={this.state.gender}
                                >
                                    <MenuItem value={'male'}>Male</MenuItem>
                                    <MenuItem value={'female'}>Female</MenuItem>
                                    <MenuItem value={'other'}>Other</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="mt-3">
                            <TextField
                                type='date'
                                label="Date of birth"
                                placeholder="Date of birth"
                                name='dob'
                                value={this.state.dob}
                                onChange={(e) => this.setState({ dob: e.target.value })}
                            />
                        </div>

                        <div className="mt-3">
                            <TextField
                                type='date'
                                label="Anniversary"
                                placeholder="Anniversary"
                                name='anniversary'
                                value={this.state.anniversary}
                                onChange={(e) => this.setState({ anniversary: e.target.value })}
                            />
                        </div>

                        <div className="mt-3">
                            <TextField
                                type="text"
                                label="Address"
                                placeholder="Address"
                                name='address'
                                value={this.state.address}
                                onChange={e => this.setState({ address: e.target.value })}
                            />
                        </div>

                        <div className="mt-3">
                            <TextField
                                type="text"
                                label="City"
                                placeholder="City"
                                name='city'
                                value={this.state.city}
                                onChange={e => this.setState({ city: e.target.value })}
                            />
                        </div>

                        <div className="mt-4 text-center">
                            <Button type='submit' variant="contained" className="bg_secondary text-white"
                                onClick={this.userUpateHandler}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { user: state.auth.user };
};

const mapDispatchToProps = (dispatch, router) => {
    return {
        updateUser: (data) => dispatch(updateUser(data, router))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UpdateProfile)
);
