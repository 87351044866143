import { getRequest } from "../services";
export const GET_META_SUCCESS = "GET_META_SUCCESS";
export const GET_META_REQUEST = "GET_META_REQUEST";
export const GET_META_FAILURE = "GET_META_FAILURE";

export const metaRequest = () => ({
  type: GET_META_REQUEST,
});
export const metaSuccess = (payload) => ({
  type: GET_META_SUCCESS,
  payload,
});
export const metaFailure = (payload) => ({
  type: GET_META_FAILURE,
  payload,
});

export const getFindMeta = (uniqueKey) => {
  return (dispatch) => {
    dispatch(metaRequest());
    return getRequest(`metadata/findByUniqueKey?uniqueKey=${uniqueKey}`)
      .then((response) => {
        dispatch(metaSuccess(response.data));
      })
      .catch((error) => {
        dispatch(metaFailure("Something Went Wrong"));
        throw error;
      });
  };
};
