import React from "react";

export default function Timer() {

  // calculating tomorrow
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [deadline] = React.useState(tomorrow.setHours(0, 0, 0, 0));
  const [remainingHr, setRemainingHr] = React.useState(0);
  const [remainingMin, setRemainingMin] = React.useState(0);
  const [remainingSec, setRemainingSec] = React.useState(0);
  const timer = setInterval(() => {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = deadline - now;

    // Time calculations for days, hours, minutes and seconds
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    setRemainingHr(hours);
    setRemainingMin(minutes);
    setRemainingSec(seconds);
    // Display the result

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(timer);
    }
  }, 1000);
  return (
    <React.Fragment>
      <p>Time Left for <b>Today's Delivery</b></p>
      <p style={{ marginTop: "-20px", textAlign:'center' }}>
        <span className="timeFont color_primary">{remainingHr}</span>
        hrs <span className="timeFont color_primary">{remainingMin}</span>
        min <span className="timeFont color_primary">{remainingSec}</span>
        sec
      </p>
    </React.Fragment>
  );
}
