import React, { Component } from "react";
import queryString from "query-string";
import { storeUser, getUserSocial } from "../../store/actions/authActions";
import { connect } from "react-redux";

class SocialMediaLogin extends Component {
  componentDidMount = async () => {
    console.log('received search',this.props.location.search);
    const params = new URLSearchParams(this.props.location.search.substring(1));
    let token = params.get('token');
    token = token.trim();

    if(token.charAt(token.length-1)==='/')
    {
      token = token.substring(0, token.length - 1)
    }
    if (token) {
      this.props.getUserSocial({ token: token });
    }
  };
  render() {
    return <h1></h1>;
  }
  // reRout() {
  //   this.props.history.push("/profile/my-account");
  // }
}

const mapDispatchToProps = (dispatch, router) => {
  return {
    saveUser: (data) => dispatch(storeUser(data, router)),
    getUserSocial: (data) => dispatch(getUserSocial(data, router)),
  };
};

export default connect(null, mapDispatchToProps)(SocialMediaLogin);
