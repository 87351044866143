import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {
    Button,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    RadioGroup,
    TextField,
    Box,
    IconButton,
    Icon,
    Typography,
} from "@material-ui/core";
import {connect} from "react-redux";
import {
    deleteAddress,
    updateAddress,
    getActiveOrders,
} from "../../store/actions/cartActions";
import {redirect, slugifyText} from "../../constants/utils";
import styles from "../Checkout/OrderDetails/OrderDetails.module.css";

class DeliveryAddress extends Component {
    render() {
        const {
            _id,
            name,
            email,
            phone,
            recipientAddress,
            landmark,
            addressType,
            phoneAlt,
            nameError,
            recipientAddressError,
            landmarkError,
            phoneError,
            phoneAltError,
            emailError,
            addressTypeError,
            isForm,
            selectedAddress,
            countryCode,
            countryCodeError,
            editAddress,
        } = this.state;
        const {city, allAddresses, product, prods, currency, cart} = this.props;
        const addresses = allAddresses?.[slugifyText(city, "_")];
        const gridStyle = {
            backgroundColor: "white",
            borderRadius: '6px'
        }
        return (
            <React.Fragment>
                {((!addresses?.length || isForm) && this.props.location.pathname !=="/checkout") ? (
                    <Grid container className={"mb-5"} spacing={2} direction={"column"}>
                        <Grid className={"mb-2"} style={gridStyle} container item direction="row">
                            <div>
                                <img
                                    loading="lazy"
                                    src={
                                        product?.images && product.images.length > 0
                                            ? product.images[0]
                                            : ""
                                    }
                                    className="rounded imgPC"
                                    alt={product?.title}
                                />
                            </div>
                            <div className={`ml-3 ${styles.detailsFont}`}>
                                <p>{product?.title}</p>
                                <p>
                                    {currency} {prods?.amount} x {prods?.quantity}
                                </p>
                            </div>
                        </Grid>
                        <Grid style={gridStyle} item>
                            <form onSubmit={this.submitForm}>
                                <h5>ADD DELIVERY ADDRESS</h5>

                                <div className="mt-4">
                                    <Grid container spacing={3} direction="column" justify="space-between">
                                        <Grid item xs>
                                            <TextField
                                                type="text"
                                                size="small"
                                                label="Name"
                                                variant="outlined"
                                                placeholder="Enter your name"
                                                value={name}
                                                name="name"
                                                error={nameError}
                                                helperText={nameError && "This Field Is Required"}
                                                onChange={(event) => this.onChange(event)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <TextField
                                                type="text"
                                                size="small"
                                                label="Resident City"
                                                variant="outlined"
                                                placeholder="Enter city here"
                                                value={city}
                                                disabled={true}
                                            />
                                        </Grid>

                                        <Grid item >
                                            <TextField
                                                type="text"
                                                size="small"
                                                label="Recipient's Address"
                                                variant="outlined"
                                                placeholder="Enter address here"
                                                value={recipientAddress}
                                                name="recipientAddress"
                                                error={recipientAddressError}
                                                helperText={
                                                    recipientAddressError && "This Field Is Required"
                                                }
                                                onChange={(event) => this.onChange(event)}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <TextField
                                                type="text"
                                                size="small"
                                                label="Landmark"
                                                variant="outlined"
                                                placeholder="Enter it here"
                                                value={landmark}
                                                name="landmark"
                                                error={landmarkError}
                                                helperText={landmarkError && "This Field Is Required"}
                                                onChange={(event) => this.onChange(event)}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs
                                            style={{display: "flex", flexDirection: "column"}}
                                        >
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                <TextField
                                                    className={"mr-2"}
                                                    type="text"
                                                    inputProps={{min: "0"}}
                                                    size="small"
                                                    label="+971"
                                                    style={{display: "flex", flex: 2}}
                                                    variant="outlined"
                                                    placeholder="Enter CountryCode"
                                                    value={countryCode}
                                                    name="countryCode"
                                                    error={countryCodeError}
                                                    helperText={
                                                        countryCodeError && this.state.txt
                                                    }
                                                    onChange={(event) => this.onChange(event)}
                                                />

                                                <TextField
                                                    type="number"
                                                    inputProps={{min: "0"}}
                                                    size="small"
                                                    label="Recipient's Mobile"
                                                    variant="outlined"
                                                    style={{display: "flex", flex: 5}}
                                                    placeholder="Enter Mobile Number here"
                                                    value={phone}
                                                    name="phone"
                                                    error={phoneError}
                                                    helperText={
                                                        phoneError &&
                                                        this.state.txtphone
                                                    }
                                                    onChange={(event) => this.onChange(event)}
                                                />
                                            </div>
                                            <Typography variant={"body2"}>*If its a surprise gift please use substitute mobile number</Typography>
                                        </Grid>

                                            <Grid item xs>
                                                <TextField
                                                    type="number"
                                                    size="small"
                                                    inputProps={{min: "0"}}
                                                    label="Recipient's Alt Mobile"
                                                    variant="outlined"
                                                    placeholder="Enter mobile number here"
                                                    value={phoneAlt}
                                                    name="phoneAlt"
                                                    error={phoneAltError}
                                                    helperText={
                                                        phoneAltError && "Mobile number must be of 11 digits"
                                                    }
                                                    onChange={(event) => this.onChange(event)}
                                                />
                                            </Grid>

                                            <Grid item >
                                                <TextField
                                                    type="email"
                                                    size="small"
                                                    label="Recipient's Email"
                                                    variant="outlined"
                                                    placeholder="Enter email here"
                                                    value={email}
                                                    name="email"
                                                    error={emailError}
                                                    helperText={emailError && "This Field Is Required"}
                                                    onChange={(event) => this.onChange(event)}
                                                />
                                            </Grid>


                                        <Grid item >
                                            <div>
                                                <InputLabel>Address Type</InputLabel>
                                                <RadioGroup
                                                    row
                                                    className="radioCustomized"
                                                    style={{marginTop: "-12px"}}
                                                    value={addressType}
                                                    name="addressType"
                                                    onChange={(event) => this.onChange(event)}
                                                >
                                                    <FormControlLabel
                                                        value="home"
                                                        control={<Radio size="small"/>}
                                                        label="Home"
                                                    />
                                                    <FormControlLabel
                                                        value="office"
                                                        control={<Radio size="small"/>}
                                                        label="Office"
                                                    />
                                                    <FormControlLabel
                                                        value="other"
                                                        control={<Radio size="small"/>}
                                                        label="Other"
                                                    />
                                                </RadioGroup>
                                                {addressTypeError && (
                                                    <span className="text-danger">
                        {"This Field Is Required"}
                      </span>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            direction='column'
                                            className="mt-4 mb-2"
                                        >

                                                {addresses?.length && (
                                                    <Grid item xs>
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        className="bg_dark_grey text-white w-100"
                                                        onClick={() =>
                                                            this.setState({
                                                                isForm: false,
                                                                _id: "",
                                                                name: "",
                                                                email: "",
                                                                phone: "",
                                                                recipientAddress: "",
                                                                landmark: "",
                                                                addressType: "",
                                                                countryCode: "",
                                                            })
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                    </Grid>
                                                )}


                                            <Grid item xs>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    className="bg_secondary text-white w-100 mt-3"
                                                >
                                                    Save and Deliver Here
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                ) : (
                    (this.props.location.pathname !== "/checkout" && (
                        <div
                            className="addAddressBtnP"
                            onClick={() => this.setState({isForm: true})}
                        >
                            <span>+ Add Address</span>
                        </div>
                    ))

                )}
                {(addresses?.length && this.props.location.pathname !== "/checkout") ? (
                    <Grid container direction={"column"}>
                        <Grid item>
                                <div className="my-5">
                                    <h5>SAVED ADDRESS</h5>
                                    {addresses.map((address, key) => {
                                        return (
                                            <Box
                                                className={
                                                    "mt-3 border py-3 px-4 rounded addressBox " +
                                                    (address._id === selectedAddress ? "selected" : "")
                                                }
                                                key={key}
                                                boxShadow={4}
                                                style={{display: "flex", justifyContent: "space-between"}}
                                            >
                                                <div
                                                    style={{flexDirection: "row", display: "flex"}}
                                                    onClick={() => this.handleAddressSelect(address._id)}
                                                >
                                                    <div className="checkP">
                      <span className="check">
                        <span/>
                      </span>
                                                    </div>
                                                    <div>
                                                        <p
                                                            className="font-weight-bold"
                                                            style={{fontSize: "18px"}}
                                                        >
                                                            {address.name} - {address.phone}
                                                        </p>
                                                        <kbd>{address.addressType?.toUpperCase()}</kbd>
                                                        <span className="ml-2">
                        {address.recipientAddress} - {address.recipientCity}
                      </span>
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignSelf: "flex-end",
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <IconButton>
                                                                <Icon>edit</Icon>
                                                            </IconButton>
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.onEdit(address);
                                                        }}
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <IconButton>
                                                                <Icon>delete</Icon>
                                                            </IconButton>
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.deleteAddress(address._id);
                                                        }}
                                                    />
                                                </div>
                                            </Box>
                                        );
                                    })}
                                </div>
                        </Grid>
                        {
                            (this.props.location.pathname !== "/checkout" &&(
                                <Grid item xs>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        onClick={async() => {
                                            await this.setState({editAddress:false});
                                            this.props.history.push("/checkout");
                                        }}
                                        className="bg_secondary text-white w-100"
                                    >
                                        Checkout
                                    </Button>
                                </Grid>
                            ))
                        }

                    </Grid>
                            )

                 : (
                        (this.props.match.path !== "/address/:cartId" && (
                            <div>

                                    {
                                        addresses?.map((address) =>{
                                            return(
                                            (address._id === selectedAddress? (
                                                <div className={`${styles.selectedAddress}`}>
                                                <div style={{fontSize:'16px', fontWeight:'bold'}} >
                                                    {address.name}, {address.recipientAddress},{address.recipientCity}
                                                </div>
                                                </div>
                                            ):
                                            '')
                                        )})
                                    }

                                {(!this.state.showButton)? (
                                    <div className={`${styles.changeAddress}`} onClick={ async () => {
                                        await this.setState({editAddress:true})
                                        this.props.history.push(
                                            {
                                                pathname:`/address/${cart}`,
                                                edit:true
                                            });

                                    }}>
                                        change delivery address <Icon>keyboard_arrow_right</Icon>
                                    </div>
                                ):(
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        onClick={ async () => {
                                            await this.setState({editAddress:true})
                                            await this.setState({showButton: false})
                                            this.props.history.push(
                                                {
                                                    pathname:`/address/${cart}`,
                                                    edit:true,
                                                    showButton:false,
                                                });

                                        }}
                                        className="text-white w-100" style={{backgroundColor:'#F70505'}}
                                    >
                                        Add Address
                                    </Button>
                                )

                                }


                            </div>
                        ))


                )}
            </React.Fragment>
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            recipientAddress: "",
            landmark: "",
            addressType: "",
            phoneAlt: "",
            // Errors
            showSavedAddress: false,
            recipientAddressError: false,
            landmarkError: false,
            phoneError: false,
            addressTypeError: false,
            phoneAltError: false,
            emailError: false,
            openDeliveryInfo: false,
            searched: true,
            isForm: false,
            selectedAddress: "",
            countryCode: "",
            countryCodeError: "",
            editAddress:false,
            showButton: false,
            txt:"",
            txtphone:"",

        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.delivery!==prevProps.delivery){
            this.setState({selectedAddress:this.props.delivery});
        }

        if (this.props.prods.deliveryAddress!==prevProps.prods.deliveryAddress){
            if (!this.props.prods.deliveryAddress){
                this.setState({showButton:true})
            }else{
                this.setState({showButton:false})
            }
        }
    }

    componentDidMount() {
        this.setState({selectedAddress: this.props.delivery});
        if(this.props.location.edit){
            this.setState({editAddress:true});
        }

        if (!this.props.location.showButton){
            this.setState({showButtons:false})
        }

        if (!this.props.prods.deliveryAddress){
            this.setState({showButton:true})
        }else{
            this.setState({showButton:false})
        }
    }

    onEdit(address) {
        const {
            _id,
            name,
            email,
            phone,
            recipientAddress,
            landmark,
            addressType,
            phoneAlt,
            nameError,
            recipientAddressError,
            landmarkError,
            phoneError,
            phoneAltError,
            emailError,
            addressTypeError,
            countryCode,
            countryCodeError,
        } = address;
        this.setState({
            _id,
            name,
            email,
            phone,
            recipientAddress,
            landmark,
            addressType,
            phoneAlt,
            nameError,
            recipientAddressError,
            landmarkError,
            phoneError,
            phoneAltError,
            emailError,
            addressTypeError,
            countryCode,
            isForm: true,
            countryCodeError,
        });
    }

    deleteAddress(_id) {
        var data = {_id: this.props.cart, deliveryAddress: null};
        this.props.onSelect(data);
        this.props.delete(_id);
    }

    validationCheck = async () => {
        let isError = false;
        let txt="";
        let txtphone="";
        const errors = {};
        const {
            name,
            email,
            phone,
            recipientAddress,
            landmark,
            addressType,
            countryCode,
        } = this.state;
        if (name === "") {
            errors.nameError = true;
            isError = true;
        } else {
            errors.nameError = false;
        }
        if (countryCode && countryCode.length > 5) {
            errors.countryCodeError = true;
            isError = true;
            txt = "Country Code cannot contain more than 5 characters.";
        } else if (countryCode.length === 0) {
            errors.countryCodeError = true;
            isError = true;
            txt = "Country Code is required.";

        } else {
            errors.countryCodeError = false;
        }
        if (recipientAddress === "") {
            errors.recipientAddressError = true;
            isError = true;
        } else {
            errors.recipientAddressError = false;
        }
        if (phone.length > 10) {
            errors.phoneError = true;
            txtphone = "Mobile number cannot contain more than 10 characters.";
            isError = true;
        } else if (phone.length === 0) {
            errors.phoneError = true;
            isError = true;
            txtphone = "Mobile number is required.";
        } else {
            errors.phoneError = false;
        }
        this.setState({...this.state, ...errors, txt, txtphone});
        return isError;
    };

    submitForm = async (event) => {
        event.preventDefault();
        const {
            _id,
            name,
            email,
            phone,
            recipientAddress,
            landmark,
            addressType,
            countryCode,
        } = this.state;
        var isValid = await this.validationCheck();
        if (!isValid) {
            var data = {
                name: name,
                email: email,
                phone: phone,
                recipientCity: this.props.city,
                recipientAddress: recipientAddress,
                landmark: landmark,
                addressType: addressType,
                countryCode: countryCode,
                cart: this.props.cart,
            };
            if (_id) {
                delete data.cart;
                await this.setState({
                    isForm: false,
                    _id: "",
                    name: "",
                    email: "",
                    phone: "",
                    recipientAddress: "",
                    landmark: "",
                    addressType: "",
                    countryCode: "",
                });
                await this.props.update(data, _id);
                this.props.history.push('/checkout')


            } else {
                this.setState({
                    isForm: false,
                    _id: "",
                    name: "",
                    email: "",
                    phone: "",
                    recipientAddress: "",
                    landmark: "",
                    addressType: "",
                    countryCode: "",
                });
                var res = await this.props.onSave(data);

                await this.setState({selectedAddress: res?._id});
                this.props.getActiveOrders();
                this.props.onSelect(this.state.selectedAddress);
                if(!this.props.location.edit){
                    this.props.history.push('/checkout')
                }

            }
        } else {
            // alert('vai')
        }
    };
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
    changeButton = (invalid) => {
        this.setState({openDeliveryInfo: true});
    };
    closeDeliveryInfo = (data) => {
    };
    handleAddressSelect = (id) => {
        this.setState({selectedAddress: id});
        var data = {_id: this.props.cart, deliveryAddress: id};
        this.props.onSelect(data);
    };
}

const mapStateToProps = (state) => {
    return {
        allAddresses: state?.cart?.addresses,
        currency: state?.auth?.currency,
    };
};

const mapDispatchToProps = (dispatch, router) => {
    return {
        delete: (data) => dispatch(deleteAddress(data, router)),
        update: (data, _id) => dispatch(updateAddress(data, _id, router)),
        getActiveOrders: () => dispatch(getActiveOrders()),
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DeliveryAddress)
);
