import React, { Component } from "react";
import {
  Card,
  Grid,
  CardContent,
  Divider,
  TextField,
  Button,
  Text,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { applyCoupons, enableButton } from "../../../store/actions/cartActions";
import { logout } from "../../../store/actions/authActions";
import { forEach } from "underscore";
export class CheckoutTotal extends Component {
  async componentDidMount() {
    const { orders } = this.props;
    await this.setState({
      totalProductPrice: orders?.totalAmount,
      grandTotal: orders?.grandTotal,
    });
    this.props.updatePricing(
      this.state.totalProductPrice + orders?.totalShippingPrice,
      this.state.totalProductPrice,
      orders?.totalShippingPrice
    );
    this.props.enableButton();
  }
  async componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.orders.totalAmount != this.state.previousValue ||
      nextProps.orders?.grandTotal !== this.props.orders.grandTotal
    ) {
      await this.setState({
        totalProductPrice: (nextProps.orders?.totalAmount)?.toFixed(2),
        grandTotal: nextProps.orders?.grandTotal,
      });
      this.props.updatePricing(
        this.state.grandTotal,
        this.state.totalProductPrice
      );
      this.setState({ previousValue: nextProps.orders.totalAmount });
    }
    if (nextProps.priceUpdate) {
      this.setState({
        grandTotal: nextProps.grandTotal,
        totalProductPrice: nextProps.totalProductPrice,
      });
    }
  }

  state = {
    showLink: false,
    promoCode: "",
    totalProductPrice: "",
    discount: "",
    grandTotal: "",
    showValid: true,
    isApplied: false,
    previousValue: "",
    isDisabled: false,
  };
  render() {
    const { currency, orders } = this.props;
    return (
      <div className>
        <Card elevation={4}>
          <CardContent>
            <h5> Price Details </h5>

            <Grid container justify="space-between">
              <p> Total Product Price </p>
              <p>
                {" "}
                {currency} {this.state.totalProductPrice}{" "}
              </p>
            </Grid>

            {this.state.discount && (
              <Grid container justify="space-between">
                <p> discount </p>
                <p>
                  {" "}
                  {currency} {this.state.discount}{" "}
                </p>
              </Grid>
            )}

            <Grid container justify="space-between">
              <p> Shipping </p>
              <p>
                {" "}
                {currency} {orders?.totalShippingPrice}{" "}
              </p>
            </Grid>

            <Grid container justify="space-between">
              <h5> TOTAL </h5>
              <h5 className="text-danger">
                {" "}
                {currency} {this.state.grandTotal}{" "}
              </h5>
            </Grid>

            <Divider
              orientation="horizontal"
              variant="fullWidth"
              className="my-2"
            />
            {!this.state.showLink && (
              <Link
                to={"#"}
                name="coupon"
                onClick={(e) => this.handleClick(e, 0)}
              >
                <div className="text-center">
                  <span className="text-center text-primary">
                    Have a Discount Coupon?
                  </span>
                </div>
              </Link>
            )}
            {this.state.showLink && this.state.showValid && (
              <div>
                <TextField
                  id="outlined-basic"
                  disabled={this.state.isDisabled}
                  style={{ width: "80%", margin: "normal" }}
                  size="small"
                  label="Enter Coupon Code"
                  variant="outlined"
                  value={this.state.promoCode}
                  onChange={(e) => this.setState({ promoCode: e.target.value })}
                />
                <Button
                  variant="contained"
                  disabled={this.state.isDisabled}
                  style={{ width: "18%", marginLeft: "5px" }}
                  name="entry"
                  color="primary"
                  onClick={(e) => this.handleClick(e, 1)}
                >
                  APPLY
                </Button>
              </div>
            )}
            {this.state.showLink && !this.state.showValid && (
              <div>
                <TextField
                  id="outlined-basic"
                  disabled="true"
                  style={{ width: "80%", margin: "normal" }}
                  size="small"
                  label="Enter Coupon Code"
                  variant="outlined"
                  value={this.state.promoCode}
                  onChange={(e) => this.setState({ promoCode: e.target.value })}
                />
                <Button
                  variant="contained"
                  name="done"
                  style={{ width: "18%", marginLeft: "5px" }}
                  color="primary"
                  onClick={(e) => this.handleClick(e, 2)}
                >
                  REMOVE
                </Button>
                {!this.state.isApplied && (
                  <div style={{ display: "flex", marginTop: "15px" }}>
                    <span
                      className="material-icons"
                      style={{ color: "red", marginRight: "5px" }}
                    >
                      error
                    </span>
                    <p style={{ color: "red" }}>Coupon not applied</p>
                  </div>
                )}
                {this.state.isApplied && (
                  <div style={{ display: "flex", marginTop: "15px" }}>
                    <span
                      className="material-icons"
                      style={{ color: "green", marginRight: "5px" }}
                    >
                      local_offer
                    </span>
                    <p style={{ color: "green" }}>Coupon applied</p>
                  </div>
                )}
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
  async handleClick(e, i) {
    e.preventDefault();
    if (i === 1) {
      const { orders } = this.props;

      let products = {};

      const carts = this.props.orders?.carts;

      products.carts = carts;

      products.amount = orders.totalAmount;

      products.promoCode = this.state.promoCode;

      //console.log(products);
      let discount = await this.props.applyCoupons(products);
      if (discount > 0) {
        discount = (this.state.totalProductPrice * discount) / 100;
        await this.setState((prevState) => ({
          isApplied: true,
          showValid: false,
          discount: -(discount).toFixed(2),
          totalProductPrice: prevState.totalProductPrice - discount,
          grandTotal: prevState.grandTotal - discount,
        }));
        this.props.updatePricing(this.state.totalProductPrice);
      } else {
        this.setState({ isApplied: false, showValid: false });
      }
    } else if (i === 2) {
      this.props.enableButton();
      const { orders } = this.props;
      await this.setState({
        discount: "",
        totalProductPrice: parseFloat(parseFloat(orders?.totalAmount).toFixed(2)),
        grandTotal:  parseFloat(parseFloat(orders?.grandTotal).toFixed(2)),
      });
      this.setState({
        promoCode: "",
        showLink: false,
        showValid: true,
        isDisabled: false,
      });
      this.props.updatePricing(this.state.totalProductPrice);
    } else {
      this.setState({ showLink: true });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currency: state?.auth?.currency,
    orders: state?.cart?.orders,
    discount: state?.cart?.discount,
  };
};

const mapDispatchToProps = (dispatch, router) => {
  return {
    applyCoupons: (data) => dispatch(applyCoupons(data, router)),
    enableButton: () => dispatch(enableButton()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTotal);
