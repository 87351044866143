import React,{useEffect} from "react";
import AddressBook from "./AddressBook";
import Reminders from "./Reminders";
import Orders from "./Orders";
import Redemptions from "./Redemptions";
import ChangePasswords from "./ChangePassword";
import UpdateProfile from "./UpdateProfile";
import UpdateAddress from "./UpdateAddress";
import CustomOrderView from "./CustomOrderView";
import {connect} from "react-redux";
import {logout} from "../../store/actions/authActions";
import {Notify} from "../../constants/utils";
import {withRouter,useLocation} from "react-router-dom";
import ComponentNotFound from "../ComponentNotFound";
import Profile from "./Profile";
import TopNavCart from "../TopNav/TopNavCart";



const Information= (props) =>{
    const checkToken= () =>{
        const {token} = props;
        if (!token) {
            props.history.push('/login');
            return false;
        }
    };
  useEffect(() =>{
      checkToken()
  },[]);

  const url= useLocation();
  switch (url.pathname) {
      case "/profile/address-book":
          return <AddressBook/>;
      case "/profile/reminders":
          return <Reminders/>;
      case "/profile/orders":
          return <Orders/>;
      case "/profile/redemptions":
          return <Redemptions/>;
      case "/profile/change-password":
          return <ChangePasswords {...props} />;
      case "/profile/profile-edits":
          return <UpdateProfile/>;
      case "/profile/address-book/edit":
          return <UpdateAddress/>;
      case "/profile/customerorder-view":
          return <CustomOrderView/>;
      case '/profile/my-account':
          return <Profile/>;
      case "/profile/cartsummary":
          return <TopNavCart/>;
      default:
          return <ComponentNotFound/>
  }

};

const mapStateToProps = (state) => ({token: state.auth.token});

const mapDispatchToProps = (dispatch, router) => {
    return {
        logoutNow: () => {
            dispatch(logout(router));
            Notify('success', 'You are logged out', 'Success');
        },
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Information));
