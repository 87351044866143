import { postRequest, putRequest } from "../services";
import { redirect } from "../../constants/utils";
import {useHistory} from "react-router";
import { getActiveOrders, clearStore } from "./cartActions";
import queryString from "query-string";
export const loginNow = (data, router) => (dispatch, getState) => {
  postRequest("auth/login", data)
    .then((response) => {

      dispatch(storeUser(response?.data, router));
    })
    .catch((error) => console.error(error));
};
export const loginWithoutPassword = (data, router) => (dispatch, getState) => {
  postRequest("auth/loginWithoutPassword", data)
      .then((response) => {

        dispatch(storeUser(response?.data, router));
      })
      .catch((error) => console.error(error));
};
export const createOfflindeOrders=(data,customer,router)=>(dispatch,getState)=>{
  putRequest('cart/createOfflineCarts/'+customer, {carts:data},false).then((res)=>{

  })
}
export const storeUser = (data, router, navigate = true) =>async (
  dispatch,
  getState
) => {
  let route = "/profile/my-account";
  // let route =(router.location.search)?router.location.search.split('=')[1] : "/profile/my-account";
  // if(router.location.hash){
  //   route = route.concat(router.location.hash)
  // }
  // var prevRoute = queryString.parse(router?.location.search)?.redirect;
  // route = prevRoute ? prevRoute + router?.location?.hash : route;
  let temp = getState().auth?.route;
  if (temp !== "") {
    route = temp;
  }

  let orders = getState().cart?.orders;
  if (orders?.carts?.length > 0) {
    if(orders.carts[0]._id){
    }else{
      await dispatch(createOfflindeOrders(orders.carts,data._id))
    }
  }

  await dispatch({ type: "STORE_USER", payload: data });
  dispatch(getActiveOrders());
  if (navigate) {
    dispatch({ type: "STORE_ROUTE", payload: "" });
    redirect(router, route);
  }
};
export const saveRout = (router, navigate = true) => (dispatch) => {
  let route = "/profile/my-account";
  let prevRoute = queryString.parse(router?.location.search)?.redirect;

  route = prevRoute ? prevRoute + router?.location?.hash : route;
  console.log("route", route);
  dispatch({ type: "STORE_ROUTE", payload: route });
};
export const saveData = (router, navigate = true) => (dispatch, getState) => {
  let productDetails = getState().orderOption?.productDetails;

  if (productDetails) {
    localStorage.setItem("productDetails", JSON.stringify(productDetails));
  }
  return;
};
export const changeCurrency = (currency) => async (dispatch, getState) => {
  let user = getState().auth?.user;
  if (user?._id) {
    await dispatch({ type: "UPDATE_CURRENCY", payload: currency });
    await dispatch(updateUser({ _id: user?._id, currency: currency }));
    await dispatch(getActiveOrders());
  } else {
    await dispatch({ type: "UPDATE_CURRENCY", payload: currency });
  }
};
export const updateUser = (data, router) => (dispatch, getState) => {
  var token = getState().auth?.user?.token;
  putRequest("users/update/" + data?._id, data, true, {
    "access-token": token,
  })
    .then((response) => {
      var user = { ...response?.data?.[0], token: token };
      dispatch(storeUser(user, null, false));
      router.history.push("/profile/my-account");
    })
    .catch((error) => console.error(error));
};
export const logout = (router) => (dispatch) => {
  dispatch(clearStore());
  dispatch({ type: "LOGOUT", payload: {} });
  localStorage.clear();
  redirect(router, "/");
};
export const verifyPinCode = (data, router) => (dispatch, getState) => {
  return putRequest("auth/varify", data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
};
export const updatePassword = (data, router) => (dispatch, getState) => {
  return putRequest("auth/update", data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
};
export const sendOtp = (data, router) => (dispatch, getState) => {
  return putRequest("auth/userPasswordReset", data, true)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
};
export const getUserSocial = (data, router) => (dispatch, getState) => {
  return putRequest("auth/getUserSocial", {}, false, {
    "access-token": data.token,
  })
    .then((response) => {
      dispatch(storeUser({ token: data.token, ...response.data }, router));
    })
    .catch((error) => {
      let route = getState().auth?.route;
      if(route){
        redirect(router,route)
      }
      else{
        redirect(router,'/login')
      }
    });
};
export const validate=(router)=>(dispatch,getState)=>{
  // var token = getState().auth?.user?.token;
  var token = localStorage.getItem('token');
  if(token){

    putRequest("cart/validate",{},false,{
      "access-token": token,
    }).then(()=>{
      window.location = "/checkout";
    }).catch(()=>{

      window.location = "/login?redirect=/checkout";
    });
  }
  else{
    window.location = "/login?redirect=/checkout";
  }

}

