import React, { Component } from "react";
import { Grid, Divider, TextField, Button } from "@material-ui/core";
import seal from "../../../assets/placeHolders/seal.png";
import axios from "axios";

class CorporateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      productImage: "",
    };
  }

  async componentDidMount() {
    try {
      let {pathname}=this.props.location;
      let filterPath= pathname?.split("/");
      const id = filterPath?.[filterPath?.length -1];
      // const id = this.props.location.hash.replace("#", "");
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}products/get/${id}`
      );

      if (res) {
        this.setState({
          product: res.data.data,
          productImage: res.data.data.images[0],
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { product, productImage } = this.state;

    return (
      <div>
        <p className="mt-3 mx-4">Home >> Corporate >> {product.title}</p>

        <div className="mt-5 mx-4">
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <img loading="lazy" alt="product" src={productImage} height="372px" width="372px" alt={product?.title} />
              <div className="text-center mt-3 mb-4">
                <img loading="lazy" alt="Stamp Of Approval" src={seal} />
              </div>
            </Grid>

            <Grid item xs={5}>
              <div>
                <h5>{product?.title?.toUpperCase()}</h5>
                <Divider />
                <p className="mt-3">{product?.description}</p>

                <div className="mt-4">
                  <p>
                    <span className="font-weight-bold">Price : </span>AED{" "}
                    {product.amount}
                  </p>
                  <p>
                    <span className="font-weight-bold">
                      Minimum Order Quantity :{" "}
                    </span>
                    10
                  </p>
                  <p>
                    <span className="font-weight-bold">Customization : </span>
                    Yes
                  </p>
                  <p>
                    <span className="font-weight-bold">Product Code : </span>
                    RAMADANUAE119
                  </p>
                </div>
              </div>
            </Grid>

            <Grid item xs={3}>
              <form>
                <div
                  style={{
                    backgroundColor: "#666666",
                    color: "#FFFFFF",
                    padding: "20px",
                  }}
                  className="rounded"
                >
                  <h4 className="text-center">GET IN TOUCH WITH US</h4>
                  <div>
                    <TextField
                      type="text"
                      placeholder="Contact Person"
                      variant="outlined"
                      size="small"
                      className="bg-white"
                    />
                  </div>
                  <div className="mt-3">
                    <TextField
                      type="number"
                      inputProps={{ min: 0 }}
                      placeholder="Contact Number"
                      variant="outlined"
                      size="small"
                      className="bg-white"
                    />
                  </div>
                  <div className="mt-3">
                    <TextField
                      type="email"
                      placeholder="Your Email"
                      variant="outlined"
                      size="small"
                      className="bg-white"
                    />
                  </div>
                  <div className="mt-3">
                    <TextField
                      type="number"
                      inputProps={{ min: 0 }}
                      placeholder="Quantity Min 10"
                      variant="outlined"
                      size="small"
                      className="bg-white"
                    />
                  </div>
                  <div className="mt-3">
                    <TextField
                      type="text"
                      placeholder="Company Name"
                      variant="outlined"
                      size="small"
                      className="bg-white"
                    />
                  </div>

                  <div className="mt-4 text-center">
                    <Button
                      type="submit"
                      variant="contained"
                      className="bg_secondary text-white w-100"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>

        {/*slider*/}
        {/*<div className="mt-4 mb-5 pb-4 text-center shadow">*/}
        {/*  <h4 className="pt-4">OUR CLIENTS</h4>*/}
        {/*  <Carousel*/}
        {/*    showDots={true}*/}
        {/*    responsive={responsive}*/}
        {/*    infinite={true}*/}
        {/*    autoPlay={this.props.deviceType !== "mobile" ? true : false}*/}
        {/*    autoPlaySpeed={4000}*/}
        {/*    keyBoardControl={true}*/}
        {/*    customTransition="transform 1000ms ease-in-out"*/}
        {/*    containerClass="carousel-container"*/}
        {/*    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}*/}
        {/*    deviceType={this.props.deviceType}*/}
        {/*    dotListClass="custom-dot-list-style"*/}
        {/*    itemClass={"carousel-item-padding-40-px"}*/}
        {/*  >*/}
        {/*    <img alt="" src={logo1} className="py-5" />*/}
        {/*    <img alt="" src={logo2} className="py-5" />*/}
        {/*    <img alt="" src={logo3} className="py-5" />*/}
        {/*    <img alt="" src={logo4} className="py-5" />*/}
        {/*    <img alt="" src={logo5} className="py-5" />*/}
        {/*    <img alt="" src={logo6} className="py-5" />*/}
        {/*    <img alt="" src={logo7} className="py-5" />*/}
        {/*    <img alt="" src={logo8} className="py-5" />*/}
        {/*    <img alt="" src={logo9} className="py-5" />*/}
        {/*  </Carousel>*/}
        {/*</div>*/}
        {/*slider ends here*/}
      </div>
    );
  }
}

export default CorporateOrder;
