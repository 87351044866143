import React, { Component } from "react";
import { Button, TextField } from "@material-ui/core";
import { Backdrop } from "@material-ui/core";
import axios from "axios";
import { Notify } from "../../../constants/utils";

class PhonePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: true,
      mobile: "",
      mobileError: false,
      mobileErrorMsg: "",
    };
  }

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validationCheck = () => {
    let isError = false;
    let errors = {};

    if (this.state.mobile === "") {
      isError = true;
      errors.mobileError = true;

      errors.mobileErrorMsg = "Mobile number must be of 10 digits";
    }
    if (this.state.mobile.length > 10) {
      isError = true;
      errors.mobileError = true;

      errors.mobileErrorMsg =
        "Mobile number cannot contain more than 10 characters";
    }

    if (isError) {
      this.setState({
        ...errors,
      });
    }

    return isError;
  };

  formSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");

    const valid = this.validationCheck();

    if (!valid) {
      this.setState({ mobileError: false, mobileErrorMsg: "" });

      try {
        const res = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}users/update/${this.props.user._id}`,
          { mobile: this.state.mobile },
          { headers: { "access-token": token } }
        );

        if (res) {
          this.setState({ showDialog: false });
          this.props.storeUser({
            ...this.props.user,
            mobile: this.state.mobile,
          });
          Notify("success", "Mobile number is saved successfully", "Success");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  render() {
    return (
      <Backdrop open={this.state.showDialog} className="loaderP">
        <div className="bg-white w-50 text-center py-4 rounded">
          <form onSubmit={this.formSubmit}>
            <h4 className="color_primary">
              In order to proceed further, you need to provide your mobile
              number
            </h4>
            <div className="mt-3">
              <TextField
                type="number"
                className="w-75"
                variant="outlined"
                inputProps={{ min: 0 }}
                helperText={this.state.mobileErrorMsg}
                error={this.state.mobileError}
                label="Mobile No"
                value={this.state.mobile}
                placeholder="Enter your mobile number"
                name="mobile"
                onChange={this.handleInput}
              />
            </div>
            <div className="mt-3">
              <Button
                type="submit"
                variant="contained"
                className="bg_secondary text-white"
              >
                <span className="mx-5">Save</span>
              </Button>
            </div>
          </form>
        </div>
      </Backdrop>
    );
  }
}

export default PhonePopUp;
