import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Option,
} from "@material-ui/core";
import { connect } from "react-redux";

class CareGuideDetail extends Component {
  state = {
    type: "",
    plantType: "",
    flowerType: "",
    cakeType: "",
  };
  componentDidMount() {
    let type = this.props.location.state.type;

    if (type === "cake") {
      this.setState({ type: "cake", cakeType: "" });
    } else if (type === "flower") {
      this.setState({ type: "flower", flowerType: "How to Care of Poinsetta" });
    } else if (type === "plant") {
      this.setState({ type: "plant", plantType: "Bonsai" });
    }
  }
  render() {
    return (
      <div
        className="homeMargin mx-4"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginRight: "50px",
            marginLeft: "50px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1 }}>
            <div style={{ width: "80%", border: "1px solid #d5d5d5" }}>
              <div
                onClick={() =>
                  this.setState({ type: "plant", plantType: "Bonsai" })
                }
                style={{
                  color: "#e16081",
                  borderLeft:
                    this.state.type === "plant" ? "4px solid #da5281" : "0px",
                  padding: "15px",
                  borderBottom: "1px solid #d5d5d5",
                  fontSize: "20px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Plant Care Guide
              </div>
              {this.state.type === "plant" && (
                <div>
                  {" "}
                  <div
                    onClick={() => this.setState({ plantType: "Bonsai" })}
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #d5d5d5",
                      width: "100%",
                      cursor: "pointer",
                      color:
                        this.state.plantType === "Bonsai"
                          ? "#e16081"
                          : "#2ba3bf",
                    }}
                  >
                    Bonsai
                  </div>
                  <div
                    onClick={() => this.setState({ plantType: "Terrarium" })}
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #d5d5d5",
                      width: "100%",
                      cursor: "pointer",
                      color:
                        this.state.plantType === "Terrarium"
                          ? "#e16081"
                          : "#2ba3bf",
                    }}
                  >
                    Terrarium
                  </div>
                  <div
                    onClick={() => this.setState({ plantType: "Indoor Plant" })}
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #d5d5d5",
                      width: "100%",
                      cursor: "pointer",
                      color:
                        this.state.plantType === "Indoor Plant"
                          ? "#e16081"
                          : "#2ba3bf",
                    }}
                  >
                    Indoor Plant
                  </div>
                  <div
                    onClick={() => this.setState({ plantType: "Lucky Bamboo" })}
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #d5d5d5",
                      width: "100%",
                      cursor: "pointer",
                      color:
                        this.state.plantType === "Lucky Bamboo"
                          ? "#e16081"
                          : "#2ba3bf",
                    }}
                  >
                    Lucky Bamboo
                  </div>
                  <div
                    onClick={() =>
                      this.setState({ plantType: "Flowering Plants" })
                    }
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #d5d5d5",
                      width: "100%",
                      cursor: "pointer",
                      color:
                        this.state.plantType === "Flowering Plants"
                          ? "#e16081"
                          : "#2ba3bf",
                    }}
                  >
                    Flowering Plants
                  </div>
                </div>
              )}
              <div
                onClick={() =>
                  this.setState({
                    type: "flower",
                    flowerType: "How to Care of Poinsetta",
                  })
                }
                style={{
                  color: "#e16081",
                  borderLeft:
                    this.state.type === "flower" ? "4px solid #da5281" : "0px",
                  padding: "15px",
                  borderBottom: "1px solid #d5d5d5",
                  fontSize: "20px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Flower Care Guide
              </div>
              {this.state.type === "flower" && (
                <div>
                  <div
                    onClick={() =>
                      this.setState({ flowerType: "How to Care of Poinsetta" })
                    }
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #d5d5d5",
                      width: "100%",
                      cursor: "pointer",
                      color:
                        this.state.flowerType === "How to Care of Poinsetta"
                          ? "#e16081"
                          : "#2ba3bf",
                    }}
                  >
                    How to Care of Poinsetta
                  </div>
                </div>
              )}
              <div
                onClick={() =>
                  this.setState({
                    type: "cake",
                    cakeType: "",
                  })
                }
                style={{
                  color: "#e16081",
                  borderLeft:
                    this.state.type === "cake" ? "4px solid #da5281" : "0px",
                  padding: "15px",
                  borderBottom: "1px solid #d5d5d5",
                  fontSize: "20px",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                Cake Care Guide
              </div>
              {this.state.type === "cake" && (
                <div>
                  <div
                    onClick={() => this.setState({ cakeType: "Cream Cake" })}
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #d5d5d5",
                      width: "100%",
                      cursor: "pointer",
                      color:
                        this.state.cakeType === "Cream Cake"
                          ? "#e16081"
                          : "#2ba3bf",
                    }}
                  >
                    Cream Cake
                  </div>
                  <div
                    onClick={() => this.setState({ cakeType: "Fondant Cake" })}
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #d5d5d5",
                      width: "100%",
                      cursor: "pointer",
                      color:
                        this.state.cakeType === "Fondant Cake"
                          ? "#e16081"
                          : "#2ba3bf",
                    }}
                  >
                    Fondant Cake
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{ flex: 3, alignItems: "center", justifyContent: "center" }}
          >
            {this.state.type === "plant" && this.state.plantType === "Bonsai" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "justify",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "black",
                      width: "100px",
                      height: "1px",
                    }}
                  ></div>
                  <h2 style={{ color: "#d6286d" }}>How to take care of</h2>
                  <h2 style={{ marginLeft: "5px" }}>Bonsai Plants?</h2>
                  <div
                    style={{
                      backgroundColor: "black",
                      width: "100px",
                      height: "1px",
                    }}
                  ></div>
                </div>
                <div>
                  <p>
                    Bonsai, translated from Japanese as "Tray planting", is a
                    millennia-old tradition that still thrives with plant
                    enthusiasts today. Bonsai is an art, where tree and pot form
                    a harmonious unit with synchronised shape, texture and
                    colour. Many different types of Bonsai trees exist today.
                  </p>
                  <p>
                    Bonsai tree care includes proper watering. Once a week,
                    immerse your Bonsai in water until the bubbles stop reaching
                    the surface. Do not use too much water pressure. Don't just
                    water the soil, as all parts of bonsai plant absorb water to
                    some degree.
                  </p>
                  <p>
                    If your Bonsai flourishes better outdoors, pick a spot that
                    provides six or more hours of sunlight. Indoor Bonsai need
                    two to four hours of natural sunlight or artificial light.
                  </p>
                  <p>
                    The tree must be shaped regularly. Remove any branches that
                    clutter the chosen style. Then, prune to redirect growth-
                    pruning of branches is generally performed in spring and a
                    lot of the new growth is removed.
                  </p>
                </div>
              </div>
            )}

            {this.state.type === "plant" &&
              this.state.plantType === "Terrarium" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "justify",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                    <h2 style={{ color: "#d6286d" }}>How to take care of</h2>
                    <h2 style={{ marginLeft: "5px" }}>Terrarium Plants?</h2>
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div>
                    <ul>
                      <li>First select a good spot for your terrarium!</li>
                      <li>
                        Water lightly only after the soil gets dry every 1-2
                        Weeks, depending on conditions. Never overwater! The key
                        is to watch your terrarium closely until you understand
                        its drying pattern. Too much watering can be worse for
                        the plants than too little.
                      </li>
                      <li>
                        Place in a bright area indoors, some direct sunlight is
                        fine.
                      </li>
                      <li>
                        When the plants get as big as you want, pinch off the
                        newest growth to encourage bushier growth.
                      </li>
                      <li>
                        Water at the base of the plants and not the leaves
                        because they can rot easily.
                      </li>
                      <li>Enjoy your terrarium.</li>
                    </ul>
                  </div>
                </div>
              )}

            {this.state.type === "plant" &&
              this.state.plantType === "Indoor Plant" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "justify",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                    <h2 style={{ color: "#d6286d" }}>How to take care of</h2>
                    <h2 style={{ marginLeft: "5px" }}>Indoor Plants?</h2>
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div>
                    <ul>
                      <li>
                        Plants are a great enhancement to any home decor. They
                        are also beneficial to your health. Ferns N Petals
                        provides these guidelines to keep houseplants thriving.
                      </li>
                      <li>
                        Keep plants in medium light locations, out of direct
                        sunlight. Natural light is best, but some plants can
                        also thrive in office fluorescent light.
                      </li>
                      <li>
                        Plant soil should be kept moist at all time. Be careful
                        to avoid overwatering. Do not allow plants to stand in
                        water.
                      </li>
                      <li>
                        Avoid wetting plant leaves excessively. A spray of water
                        should help in case of flowering plants.
                      </li>
                      <li>
                        Plant should be kept in a cool spot (between 18-28^C)
                        for best performance.
                      </li>
                      <li>Remove waste leaves and stems from time to time.</li>
                    </ul>
                  </div>
                </div>
              )}

            {this.state.type === "plant" &&
              this.state.plantType === "Lucky Bamboo" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "justify",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                    <h2 style={{ color: "#d6286d" }}>How to take care of</h2>
                    <h2 style={{ marginLeft: "5px" }}>Lucky Bamboo?</h2>
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div>
                    <ul>
                      <li>
                        Indirect light at moderate levels is best for a lucky
                        bamboo plant.
                      </li>
                      <li>
                        Add fresh, clean water to your bamboo plant two or three
                        times each week. It is important to keep several inches
                        of water in the container, making sure to keep the roots
                        of the plant wet.
                      </li>
                      <li>
                        Changing the water every 7 to 10 days keeps your lucky
                        bamboo in top condition.
                      </li>
                    </ul>
                  </div>
                </div>
              )}

            {this.state.type === "plant" &&
              this.state.plantType === "Flowering Plants" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "justify",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                    <h2 style={{ color: "#d6286d" }}>How to take care of</h2>
                    <h2 style={{ marginLeft: "5px" }}>Flowering Plants?</h2>
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div>
                    <ul>
                      <li>
                        Indirect light at moderate levels is best for a lucky
                        bamboo plant.
                      </li>
                      <li>
                        Add fresh, clean water to your bamboo plant two or three
                        times each week. It is important to keep several inches
                        of water in the container, making sure to keep the roots
                        of the plant wet.
                      </li>
                      <li>
                        Changing the water every 7 to 10 days keeps your lucky
                        bamboo in top condition.
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            {this.state.type === "flower" &&
              this.state.flowerType === "How to Care of Poinsetta" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "justify",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                    <h2 style={{ color: "#d6286d" }}>How to take care of a</h2>
                    <h2 style={{ marginLeft: "5px" }}>Poinsetta</h2>
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div style={{ textAlign: "justify" }}>
                    <p>
                      Poinsettia is a well-known plant species which has been
                      grown in Egypt from the 1860s onwards when it came from
                      Mexico. It is known as the consul’s daughter which is a
                      reference to Joel Roberts Poinsett, the U.S. Ambassador to
                      Mexico who introduced it in the United States. Poinsettia
                      is known for its distinctive green and red foliage and has
                      more than 100 cultivated types.
                    </p>
                    <p>
                      Poinsettias are attractive options for festivals and
                      celebrations like Christmas. They are popular when it
                      comes to holiday decorations. However, the right
                      Poinsettia care techniques are essential in order to enjoy
                      their beauty for prolonged periods of time. Caring for
                      these Christmas plants requires a little effort and
                      homework on your part.
                    </p>
                    <h5 style={{ color: "#59b5da" }}>
                      Here Are Some of the Top Care Tips for Poinsettia Plants:
                    </h5>
                    <ul>
                      <li>
                        <b>Right Temperature, Water & Light - </b>
                        Caring for poinsettia flowers starts with ensuring that
                        they are kept in humid and semi-cool environments with
                        indirect yet bright light and ample moisture. Poinsettia
                        plants should always be watered extensively and do
                        ensure that drainage is present. You should not keep
                        these in saucers filled with water. The addition of
                        plants nearby can also help in scaling up humidity
                        levels and so will humidifiers. The White Poinsettia or
                        other varieties have to be repositioned in darker and
                        cooler zones till spring or April approximately.
                      </li>
                      <li>
                        <b>Suitable Fertilizing Techniques - </b>
                        Poinsettias should be fertilized while they are
                        blooming. Fertilizer should be applied every couple of
                        weeks or on a monthly basis. The plant will start
                        regrowing within a few weeks.
                      </li>
                      <li>
                        <b>Post-Holiday Care - </b>
                        In the spring season, the plant should be returned to a
                        sunny zone and should be suitably watered. All
                        branches/canes should be cut to approximately 6 inches
                        from the rim of the pot. Poinsettias can be placed
                        indoors in the summer season and the tips should be
                        pinched out for more branching once the plant attains
                        6-10 inches of growth. This has to be done once every
                        month till mid-August.
                      </li>
                      <li>
                        <b> Leaves Falling Off - </b>When the leaves of these
                        white/red Christmas flowers start falling off, you
                        should zero in on the right causes. Environmental
                        aspects including warm and dry climes usually lead to
                        dropping of leaves. Keep the plant in a cool zone and
                        water nicely.
                      </li>
                      <li>
                        <b>Light -</b>
                        The plant should be kept in decent light although
                        positioning it in direct sunlight will hinder growth.
                        People often make a mistake of moving these plants
                        continually between cold and hot temperatures.
                      </li>
                      <li>
                        <b>Overwatering - </b>Refrain from overwatering the
                        plant and pause until the compost surface dries out
                        completely. You should then water extensively and use
                        houseplant fertilizer for feeding the plant once every
                        week.
                      </li>
                      <li>
                        <b>Keeping the plant indoors - </b>You can use a pebble
                        tray for keeping the plant since humid environs prolong
                        its life in most cases.
                      </li>
                      <li>
                        <b>Rescuing a Wilted Plant - </b>One should never
                        discard a poinsettia plant, which has wilted. Wilted
                        Poinsettia plants may have lowered lifespans and you
                        should try to rescue it. The root ball should be soaked
                        in warm water and this will help in revival of the
                        plant. The plant should be able to revive within an hour
                        and you should not discard the plant until then
                      </li>
                      <li>
                        <b>Lowering Watering Levels - </b>Lower watering in the
                        month of March and prune the plant once the leaves are
                        dropped. Keep it mostly dry in this period.
                      </li>
                      <li>
                        <b>Increasing Watering Levels - </b>Increase watering
                        for the plant in early-May and re-pot the same when new
                        shoots start appearing.
                      </li>
                      <li>
                        <b>Weekly feeding - </b> Feed the plant once it grows
                        with a liquid fertilizer on a weekly basis.
                      </li>
                      <li>
                        <b>Balanced Temperature Conditions - </b>From the 10th
                        of October, to the end of the month, allow your
                        Poinsettia plants to get 12 hours of light every day and
                        they should be in darkness for the next 12 hours.
                        Minimum temperatures should touch 18 degrees Celsius.
                        Artificial light can be used wherever necessary.
                      </li>
                    </ul>
                    <p>
                      These tips will help you take care of Poinsettia plants
                      and watch them bloom all over again even after the holiday
                      season is over!
                    </p>
                  </div>
                </div>
              )}

            {this.state.type === "cake" && this.state.cakeType === "" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "justify",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "black",
                      width: "100px",
                      height: "1px",
                    }}
                  ></div>
                  <h2 style={{ color: "#d6286d" }}>Cake Care: </h2>
                  <h2 style={{ marginLeft: "5px" }}>
                    Handling, Transportation and Storage
                  </h2>
                  <div
                    style={{
                      backgroundColor: "black",
                      width: "100px",
                      height: "1px",
                    }}
                  ></div>
                </div>
                <div>
                  <p>
                    We all love to gorge on sweet delicacy when it comes about
                    celebrating any special occasion or event. Among, all these
                    sweet delicacies, cakes are the most preferred by people to
                    add sweetness and unforgettable memory during the
                    celebrations. A cake is a wonderful thing to greet people
                    and to bring home for enhancing the celebrations. The joy of
                    sweetness related to the cakes can leave your mouth with
                    utter disgust if the cakes are not preserved after they are
                    delivered at your doorstep.
                  </p>
                  <p>
                    Although, the common practice of preserving a cake for a
                    long time includes refrigerating it immediately to help
                    retain its freshness and sweet taste for a long time. There
                    is still a lot which needs to be done in this direction to
                    maintain the authentic taste of your cake for a significant
                    period of time. Here are some of the tips which one must
                    know to care for a cake to enjoy the mouthful of heaven in a
                    better way.
                  </p>
                  <h5 style={{ color: "#59b5d7" }}>
                    Care tips (when it is delivered at your place):
                  </h5>
                  <ul>
                    <li>
                      Always remember that cakes are best enjoyed at normal room
                      temperature, but having said that do remember to keep the
                      Fresh Fruit cake, Cream Cheese cake, or Egg Based Fillings
                      like pastry cream and lemon curd in refrigerator if not
                      consumed immediately.
                    </li>
                    <li>
                      If your Cake is delivered directly on the event venue
                      avoid keeping it in direct sunlight and keep it placed in
                      an air-conditioned room.
                    </li>
                    <li>
                      If your cake contains some figurines or sculptures these
                      may include wire supports, wooden skewers or toothpicks.
                      Please inform people especially before serving to small
                      children.
                    </li>
                    <li>
                      If your cake contains some figurines or sculptures these
                      may include wire supports, wooden skewers or toothpicks.
                      Please inform people especially before serving to small
                      children.
                    </li>
                    <li>
                      Hand Painted & airbrushed cakes should not be refrigerated
                      because as soon as you take them out of refrigeration,
                      condensation causes the food colourings to drip and run.
                    </li>
                    <li>
                      Daylight and fluorescent lights can fade the colour of
                      your cake decorations especially if these are in pink and
                      purple colour.
                    </li>
                  </ul>
                  <h5 style={{ color: "#59b5d7" }}>
                    Care tips (when you are picking the cake from shop
                    yourself):
                  </h5>
                  <ul>
                    <li>
                      You must check the cake and by that we mean the name you
                      wanted over the cake, personal message, colour of cream,
                      spelling mistakes, etc.
                    </li>
                    <li>
                      While leaving you must keep both your hands under the
                      board so that weight is evenly distributed. If you don't
                      do so, there may be cracks in fondant or cream layers.
                    </li>
                    <li>
                      Do not squeeze the side of the cake boxes and save your
                      cake from unnecessary spilling of cream layers.
                    </li>
                    <li>
                      You must choose the vehicle well while you are returning
                      with the cake. Auto and cars are preferred because you
                      have space to spread unlike bike or bus or train.
                    </li>
                    <li>
                      In case your cake is a large customized one, the shop may
                      not give you a box to take back home. There would be only
                      a cake platform with open box for safety reasons only.
                    </li>
                    <li>
                      Now once you are in your vehicle, you must place the cake
                      at the flattest part of your transport. Floorboard or SUV
                      flat bed space is best suited. Generally, seats are not
                      preferred because of the slanting nature. The moment any
                      road-breakers come, there's possibility of sliding
                      sideways or down if you keep the cake on your seat.
                    </li>
                    <li>
                      Don't keep any item around the cake box to be safe and
                      secure.
                    </li>
                    <li>
                      When you are going to pick up the cake, please avoid kids
                      and pets as they make be at their mischievous best.
                    </li>
                    <li>
                      When you have a decorative or designer or fondant cake to
                      take back home, you must avoid any other engagements here
                      and there because the more you roam around, there is risk
                      of sliding, tipping, leaning, and leaping of the cake.
                      Generally warm buttercream slides down a bit. So, don't
                      risk it and take the cake straight to its destination.
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {this.state.type === "cake" &&
              this.state.cakeType === "Cream Cake" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "justify",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                    <h2 style={{ color: "#d6286d" }}>Cream </h2>
                    <h2 style={{ marginLeft: "5px" }}>Cake Care</h2>
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div>
                    <h5 style={{ color: "#59b5d7" }}>
                      Care tips (when you are picking the cake from shop
                      yourself):
                    </h5>
                    <ul>
                      <li>
                        Upon receiving the cake, immediately refrigerate it.
                      </li>
                      <li>
                        Always remember that cakes are best enjoyed at room
                        temperature. Please allow it to come to room temperature
                        before serving/eating.
                      </li>
                      <li>
                        The cake should be placed back in the fridge and should
                        be consumed within 24 hours.
                      </li>
                      <li>
                        Sculptural elements and figurines may contain wire
                        supports or toothpicks or wooden skewers for support.
                        Please check the placement of these items before serving
                        to small children.
                      </li>
                      <li>
                        Daylight and fluorescent lights have the tendency to
                        destroy the pink and purple colored cakes. So, take care
                        of that your care is not exposed to any of these.
                      </li>
                    </ul>
                    <h5 style={{ color: "#59b5d7" }}>
                      Care tips (when you are picking the cake from shop
                      yourself):
                    </h5>
                    <ul>
                      <li>
                        Ensure that the correct message is written on the cake.
                      </li>
                      <li>
                        While leaving you must keep both your hands under the
                        box so that weight is evenly distributed. If you don't
                        do so, there may be cracks in the cream layers.
                      </li>
                      <li>
                        Do not squeeze the side of the cake box and save your
                        cake from getting damaged.
                      </li>
                      <li>
                        You must choose the vehicle well while you are returning
                        with the cake. Autos and cars are preferred because you
                        have space to put the cake box unlike bike or bus or
                        train.
                      </li>
                      <li>
                        In case your cake is a large, customized one, the shop
                        may not give you a box to take back home. There would be
                        only a cake board with open box.
                      </li>
                      <li>
                        Now once you are in your vehicle, you must place the
                        cake at the flattest part of your transport. Floorboard
                        or SUV flat bed space is best suited. Generally, seats
                        are not preferred because of the slanting nature. If you
                        keep the cake on your seat, there's a possibility of
                        sliding sideways or down.
                      </li>
                      <li>
                        Don't keep any item around the cake box to be safe and
                        secure.
                      </li>
                      <li>
                        When you are going to pick up the cake, please avoid
                        kids and pets as they may be at their mischievous best.
                      </li>
                      <li>
                        After you have picked up the cake, you must avoid any
                        other engagements because the more you roam around, the
                        more there is a risk of sliding, tipping, leaning, and
                        melting of the cake. So, don't risk it and take the cake
                        straight to its destination.
                      </li>
                      <li>
                        If you have to go really far with the cake, you must let
                        us know earlier so that the cake can be refrigerated for
                        more hours to keep every design and decoration firm.
                      </li>
                      <li>Please avoid heaters and direct sunlight.</li>
                      <li>
                        Keep the air condition of your car on until you reach
                        the destination. The temperature needs to stay below
                        16-18 degrees Celsius.
                      </li>
                      <li>
                        After arriving at the destination, immediately
                        refrigerate the cake.
                      </li>
                      <li>
                        Always remember that cakes are best enjoyed at room
                        temperature. Please allow it to come to room temperature
                        before serving/eating.
                      </li>
                      <li>
                        The cake should be placed back in the fridge and should
                        be consumed within 24 hours.
                      </li>
                      <li>
                        Sculptural elements and figurines may contain wire
                        supports or toothpicks or wooden skewers for support.
                        Please check the placement of these items before serving
                        to small children.
                      </li>
                      <li>
                        Daylight and fluorescent lights have the tendency to
                        destroy the pink and purple colored cakes. So, take care
                        of that your care is not exposed to any of these.
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            {this.state.type === "cake" &&
              this.state.cakeType === "Fondant Cake" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "justify",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                    <h2 style={{ color: "#d6286d" }}>Fondant</h2>
                    <h2 style={{ marginLeft: "5px" }}>Cake Care</h2>
                    <div
                      style={{
                        backgroundColor: "black",
                        width: "100px",
                        height: "1px",
                      }}
                    ></div>
                  </div>
                  <div>
                    <h5 style={{ color: "#59b5d7" }}>
                      Care tips (when it is delivered at your place):
                    </h5>
                    <ul>
                      <li>
                        Store fondant cakes in an air conditioned environment.
                        16-18 degrees Celsius is ideal.
                      </li>
                      <li>
                        Always remember that cakes are best enjoyed at normal
                        room temperature. Please allow it to come to room
                        temperature before serving/eating.
                      </li>
                      <li>Use a serrated knife to cut a fondant cake.</li>
                      <li>The cake should be consumed within 24 hours.</li>
                      <li>
                        Sculptural elements and figurines may contain wire
                        supports or toothpicks or wooden skewers for support.
                        Please check the placement of these items before serving
                        to small children.
                      </li>
                      <li>
                        Daylight and fluorescent lights have the tendency to
                        destroy the pink and purple colored cakes. So, take care
                        of that your care is not exposed to any of these.
                      </li>
                    </ul>
                    <h5 style={{ color: "#59b5d7" }}>
                      Care tips (when you are picking the cake from shop
                      yourself):
                    </h5>
                    <ul>
                      <li>
                        Ensure that the correct message is written on the cake.
                      </li>
                      <li>
                        While leaving you must keep both your hands under the
                        box so that weight is evenly distributed. If you don't
                        do so, there may be cracks in the fondant layers.
                      </li>
                      <li>
                        Do not squeeze the side of the cake box and save your
                        cake from getting damaged.
                      </li>
                      <li>
                        You must choose the vehicle well while you are returning
                        with the cake. Autos and cars are preferred because you
                        have space to put the cake box unlike bike or bus or
                        train.
                      </li>
                      <li>
                        In case your cake is a large, customized one, the shop
                        may not give you a box to take back home. There would be
                        only a cake board with open box.
                      </li>
                      <li>
                        Now once you are in your vehicle, you must place the
                        cake at the flattest part of your transport. Floorboard
                        or SUV flat bed space is best suited. Generally, seats
                        are not preferred because of the slanting nature. If you
                        keep the cake on your seat, there's a possibility of
                        sliding sideways or down.
                      </li>
                      <li>
                        Don't keep any item around the cake box to be safe and
                        secure.
                      </li>
                      <li>
                        When you are going to pick up the cake, please avoid
                        kids and pets as they may be at their mischievous best.
                      </li>
                      <li>
                        After you have picked up the cake, you must avoid any
                        other engagements because the more you roam around, the
                        more there is a risk of sliding, tipping, leaning, and
                        melting of the cake. So, don't risk it and take the cake
                        straight to its destination.
                      </li>
                      <li>Please avoid heaters and direct sunlight.</li>
                      <li>
                        Keep the air condition of your car on until you reach
                        the destination. The temperature needs to stay below
                        16-18 degrees Celsius.
                      </li>
                      <li>
                        Store fondant cakes in an air conditioned environment.
                        16-18 degrees Celsius is ideal.
                      </li>
                      <li>
                        Always remember that cakes are best enjoyed at room
                        temperature. Please allow it to come to room temperature
                        before serving/eating.
                      </li>
                      <li>Use a serrated knife to cut a fondant cake.</li>
                      <li>
                        The cake should be placed back in the fridge and should
                        be consumed within 24 hours.
                      </li>
                      <li>
                        Sculptural elements and figurines may contain wire
                        supports or toothpicks or wooden skewers for support.
                        Please check the placement of these items before serving
                        to small children.
                      </li>
                      <li>
                        Daylight and fluorescent lights have the tendency to
                        destroy the pink and purple colored cakes. So, take care
                        of that your care is not exposed to any of these.
                      </li>
                    </ul>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, router) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CareGuideDetail));
