import React, { useState, useEffect } from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HomeIcon from "@material-ui/icons/Home";
import HelpIcon from "@material-ui/icons/Help";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ListIcon from "@material-ui/icons/List";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const useStyle = makeStyles((theme) => ({
  paper: {
    backgroundColor: "White",
    // height
  },
  positionFixed: {
    top: "auto",
    bottom: "0",
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabTextColor: {
    color: "#666666",
    fontSize: "12px",
    padding: "0",
  },
  tabTextSize: {
    fontSize: "10px",
  },
  labelIcon: {
    paddingTop: "4px",
    minHeight: "37px !important",
  },
  muiTabRoot: {
    padding: "1px 12px",
    textTransform: "none !important",
  },
}));
const BottomNavBar = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const redirect = useHistory();
  const currentLocation = useLocation();
  const {
    drawer: { flag },
  } = useSelector((state) => state);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (currentLocation.pathname === "/") {
      setValue(0);
      // } else if (currentLocation.pathname === '/categories') {
      //     setValue(1)
    } else if (currentLocation.pathname === "/profile/my-account") {
      setValue(2);
    } else if (currentLocation.pathname === "/contact-us") {
      setValue(3);
    } else {
      setValue(null);
    }
  }, []);

  useEffect(() => {
    if (value === 1) {
      setValue(0);
    }
  }, [flag]);

  const setTabValue = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      redirect.push("/");
      // } else if (newValue === 1) {
      //     redirect.push('/categories')
    } else if (newValue === 2) {
      redirect.push("/profile/my-account");
    } else if (newValue === 3) {
      redirect.push("/contact-us");
    }
  };

  const handleToggleDrawer = () => {
    setValue(1);
    dispatch({ type: "toggleDrawer" });
  };

  return (
    <>
      <AppBar
        elevation={5}
        color="default"
        position="fixed"
        classes={{
          colorDefault: classes.paper,
          positionFixed: classes.positionFixed,
        }}
      >
        <Tabs
          variant={"fullWidth"}
          value={value}
          onChange={setTabValue}
          classes={{ root: classes.tabTextColor }}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#B379C5",
            },
          }}
        >
          <Tab
            label="Home"
            style={value === 0 ? { color: "#B379C5" } : {}}
            className={classes.tabTextSize}
            icon={<HomeIcon fontSize="default" />}
            classes={{ labelIcon: classes.labelIcon, root: classes.muiTabRoot }}
          />
          <Tab
            label="Category"
            style={value === 1 ? { color: "#B379C5" } : {}}
            className={classes.tabTextSize}
            icon={<ListIcon />}
            classes={{ labelIcon: classes.labelIcon, root: classes.muiTabRoot }}
            onClick={handleToggleDrawer}
          />

          <Tab
            label="Account"
            style={value === 2 ? { color: "#B379C5" } : {}}
            classes={{ labelIcon: classes.labelIcon, root: classes.muiTabRoot }}
            className={classes.tabTextSize}
            icon={<AccountCircleIcon />}
          />

          <Tab
            label="Help"
            style={value === 3 ? { color: "#B379C5" } : {}}
            classes={{ labelIcon: classes.labelIcon, root: classes.muiTabRoot }}
            className={classes.tabTextSize}
            icon={<HelpIcon />}
          />
        </Tabs>
      </AppBar>
    </>
  );
};

export default React.memo(BottomNavBar);
