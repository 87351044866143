import React, {Component} from "react";
import {connect} from "react-redux";
import DeliveryAddress from "./DeliveryAddress";
import {
    createAddon, createAddress,
    deleteAddon,
    deleteOrder,
    updateAddon,
    updateCart,
    updateQuantity,
} from "../../store/actions/cartActions";
import {withRouter} from "react-router-dom";
import {getRequest} from "../../store/services";
import {Fab} from "@material-ui/core";
import {WhatsApp} from "@material-ui/icons";

class SavedAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDeliveryInfo: false,
            addons: [],
            quantityState: [],
            cartState: [],
            newProduct: [],
            city: null,
            show: false,
            showSaved: false,
            cart: "",
        };
    }
    selectAddress = (data) => {
        this.props.updateCart(data);
    };
    componentDidMount = async () => {
        if(this.props?.product?._id){
            await getRequest(
                "products/get/" + this.props.prods?.products[0]?._id + "/false"
            ).then((res) => {
                getRequest("cities/get/" + res.data.product?.metaData?.country).then(
                    (cities) => {
                        let newCity = cities.data.filter(
                            (city) => city.name === this.props.prods.city
                        );
                        this.setState({ newProduct: res.data, city: newCity[0]._id });
                    }
                );
            });
            var addons = await getRequest(
                "products/addons?limit=30&offset=0&currentProduct=" +
                this.props.product._id
            );
            addons = addons?.data;
            this.setState({ addons: addons });
            let quantityState = [];
            for (let i = 0; i < addons.length; i++) {
                quantityState.push(0);
            }
            let cartState = [];
            for (let i = 0; i < addons.length; i++) {
                cartState.push(0);
            }
            this.setState({ cartState, quantityState });
        }
    };
    changeButton = () => {
        this.setState({ openDeliveryInfo: true });
    };
    closeDeliveryInfo = (data) => {
        let finalDeliveryDate = data.finalDeliveryDate;
        let partyId = data.partyId;
        let selectedDate = data.selectedDate;
        let newData = {
            shippingType: finalDeliveryDate?.type,
            shippingPrice: finalDeliveryDate?.price,
            shippingTime: finalDeliveryDate.time,
            shippingDate: selectedDate,
            partyId: partyId,
            _id: this.props.prods._id,
        };
        this.setState({ openDeliveryInfo: false });
        this.props.updateCart(newData);
    };
    closeWindow = () => {
        this.setState({ openDeliveryInfo: false });
    };
    handleSave = () => {
        this.setState({ show: false, showSaved: true });
    };
    handleClose = () => {
        this.setState({ show: false });
    };
    openModal = () => {
        this.setState({ show: true, cart: this.props.prods });
    };
    componentDidUpdate=async(prevProps)=> {
        if (prevProps.prods !== this.props.prods) {
            this.setState({ cart: this.props.prods });
            await getRequest(
                "products/get/" + this.props.prods?.products[0]?._id + "/false"
            ).then((res) => {
                getRequest("cities/get/" + res.data.product?.metaData?.country).then(
                    (cities) => {
                        let newCity = cities.data.filter(
                            (city) => city.name === this.props.prods.city
                        );
                        this.setState({ newProduct: res.data, city: newCity[0]._id });
                    }
                );
            });
            var addons = await getRequest(
                "products/addons?limit=30&offset=0&currentProduct=" +
                this.props.product._id
            );
            addons = addons?.data;
            this.setState({ addons: addons });
            let quantityState = [];
            for (let i = 0; i < addons.length; i++) {
                quantityState.push(0);
            }
            let cartState = [];
            for (let i = 0; i < addons.length; i++) {
                cartState.push(0);
            }
            this.setState({ cartState, quantityState });
        }
    }
    saveAddress = async (data) => {
        return await this.props.createAddress(data);
    };
    render() {
        const {
            prods,
        } = this.props;
        const cartID= this.props.match.params.cartId

        const orderProduct=this.props?.order?.filter((e) => e._id=== +cartID)




        const shippingDate = new Date(prods?.shippingDate).setHours(0, 0, 0, 0);
        const currentDate = new Date().setHours(0, 0, 0, 0);
        let isDateInvalid = false;
        if (shippingDate < currentDate) {
            isDateInvalid = true;
        }
        return (
        <div className="mt-4 p-4">
            <a
                href="https://wa.me/971555231077"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Fab className="whatsApp">
                    <WhatsApp fontSize="large"/>
                </Fab>
            </a>
            {
                orderProduct?.map(prod =>{
                const product = prod?.products?.[0];
                return (
                <DeliveryAddress
                onSave={this.saveAddress}
                onSelect={this.selectAddress}
                prods={prod}
                city={prod?.city}
                cart={prod._id}
                product={product}
                onHourDelivery={this.state.newProduct.oneHourDelivery}
                twoHoursDelivery={this.state.newProduct.twoHoursDelivery}
                delivery={prod.deliveryAddress?._id}
                />
                )
            })}

        </div>
        );
    }
    addNewAddon(addon) {}
}

const mapStateToProps = (state) => ({
    order: state?.cart?.orders?.carts,
});

const mapDispatchToProps = (dispatch, router) => {
    return {
        createAddress: (data) => dispatch(createAddress(data, router)),
        handleQuantity: (type, id, quantity) =>
            dispatch(updateQuantity(type, id, quantity)),
        handleAddonQuantity: (type, id, quantity) =>
            dispatch(updateAddon(type, id, quantity)),
        makeOrder: (data) => dispatch(createAddon(data, router)),
        delete: (id) => dispatch(deleteOrder(id)),
        deleteAddon: (id) => dispatch(deleteAddon(id)),
        updateCart: (data) => dispatch(updateCart(data)),
    };
};
// export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SavedAddress)
);
