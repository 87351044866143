import React, {Component} from 'react';
import {Button, TextField} from '@material-ui/core';
import axios from 'axios';
import {Notify} from '../../constants/utils';

class ChangePasswords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPassword: '',
            password: '',
            verifyPassword: '',
            currentPasswordError: false,
            currentPasswordErrorMsg: '',
            passwordError: false,
            passwordErrorMsg: '',
            verifyPasswordError: '',
            verifyPasswordErrorMsg: '',
        };
    }

    changeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    validityCheck = () => {
        let isError = false;
        const errors = {};

        if (this.state.currentPassword === '') {
            isError = true;
            errors.currentPasswordErrorMsg = 'This field is required';
            errors.currentPasswordError = true;
        }

        if (this.state.password === '' || this.state.password.length < 6) {
            isError = true;
            errors.passwordErrorMsg = 'Password must be of at least 6 characters';
            errors.passwordError = true;
        }

        if (this.state.verifyPassword !== this.state.password) {
            isError = true;
            errors.verifyPasswordErrorMsg = 'Passwords are not matched';
            errors.verifyPasswordError = true;
        }

        if (isError) {
            this.setState({
                ...errors,
            });
        }
        return isError;
    };

    submitForm = async (event) => {
        event.preventDefault();
        const valid = this.validityCheck();

        if (!valid) {

            try {
                const token = localStorage.getItem('token');
                const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}users/passwordupdate`,
                    {password: this.state.password},
                    {headers: {'access-token': token}});

                if (res) {
                    Notify('success', 'Password Changed Successfully', 'Success');
                    this.props.history.push('/');
                }
            } catch (err) {
                console.log(err);
            }

        }
    };

    render() {
        return (
            <div className={'p-2'}>
                <form onSubmit={this.submitForm}>
                    <h4> Change Password </h4>

                    <div className="mt-3">
                        <div>
                            <TextField
                                type="password"
                                label="Current Password"
                                placeholder="Enter your old password here"
                                value={this.state.currentPassword}
                                name='currentPassword'
                                onChange={this.changeHandler}
                                error={this.state.currentPasswordError}
                                helperText={this.state.currentPasswordErrorMsg}
                            />
                        </div>

                        <div className="mt-3">
                            <TextField
                                type="password"
                                label="New Password"
                                placeholder="Enter your new password here"
                                value={this.state.password}
                                name='password'
                                onChange={this.changeHandler}
                                error={this.state.passwordError}
                                helperText={this.state.passwordErrorMsg}
                            />
                        </div>

                        <div className="mt-3">
                            <TextField
                                type="password"
                                label="Verify New Password"
                                placeholder="Retype your new password here"
                                value={this.state.verifyPassword}
                                name='verifyPassword'
                                onChange={this.changeHandler}
                                error={this.state.verifyPasswordError}
                                helperText={this.state.verifyPasswordErrorMsg}
                            />
                        </div>

                        <div className="mt-4 text-center">
                            <Button type='submit' variant="contained" className="bg_secondary text-white">
                                Save
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ChangePasswords;
