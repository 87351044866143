import React from "react";
import {
  IconButton,
  Icon,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "./TopNav.css";
// import { slugifyText } from "../../constants/utils";
import { connect } from "react-redux";
// import { parseJson } from "../../constants/utils";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  menuIconStyle: {
    padding: "7px 7px",
    backgroundColor: "#F2F2F2",
    borderRadius: "0",
    marginRight: "3px",
  },
});

function TopSearchBox({ navigations, history, location, ToggleDrawer }) {
  var pathParts = location.pathname.split("/");
  const isCorporate = pathParts?.[1] === "corporate" ? true : false;
  // const searchItems = !isCorporate ? parseJson(mainCats) : [];
  const searchItems = !isCorporate ? navigations : [];
  const [searchBox, setSearchBox] = React.useState("hidden");
  const [searchFirstItem, setSearchFirstItem] =
    React.useState("Popular Searches");
  const [searchInput, setSearchInput] = React.useState("");
  const [searchSuggestions, setSearchSuggestions] = React.useState(searchItems);

  const handleSearchOpen = (force = false) => {
    var final = searchBox;
    if (force) {
      final = force;
    }
    if (final === "hidden") {
      setSearchBox("open");
    } else {
      setSearchBox("hidden");
    }
  };
  const handleSearch = (e) => {
    setSearchBox("open");
    setSearchFirstItem("Your Personalized Category Suggestions");
    var value = e.target?.value;
    setSearchInput(value);
    var suggestions = searchItems;
    if (!value.length) {
      suggestions = searchItems;
    } else {
      var regex = new RegExp(`^${value}`, "i");
      suggestions = searchItems
        .sort()
        .filter((v) => v?.name && regex.test(v?.name));
    }
    setSearchSuggestions(suggestions);
  };
  const handleSearchClick = (search) => {
    let value;

    const isNavigation = navigations.find((nav) => nav.name === search.name);
    if (isNavigation) {
      history.push(`/${search?.slug}`);
      value = search?.name;
    } else {
      value = search;
      history.push(`/search?qs=${value}`);
    }
    // if (search?._id) {
    //   // history.push(
    //   //   `/${slugifyText(search?.catalog?.name)}/${slugifyText(search?.name)}/${
    //   //     search?._id
    //   //   }`
    //   // );
    //   history.push(`/${search?.slug}`);
    //   value = search?.name;
    // } else {
    //   value = search;
    //   history.push(`/search?qs=${value}`);
    // }
    setSearchInput(value);
  };
  const handleInputEnter = (e) => {
    if (e.key === "Enter") {
      handleSearchClick(searchInput);
      setSearchBox("hidden");
    }
  };
  const classes = useStyles();
  return (
    <React.Fragment>
      <FormControl
        variant="outlined"
        size="small"
        className="bg-white navSearch"
        onClick={() => handleSearchOpen()}
        onMouseLeave={() => handleSearchOpen("open")}
      >
        <OutlinedInput
          type="text"
          placeholder="Search flowers, cakes, gifts etc."
          onChange={(e) => handleSearch(e)}
          onKeyPress={(e) => handleInputEnter(e)}
          style={{ borderRadius: "3px 20px 20px 3px", marginRight: "0px" }}
          value={searchInput}
          pointer
          startAdornment={
            <InputAdornment position="start">
              <IconButton
                edge="start"
                className={classes.menuIconStyle}
                onClick={ToggleDrawer}
              >
                <MenuIcon />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                edge="end"
                style={{ marginLeft: "0px" }}
                className="bg_secondary text-white searchIcon"
                onClick={() => handleSearchClick(searchInput)}
              >
                <Icon>search</Icon>
              </IconButton>
            </InputAdornment>
          }
        />
        <ul className={"list-group " + searchBox}>
          <li className="list-group-item bg-grey">{searchFirstItem}</li>
          {searchSuggestions &&
            searchSuggestions.map((suggestion, key) => {
              return (
                <li
                  key={key}
                  onClick={() => handleSearchClick(suggestion)}
                  className="list-group-item"
                  style={{ textTransform: "capitalize" }}
                >
                  {suggestion?.name.toLowerCase()}
                </li>
              );
            })}
        </ul>
      </FormControl>
    </React.Fragment>
  );
}
const mapsStateToProps = (state) => {
  return {
    // mainCats: state?.others?.mainCats,
    navigations: state?.navigations?.navigations,
    flag: state?.drawer?.flag,
  };
};
const mapDispatchToProps = (dispatch, router) => ({
  ToggleDrawer: () => dispatch({ type: "toggleDrawer" }),
});
export default withRouter(
  connect(mapsStateToProps, mapDispatchToProps)(TopSearchBox)
);
