import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardContent, Icon, IconButton, Tooltip, Divider, Grid } from '@material-ui/core';
import axios from 'axios';
import { Notify } from '../../constants/utils';
import { setLastAddress, deleteAdress, getAllAdress } from "../../store/actions/profileAction";

class AddressBook extends Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         addresses: []
    //     };
    // }


    async componentDidMount() {
        this.props.getAllAdress();
        // try {
        //     const token = localStorage.getItem('token');
        //     const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}address/all`,
        //         {
        //             headers: { 'access-token': token }
        //         });

        //     if (res) {
        //         this.setState({ addresses: res.data.data });
        //         Notify('success', 'Showing your saved addresses', 'Success');
        //     }

        // } catch (err) {
        //     console.log(err);
        //     Notify('danger', 'Error while fetching addresses', 'Error');
        // }

    }

    editHandler = (id) => {
        this.props.setLastAddress(id);
    }

    deleteHandler = (id) => {
        this.props.deleteAdress(id);
    }

    render() {
        return (
            <div className={'p-3'}>
                <h4>My Address Book</h4>

                <div className='my-4'>

                    <Grid container direction='row' spacing={3}>

                        {
                            this.props.address.map((addr) => {
                                return (
                                    <Grid item xs={12} key={addr._id}>
                                        <Card elevation={2}>
                                            <CardContent>

                                                <div className='text-right'>

                                                    <Tooltip title={'Delete Address'} placement='top-end'
                                                        onClick={() => this.deleteHandler(addr._id)}
                                                    >
                                                        <IconButton>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title={'Edit Address'} placement='top-end'
                                                        onClick={() => this.editHandler(addr._id)}
                                                    >
                                                        <IconButton>
                                                            <Icon>edit</Icon>
                                                        </IconButton>
                                                    </Tooltip>

                                                </div>

                                                <div>
                                                    <h5>{addr.name}</h5>
                                                    <Divider />

                                                    <p>{addr.recipientAddress}</p>
                                                    <p>{addr.recipientCity}</p>
                                                    <Divider />

                                                    <p>Mobile No: {addr.phone}</p>
                                                    <Divider />

                                                    <p>Email: {addr.email}</p>
                                                </div>

                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })
                        }

                    </Grid>

                </div>

            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        address: state.profile.address
    };
};

const mapDispatchToProps = (dispatch, router) => {
    return {
        setLastAddress: (data) => dispatch(setLastAddress(data, router)),
        deleteAdress: (data) => dispatch(deleteAdress(data, router)),
        getAllAdress: (data) => dispatch(getAllAdress(data, router))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddressBook)
);
