import { defaultCurrency } from "../../constants/strings";
const token = localStorage.getItem("token");
const currentUser = localStorage.getItem("currentUser");
const initialState = {
  user: JSON.parse(currentUser),
  token: token,
  currency: defaultCurrency,
  route: "",
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_USER":
      var payload = action.payload;
      var token = payload.token;
      localStorage.setItem("currentUser", JSON.stringify(payload));
      localStorage.setItem("token", token);
      var data = {
        ...state,
        user: payload,
        token: token,
        // currency: payload?.currency ? payload?.currency : defaultCurrency,
      };
      return data;
    case "UPDATE_CURRENCY":
      var cur = { ...state, currency: action.payload };
      return cur;
    case "STORE_ROUTE":
      return { ...state, route: action.payload };

    case "LOGOUT":
      localStorage.clear();
      var final = {
        ...state,
        user: {},
        token: null,
        currency: defaultCurrency,
      };
      return final;
    default:
      return state;
  }
};
export default authReducer;
